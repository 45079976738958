import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { USER_TYPE } from "../../utils/Constants";
import profile from "../../assets/Images/user.png";

const initialState = {
  currentLang: "en",
  currentUser: {},
  myProfile: {},
  isLoading: false,
  userToken: null,
  socialUserData: {},
  signupFormData: {},
  updateUser: {},
  nextStep: 0,
  previousStep: false,
  userType: USER_TYPE.CUSTOMER,
  stripeCustomerId: "",
  success: false,
  error: null,
  profileImage: profile,
};

// Thunk for customer login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for customer SignUp
export const userSignup = createAsyncThunk(
  "user/userSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.signUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for driver vehicle list Info
export const vehicleListInfo = createAsyncThunk(
  "user/vehicleListInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.vehicleDataList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for master list api
export const masterList = createAsyncThunk(
  "user/masterList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.masterDataList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for driver SignUp
export const driverSignup = createAsyncThunk(
  "user/driverSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.driverSignUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for driver login
export const driverLogin = createAsyncThunk(
  "user/driverLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.driverLogin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get driver document
export const driverDocument = createAsyncThunk(
  "user/driverDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getDriverDocument(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for update driver document
export const updateDriverDocument = createAsyncThunk(
  "user/updateDriverDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.updateDriverDocument(
        data.requestData,
        data.userToken
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get driver details
export const driverDetails = createAsyncThunk(
  "user/driverDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getDriverDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for update driver details
export const updateDriverDetails = createAsyncThunk(
  "user/updateDriverDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.driverDetailsUpdate(
        data.requestData,
        data.userToken
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get user details
export const userDetails = createAsyncThunk(
  "user/userDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getUserDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for update user details
export const updateUserDetails = createAsyncThunk(
  "user/updateUserDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userDetailsUpdate(
        data.requestData,
        data.userToken
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get customer notification list
export const getNotificationList = createAsyncThunk(
  "user/getNotificationList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getNotification(
        data.userToken,
        data.page_no
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get driver notification list
export const getDriverNotificationList = createAsyncThunk(
  "user/getDriverNotificationList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getDriverNotification(
        data.userToken,
        data.page_no
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userToken = null;
      state.updateUser = {};
      state.success = false;
      state.socialUserData = {};
      state.userType = USER_TYPE.CUSTOMER;
    },

    setSocialUserData: (state, action) => {
      state.socialUserData = action.payload;
    },
    setCurrentLang: (state, action) => {
      state.currentLang = action.payload;
    },
    setNextStep: (state, action) => {
      state.nextStep = action.payload;
    },
    setPreviousStep: (state, action) => {
      state.previousStep = action.payload;
    },
    setSignupFormData: (state, action) => {
      const { payload } = action;
      state.signupFormData = { ...state.signupFormData, ...payload };
    },
    clearForm: (state) => {
      state.signupFormData = {};
    },
    resetNextStep: (state) => {
      state.nextStep = 0;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for customer login-------
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;

      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response && response.data && response.data.token;
        state.currentUser = response && response.data;
        state.userType = response && response.data && response.data.user_role;
        state.stripeCustomerId =
          response && response.data && response.data.stripe_customer_id;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for customer signup-------
    builder.addCase(userSignup.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userSignup.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;

      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response && response.data && response.data.token;
        state.currentUser = response && response.data;
        state.userType = response && response.data && response.data.user_role;
        state.stripeCustomerId =
          response && response.data && response.data.stripe_customer_id;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(userSignup.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for  driver vehicle list Info -------
    builder.addCase(vehicleListInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(vehicleListInfo.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });
    builder.addCase(vehicleListInfo.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for master list -------
    builder.addCase(masterList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(masterList.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });
    builder.addCase(masterList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for driver signup-------
    builder.addCase(driverSignup.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(driverSignup.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;

      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response && response.data && response.data.token;
        state.currentUser = response && response.data;
        state.userType = response && response.data && response.data.user_role;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(driverSignup.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for driver login-------
    builder.addCase(driverLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(driverLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;

      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response && response.data && response.data.token;
        state.currentUser = response && response.data;
        state.userType = response && response.data && response.data.user_role;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(driverLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for driver document list -------
    builder.addCase(driverDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(driverDocument.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });
    builder.addCase(driverDocument.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for update document list -------
    builder.addCase(updateDriverDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateDriverDocument.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateDriverDocument.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for get driver details-------
    builder.addCase(driverDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(driverDetails.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.updateUser = response && response.data;
        state.success = true;
      } else {
        state.updateUser = {};
        state.success = false;
      }
    });
    builder.addCase(driverDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for update driver details-------
    builder.addCase(updateDriverDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateDriverDetails.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.updateUser = response && response.data[0];
        state.success = true;
      } else {
        state.updateUser = {};
        state.success = false;
      }
    });
    builder.addCase(updateDriverDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for get user details-------
    builder.addCase(userDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.profileImage =
        response.data.profile_image !== null
          ? response.data.profile_image
          : profile;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.success = true;
      }
    });
    builder.addCase(userDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for update user details-------
    builder.addCase(updateUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  userLogoutClear,
  setSocialUserData,
  setCurrentLang,
  setNextStep,
  setPreviousStep,
  setSignupFormData,
  clearForm,
  resetNextStep,
} = userSlice.actions;
export default userSlice.reducer;
