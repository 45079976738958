import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";

const initialState = {
  activeTab: "first",
  activeCategory: "",
  isLoading: false,
  categoryList: [],
  floorOptions: [],
  helpersList: [],
  unitTypesList: [],
  vehicleInfoList: [],
  selectedCoupon: "",
  createJobData: {
    first: {},
    second: {},
    third: {},
    fourth: {},
    fifth: {},
  },
  isRedirect: false,
  isBackToFifth: false,
};

// Thunk for get categoryList
export const categoryList = createAsyncThunk(
  "job/categoryList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.fetchCategoryList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get floorOptions
export const floorOptions = createAsyncThunk(
  "job/floorList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.fetchFloorOptions();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetchMasterDataList
export const fetchMasterDataList = createAsyncThunk(
  "job/fetchMasterDataList",
  async (refKey, { rejectWithValue }) => {
    try {
      const response = await SublyApi.fetchMasterDataList(refKey);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get vehicleList
export const vehicleInfoList = createAsyncThunk(
  "job/vehicleList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.fetchVehicleInfoList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get vehicleList
export const getHelpersList = createAsyncThunk(
  "job/helpersList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.fetchHelpersList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get unitLists
export const getUnitTypeList = createAsyncThunk(
  "job/unitList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.fetchUnitTypes();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get getDriversList
export const getDriversLists = createAsyncThunk(
  "job/driversLists",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getDriversList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for acceptJob
export const acceptJob = createAsyncThunk(
  "job/acceptJob",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.acceptJob(
        data.requestData,
        data.userToken
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createJobSlice = createSlice({
  name: "createJob",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    updateJobData: (state, action) => {
      const { step, data } = action.payload;
      state.createJobData[step] = data;
    },
    updateSelectedCoupon: (state, action) => {
      state.selectedCoupon = action.payload;
    },
    resetAllJobData: (state, action) => {
      const resetAll = action.payload !== undefined ? action.payload : true;
      return {
        ...state,
        createJobData: {
          ...(resetAll ? { first: {} } : state.createJobData),
          second: {},
          third: {},
          fourth: {},
          fifth: {},
        },
        selectedCoupon: "",
        isRedirect: false, // setting false when all job data reset
      };
    },
    updateIsRedirect: (state, action) => {
      state.isRedirect = action.payload;
    },
    updateIsBackToFifth: (state, action) => {
      state.isBackToFifth = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for categoryList-------
    builder.addCase(categoryList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(categoryList.fulfilled, (state, action) => {
      state.categoryList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(categoryList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for floorOptions-------
    builder.addCase(floorOptions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(floorOptions.fulfilled, (state, action) => {
      state.floorOptions = action.payload?.data.floors;
      state.isLoading = false;
    });

    builder.addCase(floorOptions.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for fetchMasterDataList-------
    builder.addCase(fetchMasterDataList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMasterDataList.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(fetchMasterDataList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for vehicleInfoList-------
    builder.addCase(vehicleInfoList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(vehicleInfoList.fulfilled, (state, action) => {
      state.vehicleInfoList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(vehicleInfoList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getHelpersList-------
    builder.addCase(getHelpersList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getHelpersList.fulfilled, (state, action) => {
      state.helpersList = action.payload?.data.helpers;
      state.isLoading = false;
    });

    builder.addCase(getHelpersList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getUnitTypeList-------
    builder.addCase(getUnitTypeList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getUnitTypeList.fulfilled, (state, action) => {
      state.unitTypesList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getUnitTypeList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getDriversLists-------
    builder.addCase(getDriversLists.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getDriversLists.fulfilled, (state, action) => {
      // state.unitTypesList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getDriversLists.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for acceptJob-------
    builder.addCase(acceptJob.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(acceptJob.fulfilled, (state, action) => {
      // state.unitTypesList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(acceptJob.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setActiveTab,
  setActiveCategory,
  updateJobData,
  updateSelectedCoupon,
  resetAllJobData,
  updateIsRedirect,
  updateIsBackToFifth,
} = createJobSlice.actions;
export default createJobSlice.reducer;
