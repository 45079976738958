import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../../store/slices/createJob";
import { useTranslation } from "react-i18next";

export default function SelectOption({ showB2bOptions, setShowB2bOptions }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { first } = useSelector((state) => state.createJob.createJobData);
  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
  });
  const [showErr, setshowErr] = useState(false);

  const options = [
    {
      id: 1,
      val: "Job Creation",
    },
    {
      id: 2,
      val: "Enquiry",
    },
  ];

  useEffect(() => {
    if (first.isSubmit) {
      //   setCurrentValue(options[0]);
    } else {
      setCurrentValue(showB2bOptions.value);
    }
  }, [showB2bOptions.show]);

  const handleCheckboxChange = (id, value, time) => {
    setCurrentValue({ val: value, id: id });
  };

  const handleSubmit = () => {
    if (currentValue.id == "") {
      setshowErr(true);
      return;
    }

    setShowB2bOptions({ show: false, value: currentValue });
    if (currentValue.val == "Enquiry") {
      dispatch(setActiveTab("b2bEnquiry"));
    }
  };

  return (
    <>
      <Modal
        show={showB2bOptions.show}
        onHide={() => {
          setShowB2bOptions((prevState) => ({ ...prevState, show: false }));
          setshowErr(false);
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>Choose Option</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                setShowB2bOptions((prevState) => ({
                  ...prevState,
                  show: false,
                }));
                setshowErr(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {options?.map((checkbox) => (
              <div
                key={checkbox.id}
                className="floorCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(checkbox.id, checkbox.val);
                }}
              >
                <label
                  className={
                    currentValue?.val === checkbox.val ? styles.activeFloor : ""
                  }
                >
                  {checkbox.val}
                </label>
                {/* <Button
                  variant={
                    currentValue?.val === checkbox.val ? "primary" : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(checkbox.id, checkbox.val);
                  }}
                >
                  {currentValue?.val === checkbox.val
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
            {showErr && <p className="errorMsg">{t("PLEASE_CHOOSE_OPTION")}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              Done
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
