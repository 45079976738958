import Modal from "react-bootstrap/Modal";
import styles from "../DriverSignUp/DriverSignUp.module.css";
import { MdCancel } from "react-icons/md";
import "../../../assets/Styles/DriverCommon.css";
import { useEffect, useState } from "react";
import { vehicleListInfo } from "../../../store/slices/UserSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../../utils/StatusCode";

//---------function for vehicle type popup modal---------
function VehicleTypeModal({
  vehicleTypeModal,
  setVehicleTypeModal,
  selectVehicle,
  setSelectVehicle,
  clearErrors,
  setValue,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vehicleList, setVehicleList] = useState("");

  // ----------- function for manage vehicle select onChange----------------
  const handleVehicleChange = (vehicle) => {
    setSelectVehicle({
      id: vehicle.id,
      vehicle_name: vehicle.vehicle_name,
    });
    setValue("vanType", vehicle.vehicle_name);
    clearErrors("vanType");
  };

  // --------function for driver vehicle list Info api call--------
  useEffect(() => {
    dispatch(vehicleListInfo()).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setVehicleList(response && response.data);
      }
    });
  }, []);

  // --------function for vehicle select--------
  const handleVehicle = () => {
    setVehicleTypeModal(false);
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={vehicleTypeModal}
        onHide={() => {
          setVehicleTypeModal(false);
        }}
        className="colorModal"
        backdrop="static"
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            {/* <h4>{t("NEW_DRIVER_SELECT_VAN")}</h4> */}
            <h4>{t("VEHICLE_SELECT")}</h4>
            <MdCancel
              onClick={() => {
                setVehicleTypeModal(false);
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <>
            {vehicleList &&
              vehicleList.length > 0 &&
              vehicleList.map((vehicle, index) => (
                <div className="vehicleInput" key={index}>
                  <input
                    type="radio"
                    id={vehicle.id}
                    name="select"
                    value={vehicle.vehicle_name}
                    checked={selectVehicle && selectVehicle.id === vehicle.id}
                    onChange={() => handleVehicleChange(vehicle)}
                  />
                  <label
                    htmlFor={vehicle.id}
                    className={styles.vehicleInputDisplay}
                  >
                    <div className={styles.vehicleInputText}>
                      <h2>{vehicle.vehicle_name}</h2>
                      <p>{vehicle.vehicle_description}</p>
                    </div>
                    <img src={vehicle.vehicle_image} alt="vehicle-img" />
                  </label>
                </div>
              ))}
            <div className="commonButton">
              <button
                type="submit"
                onClick={() => {
                  handleVehicle();
                }}
              >
                {t("DONE")}
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VehicleTypeModal;
