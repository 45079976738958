import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "./DriverJobDetails.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import chatIcon from "../../../assets/DriverImages/chatIcon.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FcNext, FcPrevious } from "react-icons/fc";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import userImg from "../../../assets/Images/user.png";
import Loader from "../../../utils/Loader/Loader";
import MapView from "../../../appComponents/Jobs/MapView";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import DocumentAlert from "../../authComponent/DriverSignUp/DocumentAlert";
import { Rating } from "@mui/material";
import { PAYMNET_STATUS } from "../../../utils/Constants";
import { removeExtraPlusSigns } from "../../../utils/Loader/Helper/helper";

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <FcPrevious
        className={`${styles.PrevSlickArrow} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <>
      <FcNext
        className={`${styles.NextSlickArrow} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}
function PrevArrowCustom(props) {
  const { onClick } = props;
  return (
    <>
      <FcPrevious
        className={`${styles.PrevSlickArrowCustom} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function NextArrowCustom(props) {
  const { onClick } = props;
  return (
    <>
      <FcNext
        className={`${styles.NextSlickArrowCustom} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function DriverJobDetails() {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrowCustom />,
    prevArrow: <PrevArrowCustom />,
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { jobId } = useParams();
  const { userToken } = useSelector((state) => state.user);
  const [jobInfo, setJobInfo] = useState();

  const [adminItems, setAdminItems] = useState([]);
  const [ownItems, setOwnItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedInfo, setSelectedInfo] = useState("");
  const [driverAmount, setDriverAmount] = useState();

  const getJobDetails = () => {
    setIsLoading(true);
    const res = SublyApi.getDriversJobDetails(jobId, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setJobInfo(response.data);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getJobDetails();
  }, [jobId]);

  useEffect(() => {
    const adminItems = jobInfo?.items.filter((el) => el.is_custom_item == 0);
    setAdminItems(adminItems);
    const ownItems = jobInfo?.items.filter((el) => el.is_custom_item == 1);
    setOwnItems(ownItems);
  }, [jobInfo]);

  useEffect(() => {
    if (jobInfo) {
      if (jobInfo?.payment_summary[0]?.admin_amount) {
        setDriverAmount(jobInfo?.payment_summary[0]?.admin_amount);
      } else {
        setDriverAmount(jobInfo?.payment_summary[0]?.amount);
      }
    }
  }, [jobInfo]);

  const onBack = () => {
    if (location?.state?.route == "jobHistory") {
      navigate("/driver-job-history");
    } else {
      navigate("/driver-home");
    }
  };

  function convertMinutesToHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    return formattedTime.trim(); // Remove trailing space if no minutes
  }

  const handleOpenAlert = (type) => {
    setAlertModal(true);
    setSelectedInfo(type);
    if (type == "reject_driver") {
      setTitle(t("REJECT_THIS_JOB"));
    } else {
      setTitle(t("ACCEPT_THIS_JOB"));
    }
  };

  const handleChangeStatus = () => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("request_status", selectedInfo);

    let res = SublyApi.changeRequestJobStatus(jobId, userToken, requestData);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster("Status changed successfully.", "success");
        navigate("/driver-home");
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  function calculatePercentageAmount(jobInfo) {
    var amount;
    if (jobInfo.payment_summary[0].admin_amount) {
      amount = jobInfo.payment_summary[0].admin_amount;
    } else {
      amount = jobInfo.payment_summary[0].amount;
    }
    return (
      (amount * jobInfo.payment_summary[0].admin_commission) /
      100
    ).toFixed(2);
  }

  function calculateTotalAmount(jobInfo) {
    var amount;
    if (jobInfo.payment_summary[0].admin_amount) {
      amount = jobInfo.payment_summary[0].admin_amount;
    } else {
      amount = jobInfo.payment_summary[0].amount;
    }
    var percentageAmount = (
      (amount * jobInfo.payment_summary[0].admin_commission) /
      100
    ).toFixed(2);
    return (amount - percentageAmount).toFixed(2);
  }

  const renderStatusMsg = (type) => {
    if (type == null) {
      return t("PYMT_PENDING_FROM_ADMIN");
    }
    if (type == PAYMNET_STATUS.REQUEST_PAYMENT) {
      return t("PYMT_PENDING_FROM_ADMIN");
    }
    if (type == PAYMNET_STATUS.PAYMENT_RELEASE) {
      return t("PYMT_PENDING_FROM_ADMIN");
    }
    if (type == PAYMNET_STATUS.RELEASE_PAYMENT) {
      return t("COMPLETED");
    }
  };

  const viewFullImage = (url) => {
    // Create a new window
    const newWindow = window.open("", "_blank");

    if (newWindow) {
      // Write HTML to the new window
      newWindow.document.write(`
        <html>
          <head><title>Image</title></head>
            <body style="margin: 0; padding: 0; overflow: hidden;">
            <img src="${url}" style="display: block; width: 100vw; height: 100vh; object-fit: contain;">
          </body>
        </html>
      `);
      newWindow.document.close(); // Close the document to trigger rendering
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main jobDetails">
        <div className="spaceTopManage">
          <div className={styles.topHeadSummmary}>
            <div className={styles.topHeadBackBtn}>
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div>
              <span className={styles.topTitle}>{t("JOB_DETAILS")}</span>
            </div>
            <div></div>
          </div>
          {jobInfo && (
            <div className={styles.mainContainerWrapper}>
              <div className={styles.mainContainer}>
                <Row>
                  <Col lg={5}>
                    <div className={styles.subContainer}>
                      <div>{<MapView jobInfo={jobInfo} />}</div>
                      <div className={styles.titleName}>{t("ADDRESS")}</div>
                      <div className={styles.addressRouteWrapper}>
                        <div className={styles.routeLinePath}>
                          <div>
                            <Icon
                              icon="fluent:location-arrow-up-16-regular"
                              height="22"
                              color="#828282"
                            />
                          </div>
                          <div className={styles.leftRouteLine}></div>
                          <div>
                            <Icon
                              icon="fluent:location-arrow-up-16-regular"
                              vFlip
                              height="22"
                              color="#828282"
                            />
                          </div>
                        </div>
                        <div className={styles.subLocationContainer}>
                          <div className={styles.subTitleName}>
                            {t("COLLECT_ADDRESS")}
                          </div>
                          <div className={styles.titleDescription}>
                            {jobInfo.source_location}
                          </div>
                          <div className={styles.floorSection}>
                            {jobInfo.category_name == "Home Removal" && (
                              <div>
                                <span className={styles.floorSectionTitle}>
                                  {t("Property")} :{" "}
                                </span>
                                <span className={styles.floorValue}>
                                  {jobInfo.source_property_type}
                                </span>
                              </div>
                            )}
                            {jobInfo.source_floor && (
                              <div>
                                <span className={styles.floorSectionTitle}>
                                  {t("FLOOR")} :{" "}
                                </span>
                                <span className={styles.floorValue}>
                                  {jobInfo.source_floor}
                                </span>
                              </div>
                            )}
                            {jobInfo.source_floor && (
                              <div>
                                <span className={styles.floorSectionTitle}>
                                  {t("LIFT_AVAILABLE")} :
                                </span>
                                {jobInfo.is_source_lift ? (
                                  <span className={styles.liftCheckYes}>
                                    {" "}
                                    {t("YES")}
                                  </span>
                                ) : (
                                  <span className={styles.liftCheckNo}>
                                    {" "}
                                    {t("NO")}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                          <div className={styles.hrLineDraw}></div>
                          {jobInfo?.stops?.length !== 0 &&
                            jobInfo?.stops?.map((stop, ind) => {
                              return (
                                <>
                                  <div className={styles.stopTitle}>
                                    {t("VIA")}
                                    {jobInfo.stops.length > 1 ? ind + 1 : ""}
                                  </div>
                                  <div className={styles.titleDescription}>
                                    {stop.location}
                                  </div>
                                  <div className={styles.hrLineStops}></div>
                                </>
                              );
                            })}
                          <div className={styles.subTitleName}>
                            {t("DELIVER_ADDRESS")}
                          </div>
                          <div className={styles.titleDescription}>
                            {jobInfo.destination_location}
                          </div>
                          <div className={styles.floorSection}>
                            {jobInfo.category_name == "Home Removal" && (
                              <div>
                                <span className={styles.floorSectionTitle}>
                                  {t("Property")} :{" "}
                                </span>
                                <span className={styles.floorValue}>
                                  {jobInfo.destination_property_type}
                                </span>
                              </div>
                            )}
                            {jobInfo.destination_floor && (
                              <div>
                                <span className={styles.floorSectionTitle}>
                                  {t("FLOOR")} :{" "}
                                </span>
                                <span className={styles.floorValue}>
                                  {jobInfo.destination_floor}
                                </span>
                              </div>
                            )}
                            {jobInfo.destination_floor && (
                              <div>
                                <span className={styles.floorSectionTitle}>
                                  {t("LIFT_AVAILABLE")} :
                                </span>
                                {jobInfo.is_destination_lift ? (
                                  <span className={styles.liftCheckYes}>
                                    {" "}
                                    {t("YES")}
                                  </span>
                                ) : (
                                  <span className={styles.liftCheckNo}>
                                    {" "}
                                    {t("NO")}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {jobInfo.new_location && (
                        <>
                          <div className={styles.hrLineDraw}></div>
                          <div className={styles.newLocationSection}>
                            {t("NEW_LOCATION")} :&nbsp;
                            <span>{jobInfo.new_location}</span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.driverInfo}>
                      {t("CUSTOMER_INFO")}
                    </div>
                    <div className={styles.mainDriverProfile}>
                      <div>
                        <div className={styles.driverProfile}>
                          <div className={styles.driverImage}>
                            <img
                              src={
                                jobInfo.customer_info?.profile_image
                                  ? jobInfo.customer_info?.profile_image
                                  : userImg
                              }
                              alt="star-icon"
                            />
                          </div>
                          <div>
                            <div className={styles.driverName}>
                              {jobInfo.customer_info?.first_name}{" "}
                              {jobInfo.customer_info?.last_name}
                            </div>
                            <div className={styles.driverEmail}>
                              {jobInfo.customer_info?.email}
                            </div>
                            <div className={styles.driverEmail}>
                              {jobInfo.customer_info?.dial_code &&
                              jobInfo.customer_info?.phone_number
                                ? removeExtraPlusSigns(
                                    `+${jobInfo.customer_info.dial_code} ${jobInfo.customer_info.phone_number}`
                                  )
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className={styles.driverAmount}>
                        {jobInfo.payment_summary[0].currency_symbol}
                        {jobInfo.payment_summary[0].sub_amount}
                      </div> */}
                    </div>
                  </Col>
                  <Col lg={7}>
                    {adminItems?.length > 1 && (
                      <Slider {...settings}>
                        {adminItems.map((items, ind, arr) => {
                          return (
                            <div className={styles.furnitureContainerSlider}>
                              <div className={styles.itemsSection}>
                                <div className={styles.titleName}>
                                  {jobInfo.category_name}
                                </div>
                                <div>
                                  <span>{ind + 1}</span>
                                  <span className={styles.tabCount}>
                                    /{arr.length}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.itemsSection}>
                                <div className={styles.furnitureName}>
                                  {items.subcategory}
                                </div>
                                <div className={styles.rightQuantity}>
                                  <span className={styles.tabCount}>
                                    {t("QUANTITY")} :{" "}
                                  </span>
                                  {items.quantity}
                                </div>
                              </div>
                              <div className={styles.subTitleName}>
                                {items.item}
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    )}
                    {adminItems?.length == 1 &&
                      adminItems.map((items, ind, arr) => {
                        return (
                          <div
                            className={styles.singleFurnitureContainerSlider}
                          >
                            <div className={styles.itemsSection}>
                              <div className={styles.titleName}>
                                {jobInfo.category_name}
                              </div>
                              <div>
                                <span>{ind + 1}</span>
                                <span className={styles.tabCount}>
                                  /{arr.length}
                                </span>
                              </div>
                            </div>
                            <div className={styles.itemsSection}>
                              <div className={styles.furnitureName}>
                                {items.subcategory}
                              </div>
                              <div>
                                <span className={styles.tabCount}>
                                  {t("QUANTITY")} :{" "}
                                </span>
                                {items.quantity}
                              </div>
                            </div>
                            <div className={styles.subTitleName}>
                              {items.item}
                            </div>
                          </div>
                        );
                      })}
                    {ownItems?.length !== 0 && ownItems && (
                      <div className={styles.ownTitleHeading}>Own Item</div>
                    )}
                    {ownItems?.length == 1 &&
                      ownItems.map((items, ind, arr) => {
                        return (
                          <>
                            <div
                              className={styles.singleFurnitureContainerSlider}
                            >
                              <div className={styles.itemsSection}>
                                <div className={styles.titleName}>
                                  {items.item}
                                </div>
                                <div>
                                  <span>{ind + 1}</span>
                                  <span className={styles.tabCount}>
                                    /{arr.length}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.ownQuantity}>
                                <span className={styles.tabCount}>
                                  {t("QUANTITY")} :{" "}
                                </span>
                                {items.quantity}
                              </div>
                              <hr />
                              <div className={styles.titleNameDimension}>
                                {t("ESTIMATED_DIMENSIONS")}
                              </div>
                              {/* <div className={styles.itemsSection}>
                                <div className={styles.itemsSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("WIDTH")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.width
                                        ? items.width +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.verticalDivider}></div>
                                <div className={styles.itemsSubSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("HEIGHT")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.height
                                        ? items.height +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.verticalDivider}></div>
                                <div className={styles.itemsSubSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("DEPTH")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.depth
                                        ? items.depth +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.verticalDivider}></div>
                                <div className={styles.itemsSubSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("WEIGHT")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.estimated_weight
                                        ? items.estimated_weight +
                                          " " +
                                          items.weight_unit
                                        : "NA"}
                                    </span>
                                  </div>
                                </div>
                              </div> */}
                              <div className={styles.topDimensionWrapper}>
                                <div className={styles.innerSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("WIDTH")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.width
                                        ? items.width +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("DEPTH")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.depth
                                        ? items.depth +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.innerSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("HEIGHT")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.height
                                        ? items.height +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("WEIGHT")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.estimated_weight
                                        ? items.estimated_weight +
                                          " " +
                                          items.weight_unit
                                        : "NA"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    {ownItems?.length > 1 && (
                      <Slider {...settings2}>
                        {ownItems?.map((items, ind, arr) => {
                          return (
                            <>
                              <div className={styles.furnitureContainerSlider}>
                                <div className={styles.itemsSection}>
                                  <div className={styles.titleName}>
                                    {items.item}
                                  </div>
                                  <div>
                                    <span>{ind + 1}</span>
                                    <span className={styles.tabCount}>
                                      /{arr.length}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.ownQuantity}>
                                  <span className={styles.tabCount}>
                                    {t("QUANTITY")} :{" "}
                                  </span>
                                  {items.quantity}
                                </div>
                                <hr />
                                <div className={styles.titleName}>
                                  {t("ESTIMATED_DIMENSIONS")}
                                </div>
                                {/* <div className={styles.itemsSection}>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("WIDTH")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.width
                                        ? items.width +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("HEIGHT")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.height
                                        ? items.height +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("DEPTH")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.depth
                                        ? items.depth +
                                          " " +
                                          items.dimension_units
                                        : "NA"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={styles.dimensionType}>
                                      {t("WEIGHT")} :{" "}
                                    </span>
                                    <span className={styles.dimensionValue}>
                                      {items.estimated_weight
                                        ? items.estimated_weight +
                                          " " +
                                          items.weight_unit
                                        : "NA"}
                                    </span>
                                  </div>
                                </div> */}
                                <div className={styles.topDimensionWrapper}>
                                  <div className={styles.innerSection}>
                                    <div>
                                      <span className={styles.dimensionType}>
                                        {t("WIDTH")} :{" "}
                                      </span>
                                      <span className={styles.dimensionValue}>
                                        {items.width
                                          ? items.width +
                                            " " +
                                            items.dimension_units
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div>
                                      <span className={styles.dimensionType}>
                                        {t("DEPTH")} :{" "}
                                      </span>
                                      <span className={styles.dimensionValue}>
                                        {items.depth
                                          ? items.depth +
                                            " " +
                                            items.dimension_units
                                          : "NA"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.innerSection}>
                                    <div>
                                      <span className={styles.dimensionType}>
                                        {t("HEIGHT")} :{" "}
                                      </span>
                                      <span className={styles.dimensionValue}>
                                        {items.height
                                          ? items.height +
                                            " " +
                                            items.dimension_units
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div>
                                      <span className={styles.dimensionType}>
                                        {t("WEIGHT")} :{" "}
                                      </span>
                                      <span className={styles.dimensionValue}>
                                        {items.estimated_weight
                                          ? items.estimated_weight +
                                            " " +
                                            items.weight_unit
                                          : "NA"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </Slider>
                    )}

                    <div className={styles.titleHeading}>
                      {t("VEHICLE_INFO")}
                    </div>
                    <div className={styles.vehicleContainer}>
                      <div className={styles.vehicleType}>
                        <div>
                          <div className={styles.subTitleName}>
                            {jobInfo.customer_vehicle?.vehicle_name}
                          </div>
                          <div className={styles.titleDescription}>
                            {/* {jobInfo.driver_vehicle?.vehicle_description} */}
                          </div>
                        </div>
                        <div>
                          <img
                            src={jobInfo.customer_vehicle?.vehicle_image}
                            alt="not found"
                            className={styles.vehicleImage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.titleHeading}>
                      {t("HELPER_AVAILABILITY")}
                    </div>
                    <div className={styles.furnitureContainer}>
                      <div className={styles.vehicleType}>
                        <div>
                          <div className={styles.subTitleName}>
                            {t("HELPER")}
                          </div>
                        </div>
                        <div className={styles.isHelper}>
                          <div className={styles.availabilityDay}>
                            {jobInfo.helper}
                          </div>
                          <div className={styles.availabilityDayd}>
                            {t("FLOOR")} :{" "}
                            <span>
                              {jobInfo.floors <= 8
                                ? jobInfo.floors
                                : t("ABOVE_8TH_FLOOR")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.availabilityContainer}>
                      <div className={styles.vehicleType}>
                        <div>
                          <div className={styles.subTitleName}>
                            {t("TIME_OF_JOB")}
                          </div>
                        </div>
                        <div className={styles.availabilityDay}>
                          {dayjs(jobInfo.availability_date).format(
                            "DD MMM YYYY"
                          )}
                          ,{" "}
                          {dayjs(jobInfo.availability_time, "HH:mm:ss").format(
                            "h:mmA"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.estimatedTime}>
                      <div>
                        {t("ESTIMATED_TIME_JOB")} -{" "}
                        <span>
                          {convertMinutesToHoursAndMinutes(
                            jobInfo.estimated_time
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={styles.amountWrapper}>
                      {/* <div className={styles.amountInfo}>
                        <div>{t("AMOUNT")}</div>
                        <div>
                          {jobInfo.payment_summary[0].currency_symbol}
                          {jobInfo.payment_summary[0].amount}
                        </div>
                      </div> */}
                      {/* <hr /> */}
                      {/* {jobInfo.payment_summary[0].admin_amount && (
                        <>
                          <div className={styles.amountInfo}>
                            <div>{t("ADMIN_GIVEN_PRICE")}</div>
                            <div>
                              {jobInfo.payment_summary[0].currency_symbol}
                              {jobInfo.payment_summary[0].admin_amount}
                            </div>
                          </div>
                          <hr />
                        </>
                      )} */}
                      {/* <div className={styles.amountInfo}>
                        <div>
                          {t("ADMIN_COMMISSION")}(
                          {jobInfo.payment_summary[0].admin_commission
                            ? jobInfo.payment_summary[0].admin_commission
                            : 0}
                          %)
                        </div>
                        <div className={styles.discountPrice}>
                          - {jobInfo.payment_summary[0].currency_symbol}
                          {calculatePercentageAmount(jobInfo)}
                        </div>
                      </div>
                      <hr /> */}
                      <div className={styles.amountInfoTotal}>
                        <div>{t("TOTAL_AMOUNT")}</div>
                        {/* <div>
                          {jobInfo.payment_summary[0].currency_symbol}
                          {calculateTotalAmount(jobInfo)}
                        </div> */}
                        <div>
                          {jobInfo.payment_summary[0].currency_symbol}
                          {driverAmount}
                        </div>
                      </div>
                    </div>

                    {jobInfo.pickup_image &&
                      jobInfo.pickup_image.lenght !== 0 && (
                        <>
                          <div className={styles.pickUpTitle}>
                            {t("PICKUP_IMAGE")}
                          </div>
                          <div className={styles.imageWrapper}>
                            {jobInfo.pickup_image.map((el) => {
                              return (
                                <div className="mb-3">
                                  <img
                                    src={el}
                                    className={styles.pickUpImage}
                                    onClick={() => viewFullImage(el)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    {jobInfo.stop_images &&
                      jobInfo.stop_images.lenght !== 0 && (
                        <>
                          <Row className={styles.imageWrapper}>
                            {jobInfo.stop_images.map((via, index) => {
                              return (
                                <>
                                  <div className={styles.pickUpTitle}>
                                    {`${t("VIA")}-${index + 1}`}
                                  </div>
                                  <div className={styles.imageWrapper}>
                                    {via.image.map((val) => {
                                      return (
                                        <div className="mb-3">
                                          <img
                                            src={val}
                                            className={styles.pickUpImage}
                                            onClick={() => viewFullImage(val)}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              );
                            })}
                          </Row>
                        </>
                      )}
                    {jobInfo.delivered_image &&
                      jobInfo.delivered_image.lenght !== 0 && (
                        <>
                          <div className={styles.pickUpTitle}>
                            {t("DELIVERY_IMAGE")}
                          </div>
                          <div className={styles.imageWrapper}>
                            {jobInfo.delivered_image.map((val) => {
                              return (
                                <div className="mb-3">
                                  <img
                                    src={val}
                                    className={styles.pickUpImage}
                                    onClick={() => viewFullImage(val)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    {jobInfo.job_status == "pending" && (
                      <div className={styles.acceptButtons}>
                        <div
                          className={styles.rejectBtn}
                          onClick={() => handleOpenAlert("reject_driver")}
                        >
                          <div>
                            <MdCancel color="#EC3B1E" />
                          </div>
                          <div>{t("REJECT")}</div>
                        </div>
                        <div
                          className={styles.acceptBtn}
                          onClick={() => handleOpenAlert("accept")}
                        >
                          <div>
                            <FaCheckCircle color="#058321" />
                          </div>
                          <div>{t("ACCEPT_TEXT")}</div>
                        </div>
                      </div>
                    )}
                    {jobInfo.job_status == "complete" && (
                      <>
                        {jobInfo.rating_review &&
                          jobInfo?.rating_review?.review &&
                          jobInfo?.rating_review?.review?.lenght !== 0 && (
                            <div className={styles.reviewSection}>
                              <div className={styles.reviewSectionSub}>
                                <div>
                                  <Rating
                                    name="reviewRating"
                                    value={jobInfo.rating_review.rating}
                                    precision={0.5}
                                    readOnly
                                  />
                                </div>
                                <div className={styles.ratingCount}>
                                  {jobInfo.rating_review.rating}
                                </div>
                              </div>
                              <div className={styles.reviewText}>
                                {jobInfo?.rating_review?.review}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {jobInfo.job_status == "complete" && (
                      <>
                        <div className={styles.pendingStatus}>
                          {renderStatusMsg(jobInfo.payment_release)}
                        </div>
                      </>
                    )}
                    {jobInfo.job_status == "cancel_customer" && (
                      <>
                        <div className={styles.pendingStatus}>
                          {t("CANCEL_JOB_CUSTOMER")}
                        </div>
                      </>
                    )}
                    {jobInfo.job_status == "cancel_by_admin" && (
                      <>
                        <div className={styles.pendingStatus}>
                          {t("CANCEL_JOB_ADMIN")}
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                {/* <div className={styles.chatFeature}>
                  <img src={chatIcon} alt="chat-image" />
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* alert modal popup for accept/reject job */}
      <DocumentAlert
        setShowAlertModal={setAlertModal}
        showAlertModal={alertModal}
        onImageRemove={handleChangeStatus}
        isDelete={true}
        title={title}
      />
    </>
  );
}
export default DriverJobDetails;
