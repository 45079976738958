import React, { useEffect, useState } from "react";
import "../../assets/Styles/Common.css";
import styles from "./JobHistory.module.css";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../utils/Loader/Loader";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toaster";
import dayjs from "dayjs";
import { userLogoutClear } from "../../store/slices/UserSlice";
import chatIcon from "../../assets/Images/chatIcon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";

export default function Jobs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const getJobLists = (value = "") => {
    setIsLoading(true);
    const params = {
      type: "all",
      page_no: pageNo,
    };

    let res = SublyApi.getJobList(params, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setIsLoadMore(response.data.loadMore);
        if (isLoadMore) {
          setJobList((prevJobList) => [
            ...prevJobList,
            ...response.data.jobList,
          ]);
        } else {
          setJobList(response.data.jobList);
        }
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        }
        if (
          response.code == STATUS_CODES.INVALID_TOKEN ||
          response.data.code == STATUS_CODES.INVALID_TOKEN
        ) {
          Toster(t(responseCode), "error");
          dispatch(userLogoutClear());
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getJobLists();
  }, [pageNo]);

  const handleViewDetails = (jobId) => {
    // navigate(`/job-details/${jobId}`);
    navigate(`/job-details/${jobId}`, { state: { route: "jobHistory" } });
  };

  const onBack = () => {
    navigate("/profile");
  };

  const handleLoadMore = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHeadSummmary}>
            <div className={styles.topHeadBackBtn}>
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div>
              <span className={styles.topTitle}>{t("JOB_HISTORY")}</span>
            </div>
            <div></div>
          </div>
          <div>
            <Container>
              <Row>
                {jobList &&
                  jobList.map((jobInfo) => {
                    return (
                      <Col lg={4} md={6} sm={12} className="mb-3">
                        <div
                          className={styles.topMainContainer}
                          onClick={() => handleViewDetails(jobInfo.id)}
                        >
                          <div className={styles.infoAvailabilty}>
                            <div>
                              <span className={styles.availabilityTitle}>
                                {t("AVAILABILITY")} -{" "}
                              </span>
                              <span className={styles.availabilityValue}>
                                {dayjs(jobInfo.availability_date).format(
                                  "DD MMM YYYY"
                                )}
                                ,{" "}
                                {dayjs(
                                  jobInfo.availability_time,
                                  "HH:mm:ss"
                                ).format("hh:mmA")}
                              </span>
                            </div>
                            <div>
                              <span className={styles.availabilityTitle}>
                                {t("HELPER")} :{" "}
                              </span>
                              <span className={styles.helperValue}>
                                {jobInfo.helper}
                              </span>
                            </div>
                          </div>
                          <hr className={styles.hrSection} />
                          <div className={styles.locationContainer}>
                            <div className={styles.viaShow}>
                              <span className={styles.VehicleTitle}>
                                {t("VIA")} :{" "}
                              </span>
                              <span className={styles.vehicleValue}>
                                {jobInfo.stopCount ? jobInfo.stopCount : 0}
                              </span>
                            </div>
                            <div className={styles.addressRouteWrapper}>
                              <div className={styles.subLocationInner}>
                                <div className={styles.routeLinePath}>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                  <div className={styles.leftRouteLine}></div>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      vFlip
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                </div>
                                <div className={styles.subLocationContainer}>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("COLLECT_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.source_city &&
                                      jobInfo.source_postal_code
                                        ? `${jobInfo.source_city} ${jobInfo.source_postal_code}`
                                        : jobInfo.source_city ||
                                          jobInfo.source_location}
                                    </div>
                                  </div>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("DELIVER_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.destination_city &&
                                      jobInfo.destination_postal_code
                                        ? `${jobInfo.destination_city} ${jobInfo.destination_postal_code}`
                                        : jobInfo.destination_city ||
                                          jobInfo.destination_location}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={styles.infoContainer}>
                            <hr className={styles.hrSection} />
                            <div className={styles.infoCategory}>
                              <div>
                                <span className={styles.categoryName}>
                                  {jobInfo.category_name}
                                </span>
                              </div>
                            </div>
                            <hr className={styles.hrSection} />
                            <div className={styles.infoItemVan}>
                              <div>
                                <span className={styles.VehicleTitle}>
                                  {t("VEHICLE_TYPE")} :{" "}
                                </span>
                                <span className={styles.vehicleValue}>
                                  {jobInfo.vehicle_type_name}
                                </span>
                              </div>
                              {jobInfo.job_status == "complete" && (
                                <div className={styles.jobStatus}>
                                  {t("COMPLETED")}
                                </div>
                              )}
                              {jobInfo.job_status == "cancel_customer" && (
                                <div className={styles.jobStatusCancel}>
                                  {t("CANCELLED")}
                                </div>
                              )}
                              {jobInfo.job_status == "cancel_by_admin" && (
                                <div className={styles.jobStatusCancel}>
                                  {t("CANCELLED")}
                                  {/* {t("CANCEL_BY_ADMIN")} */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              <Row>
                {isLoadMore && !isLoading && (
                  <div className={styles.loadMoreSection}>
                    <span
                      className={styles.loadMoreBtn}
                      onClick={() => handleLoadMore()}
                    >
                      {t("LOAD_MORE")}
                    </span>
                  </div>
                )}
              </Row>
              {!isLoading && jobList.length == 0 && (
                <Row>
                  <div className={styles.notFoundContainer}>
                    <div className={styles.notFoundIcon}>
                      <Icon
                        icon="lucide:search-x"
                        color="grey"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className={styles.notFoundMessage}>
                      {t("JOBS_NOT_FOUND")}
                    </div>
                  </div>
                </Row>
              )}
            </Container>
          </div>
        </div>
        <div className={styles.chatFeature}>
          {/* <img src={chatIcon} alt="chat-image" /> */}
        </div>
      </div>
    </>
  );
}
