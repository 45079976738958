import Modal from "react-bootstrap/Modal";
import { MdCancel } from "react-icons/md";
import "../../../assets/Styles/DriverCommon.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { CiCamera } from "react-icons/ci";
import uploadImg from "../../../assets/DriverImages/uploadIcon.png";
import styles from "../../authComponent/DriverSignUp/DriverSignUp.module.css";
import Loader from "../../../utils/Loader/Loader";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import {
  updateDriverDocument,
  userLogoutClear,
} from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import pdf from "../../../assets/DriverImages/pdf.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

//---------function for edit document---------
function DocumentEditModal({
  documentModal,
  setDocumentModal,
  documentList,
  title,
  getDocumentList,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, isLoading } = useSelector((state) => state.user);
  const [documentPreview, setDocumentPreview] = useState("");
  const [documentImage, setDocumentImage] = useState("");
  const [date, setDate] = useState("");
  const [formatedDate, setFormatedDate] = useState("");
  const currentDate = new Date();

  // function handle date change
  const handleDateChange = (date) => {
    setDate(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY-MM-DD");
      setFormatedDate(formattedYear);
    } else {
      setFormatedDate("");
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // function for apply validation in image field
  const validateImage = () => {
    if (!documentPreview) {
      return `${title} ${t("REQUIRED")}`;
    }
    return true;
  };

  // function for apply validation in date field
  const validateDate = () => {
    if (!date) {
      return `${title} ${t("EXPIRE_DATE")}.`;
    }
    return true;
  };

  //----- function for Upload image-----
  function onImageChange(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setDocumentPreview(URL.createObjectURL(e.target.files[0]));
      setDocumentImage(e.target.files[0]);
    }
  }

  //----- function for remove image-----
  function onImageRemove() {
    setDocumentPreview("");
    setDocumentImage("");
  }

  //----- function for check image type ----------
  const renderImage = () => {
    if (!documentPreview) return null;

    if (documentImage) {
      return documentImage.type.startsWith("image") ? (
        <img src={documentPreview} alt="uploaded" />
      ) : (
        <img src={pdf} alt="pdf-icon" />
      );
    } else {
      return documentPreview.split(".").pop() === "pdf" ? (
        <img src={pdf} alt="pdf-icon" />
      ) : (
        <img src={documentPreview} alt="uploaded" />
      );
    }
  };

  useEffect(() => {
    setDate("");
    setDocumentPreview("");
    setDocumentImage("");
    setFormatedDate("");
  }, [documentModal]);

  // useEffect(() => {
  //   reset();
  //   if (Object.keys(documentList).length > 0) {
  //     setDocumentPreview(documentList ? documentList.document_image : "");
  //     const formattedDate = documentList
  //       ? formatDate(documentList.expiry_date)
  //       : "";
  //     setDate(formattedDate);
  //   } else {
  //     setDocumentPreview("");
  //     setDocumentImage("");
  //   }
  // }, [documentList, documentModal]);

  // --------function for update driver document api calling--------
  const onSubmit = () => {
    let requestData = new FormData();
    requestData.append("document_type", documentList.document_type);
    requestData.append("document_file", documentImage ? documentImage : "");
    requestData.append("expiry_date", formatedDate ? formatedDate : "");
    const data = { requestData: requestData, userToken: userToken };
    dispatch(updateDriverDocument(data)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_DOCUMENT"), "success");
        setDocumentModal(false);
        getDocumentList();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading === true ? <Loader /> : ""}
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={documentModal}
          onHide={() => {
            setDocumentModal(false);
          }}
          className="colorModal"
          backdrop="static"
        >
          <Modal.Header>
            <div className={styles.modalHeader}>
              <h4>{title}</h4>
              <MdCancel
                onClick={() => {
                  setDocumentModal(false);
                }}
              />
            </div>
          </Modal.Header>

          <Modal.Body>
            <>
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <div className={styles.uploadSection}>
                    <h4>{title}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="documentImage"
                        render={({ field }) => (
                          <input
                            id="uploadImage"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*,.pdf"
                            onChange={(e) => {
                              onImageChange(e);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage }}
                      />
                      <div className={styles.cancelIcon}>
                        {documentPreview && (
                          <MdCancel
                            onClick={(e) => {
                              onImageRemove(e);
                            }}
                          />
                        )}
                      </div>
                      {documentPreview ? (
                        <div className={styles.uploadInputImg}>
                          {renderImage()}
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>
                            {t("UPLOAD")} {title}
                          </p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label
                          htmlFor="uploadImage"
                          className={styles.uploadbutton}
                        >
                          <CiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!documentPreview && errors.documentImage && (
                        <span className="errorMsg">
                          {errors.documentImage.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <h4 className={styles.dateHeading}>
                  {title} {t("EXPIRE_DATE")}
                </h4>
                <Form.Group className="mb-3 dateInput">
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => (
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            value={date}
                            views={["year", "month", "day"]}
                            className="muiDatepicker muiDatepickerfull"
                            onChange={handleDateChange}
                            renderInput={(params) => <input {...params} />}
                            minDate={dayjs(currentDate)}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                    rules={{ validate: validateDate }}
                  />
                  <div className="otpError">
                    {!date && errors.date && (
                      <span className="errorMsg">{errors.date.message}</span>
                    )}
                  </div>
                </Form.Group>
                <div className="commonButton">
                  <button type="submit">{t("UPDATE")}</button>
                </div>
              </Form>
            </>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default DocumentEditModal;
