//---------------- Common Text----------------------
export const TRANSLATIONS_AR = {
  //----------------Header Text----------------------
  HOME: "بيت",
  CREATE_JOB: "إنشاء وظيفة",
  LOGIN: "تسجيل الدخول",
  DRIVER: "كن سائقًا",
  FAQ: "الأسئلة الشائعة",
  SUPPORT: "الدعم",
  JOB: "الوظائف",
  ENGLISH: "الإنجليزية",
  SPANISH: "الإسبانية",
  FRENCH: "الفرنسية",
  TURKISH: "التركية",
  CHINESE: "الصينية",
  ARABIC: "العربية",

  //----------------Footer Text----------------------
  COPYRIGHT: "جميع الحقوق محفوظة",
  SWIFTVAN: "سويفت فان",
  PRIVACY: "سياسة الخصوصية",
  TERMS: "الشروط والأحكام",

  //----------------HomePage Text----------------------
  BOOK_MOVE: "احجز تحركك في 60 ثانية",
  WHAT_MOVING: "ما الذي تقوم بنقله؟",
  BECOME_DRIVER: "كن سائقًا",
  CALCULATE: "حساب",
  SOURSE_LOCATION: "أدخل موقع المصدر",
  DESTINY_LOCATION: "أدخل موقع الوجهة",
  ABOUT_US: "من نحن",
  CATEGORIES: "ما نحب",
  DOING: "القيام بـ",
  WORKS: "يعمل",
  HOW: "كيف",
  FURNITURE_OTHER: "الأثاث والعناصر الأخرى",
  HOME_REMOVAL: "نقل المنزل",
  BTOB: "من الشركة إلى الشركة",
  SCHEDULE_PICKUP: "جدولة لاستلام سهل",
  ARRIVE: "نصل إلى عتبة بابك",
  DELIVER: "تسليم في الوقت المحدد",

  //----------------LoginForm Text----------------------
  EMAIL: "البريد الإلكتروني",
  EMAIL_TEXT: "أدخل البريد الإلكتروني",
  PASSWORD: "كلمة المرور",
  PASSWORD_TEXT: "أدخل كلمة المرور",
  LOGIN: "تسجيل الدخول",
  FORGOT_PASSWORD: "هل نسيت كلمة المرور؟",
  EMAIL_REQUIRED: "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك.",
  EMAIL_INVALID: "الرجاء إدخال عنوان بريد إلكتروني صالح.",
  PASSWORD_REQUIRED: "الرجاء إدخال كلمة المرور الخاصة بك.",
  PASS_MINLENGTH: "الرجاء إدخال كلمة المرور، مع الحد الأدنى من 6 أحرف.",
  PASS_MAXLENGTH: "الرجاء إدخال كلمة مرور، مع الحد الأقصى للحروف 12.",
  NO_ACCOUNT: "ليس لديك حساب؟",
  SINGUP: "التسجيل",

  //----------------ForgotPassword Text----------------------
  CANCEL: "إلغاء",
  RESET: "إعادة تعيين",
  FORGOT_TEXT:
    "أدخل عنوان بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور",
  FORGOT_PASSWORD_TEXT: "هل نسيت كلمة السر",

  //----------------SignUp Form Text----------------------
  CREATE_ACCOUNT: "إنشاء حساب",
  FIRST_NAME: "الاسم الأول",
  LAST_NAME: "الاسم الأخير",
  CONTACT: "رقم الاتصال",
  CONFIRM_PASSWORD: "تأكيد كلمة المرور",
  FIRSTNAME_TEXT: "أدخل الاسم الأول",
  FIRSTNAME_REQUIRED: "الرجاء إدخال الاسم الأول الخاص بك.",
  FIRSTNAME_MINLENGTH: "الرجاء إدخال الاسم الأول، مع الحد الأدنى من 2 أحرف.",
  FIRSTNAME_MAXLENGTH: "الرجاء إدخال الاسم الأول، مع الحد الأقصى للحروف 50.",
  LASTNAME_TEXT: "أدخل الاسم الأخير",
  LASTNAME_REQUIRED: "الرجاء إدخال اسم العائلة الخاص بك.",
  LASTNAME_MINLENGTH: "الرجاء إدخال اسم العائلة، مع الحد الأدنى من 2 أحرف.",
  LASTNAME_MAXLENGTH: "الرجاء إدخال اسم العائلة، مع الحد الأقصى للحروف 50.",
  CONTACT_TEXT: "حدد رقم الاتصال",
  CONTACT_REQUIRED: "الرجاء إدخال رقم الاتصال الخاص بك.",
  CONFIRM_PASS_TEXT: "أدخل تأكيد كلمة المرور",
  CONFIRM_PASS_REQUIRED: "الرجاء إدخال تأكيد كلمة المرور الخاصة بك.",
  CONFIRM_PASS_MINLENGTH:
    "الرجاء إدخال تأكيد كلمة المرور، مع الحد الأدنى من 6 أحرف.",
  CONFIRM_PASS_MAXLENGTH:
    "الرجاء إدخال تأكيد كلمة المرور، مع الحد الأقصى للحروف 12.",
  NOT_MATCH: "كلمة المرور غير متطابقة مع تأكيد كلمة المرور.",
  ALREADY_ACCOUNT: "هل لديك حساب بالفعل؟",
  SIGNIN: "تسجيل الدخول",
  SIGNUP_TEXT: "التسجيل",
  INVALID_PASSWORD:
    "يجب أن تحتوي كلمة المرور على 1 حرف كبير، 1 حرف صغير، 1 رقم، 1 حرف خاص بحد أدنى من 6 أحرف.",

  //----------------Reset Password Text----------------------
  NEW_PASSWORD: "كلمة المرور الجديدة",
  SUBMIT: "إرسال",
  OTP_REQUIRED: "مطلوب مكتب المدعي العام",
  DIGIT_REQUIRED: "الرجاء إدخال OTP من 4 أرقام",
  NUMBER_REQUIRED: "الرجاء إدخال أرقام فقط.",
  BACK: "خلف",

  //----------------SUCCESS Messages Text----------------------
  LOGIN_SUCCESSFULLY: "تم تسجيل الدخول بنجاح.",
  CREATE_ACCOUNT_SUCCESSFUL: "تم إنشاء الحساب بنجاح.",
  RESET_SUCCESFULLY: "تم إرسال رمز إعادة التعيين بنجاح!",
  UPDATE_SUCCESFULLY: "إعادة تعيين كلمة المرور بنجاح.",

  //----------------Error Messages Text----------------------
  HEADER_IS_MISSING: "الرأس مفقود.",
  DEVICE_TYPE_NOT_ALLOWED: "نوع الجهاز غير مسموح به.",
  INVALID_API_KEY: "مفتاح API غير صالح.",
  TOKEN_REQUIRE_AUTHENTICATION: "المصادقة تتطلب رمزًا.",
  INVALID_TOKEN: "رمز غير صالح.",
  SERVER_VALIDATION: "خطأ في التحقق.",
  USER_NOT_FOUND: "المستخدم غير موجود.",
  ACCOUNT_BLOCKED: "تم حظر حسابك حاليًا.",
  SOCIAL_USER_NOT_FOUND: "المستخدم الاجتماعي غير موجود.",
  INTERNAL_SERVER_ERROR: "خطأ داخلي في الخادم.",
  INVALID_LOGIN_CREDENTIAL: "بيانات تسجيل الدخول غير صالحة.",
  ACCOUNT_NOT_FOUND: "الحساب غير موجود، يرجى إدخال البريد الإلكتروني الصحيح.",
  ACCOUNT_INACTIVE: "حسابك غير نشط حاليًا.",
  ACCOUNT_DELETED: "تم حذف حسابك.",
  OLD_PASSWORD_DOES_NOT_MATCH: "كلمة المرور القديمة غير متطابقة.",
  EMAIL_ALREADY_IN_USE:
    "البريد الإلكتروني قيد الاستخدام بالفعل، يرجى استخدام بريد آخر.",
  PHONE_NUMBER_EXIST:
    "رقم الهاتف قيد الاستخدام بالفعل، يرجى استخدام رقم هاتف آخر.",
  SOCIALID_EXIST: "المعرف الاجتماعي موجود بالفعل.",
  CODE_EXIST: "الرمز قيد الاستخدام بالفعل.",
  CODE_NOT_MATCH: "الرمز غير متطابق.",
  EMAIL_LINKED_SOCIALUSER: "البريد الإلكتروني مرتبط بمستخدم اجتماعي.",
  EMAIL_LINKED_NORMALUSER: "البريد الإلكتروني مرتبط بمستخدم عادي.",
  EMAIL_FAIL: "فشل إرسال البريد الإلكتروني.",
  VEHICLE_IMG: "تتطلب صورة السيارة",
  LICENSE_IMG: "تتطلب رخصة القيادة",
  GT_INSURANCE_IMG: "تتطلب تأمين البضائع في العبور",
  HR_INSURANCE_IMG: "تتطلب تأمين الكراء والمكافأة",
  PL_INSURANCE_IMG: "تتطلب تأمين المسؤولية المدنية",
  VEHICLE_IMG_SIZE: "حجم صورة السيارة يصل إلى 10 ميجابايت",
  INVALID_VEHICLE_IMG: `امتداد صورة السيارة غير صالح، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_VEHICLE_ID: "معرف سيارة غير صالح",
  INVALID_COLOR_ID: "معرف لون سيارة غير صالح",
  LICENSE_IMG_SIZE: "حجم رخصة القيادة يصل إلى 10 ميجابايت",
  INVALID_LICENSE_IMG: `امتداد رخصة القيادة غير صالح، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  GT_INSURANCE_IMG_SIZE: "حجم تأمين البضائع في العبور يصل إلى 10 ميجابايت",
  INVALID_GT_INSURANCE_IMG: `امتداد تأمين البضائع في العبور غير صالح، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  HR_INSURANCE_IMG_SIZE: "حجم تأمين الكراء والمكافأة يصل إلى 10 ميجابايت",
  INVALID_HR_INSURANCE_IMG: `امتداد تأمين الكراء والمكافأة غير صالح، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  PL_INSURANCE_IMG_SIZE: "حجم تأمين المسؤولية المدنية يصل إلى 10 ميجابايت",
  INVALID_PL_INSURANCE_IMG: `امتداد تأمين المسؤولية المدنية غير صالح، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  FAILED_IMG: "فشل تحميل الصورة",
  FAILED_LICENSE_IMG: "فشل تحميل رخصة القيادة",
  FAILED_GT_IMG: "فشل تحميل تأمين البضائع في العبور",
  FAILED_HR_IMG: "فشل تحميل تأمين الكراء والمكافأة",
  FAILED_PL_IMG: "فشل تحميل تأمين المسؤولية المدنية",
  INVALID_CATEGORY_ID: "معرف الفئة غير صالح / غير نشط",
  INVALID_VEHICLETYPE_ID: "معرف نوع السيارة غير صالح / غير نشط",
  INVALID_DRIVER_ID: "معرف السائق غير صالح / غير نشط",
  INVALID_SOURCE_FLOOR_ID: "معرف الطابق الأصلي غير صالح / غير نشط",
  INVALID_DESTINATION_FLOOR_ID: "معرف الطابق الهدف غير صالح / غير نشط",
  INVALID_HELPER_ID: "معرف المساعد غير صالح / غير نشط",
  INVALID_CATEGORY_JSON: "JSON الخاص بالفئة غير صالح / غير نشط",
  ACCESS_DENIED:
    "تم رفض الوصول. ملحوظة: سيظهر هذا الرمز عندما يحاول أحد الموظفين تشغيل واجهات برمجة التطبيقات التي لا تسمح له بها.",
  WRONG: "حدث خطأ ما",
  INVALID_UNIT_ID: "معرف الوحدة غير صالح / غير نشط",
  INVALID_STOP: "JSON الذي يضيف توقفًا غير صالح",
  INVALID_SOURCE_PROPERTY_ID: "معرف نوع الملكية الأصلي غير صالح / غير نشط",
  INVALID_DESTINATION_PROPERTY_ID: "معرف نوع الملكية الهدف غير صالح / غير نشط",
  INVALID_SCHEDULE_DATA: "صيغة JSON غير صالحة لبيانات الجدول",
  TIME_EXIST: "هذا الوقت موجود بالفعل لنفس اليوم. يرجى استخدام وقت بدء آخر",
  CUSTOMER_NOT_PERMITTED: "العميل ليس لديه إذن لهذا الإجراء",
  INVALID_PRICE: "صيغة JSON غير صالحة لبيانات تحديد السعر",
  FOR_SCHEDULE: "لبيانات الجدول الزمني يُسمح فقط بفتحين في نفس اليوم",
  DRIVER_NOT_PERMISION: "السائق ليس لديه إذن لهذا الإجراء",
  CAN_NOT_DELETE: "لا يمكن للمستخدم حذف هذا الحساب",
  ALEREADY_LICENCE_USE:
    "رقم الترخيص مستخدم بالفعل، يرجى استخدام أرقام تراخيص أخرى.",
  PROFILE_REQUIRED: "الصورة الشخصية مطلوبة",
  PROFILE_IMG_SIZE: "الحد الأقصى لحجم الصورة الشخصية هو 10 ميجابايت",
  INVALID_PROFILE: `صورة الملف الشخصي غير صالحة، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp"`,
  DOC_IMG_REQUIRED: "صورة المستند مطلوبة",
  DOC_IMG_SIZE: "الحد الأقصى لحجم صورة المستند هو 10 ميجابايت",
  INVALID_DOC_IMG: `صورة المستند غير صالحة، يرجى استخدام ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_DOC: "مستند غير صالح",
  PASS_NOT_MATCH: "كلمة المرور الجديدة وتأكيد كلمة المرور غير متطابقين",
  NOTIFY_ALREADY_EXIST: "هذا الإشعار موجود بالفعل",
  ADD_VEHICLE: "الرجاء إضافة مركبة",
  VEHICLE_NAME_ALREDY: "اسم المركبة موجود بالفعل، يرجى استخدام اسم آخر",
  NOT_PERMIT_USER: "المستخدم ليس لديه إذن لهذا الإجراء",
  VEHICLE_TYPE_NOT_DELETE: "لا يمكن حذف نوع المركبة هذا",
  VEHICLE_TYPE_STATUS: "حالة نوع المركبة موجودة بالفعل",
  ESTIMATE_WEIGHT_ID: "معرف وزن تقديري غير صالح",
  INVALID_JOB_TEMP_ID: "معرف وظيفة مؤقتة غير صالح",
  INVALID_KEY: "اسم المفتاح غير صالح",
  MASTER_DATA_EXIST: "اسم بيانات الرئيس موجود بالفعل",
  INVALID_MASTER_DATA: "معرف بيانات رئيس غير صالح",
  MASTER_DATA_NOT_DELETE: "لا يمكن حذف هذه البيانات الرئيسية",
  MASTER_DATA_STATUS: "حالة بيانات الرئيس موجودة بالفعل",
  CATEGORY_ICON: "مطلوب رمز الفئة",
  CATEGORY_ICON_SIZE: "حجم رمز الفئة يصل إلى 10 ميجابايت كحد أقصى",
  INVALID_CATEGORY_ICON:
    'امتداد رمز الفئة غير صالح، يرجى استخدام ".jpg"، ".jpeg"، ".png"، ".webp"',
  CATEGORY_ADD: "تمت إضافة الفئة بنجاح",
  CATEGORY_UPDATE: "تم تحديث الفئة بنجاح",
  CUSTOMER_EXIST: "المستخدمون مرتبطون بالفعل مع العميل",
  DRIVER_EXIST: "المستخدمون مرتبطون بالفعل مع السائق",

  //----------------Session Expire Text----------------------
  SESSION_EXPIRED: "انتهت جلستك!",

  //----------------Profile Page Text----------------------
  PROFILE: "ملفي",
  SETTINGS: "الإعدادات",
  EDIT_PROFILE: "تعديل الملف",
  USER_INFO: "معلومات المستخدم",
  UPDATE: "تحديث",
  LOGOUT: "تسجيل الخروج",
  DELETE_ACCOUNT: "حذف الحساب",
  SAVE_CARD: "حفظ البطاقات",
  CHANGE_PASSWORD: "تغيير كلمة المرور",
  JOB_HISTORY: "سجل الوظائف",

  //----------------Driver auth Page Text----------------------
  BACK: "خلف",
  NEXT: "التالي",
  DONE: "تم",
  SELECT_VEHICLE_COLOR: "اختر لون السيارة",
  SELECT_VAN: "اختر فان",
  VEHICLE_INFO: "معلومات السيارة",
  LICENSE_PLATE_NUMBER: "رقم لوحة الترخيص",
  ENTER_LICENSE: "أدخل رقم لوحة الترخيص",
  LICENSE_REQUIRED: "يرجى إدخال رقم لوحة الترخيص.",
  LICENSE_MINLENGTH: "الرجاء إدخال رقم لوحة ترخيص يتكون من حرفين على الأقل.",
  LICENSE_MAXLENGTH: "الرجاء إدخال رقم لوحة ترخيص بحد أقصى 50 حرفًا.",
  VAN_TYPE: "نوع الفان",
  SELECT_VAN: "اختر نوع الفان",
  VAN_REQUIRED: "يرجى تحديد الفان.",
  COLOR_VEHICLE: "لون السيارة",
  SELECT_COLOR: "اختر لون السيارة",
  COLOR_REQUIRED: "يرجى تحديد لون السيارة.",
  VEHICLE_YEAR: "سنة السيارة",
  YEAR_OF_VEHICLE: "أدخل سنة السيارة",
  VEHICLE_YEAR_REQUIRED: "يرجى إدخال سنة السيارة",
  YEAR_ALLOW_DIGITS: "الرجاء إدخال سنة مكونة من 4 أرقام",
  VEHICLE_PHOTO: "صورة السيارة",
  VEHICLE_PHOTO_REQUIRED: "تتطلب صورة السيارة",
  UPLOAD_VEHICLE: "تحميل صورة السيارة",
  UPLOAD: "تحميل",
  DESCRIPTION: "الوصف",
  ENTER_DISCRIPTION: "أدخل الوصف",
  DESCIPTION_REQUIRED: "يرجى إدخال وصف للسيارة.",
  DESCRIPTION_MINLENGTH: "الرجاء إدخال وصف للسيارة يتكون من حرفين على الأقل.",
  DESCRIPTION_MAXLENGTH: "الرجاء إدخال وصف للسيارة بحد أقصى 300 حرف.",
  UPLOAD_DOCUMENT: "تحميل المستندات",
  DRIVING_LICENSE: "رخصة القيادة",
  DRIVING_LICENSE_REQUIRED: "تتطلب رخصة القيادة",
  GT_INSURANCE_REQUIRED: "تتطلب تأمين البضائع في العبور",
  HR_INSURANCE_REQUIRED: "تتطلب تأمين الكراء والمكافأة",
  PL_INSURANCE_REQUIRED: "تتطلب تأمين المسؤولية المدنية",
  GT_TITLE: "تأمين البضائع في العبور",
  HR_TITLE: "تأمين الكراء والمكافأة",
  PL_TITLE: "تأمين المسؤولية المدنية",
  UPLOAD_LICENSE: "تحميل رخصة القيادة",
  UPLOAD_REGISTRATION: "تحميل تسجيل السيارة",
  ALERT: "تنبيه",
  REMOVE_ALERT: "هل أنت متأكد أنك تريد إزالته",
  YES: "نعم",

  //-----------------------Create job localization text--------------------
  ENTER: "أدخل",
  FURNITURE_AND_OTHER: "الأثاث والعناصر الأخرى",
  SOURCE_LOCATION: "موقع المصدر",
  DESTINATION_LOCATION: "موقع الوجهة",
  SELECT_FLOOR: "اختر الطابق",
  LIFT_AVAILABLE: "المصعد متوفر",
  STOPS: "التوقفات",
  ADD_STOPS: "إضافة توقفات",
  ADD_MORE_STOPS: "إضافة المزيد من التوقفات",
  AVAILABILITY: "التوفر",
  ESTIMATED_TIME_ARRIVAL: "الوقت المتوقع للوصول",
  WANT_PACKING: "هل ترغب في التعبئة؟",

  //-----------------------Create job validations text--------------------
  ENTER_SOURCE_LOCATION: "أدخل موقع المصدر",
  ENTER_DESTINATION_LOCATION: "أدخل موقع الوجهة",
  ENTER_ALL_STOPS_ADDRESS: "الرجاء إدخال عنوان لجميع التوقفات.",
  MAXIMUM_STOPS_ALLOWED: "الحد الأقصى المسموح به هو 9 توقفات.",
  MIN_3_CHAR_REQUIRED: "الحد الأدنى للحروف المطلوبة هو 3.",
  SELECT_VEHICLE_TYPE: "اختر نوع المركبة الخاصة بك",
  SELECT_HELPER: "اختر المساعد",
  SELECT_DATE: "اختر التاريخ",
  SELECT_TIME: "اختر الوقت",
  SELECT_DATE_TIME: "اختر تاريخ ووقت التوفر",
};
