import React, { useEffect, useState } from "react";
import styles from "./JobTracking.module.css";
import { FiArrowLeft } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import SublyApi from "../../helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toaster";
import { Icon } from "@iconify/react/dist/iconify.js";
import MapView from "./MapView";
import manWithBoxes from "../../assets/Images/manWithBoxes.svg";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const JobTracking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const location = useLocation();
  const stopsCount = (location.state.stops && location.state.stops.length) || 0;

  useEffect(() => {
    const steps = generateSteps(stopsCount);
    setDynamicSteps(steps);
  }, [stopsCount]);

  const generateSteps = (stopsCount) => {
    const steps = ["Start", "Pickup Order"];

    if (stopsCount > 0) {
      for (let i = 1; i <= stopsCount; i++) {
        steps.push(`Via-${i} Reached`);
        steps.push(`Via-${i} Resume`);
      }
    } else {
      steps.push("On the way");
    }

    steps.push("Reached");
    steps.push("Delivered");

    return steps;
  };

  const { userToken } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [trackingInfo, setTrackingInfo] = useState([]);
  const [dynamicSteps, setDynamicSteps] = useState([]);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0F7DFF",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0F7DFF",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#0F7DFF",
      color: "white",

      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#0F7DFF",
      color: "white",
    }),
  }));

  const getDynamicIcons = () => {
    const hasStops = [
      { label: "Start", icon: <LocationOnRoundedIcon /> },
      {
        label: "Pickup Order",
        icon: <img src={manWithBoxes} alt="Man with Boxes" />,
      },
      {
        label: "Reached",
        icon: (
          <Icon
            icon="mdi:package-variant-closed-delivered"
            height="25"
            width="25"
          />
        ),
      },
      { label: "Delivered", icon: <CheckCircleIcon /> },
    ];

    const notValidStops = [
      { label: "Start", icon: <LocationOnRoundedIcon /> },
      {
        label: "Pickup Order",
        icon: <img src={manWithBoxes} alt="Man with Boxes" />,
      },
      { label: "On the way", icon: <LocalShippingIcon /> },
      {
        label: "Reached",
        icon: (
          <Icon
            icon="mdi:package-variant-closed-delivered"
            height="25"
            width="25"
          />
        ),
      },
      { label: "Delivered", icon: <CheckCircleIcon /> },
    ];

    const dynamicStepsFilter = dynamicSteps
      .filter((step) => step.includes("Via"))
      .map(() => ({
        label: "Stop",
        icon: <Icon icon="ion:hand-left" height="25" width="25" />,
      }));

    const combinedStepsWithoutStop = [
      notValidStops[0],
      notValidStops[1],
      notValidStops[2],
      notValidStops[3],
      notValidStops[4],
    ];

    const combinedStepsWithStop = [
      hasStops[0],
      hasStops[1],
      ...dynamicStepsFilter,
      hasStops[2],
      hasStops[3],
    ];

    const stepIcons = {};
    if (stopsCount !== 0) {
      combinedStepsWithStop.forEach((step, index) => {
        stepIcons[index + 1] = step.icon;
      });
      return stepIcons;
    } else {
      combinedStepsWithoutStop.forEach((step, index) => {
        stepIcons[index + 1] = step.icon;
      });
      return stepIcons;
    }
  };

  function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;
    const icons = getDynamicIcons();

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  useEffect(() => {
    setIsLoading(true);
    const res = SublyApi.trackJobStatus(jobId, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setTrackingInfo(response.data);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  }, []);

  const onBack = () => {
    navigate(`/job-details/${jobId}`);
  };

  const getActiveStep = () => {
    const arrLenght = trackingInfo.length;
    if (arrLenght !== 0) {
      return arrLenght - 1;
    } else {
      return 0;
    }
  };

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const currentDate = new Date();

    // Format time in 12-hour format
    const hours = dateTime.getHours() % 12 || 12;
    const minutes = dateTime.getMinutes();
    const ampm = dateTime.getHours() >= 12 ? "PM" : "AM";

    // Check if the date is today
    if (
      dateTime.getDate() === currentDate.getDate() &&
      dateTime.getMonth() === currentDate.getMonth() &&
      dateTime.getFullYear() === currentDate.getFullYear()
    ) {
      return `Today’s ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
    } else {
      // If not today, format as 'MM/DD/YYYY TIME'
      const ampm = dateTime.getHours() >= 12 ? "PM" : "AM";
      const time = `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
      const formattedDate = `${
        dateTime.getMonth() + 1
      }/${dateTime.getDate()}/${dateTime.getFullYear()} ${time}`;
      return formattedDate;
    }
  }

  const getJobTime = (ind) => {
    if (ind >= 0 && ind < trackingInfo.length) {
      return formatDateTime(trackingInfo[ind].created_at);
    }
    return ""; // Return an empty string if the index is out of bounds
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main jobDetails">
        <div className="spaceTopManage">
          <div className={styles.topHeadTracking}>
            <div className={styles.topHeadBackBtn}>
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div>
              <span className={styles.toptrackTitle}>{t("TRACKING")}</span>
            </div>
            <div></div>
          </div>

          <div className={styles.trackContainer}>
            <Stack sx={{ width: "100%" }} spacing={3}>
              <Stepper
                alternativeLabel
                activeStep={getActiveStep()}
                connector={<ColorlibConnector />}
                className="trackJobStepper"
              >
                {dynamicSteps.map((step, index) => (
                  <Step key={step}>
                    <StepLabel StepIconComponent={ColorlibStepIcon2}>
                      <div className={styles.trackLabel}>{step}</div>
                      <p className={styles.trackingDateTime}>
                        {getJobTime(index)}
                      </p>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </div>
          <div className={styles.topMapContainer}>
            <MapView jobInfo={location.state} />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobTracking;
