import React, { useEffect, useState } from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

export default function MapView({ jobInfo }) {
  const [directionsResponse, setDirectionsResponse] = useState(null);

  useEffect(() => {
    jobInfo.source_latitude && calculateRoute();
  }, [jobInfo]);

  const center = {
    lat: jobInfo.source_latitude,
    lng: jobInfo.source_longitude,
  };

  async function calculateRoute() {
    if (
      typeof window.google === "undefined" ||
      !new window.google.maps.DirectionsService()
    ) {
      console.error("Google Maps API not loaded.");
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const stopss = jobInfo.stops?.map((stop) => ({
      location: { lat: stop.latitude, lng: stop.longitude },
      // stopover: true, // Make sure to specify stopover as true for each stop
    }));
    const results = await directionsService.route({
      destination: {
        lat: jobInfo.destination_latitude,
        lng: jobInfo.destination_longitude,
      },
      origin: {
        lat: jobInfo.source_latitude,
        lng: jobInfo.source_longitude,
      },
      waypoints: stopss, // Add waypoints here
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results && results);
  }

  const renderGoogleMap = () => {
    return (
      directionsResponse && (
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ height: "250px", width: "100%" }}
        >
          <DirectionsRenderer
            directions={directionsResponse && directionsResponse}
          />
        </GoogleMap>
      )
    );
  };
  return <>{renderGoogleMap()}</>;
}
