import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadProfile from "../Setting/UploadProfile";
import SettingSidebar from "../Setting/SettingSidebar";
import Loader from "../../utils/Loader/Loader";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../assets/Styles/Common.css";
import styles from "../Profile/Profile.module.css";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../utils/sanitizeHtmlTags";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import { userDetails, userLogoutClear } from "../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import Toster from "../../utils/Toaster";
import { TiArrowSortedDown } from "react-icons/ti";
import SelectCategory from "../../DRIVERSIDE/appComponents/Support/Module/SelectCategory";
import SelectBookingType from "../../DRIVERSIDE/appComponents/Support/Module/SelectBookingType";
import SelectStatusType from "../../DRIVERSIDE/appComponents/Support/Module/SelectStatusType";
import SelectIssueType from "../../DRIVERSIDE/appComponents/Support/Module/SelectIssueType";
import RaiseATicketPopup from "./RaiseATicketPopup";

const Support = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [responseData, setResponseData] = useState([]);
  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
  });

  const [currentBookingValue, setCurrentBookingValue] = useState({
    val: "",
    id: "",
  });

  const [currentStatusValue, setCurrentStatusValue] = useState({
    val: "",
    id: "",
  });

  const [currentIssueValue, setCurrentIssueValue] = useState({
    val: "",
    id: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showBookingType, setShowBookingType] = useState(false);
  const [showStatusType, setShowStatusType] = useState(false);
  const [showIssueType, setShowIssueType] = useState(false);
  const [showRaiseTicket, setShowRaiseTicket] = useState(false);
  const [isNoMoreOptions, setIsNoMoreOptions] = useState(false);
  const [lastOptionID, setLastOptionID] = useState("");
  const [options, setOptions] = useState({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });
  const [show, setShow] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });

  //----- function for delete van -----
  const SupportListApi = async (id, stage = "first") => {
    // setIsLoading(true);
    await SublyApi.getCustomerSupportList(userToken, id, true).then(
      (response) => {
        // setIsLoading(false);
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          // setSupport(response && response.data);
          setIsNoMoreOptions(response.data.length == 0 ? true : false);
          if (stage == "first") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              first: response.data,
            }));
          }
          if (stage == "second") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              second: response.data,
            }));
            setShow((prevOptions) => ({
              ...prevOptions,
              second: response.data.length == 0 ? false : true,
              third: response.data.length == 0 ? false : true,
              fourth: response.data.length == 0 ? false : true,
            }));
          }
          if (stage == "third") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              third: response.data,
            }));
            setShow((prevOptions) => ({
              ...prevOptions,
              third: response.data.length == 0 ? false : true,
              fourth: response.data.length == 0 ? false : true,
            }));
          }
          if (stage == "fourth") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              fourth: response.data,
            }));
            setShow((prevOptions) => ({
              ...prevOptions,
              fourth: response.data.length == 0 ? false : true,
            }));
          }
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
        } else {
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  useEffect(() => {
    SupportListApi("", "first");
  }, []);

  const HandleShow = () => {
    setShowPopup(true);
  };

  const handleShowBookingType = () => {
    setShowBookingType(true);
  };

  const handleShowStatusType = () => {
    setShowStatusType(true);
  };

  const handleShowIssueType = () => {
    setShowIssueType(true);
  };

  const handleShowRaiseTicket = () => {
    setShowRaiseTicket(true);
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //--------function for update user details api calling--------

  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "title",
      formdata ? formdata.title && formdata.title.trim() : ""
    );
    requestData.append(
      "description",
      formdata ? formdata.description && formdata.description.trim() : ""
    );

    setLoader(true);
    await SublyApi.CoustomerSupport(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("SUPPORT_UPDATE_SUCCESS"), "success");
        setLoader(false);
        reset();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const getProfileDetails = () => {
    dispatch(userDetails(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setResponseData(response.data);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <div className="main">
      {loader && <Loader />}
      <Container>
        <div className="spaceTopManage">
          <section className="midSection">
            <div className={styles.profileSection}>
              <h2>{t("PROFILE")}</h2>
              <p></p>
              {/* <p>Lorem Ipsum is simply dummy</p> */}
            </div>

            <div className={styles.profileBox}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <SettingSidebar />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className={styles.profileSettingsSection}>
                    <h3
                      className={`${styles.profileHeading} profileHeadingBotLine`}
                    >
                      {t("SUPPORT")}
                    </h3>
                    <div className=" driverSupportCls">
                      <Form.Group className="mb-3 mt-4 setIconPassword formSection">
                        <Form.Label>{t("CATEGORIE")}</Form.Label>
                        <Form.Control
                          placeholder={`${t("SELECT_VEHICLE")}`}
                          type="text"
                          readOnly
                          value={currentValue.val}
                          onClick={() => {
                            HandleShow();
                          }}
                        />

                        <div className={styles.downiconicon}>
                          <TiArrowSortedDown
                            onClick={() => {
                              HandleShow();
                            }}
                          />
                        </div>
                      </Form.Group>

                      {currentValue.id != "" && show.second && (
                        <Form.Group className="mb-3 mt-4 setIconPassword formSection">
                          <Form.Label>{t("BOOKING_TYPE")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("SELECT_VEHICLE")}`}
                            type="text"
                            value={currentBookingValue.val}
                            onClick={() => {
                              handleShowBookingType(currentValue.id);
                            }}
                          />
                          <div className={styles.downiconicon}>
                            <TiArrowSortedDown
                              onClick={() => {
                                handleShowBookingType(currentValue.id);
                              }}
                            />
                          </div>
                        </Form.Group>
                      )}

                      {currentBookingValue.id != "" && show.third && (
                        <Form.Group className="mb-3 mt-4 setIconPassword formSection">
                          <Form.Label>{t("STATUS_TYPE")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("SELECT_VEHICLE")}`}
                            type="text"
                            readOnly
                            value={currentStatusValue.val}
                            onClick={() => {
                              handleShowStatusType(currentBookingValue.id);
                            }}
                          />
                          <div className={styles.downiconicon}>
                            <TiArrowSortedDown
                              onClick={() => {
                                handleShowStatusType(currentBookingValue.id);
                              }}
                            />
                          </div>
                        </Form.Group>
                      )}

                      {currentStatusValue.id != "" && show.fourth && (
                        <Form.Group className="mb-4 mt-4 setIconPassword formSection">
                          <Form.Label>{t("ISSUE_TYPE")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("SELECT_VEHICLE")}`}
                            type="text"
                            readOnly
                            value={currentIssueValue.val}
                            onClick={() => {
                              handleShowIssueType(currentStatusValue.id);
                            }}
                          />
                          <div className={styles.downiconicon}>
                            <TiArrowSortedDown
                              onClick={() => {
                                handleShowIssueType(currentStatusValue.id);
                              }}
                            />
                          </div>
                        </Form.Group>
                      )}

                      {isNoMoreOptions && (
                        <div
                          className={styles.supportDriverBtn}
                          style={{ width: "100%" }}
                        >
                          <button
                            onClick={() =>
                              handleShowRaiseTicket(currentIssueValue.id)
                            }
                          >
                            {t("RAISE_A_TICKET")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <UploadProfile responseData={responseData} />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </Container>
      <SelectCategory
        showPopup={showPopup}
        setCurrentValue={setCurrentValue}
        currentValue={currentValue}
        CloseHandlePopup={() => setShowPopup(false)}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
      />
      <SelectBookingType
        showBookingType={showBookingType}
        CloseHandleBookingType={() => setShowBookingType(false)}
        currentValue={currentValue}
        setCurrentBookingValue={setCurrentBookingValue}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
      />
      <SelectStatusType
        CloseHandleStatusType={() => setShowStatusType(false)}
        showStatusType={showStatusType}
        setCurrentStatusValue={setCurrentStatusValue}
        currentStatusValue={currentStatusValue}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
      />
      <SelectIssueType
        setCurrentIssueValue={setCurrentIssueValue}
        currentIssueValue={currentIssueValue}
        CloseHandleIssueType={() => setShowIssueType(false)}
        showIssueType={showIssueType}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
        setIsNoMoreOptions={setIsNoMoreOptions}
      />
      <RaiseATicketPopup
        showRaiseTicket={showRaiseTicket}
        CloseHandleRaiseTiKet={() => setShowRaiseTicket(false)}
        currentIssueValue={currentIssueValue}
        setCurrentValue={setCurrentValue}
        setCurrentBookingValue={setCurrentBookingValue}
        setCurrentStatusValue={setCurrentStatusValue}
        setCurrentIssueValue={setCurrentIssueValue}
        setIsNoMoreOptions={setIsNoMoreOptions}
        lastOptionID={lastOptionID}
      />
    </div>
  );
};

export default Support;
