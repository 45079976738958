import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
// import { helperOptions } from "../../../../mockData";
import { Button } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SelectHelper({ helper, setHelper }) {
  const { t } = useTranslation();
  const jobData = useSelector((state) => state.createJob);
  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
    time: "",
  });

  useEffect(() => {
    setCurrentValue({ val: helper.val, id: helper.id, time: helper.time });
  }, [helper]);

  const handleCheckboxChange = (id, value, time) => {
    setCurrentValue({ val: value, id: id, time });
  };

  const handleSubmit = () => {
    setHelper((prevState) => ({
      ...prevState,
      show: false,
      val: currentValue.val,
      id: currentValue.id,
      time: currentValue.time,
    }));
  };

  const onClose = () => {
    setHelper((pre) => ({ ...pre, show: false }));
  };

  return (
    <>
      <Modal
        show={helper.show}
        onHide={() => {
          onClose();
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("SELECT_HELPER")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {jobData.helpersList.map((checkbox) => (
              <div
                key={checkbox.id}
                className="floorCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(
                    checkbox.id,
                    checkbox.value_name,
                    checkbox.time
                  );
                }}
              >
                <label
                  className={
                    currentValue.val === checkbox.value_name
                      ? styles.activeFloor
                      : ""
                  }
                >
                  {checkbox.value_name}
                </label>
                {/* <Button
                  variant={
                    currentValue?.val === checkbox.value_name
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(
                      checkbox.id,
                      checkbox.value_name,
                      checkbox.time
                    );
                  }}
                >
                  {currentValue?.val === checkbox.value_name
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              Done
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default SelectHelper;
