//---------------- Ortak Metin----------------------
export const TRANSLATIONS_TR = {
  //----------------Header Text----------------------
  HOME: "Ev",
  CREATE_JOB: "İş Oluştur",
  LOGIN: "Giriş Yap",
  DRIVER: "Şoför Ol",
  FAQ: "SSS",
  SUPPORT: "Destek",
  JOB: "İşler",
  ENGLISH: "İngilizce",
  SPANISH: "İspanyolca",
  FRENCH: "Fransızca",
  TURKISH: "Türkçe",
  CHINESE: "Çince",
  ARABIC: "Arapça",

  //----------------Footer Text----------------------
  COPYRIGHT: "Tüm Hakları Saklıdır",
  SWIFTVAN: "SwiftVan",
  PRIVACY: "Gizlilik Politikası",
  TERMS: "Kullanım Şartları",

  //----------------HomePage Text----------------------
  BOOK_MOVE: "Taşınmanızı 60 saniyede planlayın",
  WHAT_MOVING: "Ne Taşıyorsunuz?",
  BECOME_DRIVER: "Şoför Ol",
  CALCULATE: "Hesapla",
  SOURSE_LOCATION: "Kaynak Konumunu Gir",
  DESTINY_LOCATION: "Hedef Konumunu Gir",
  ABOUT_US: "Hakkımızda",
  CATEGORIES: "Neleri seviyoruz",
  DOING: "yapıyoruz",
  WORKS: "işler",
  HOW: "Nasıl",
  FURNITURE_OTHER: "Mobilya ve diğer eşyalar",
  HOME_REMOVAL: "Ev taşıma",
  BTOB: "İşletmeden İşletmeye",
  SCHEDULE_PICKUP: "Kolay bir teslimat için randevu alın",
  ARRIVE: "Kapınıza geliyoruz",
  DELIVER: "Zamanında teslim ediyoruz",

  //----------------LoginForm Text----------------------
  EMAIL: "E-posta",
  EMAIL_TEXT: "E-posta adresinizi girin",
  PASSWORD: "Şifre",
  PASSWORD_TEXT: "Şifrenizi girin",
  LOGIN: "GİRİŞ",
  FORGOT_PASSWORD: "Şifrenizi mi unuttunuz?",
  EMAIL_REQUIRED: "Lütfen e-posta adresinizi girin.",
  EMAIL_INVALID: "Lütfen geçerli bir e-posta adresi girin.",
  PASSWORD_REQUIRED: "Lütfen şifrenizi girin.",
  PASS_MINLENGTH: "Lütfen en az 6 karakter içeren bir şifre girin.",
  PASS_MAXLENGTH: "Lütfen en fazla 12 karakter içeren bir şifre girin.",
  NO_ACCOUNT: "Hesabınız yok mu?",
  SINGUP: "Üye Ol",

  //----------------ForgotPassword Text----------------------
  CANCEL: "İptal",
  RESET: "Sıfırla",
  FORGOT_TEXT:
    "E-posta adresinizi girin ve şifrenizi sıfırlamak için size bir bağlantı göndereceğiz",
  FORGOT_PASSWORD_TEXT: "PAROLANIZI MI UNUTTUNUZ",

  //----------------SignUp Form Text----------------------
  CREATE_ACCOUNT: "Hesap Oluştur",
  FIRST_NAME: "Ad",
  LAST_NAME: "Soyad",
  CONTACT: "İletişim numarası",
  CONFIRM_PASSWORD: "Şifreyi Onayla",
  FIRSTNAME_TEXT: "Adınızı girin",
  FIRSTNAME_REQUIRED: "Lütfen adınızı girin.",
  FIRSTNAME_MINLENGTH: "Lütfen en az 2 karakter içeren bir ad girin.",
  FIRSTNAME_MAXLENGTH: "Lütfen en fazla 50 karakter içeren bir ad girin.",
  LASTNAME_TEXT: "Soyadınızı girin",
  LASTNAME_REQUIRED: "Lütfen soyadınızı girin.",
  LASTNAME_MINLENGTH: "Lütfen en az 2 karakter içeren bir soyad girin.",
  LASTNAME_MAXLENGTH: "Lütfen en fazla 50 karakter içeren bir soyad girin.",
  CONTACT_TEXT: "İletişim numaranızı seçin",
  CONTACT_REQUIRED: "Lütfen iletişim numaranızı girin.",
  CONFIRM_PASS_TEXT: "Şifreyi onaylayın",
  CONFIRM_PASS_REQUIRED: "Lütfen şifrenizi onaylayın.",
  CONFIRM_PASS_MINLENGTH: "Lütfen en az 6 karakter içeren bir şifre girin.",
  CONFIRM_PASS_MAXLENGTH: "Lütfen en fazla 12 karakter içeren bir şifre girin.",
  NOT_MATCH: "Şifreler eşleşmiyor.",
  ALREADY_ACCOUNT: "Zaten bir hesabınız var mı?",
  SIGNIN: "Giriş Yap",
  SIGNUP_TEXT: "Üye Ol",
  INVALID_PASSWORD:
    "Şifre 1 büyük harf, 1 küçük harf, 1 rakam, 1 özel karakter içermelidir, minimum 6 karakter.",

  //----------------Reset Password Text----------------------
  NEW_PASSWORD: "Yeni Şifre",
  SUBMIT: "Gönder",
  OTP_REQUIRED: "OTP gerekli",
  DIGIT_REQUIRED: "Lütfen 4 haneli bir OTP girin",
  NUMBER_REQUIRED: "Lütfen sadece rakam girin.",

  //----------------SUCCESS Messages Text----------------------
  LOGIN_SUCCESSFULLY: "Başarıyla giriş yapıldı.",
  CREATE_ACCOUNT_SUCCESSFUL: "Hesap başarıyla oluşturuldu.",
  RESET_SUCCESFULLY: "Sıfırlama kodu başarıyla gönderildi!",
  UPDATE_SUCCESFULLY: "Şifreyi başarıyla sıfırlayın.",

  //----------------Error Messages Text----------------------
  HEADER_IS_MISSING: "Başlık eksik.",
  DEVICE_TYPE_NOT_ALLOWED: "Cihaz türü izin verilmiyor.",
  INVALID_API_KEY: "Geçersiz API Anahtarı.",
  TOKEN_REQUIRE_AUTHENTICATION: "Kimlik doğrulaması için bir token gereklidir.",
  INVALID_TOKEN: "Geçersiz Token.",
  SERVER_VALIDATION: "Doğrulama hatası.",
  USER_NOT_FOUND: "Kullanıcı bulunamadı.",
  ACCOUNT_BLOCKED: "Hesabınız şu anda engellenmiştir.",
  SOCIAL_USER_NOT_FOUND: "Sosyal kullanıcı bulunamadı.",
  INTERNAL_SERVER_ERROR: "İç sunucu hatası.",
  INVALID_LOGIN_CREDENTIAL: "Geçersiz giriş bilgileri.",
  ACCOUNT_NOT_FOUND: "Hesap bulunamadı, lütfen doğru e-postayı girin.",
  ACCOUNT_INACTIVE: "Hesabınız şu anda etkin değil.",
  ACCOUNT_DELETED: "Hesabınız silinmiştir.",
  OLD_PASSWORD_DOES_NOT_MATCH: "Eski şifre eşleşmiyor.",
  EMAIL_ALREADY_IN_USE:
    "E-posta zaten kullanımda, lütfen başka bir e-posta kullanın.",
  PHONE_NUMBER_EXIST:
    "Telefon numarası zaten kullanımda, lütfen başka bir telefon numarası kullanın.",
  SOCIALID_EXIST: "Sosyal kimlik zaten mevcut",
  CODE_EXIST: "Kod zaten kullanılmış",
  CODE_NOT_MATCH: "Kod eşleşmiyor",
  EMAIL_LINKED_SOCIALUSER: "E-posta sosyal kullanıcıyla bağlı",
  EMAIL_LINKED_NORMALUSER: "E-posta normal kullanıcıyla bağlı",
  EMAIL_FAIL: "E-posta gönderme başarısız",
  VEHICLE_IMG: "Araç resmi gereklidir",
  LICENSE_IMG: "Sürücü belgesi gereklidir",
  GT_INSURANCE_IMG: "Taşıma sigortası gereklidir",
  HR_INSURANCE_IMG: "Kira & ödül sigortası gereklidir",
  PL_INSURANCE_IMG: "Halka açık sorumluluk sigortası gereklidir",
  VEHICLE_IMG_SIZE: "Araç resmi maksimum boyutu 10 MB",
  INVALID_VEHICLE_IMG: `Geçersiz araç resmi uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp" uzantıları kullanın`,
  INVALID_VEHICLE_ID: "Geçersiz araç kimliği",
  INVALID_COLOR_ID: "Geçersiz araç renk kimliği",
  LICENSE_IMG_SIZE: "Sürücü belgesi maksimum boyutu 10 MB",
  INVALID_LICENSE_IMG: `Geçersiz sürücü belgesi uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp , ".pdf"" uzantıları kullanın`,
  GT_INSURANCE_IMG_SIZE: "Taşıma sigortası maksimum boyutu 10 MB",
  INVALID_GT_INSURANCE_IMG: `Geçersiz taşıma sigortası uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp , ".pdf"" uzantıları kullanın`,
  HR_INSURANCE_IMG_SIZE: "Kira & ödül sigortası maksimum boyutu 10 MB",
  INVALID_HR_INSURANCE_IMG: `Geçersiz kira & ödül sigortası uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp , ".pdf"" uzantıları kullanın`,
  PL_INSURANCE_IMG_SIZE:
    "Halka açık sorumluluk sigortası maksimum boyutu 10 MB",
  INVALID_PL_INSURANCE_IMG: `Geçersiz halka açık sorumluluk sigortası uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp , ".pdf"" uzantıları kullanın`,
  FAILED_IMG: "Resim yüklenemedi",
  FAILED_LICENSE_IMG: "Sürücü belgesi yüklenemedi",
  FAILED_GT_IMG: "Taşıma sigortası yüklenemedi",
  FAILED_HR_IMG: "Kira & ödül sigortası yüklenemedi",
  FAILED_PL_IMG: "Halka açık sorumluluk sigortası yüklenemedi",
  INVALID_CATEGORY_ID: "Geçersiz / devre dışı kategori kimliği",
  INVALID_VEHICLETYPE_ID: "Geçersiz / devre dışı araç tipi kimliği",
  INVALID_DRIVER_ID: "Geçersiz / devre dışı sürücü kimliği",
  INVALID_SOURSE_FLOOR_ID: "Geçersiz / devre dışı kaynak kat kimliği",
  INVALID_DESTINATION_FLOOR_ID: "Geçersiz / devre dışı hedef kat kimliği",
  INVALID_HELPER_ID: "Geçersiz / devre dışı yardımcı kimliği",
  INVALID_CATEGORY_JSON: "Geçersiz / devre dışı kategori json",
  ACCESS_DENIED:
    "Erişim Engellendi NOT: Bu kod, bir personelin çalışmasına izin verilmeyen API'leri çalıştırmaya çalıştığında gelecek",
  WRONG: "Bir şeyler ters gitti",
  INVALID_UNITID: "Geçersiz / devre dışı birim kimliği",
  INVALID_STOP: "Geçersiz durak ekleme json",
  INVALID_SPROPERTYID: "Geçersiz / devre dışı kaynak mülk türü kimliği",
  INVALID_DPROPERTYID: "Geçersiz / devre dışı hedef mülk türü kimliği",
  INVALID_SHEDULE_DATA: "Geçersiz zamanlama veri json formatı",
  TIME_EXIST:
    "Bu saat zaten aynı gün için mevcut, lütfen başka bir başlangıç saati kullanın",
  CUSTOMER_NOT_PERMIT: "Müşterinin bu işlem için izni yok",
  INVALID_PRICE: "Fiyatlandırma verisi json formatı geçersiz",
  FOR_SCHEDULE: "Zamanlama verisi için aynı gün sadece iki yuva izin verilir",
  DRIVER_NOT_PERMISION: "Sürücünün bu işlem için izni yok",
  CAN_NOT_DELETE: "Kullanıcı bu hesabı silemez",
  ALEREADY_LICENCE_USE:
    "Lisans numarası zaten kullanılmış, lütfen başka lisans numaraları kullanın",
  PROFILE_REQUIRED: "Profil resmi gereklidir",
  PROFILE_IMG_SIZE: "Profil resmi maksimum boyutu 10 MB",
  INVALID_PROFILE: `Geçersiz profil resmi uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp" uzantıları kullanın`,
  DOC_IMG_REQUIRED: "Belge resmi gereklidir",
  DOC_IMG_SIZE: "Belge resmi maksimum boyutu 10 MB",
  INVALID_DOC_IMG: `Geçersiz belge resmi uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp" uzantıları kullanın`,
  INVALID_DOC: "Geçersiz belge",
  PASS_NOT_MATCH: "Yeni şifre ile onay şifresi eşleşmiyor",
  NOTIFY_ALREADY_EXIST: "Bu bildirim durumu zaten mevcut",
  ADD_VEHICLE: "Lütfen araç ekleyin",
  VEHICLE_NAME_ALREDY: "Araç adı zaten mevcut, lütfen başka bir ad kullanın",
  NOT_PERMIT_USER: "Kullanıcının bu işlem için izni yok",
  VEHICLE_TYPE_NOT_DELETE: "Bu araç türü silinemez",
  VEHICLE_TYPE_STATUS: "Araç türü durumu veritabanında zaten mevcut",
  ESTIMATE_WEIGHT_ID: "Geçersiz tahmini ağırlık kimliği",
  INVALID_JOB_TEMP_ID: "Geçersiz iş şablonu kimliği",
  INVALID_KEY: "Geçersiz anahtar adı",
  MASTER_DATA_EXIST: "Ana veri adı zaten mevcut",
  INVALID_MASTER_DATA: "Geçersiz ana veri kimliği",
  MASTER_DATA_NOT_DELETE: "Bu ana veri silinemez",
  MASTER_DATA_STATUS: "Ana veri durumu zaten mevcut",
  CATEGORY_ICON: "Kategori simgesi gereklidir",
  CATEGORY_ICON_SIZE: "Kategori simgesi maksimum boyutu 10 MB",
  INVALID_CATEGORY_ICON: `Geçersiz kategori simgesi uzantısı, lütfen ".jpg", ".jpeg", ".png", ".webp" kullanın`,
  CATEGORY_ADD: "Kategori başarıyla eklendi",
  CATEGORY_UPDATE: "Kategori başarıyla güncellendi",
  CUSTOMER_EXIST: "Kullanıcılar zaten müşteri ile ilişkilendirilmiş",
  DRIVER_EXIST: "Kullanıcılar zaten sürücü ile ilişkilendirilmiş",

  //----------------Session Expire Text----------------------
  SESSION_EXPIRED: "Oturumunuzun süresi doldu!",

  //----------------Profile Page Text----------------------
  PROFILE: "Benim Profilim",
  SETTINGS: "Ayarlar",
  EDIT_PROFILE: "Profili Düzenle",
  USER_INFO: "Kullanıcı Bilgisi",
  UPDATE: "Güncelle",
  LOGOUT: "Çıkış Yap",
  DELETE_ACCOUNT: "Hesabı Sil",
  SAVE_CARD: "Kartları Kaydet",
  CHANGE_PASSWORD: "Şifreyi Değiştir",
  JOB_HISTORY: "İş Geçmişi",

  //----------------Driver auth Page Text----------------------
  BACK: "Geri",
  NEXT: "Sonraki",
  DONE: "Tamamlandı",
  SELECT_VEHICLE_COLOR: "Araç Rengini Seç",
  SELECT_VAN: "Van Seç",
  VEHICLE_INFO: "Araç Bilgisi",
  LICENSE_PLATE_NUMBER: "Plaka Numarası",
  ENTER_LICENSE: "Lütfen plaka numarasını girin",
  LICENSE_REQUIRED: "Lütfen plaka numarasını girin.",
  LICENSE_MINLENGTH: "Lütfen en az 2 karakter içeren bir plaka numarası girin.",
  LICENSE_MAXLENGTH:
    "Lütfen en fazla 50 karakter içeren bir plaka numarası girin.",
  VAN_TYPE: "Van Türü",
  SELECT_VAN: "Van türünü seçin",
  VAN_REQUIRED: "Lütfen bir van seçin.",
  COLOR_VEHICLE: "Araç Rengi",
  SELECT_COLOR: "Araç rengini seçin",
  COLOR_REQUIRED: "Lütfen araç rengini seçin.",
  VEHICLE_YEAR: "Araç Yılı",
  YEAR_OF_VEHICLE: "Araç yılını girin",
  VEHICLE_YEAR_REQUIRED: "Lütfen araç yılını girin",
  YEAR_ALLOW_DIGITS: "Lütfen 4 basamaklı bir yıl girin",
  VEHICLE_PHOTO: "Araç Fotoğrafı",
  VEHICLE_PHOTO_REQUIRED: "Araç fotoğrafı gereklidir",
  UPLOAD_VEHICLE: "Araç Fotoğrafını Yükle",
  UPLOAD: "Yükle",
  DESCRIPTION: "Açıklama",
  ENTER_DISCRIPTION: "Açıklama girin",
  DESCIPTION_REQUIRED: "Lütfen araç açıklamasını girin.",
  DESCRIPTION_MINLENGTH: "Lütfen en az 2 karakter içeren bir açıklama girin.",
  DESCRIPTION_MAXLENGTH:
    "Lütfen en fazla 300 karakter içeren bir açıklama girin.",
  UPLOAD_DOCUMENT: "BELGELERİ YÜKLE",
  DRIVING_LICENSE: "Sürücü Ehliyeti",
  DRIVING_LICENSE_REQUIRED: "Sürücü ehliyeti gereklidir",
  GT_INSURANCE_REQUIRED: "Taşıma Sigortası gereklidir",
  HR_INSURANCE_REQUIRED: "Kira ve Ödül Sigortası gereklidir",
  PL_INSURANCE_REQUIRED: "Halka Açık Sorumluluk Sigortası gereklidir",
  GT_TITLE: "Taşıma Sigortası",
  HR_TITLE: "Kira ve Ödül Sigortası",
  PL_TITLE: "Halka Açık Sorumluluk Sigortası",
  UPLOAD_LICENSE: "Sürücü Ehliyetini Yükle",
  UPLOAD_REGISTRATION: "Araç Kaydını Yükle",
  ALERT: "Uyarı",
  REMOVE_ALERT: "Emin misiniz ki kaldırmak istiyorsunuz",
  YES: "Evet",

  //-----------------------Create job localization text--------------------
  ENTER: "Giriş",
  FURNITURE_AND_OTHER: "Mobilya ve Diğer Eşyalar",
  SOURCE_LOCATION: "Kaynak Konum",
  DESTINATION_LOCATION: "Hedef Konum",
  SELECT_FLOOR: "Kat Seçin",
  LIFT_AVAILABLE: "Asansör Mevcut",
  STOPS: "Duraklar",
  ADD_STOPS: "Durak Ekle",
  ADD_MORE_STOPS: "Daha Fazla Durak Ekle",
  AVAILABILITY: "Uygunluk",
  ESTIMATED_TIME_ARRIVAL: "Tahmini varış zamanı",
  WANT_PACKING: "Paketleme yapmak ister misiniz?",

  //-----------------------Create job validations text--------------------
  ENTER_SOURCE_LOCATION: "Kaynak Konumu Girin",
  ENTER_DESTINATION_LOCATION: "Hedef Konumu Girin",
  ENTER_ALL_STOPS_ADDRESS: "Lütfen tüm duraklar için adres girin.",
  MAXIMUM_STOPS_ALLOWED: "Maksimum 9 durak izin verilir.",
  MIN_3_CHAR_REQUIRED: "En az 3 karakter gerekli.",
  SELECT_VEHICLE_TYPE: "Araç türünü seçin",
  SELECT_HELPER: "Yardımcı seçin",
  SELECT_DATE: "Tarihi seçin",
  SELECT_TIME: "Zamanı seçin",
  SELECT_DATE_TIME: "Mevcut tarih ve saatini seçin",
};
