export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const validations = {
  sourceLocation: {
    required: "ENTER_SOURCE_LOCATION",
    minLength: {
      value: 3,
      message: `MIN_3_CHAR_REQUIRED`,
    },
    ...sanitizeHtmlTags(),
  },
  destinationLocation: {
    required: "ENTER_DESTINATION_LOCATION",
    minLength: {
      value: 3,
      message: `MIN_3_CHAR_REQUIRED`,
    },
    ...sanitizeHtmlTags(),
  },
};
