import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "../Module/Support.module.css";
import Loader from "../../../../utils/Loader/Loader";

const SelectCategory = ({
  showPopup,
  CloseHandlePopup,
  setCurrentValue,
  currentValue,
  SupportListApi,
  options,
  setLastOptionID,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tempValue, setTempValue] = useState(currentValue);
  const handleCheckboxChange = (id, value) => {
    setTempValue({ val: value, id: id });
  };

  const handleDoneClick = () => {
    SupportListApi(tempValue.id, "second");
    setCurrentValue(tempValue);
    setLastOptionID(tempValue.id);
    CloseHandlePopup();
  };

  const onClose = () => {
    CloseHandlePopup();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal show={showPopup} onHide={() => onClose()}>
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("SELECT_VEHICLE")}</Modal.Title>
            <IoMdCloseCircle onClick={() => onClose()} />
          </Modal.Header>
          <Modal.Body>
            {options.first &&
              options.first.map((item, index) => {
                return (
                  <div
                    className="floorCheckboxes"
                    key={index}
                    onClick={(e) => {
                      handleCheckboxChange(item.id, item.name);
                    }}
                  >
                    <label
                      className={
                        tempValue && tempValue.val === item.name
                          ? styles.activeFloor
                          : ""
                      }
                    >
                      {item.name}
                    </label>
                    {/* <input
                      type="radio"
                      checked={tempValue && tempValue.val === item.name}
                    /> */}
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <div className="commonButton" style={{ width: "100%" }}>
              <button type="submit" onClick={handleDoneClick}>
                {t("DONE")}
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default SelectCategory;
