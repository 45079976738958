import Modal from "react-bootstrap/Modal";
import { LuAlertTriangle } from "react-icons/lu";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../../../assets/Styles/Common.css";
import Loader from "../../../../utils/Loader/Loader";

//---------function for confirm type popup modal---------
function Confirm({ isLoading, show, subTitle, handleConfirm, handleCancel }) {
  const { t } = useTranslation();

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={show}
        onHide={() => {
          handleCancel();
        }}
        className="colorModal"
        backdrop="static"
      >
        <div className="confirmBox"></div>
        <Modal.Body>
          {isLoading && <Loader />}
          <Container>
            <div className="confirmIcon">
              <LuAlertTriangle />
            </div>
            <div className="confirmIconMsg">
              <h4 style={{ color: "red" }}>{t("WARNING")}</h4>
              <p style={{ textTransform: "capitalize" }}>{subTitle}</p>
            </div>
            <div className="alertButton">
              <button
                type="button"
                variant="light"
                className="alertRightButton"
                onClick={() => {
                  handleCancel();
                }}
              >
                <span
                  style={{
                    backgroundImage:
                      "linear-gradient(297.65deg, #2FC2FE 14.83%, #0094F6 83.99%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    borderRadius: "3px",
                  }}
                >
                  {t("NO")}
                </span>
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleConfirm();
                }}
                className="confirmLeftButton"
              >
                {t("YES")}
              </button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Confirm;
