import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./SaveCard.module.css";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import cardScanner from "../../assets/Images/cardScanner.png";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react/dist/iconify.js";
import Confirm from "../CreateJob/Furnitures/Modals/confirm";
import Toster from "../../utils/Toaster";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from "../CreateJob/Furnitures/Payments/AddCard";

const SaveCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { stripeCustomerId } = useSelector((state) => state.user);
  const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
  const clientSecretKey = process.env.REACT_APP_STRIPE_CLIENT_SECRET_KEY;
  const stripePromise = loadStripe(secretKey);
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState({ show: false, id: "" });
  const [isAddingCard, setIsAddingCard] = useState(false);

  const getCardList = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/customers/${stripeCustomerId}/cards`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + clientSecretKey,
          },
        }
      );
      const responseData = await response.json();

      if (responseData.data) {
        setIsLoading(false);
        setCardData(responseData.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCardList();
  }, []);

  const handleDeleteCard = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/customers/${stripeCustomerId}/sources/${showConfirm.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + clientSecretKey,
          },
        }
      );

      if (response.ok) {
        setIsLoading(false);
        Toster(t("CARD_REMOVED"), "success");
        setCardData((prevCards) =>
          prevCards.filter((card) => card.id !== showConfirm.id)
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteCard = (id) => {
    setShowConfirm({ show: true, id });
  };

  const handleConfirm = () => {
    handleDeleteCard();
    setShowConfirm({ show: false, id: "" });
  };

  const handleCancel = () => {
    setShowConfirm({ show: false, id: "" });
  };

  const handleAddCard = () => {
    setIsAddingCard(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManageDriver">
          <div className={styles.bgColor}>
            <div className={styles.bgColorRight}>
              <FiArrowLeft
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className={styles.bgColorLeft}>
              <h2>{t("SAVE_CARD")}</h2>
            </div>
            <div></div>
          </div>
          {!isLoading && cardData.length == 0 && (
            <Row>
              <div className={styles.notFoundContainer}>
                <div className={styles.notFoundIcon}>
                  <Icon
                    icon="lucide:search-x"
                    color="grey"
                    width="100"
                    height="100"
                  />
                </div>
                <div className={styles.notFoundMessage}>
                  {t("CARDS_NOT_ADD")}
                </div>
              </div>
            </Row>
          )}
          <div>
            {cardData.length !== 0 && (
              <div className={styles.cardContainers}>
                <Row className={styles.cardContainer}>
                  {cardData.map((cards, ind) => {
                    return (
                      <Col className="mb-4">
                        <div className={styles.cardBlock}>
                          <div className={styles.cardWrapper}>
                            <div className={styles.cardDetails}>
                              <div className={styles.topSection}>
                                <div>
                                  <div className={styles.cardNumber}>
                                    {t("CARD_NUMBER")}
                                  </div>
                                  <div
                                    className={styles.cardNumberValue}
                                  >{`${"XXXX XXXX XXXX"} ${cards.last4}`}</div>
                                </div>
                                <div></div>
                              </div>
                              <div className={styles.bottomSection}>
                                <div className={styles.cardScannerImg}>
                                  <img src={cardScanner} alt="Card Image" />
                                </div>
                                <div>
                                  <div className={styles.cardExpiry}>
                                    {t("EXPIRY")}
                                  </div>
                                  <div className={styles.cardExpiryValue}>
                                    {" "}
                                    {`${cards.exp_month}/${cards.exp_year}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Icon
                              icon="ic:round-cancel"
                              width="22"
                              height="22"
                              className={styles.crossIcon}
                              onClick={() => {
                                deleteCard(cards.id);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
          </div>
          <div className={styles.addCardSection}>
            <button
              className={styles.addCardBtn}
              onClick={() => handleAddCard()}
            >
              <span>{t("ADD_CARD")}</span>
            </button>
          </div>
        </div>
      </div>
      <Confirm
        show={showConfirm.show}
        subTitle={t("SURE_CARD_REMOVED")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Elements stripe={stripePromise}>
        <AddCard
          isAddingCard={isAddingCard}
          setIsAddingCard={setIsAddingCard}
          getCardList={getCardList}
        />
      </Elements>
    </>
  );
};

export default SaveCard;
