export const STATUS_CODES = {
  HEADER_IS_MISSING: 100,
  DEVICE_TYPE_NOT_ALLOWED: 101,
  INVALID_API_KEY: 102,
  TOKEN_IS_REQUIRED: 103,
  INVALID_TOKEN: 104,
  SERVER_VALIDATION: 105,
  ACCESS_DENIED: 106,
  USER_NOT_FOUND: 107,
  ACCOUNT_BLOCKED: 108,
  SOCIAL_USER_NOT_FOUND: 109,
  FAILED_TO_ACCESS: 110,
  SUCCESS: 200,
  INTERNAL_SERVER_ERROR: 500,
  INVALID_LOGIN_CREDENTIALS: 111,
  ACCOUNT_NOT_FOUND: 112,
  ACCOUNT_CURRENTLY_INACTIVE: 113,
  ACCOUNT_DELETED: 114,
  OLD_PASSWORD_NOT_MATCH: 115,
  EMAIL_ALREADY_IN_USE: 117,
  PHONE_NUMBER_EXIST: 118,
  SOCIALID_EXIST: 119,
  EMAIL_LINKED_SOCIALUSER: 120,
  EMAIL_LINKED_NORMALUSER: 121,
  EMAIL_FAIL: 123,
  VEHICLE_IMG: 124,
  LICENSE_IMG: 125,
  GT_INSURANCE_IMG: 126,
  HR_INSURANCE_IMG: 127,
  PL_INSURANCE_IMG: 128,
  VEHICLE_IMG_SIZE: 129,
  INVALID_VEHICLE_IMG: 130,
  INVALID_VEHICLE_ID: 131,
  INVALID_COLOR_ID: 132,
  CODE_EXIST: 133,
  CODE_NOT_MATCH: 134,
  LICENSE_IMG_SIZE: 135,
  INVALID_LICENSE_IMG: 136,
  GT_INSURANCE_IMG_SIZE: 137,
  INVALID_GT_INSURANCE_IMG: 138,
  HR_INSURANCE_IMG_SIZE: 139,
  INVALID_HR_INSURANCE_IMG: 140,
  PL_INSURANCE_IMG_SIZE: 141,
  INVALID_PL_INSURANCE_IMG: 142,
  FAILED_IMG: 143,
  FAILED_LICENSE_IMG: 144,
  FAILED_GT_IMG: 145,
  FAILED_HR_IMG: 146,
  FAILED_PL_IMG: 147,
  INVALID_CATEGORY_ID: 148,
  INVALID_VEHICLETYPE_ID: 149,
  INVALID_DRIVER_ID: 150,
  INVALID_SOURSE_FLOOR_ID: 151,
  INVALID_DESTINATION_FLOOR_ID: 152,
  INVALID_HELPER_ID: 153,
  INVALID_CATEGORY_JSON: 154,
  WRONG: 155,
  INVALID_UNITID: 156,
  INVALID_STOP: 157,
  INVALID_SPROPERTYID: 158,
  INVALID_DPROPERTYID: 159,
  INVALID_SHEDULE_DATA: 160,
  TIME_EXIST: 161,
  CUSTOMER_NOT_PERMIT: 162,
  INVALID_PRICE: 163,
  FOR_SCHEDULE: 164,
  DRIVER_NOT_PERMISION: 165,
  CAN_NOT_DELETE: 166,
  ALEREADY_LICENCE_USE: 167,
  PROFILE_REQUIRED: 168,
  PROFILE_IMG_SIZE: 169,
  INVALID_PROFILE: 170,
  DOC_IMG_REQUIRED: 171,
  DOC_IMG_SIZE: 172,
  INVALID_DOC_IMG: 173,
  INVALID_DOC: 174,
  PASS_NOT_MATCH: 175,
  NOTIFY_ALREADY_EXIST: 176,
  ADD_VEHICLE: 177,
  VEHICLE_NAME_ALREDY: 178,
  NOT_PERMIT_USER: 179,
  VEHICLE_TYPE_NOT_DELETE: 180,
  VEHICLE_TYPE_STATUS: 181,
  INVALID_KEY: 182,
  MASTER_DATA_EXIST: 183,
  INVALID_MASTER_DATA: 184,
  MASTER_DATA_NOT_DELETE: 185,
  MASTER_DATA_STATUS: 186,
  INVALID_JOBID: 187,
  REQUEST_STATUS: 188,
  NOT_CHANGE_STATUS: 189,
  START_TIME: 190,
  PAYMENT_INTENT: 191,
  AVALABLE_DATE: 192,
  INVALID_COUPON_ID: 193,
  COUPON_ALREADY_EXISTS: 194,
  INVALID_RATING_ID: 195,
  INVALID_COMMISSION_id_type: 196,
  INVALID_ID: 197,
  // ESTIMATE_WEIGHT_ID: 200,
  INVALID_JOB_TEMP_ID: 201,
  CATEGORY_ICON: 202,
  CATEGORY_ICON_SIZE: 203,
  INVALID_CATEGORY_ICON: 204,
  CATEGORY_ADD: 205,
  CATEGORY_UPDATE: 206,
  CUSTOMER_EXIST: 207,
  ESTIMATE_WEIGHT_ID: 208,
  DRIVER_EXIST: 209,
  TIME_IS_REQUIRED_FOR_CATEGORY_ITEMS: 210,
  TIME_IS_REQUIRED_FOR_UPDATED_FLOOR: 211,
  DRIVER_DOCUMENT_ALREDAY_REJECTED: 212,
  DRIVER_DOCUMENT_ALREDAY_APPROVED: 213,
  DRIVER_DOCUMENT_ID_INVALID: 214,
  USER_STATUS: 215,
  IMAGE_REQUIRED: 216,
  ALREADY_RATING: 217,
  PAYMENT_SUCCESS: 218,
  PAYMENT_CREATE: 219,
  PAYMENT_INCOMPLETE: 220,
  JOB_INCOMPLETE: 221,
  INVALID_EBQUIRY: 222,
  CANCEL_JOB: 223,
  REFUND_PAYMENT: 224,
  COUPON_ADD_SUCCESS: 225,
  COUPON_UPDATE_SUCCESS: 226,
  COUPON_STATUS_CHANGED_SUCCESS: 227,
  INVALID_COUPON_CODE: 228,
  CUATOM_ITEM_NAME_ALREADY_EXIST: 229,
  PAYMENT_ALREADT_RELEASED: 230,
  INVALID_USER_SERVICE_ID: 231,
  DRIVER_SERVICE_ALREADY_REJECRED: 232,
  DRIVER_SERVICE_ALREADY_APPROVED: 233,
  INVALID_DRIVER_VEHICLE_ID: 234,
  DRIVER_VEHICLE_ALREADY_REJECTED: 235,
  DRIVER_VEHICLE_ALREADY_APPROVED: 236,
};
