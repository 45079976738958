import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

export default function MapView() {
  const { first } = useSelector((state) => state.createJob.createJobData);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const locations = [];

  useEffect(() => {
    setTimeout(() => {
      first.isSubmit && calculateRoute();
    }, 1000);
  }, []);

  // Add source location to the locations array
  locations.push({
    id: "source",
    name: first.sourceLocation,
    coordinates: [first.source_latitude, first.source_longitude],
  });

  // Add stops if available
  if (first.stops && first.stops.length > 0) {
    first.stops.forEach((stop, index) => {
      locations.push({
        id: `stop_${index + 1}`,
        name: stop.location,
        coordinates: [stop.lat, stop.lng],
      });
    });
  }

  // Add destination location to the locations array
  locations.push({
    id: "destination",
    name: first.destinationLocation,
    coordinates: [first.destination_latitude, first.destination_longitude],
  });

  const position = [first.source_latitude, first.source_longitude];
  const center = {
    lat: first.source_latitude,
    lng: first.source_longitude,
  };

  async function calculateRoute() {
    if (
      typeof window.google === "undefined" ||
      !new window.google.maps.DirectionsService()
    ) {
      console.error("Google Maps API not loaded.");
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const stopss = first.stops.map((stop) => ({
      location: { lat: stop.lat, lng: stop.lng },
      // stopover: true, // Make sure to specify stopover as true for each stop
    }));
    const results = await directionsService.route({
      destination: {
        lat: first.destination_latitude,
        lng: first.destination_longitude,
      },
      origin: {
        lat: first.source_latitude,
        lng: first.source_longitude,
      },
      waypoints: stopss, // Add waypoints here
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results && results);

    const distance = results.routes[0].legs.reduce(
      (total, leg) => total + leg.distance.value,
      0
    );
    const duration = results.routes[0].legs.reduce(
      (total, leg) => total + leg.duration.value,
      0
    );
  }

  const renderGoogleMap = () => {
    return (
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ height: "250px", width: "100%" }}
      >
        <DirectionsRenderer
          directions={directionsResponse && directionsResponse}
        />
      </GoogleMap>
    );
  };
  return <>{first.isSubmit && renderGoogleMap()}</>;
}
