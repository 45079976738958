import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SelectB2B({
  showSelectCategory,
  setShowSelectCategory,
  selectedCategory,
  setSelectedCategory,
  options,
}) {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
  });

  useEffect(() => {
    setCurrentValue(selectedCategory);
  }, [showSelectCategory.show]);

  const handleCheckboxChange = (id, value, time) => {
    setCurrentValue({ val: value, id: id });
  };

  const handleSubmit = () => {
    setSelectedCategory(currentValue);
    setShowSelectCategory((prevState) => ({ ...prevState, show: false }));
  };

  return (
    <>
      <Modal
        show={showSelectCategory.show}
        onHide={() => {
          setShowSelectCategory((prevState) => ({ ...prevState, show: false }));
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>Select Category</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                setShowSelectCategory((prevState) => ({
                  ...prevState,
                  show: false,
                }));
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {options?.map((checkbox) => (
              <div
                key={checkbox.id}
                className="floorCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(checkbox.id, checkbox.value_name);
                }}
              >
                <label
                  className={
                    currentValue?.val === checkbox.value_name
                      ? styles.activeFloor
                      : ""
                  }
                >
                  {checkbox.value_name}
                </label>
                {/* <Button
                  variant={
                    currentValue?.val === checkbox.value_name
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(checkbox.id, checkbox.value_name);
                  }}
                >
                  {currentValue?.val === checkbox.value_name
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              Done
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
