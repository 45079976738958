import { useTranslation } from "react-i18next";
import { DriverSettingData } from "../../../mockData";
import { IoIosArrowForward } from "react-icons/io";
import styles from "../../../DRIVERSIDE/appComponents/DriverProfile/DriverProfile.module.css";
import { NavLink } from "react-router-dom";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DocumentAlert from "../../authComponent/DriverSignUp/DocumentAlert";
import { useState } from "react";
import SublyApi from "../../../helpers/Api";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";

//---------function for setting sidebar---------
function SettingSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");

  //----- function for manage user logout-----
  const logout = async () => {
    setLoader(true);
    await SublyApi.logoutAccount(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        // Toster(t("LOGOUT_SUCCESS"), "success");
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- function for delete driver account-----
  const accountDelete = async () => {
    setLoader(true);
    await SublyApi.deleteAccount(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        Toster(t("DELETE_ACCOUNT_SUCCESS"), "success");
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {loader && <Loader />}
      <div className={styles.profileSettingsSection}>
        <h3 className={styles.profileHeading}>{t("SETTINGS")}</h3>
        {DriverSettingData.map((item, index) => (
          <NavLink
            onClick={item.onClick}
            to={item.link}
            key={index}
            className="driverActive"
          >
            <div className={styles.displayOption}>
              <p>{item.text}</p>
              <IoIosArrowForward />
            </div>
          </NavLink>
        ))}
        <div
          className={styles.displayOption}
          onClick={() => {
            setShowAlertModal(true);
            setTitle(`${t("DELETYETITLE")}`);
          }}
        >
          <p>{t("DELETE_ACCOUNT")}</p>
          <IoIosArrowForward />
        </div>
        <div
          className={`${styles.displayOption} ${styles.displayOptionBorder}`}
        >
          <p
            onClick={() => {
              setShowLogoutModal(true);
              setTitle(`${t("LOGOUTTITLE")}`);
            }}
          >
            {t("LOGOUT")}
          </p>
        </div>

        {/* alert modal popup for delete*/}
        <DocumentAlert
          setShowAlertModal={setShowAlertModal}
          showAlertModal={showAlertModal}
          onImageRemove={accountDelete}
          isDelete={true}
          title={title}
        />

        {/* alert modal popup for logout*/}
        <DocumentAlert
          setShowAlertModal={setShowLogoutModal}
          showAlertModal={showLogoutModal}
          onImageRemove={logout}
          isDelete={true}
          title={title}
        />
      </div>
    </>
  );
}
export default SettingSidebar;
