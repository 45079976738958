import React, { useEffect, useMemo, useState } from "react";
import styles from "./JobHistory.module.css";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import dayjs from "dayjs";
import SublyApi from "../../../helpers/Api";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import chatIcon from "../../../assets/DriverImages/chatIcon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader/Loader";
import { DATE_FORMATES, PAYMNET_STATUS } from "../../../utils/Constants";
import { Space } from "antd";
import { RangePicker, rangePresets } from "../../../utils/DateRange";
import { getFormateDate } from "./helper";

export default function DriverJobHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [filterByStartDate, setFilterByStartDate] = useState(null);
  const [filterByEndDate, setFilterByEndDate] = useState(null);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const handleDateRangeChange = (dates) => {
    if (!dates) {
      setFilterByStartDate(null);
      setFilterByEndDate(null);
      return;
    }

    const [start, end] = dates;
    setFilterByStartDate(start);
    setFilterByEndDate(end);
    setPageNo(1);
  };

  const getJobLists = (value = "") => {
    setIsLoading(true);
    const params = {
      type: "all",
      page_no: pageNo,
      filter_by_start_date: filterByStartDate
        ? getFormateDate(filterByStartDate)
        : "",
      filter_by_end_date: filterByEndDate
        ? getFormateDate(filterByEndDate)
        : "",
    };

    let res = SublyApi.getDriversJobList(params, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setIsLoadMore(response.data.loadMore);
        if (pageNo == 1 && filterByEndDate) {
          setJobList(response.data.jobList);
          return;
        }
        if (isLoadMore) {
          setJobList((prevJobList) => [
            ...prevJobList,
            ...response.data.jobList,
          ]);
        } else {
          setJobList(response.data.jobList);
        }
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        }
        if (
          response.code == STATUS_CODES.INVALID_TOKEN ||
          response.data.code == STATUS_CODES.INVALID_TOKEN
        ) {
          Toster(t(responseCode), "error");
          dispatch(userLogoutClear());
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getJobLists();
  }, [pageNo, filterByEndDate, filterByStartDate]);

  const handleClear = () => {
    setPageNo(1);
    setFilterByStartDate(null);
    setFilterByEndDate(null);
  };

  const handleViewDetails = (jobId) => {
    navigate(`/driver-job-details/${jobId}`, {
      state: { route: "jobHistory" },
    });
  };

  const onBack = () => {
    navigate("/profile");
  };

  const handleLoadMore = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
  };

  function calculateTotalAmount(jobInfo) {
    // var amount;
    // if (jobInfo.payment_summary[0].admin_amount) {
    //   amount = jobInfo.payment_summary[0].admin_amount;
    // } else {
    //   amount = jobInfo.payment_summary[0].amount;
    // }
    // var percentageAmount = (
    //   (amount * jobInfo.payment_summary[0].admin_commission) /
    //   100
    // ).toFixed(2);
    // return (amount - percentageAmount).toFixed(2);

    if (jobInfo) {
      if (jobInfo?.payment_summary[0]?.admin_amount) {
        return jobInfo?.payment_summary[0]?.admin_amount;
      } else {
        return jobInfo?.payment_summary[0]?.amount;
      }
    }
  }

  const totalDriverEarning = useMemo(() => {
    const filterNotCancelled = jobList.filter(
      (job) =>
        job.job_status !== "cancel_by_admin" &&
        job.job_status !== "cancel_customer"
    );
    return filterNotCancelled
      .reduce((total, job) => {
        var amount;
        if (job.payment_summary[0].admin_amount) {
          amount = job.payment_summary[0].admin_amount;
        } else {
          amount = job.payment_summary[0].amount;
        }
        return total + parseFloat(amount);
      }, 0)
      .toFixed(2);
  }, [jobList]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHeadSummmary}>
            <div className={styles.topHeadBackBtn}>
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div>
              <span className={styles.topTitle}>{t("JOB_HISTORY")}</span>
            </div>
            <div></div>
          </div>
          <div className={styles.dateRangeField} xl={3}>
            <div>
              <Form.Group className="mb-3">
                <Form.Label className={styles.selectDate}>
                  {t("SELECT_DATE")}
                </Form.Label>
                <Form.Group>
                  <Space
                    direction="vertical"
                    size={12}
                    style={{ width: "100%" }}
                  >
                    <RangePicker
                      inputReadOnly={true}
                      className="ant-picker-outlined"
                      presets={rangePresets}
                      value={[filterByStartDate, filterByEndDate]}
                      onChange={handleDateRangeChange}
                      format={DATE_FORMATES.DD_MM_YY}
                    />
                  </Space>
                </Form.Group>
              </Form.Group>
            </div>
            <div className={styles.applyBtnSection}>
              <Button onClick={() => handleClear()}>{t("RESET")}</Button>
            </div>
          </div>
          <div>
            <Container>
              <Row>
                {jobList &&
                  jobList.map((jobInfo) => {
                    return (
                      <Col lg={4} md={6} sm={12} className="mb-3">
                        <div
                          className={styles.topMainContainer}
                          onClick={() => handleViewDetails(jobInfo.id)}
                        >
                          <div className={styles.infoAvailabilty}>
                            <div>
                              <span className={styles.availabilityTitle}>
                                {t("TIME_OF_JOB")} -{" "}
                              </span>
                              <span className={styles.availabilityValue}>
                                {dayjs(jobInfo.availability_date).format(
                                  "DD MMM YYYY"
                                )}
                                ,{" "}
                                {dayjs(
                                  jobInfo.availability_time,
                                  "HH:mm:ss"
                                ).format("hh:mmA")}
                              </span>
                            </div>
                            <div>
                              <span className={styles.availabilityTitle}>
                                {t("HELPER")} :{" "}
                              </span>
                              <span className={styles.helperValue}>
                                {jobInfo.helper}
                              </span>
                            </div>
                          </div>
                          <div className={styles.locationContainer}>
                            <div className={styles.viaShow}>
                              <span className={styles.stopTitle}>
                                {t("VIA")} :{" "}
                              </span>
                              <span className={styles.stopValue}>
                                {jobInfo.stopCount ? jobInfo.stopCount : 0}
                              </span>
                            </div>
                            <div className={styles.addressRouteWrapper}>
                              <div className={styles.subLocationInner}>
                                <div className={styles.routeLinePath}>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                  <div className={styles.leftRouteLine}></div>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      vFlip
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                </div>
                                <div className={styles.subLocationContainer}>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("COLLECT_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.source_city &&
                                      jobInfo.source_postal_code
                                        ? `${jobInfo.source_city} ${jobInfo.source_postal_code}`
                                        : jobInfo.source_city ||
                                          jobInfo.source_location}
                                    </div>
                                  </div>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("DELIVER_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.destination_city &&
                                      jobInfo.destination_postal_code
                                        ? `${jobInfo.destination_city} ${jobInfo.destination_postal_code}`
                                        : jobInfo.destination_city ||
                                          jobInfo.destination_location}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.infoContainer}>
                            <hr className={styles.hrSection} />
                            <div className={styles.infoCategory}>
                              <div>
                                <span className={styles.categoryName}>
                                  {jobInfo.category_name}
                                </span>
                              </div>
                              <div>
                                <span className={styles.VehicleTitle}>
                                  {t("STOPS")} :{" "}
                                </span>
                                <span className={styles.vehicleValue}>
                                  {jobInfo.stopCount ? jobInfo.stopCount : 0}
                                </span>
                              </div>
                            </div>
                            <hr className={styles.hrSection} />
                            <div className={styles.infoItemVan}>
                              <div className={styles.paymentSection}>
                                {jobInfo.job_status !== "cancel_customer" &&
                                  jobInfo.job_status !== "cancel_by_admin" && (
                                    <>
                                      <div className={styles.VehicleTitle}>
                                        {t("PAYMENT_TEXT")} :{" "}
                                      </div>
                                      <div className={styles.vehicleValue}>
                                        {jobInfo.payment_release == null && (
                                          <span className={styles.pendingText}>
                                            {t("PENDING_FROM_ADMIN")}
                                          </span>
                                        )}
                                        {jobInfo.payment_release ==
                                          PAYMNET_STATUS.REQUEST_PAYMENT && (
                                          <span className={styles.pendingText}>
                                            {t("PENDING_FROM_ADMIN")}
                                          </span>
                                        )}
                                        {jobInfo.payment_release ==
                                          PAYMNET_STATUS.RELEASE_PAYMENT && (
                                          <span className={styles.successText}>
                                            {t("SUCCESS")}
                                          </span>
                                        )}
                                      </div>
                                    </>
                                  )}
                              </div>
                              <div className={styles.jobAmountSection}>
                                <div className={styles.jobAmount}>
                                  {"£"}
                                  {calculateTotalAmount(jobInfo)}
                                </div>
                                {(jobInfo.job_status == "cancel_customer" ||
                                  jobInfo.job_status == "cancel_by_admin") && (
                                  <div className={styles.jobStatusCancel}>
                                    {t("CANCELLED")}
                                  </div>
                                )}
                                {jobInfo.job_status == "complete" && (
                                  <div className={styles.jobStatus}>
                                    {t("COMPLETED")}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              {!isLoading && jobList.length !== 0 && (
                <div className={styles.totalEarnSetion}>
                  <span className={styles.earnTitle}>
                    {t("TOTAL_EARNING")} :&nbsp;
                  </span>
                  <span className={styles.earnValue}>
                    {"£"}
                    {totalDriverEarning}
                  </span>
                </div>
              )}
              <Row>
                {isLoadMore && !isLoading && (
                  <div className={styles.loadMoreSection}>
                    <span
                      className={styles.loadMoreBtn}
                      onClick={() => handleLoadMore()}
                    >
                      {t("LOAD_MORE")}
                    </span>
                  </div>
                )}
              </Row>
              {!isLoading && jobList.length == 0 && (
                <Row>
                  <div className={styles.notFoundContainer}>
                    <div className={styles.notFoundIcon}>
                      <Icon
                        icon="lucide:search-x"
                        color="grey"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className={styles.notFoundMessage}>
                      {t("JOBS_NOT_FOUND")}
                    </div>
                  </div>
                </Row>
              )}
            </Container>
          </div>
        </div>
        <div className={styles.chatFeature}>
          {/* <img src={chatIcon} alt="chat-image" /> */}
        </div>
      </div>
    </>
  );
}
