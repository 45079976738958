import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverLogin/DriverLogin.module.css";
import { useTranslation } from "react-i18next";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DriverFacebookSocialLogin from "../../../DRIVERSIDE/commonComponents/DriverFacebooklLogin/DriverFacebookSocialLogin";
import DriverGoogleSocialLogin from "../../../DRIVERSIDE/commonComponents/DriverGoogleLogin/DriverGoogleLogin";
import {
  clearForm,
  driverLogin,
  resetNextStep,
  setPreviousStep,
} from "../../../store/slices/UserSlice";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Toster from "../../../utils/Toaster";
import { SIGNUP_TYPE } from "../../../utils/Constants";
import Loader from "../../../utils/Loader/Loader";
import backgroundImage from "../../../assets/DriverImages/Bg.png";
import finalLogo from "../../../assets/Images/logo2.png";

//---------function for Login form----------
function DriverLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [passwordShow, setPasswordShow] = useState(false);
  const { isLoading } = useSelector((state) => state.user);

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  useEffect(() => {
    dispatch(clearForm(true));
    dispatch(resetNextStep());
    dispatch(setPreviousStep(false));
  }, [dispatch]);

  // --------function for submit login form--------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("signup_type", SIGNUP_TYPE.NORMAL);
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );
    requestData.append(
      "password",
      formdata ? formdata.password && formdata.password.trim() : ""
    );
    dispatch(driverLogin(requestData)).then((responsejson) => {
      const response = responsejson.payload;

      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        // Toster(t("LOGIN_SUCCESSFULLY"), "success");
        navigate("/driver-profile");
        setValue("email", "");
        setValue("password", "");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {isLoading === true ? <Loader /> : ""}
      <div className="spaceTopManageDriver">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlocks}>
              {/* <div className="leftContent"> */}
              <div className={styles.logoImage}>
                <NavLink to="/">
                  <img src={finalLogo} alt="logo-image" />
                </NavLink>
              </div>
              {/* <h1 className="commonheading">{t("LOGIN")}</h1>
              <p className={styles.loginPara}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              <div className={styles.loginType}>{t("DRIVER_LOGIN")}</div>
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <Form.Label>{t("EMAIL")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("EMAIL_TEXT")}`}
                    type="text"
                    autoComplete="off"
                    {...register("email", {
                      onChange: (e) => onInputChange(e),
                      required: {
                        value: true,
                        message: `${t("EMAIL_REQUIRED")}`,
                      },
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: `${t("EMAIL_INVALID")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.email && (
                    <span className="errorMsg">{errors.email.message}</span>
                  )}
                </Form.Group>
                <Form.Group className="setIconPassword">
                  <Form.Label>{t("PASSWORD")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("PASSWORD_TEXT")}`}
                    type={passwordShow ? "text" : "password"}
                    autoComplete="new-password"
                    {...register("password", {
                      onChange: (e) => {
                        onInputChange(e);
                      },
                      required: {
                        value: true,
                        message: `${t("PASSWORD_REQUIRED")}`,
                      },
                      minLength: {
                        value: 6,
                        message: `${t("PASS_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 12,
                        message: `${t("PASS_MAXLENGTH")}`,
                      },
                      pattern: {
                        value:
                          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                        message: `${t("INVALID_PASSWORD")}`,
                      },
                    })}
                  />

                  {errors.password && (
                    <span className="errorMsg">{errors.password.message}</span>
                  )}

                  <div className="passwordicon">
                    {passwordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      />
                    )}
                  </div>
                </Form.Group>
                <div className={styles.forgotPassword}>
                  <NavLink
                    to="/driver-forgot-password"
                    style={{
                      backgroundImage:
                        "linear-gradient(297.65deg, #2FC2FE 14.83%, #0094F6 83.99%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {t("FORGOT_PASSWORD")}
                  </NavLink>
                </div>
                <div className="commonButton">
                  <button type="submit">{t("LOGIN")}</button>
                </div>

                <div className="socialbutton">
                  {/* <DriverFacebookSocialLogin /> */}
                  <DriverGoogleSocialLogin />
                </div>

                <p className={styles.bottomText}>
                  {t("NO_ACCOUNT")}{" "}
                  <NavLink to="/driver-sign-up">{t("SINGUP")}</NavLink>
                </p>

                {/* <p className={styles.bottomText}>
                    Back to <NavLink to="/"> Home</NavLink>
                  </p> */}
              </Form>
              {/* </div> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default DriverLogin;
