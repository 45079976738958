import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverProfile/DriverProfile.module.css";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import SublyApi from "../../../helpers/Api";

function BankAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  //--------function for get driver details api calling--------
  useEffect(() => {
    setLoader(true);
    SublyApi.fetchDriverBankInfo(userToken).then((response) => {
      setLoader(false);
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setValue(
          "bankName",
          response && response.data ? response.data.bank_name : ""
        );
        setValue(
          "bankAccountNumber",
          response && response.data ? response.data.bank_account_no : ""
        );
        setValue(
          "bankSortCode",
          response && response.data ? response.data.sort_code : ""
        );
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }, []);
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "bank_name",
      formdata ? formdata.bankName && formdata.bankName.trim() : ""
    );
    requestData.append(
      "bank_account_no",
      formdata
        ? formdata.bankAccountNumber && formdata.bankAccountNumber.trim()
        : ""
    );
    requestData.append(
      "sort_code",
      formdata ? formdata.bankSortCode && formdata.bankSortCode.trim() : ""
    );
    setLoader(true);
    await SublyApi.updateDriverBankInfo(requestData, userToken).then(
      (response) => {
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          Toster(t("BANK_INFO_SUCCESS"), "success");
          setLoader(false);
          // reset();
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
          setLoader(false);
        } else {
          setLoader(false);
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // Function to sanitize input value to ensure only numbers are accepted
  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row className={styles.drProfileSectionRow}>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3 className={styles.profileHeading}>
                        {t("BANK_ACCOUNT")}
                      </h3>
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="formSection formTopSpaceCls"
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>{t("BANK_NAME")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("ENTER_BANK_NAME")}`}
                            type="text"
                            {...register("bankName", {
                              onChange: (e) => handleKeyPress(e),
                              required: {
                                value: true,
                                message: `${t("BANK_NAME_REQUIRED")}`,
                              },
                              minLength: {
                                value: 3,
                                message: `${t("BANK_NAME_MINLENGTH")}`,
                              },
                              maxLength: {
                                value: 100,
                                message: `${t("BANK_NAME_MAXLENGTH")}`,
                              },
                              ...sanitizeHtmlTags(),
                            })}
                          />
                          {errors.bankName && (
                            <span className="errorMsg">
                              {errors.bankName.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("BANK_ACCOUNT_NUMBER")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("ENTER_BANK_ACCOUNT_NUMBER")}`}
                            type="text"
                            {...register("bankAccountNumber", {
                              onChange: handleChange, // Sanitize input value
                              onKeyPress: (e) => handleKeyPress(e),
                              required: {
                                value: true,
                                message: `${t("BANK_ACCOUNT_NUMBER_REQUIRED")}`,
                              },
                              minLength: {
                                value: 2,
                                message: `${t(
                                  "BANK_ACCOUNT_NUMBER_MINLENGTH"
                                )}`,
                              },
                              maxLength: {
                                value: 25,
                                message: `${t(
                                  "BANK_ACCOUNT_NUMBER_MAXLENGTH"
                                )}`,
                              },
                              ...sanitizeHtmlTags(),
                            })}
                          />
                          {errors.bankAccountNumber && (
                            <span className="errorMsg">
                              {errors.bankAccountNumber.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("BANK_SORT_CODE")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("ENTER_BANK_SORT_CODE")}`}
                            type="text"
                            {...register("bankSortCode", {
                              onChange: handleChange, // Sanitize input value
                              onKeyPress: (e) => handleKeyPress(e),
                              required: {
                                value: true,
                                message: `${t("BANK_SORT_CODE_REQUIRED")}`,
                              },
                              minLength: {
                                value: 2,
                                message: `${t("BANK_SORT_CODE_MINLENGTH")}`,
                              },
                              maxLength: {
                                value: 25,
                                message: `${t("BANK_SORT_CODE_MAXLENGTH")}`,
                              },
                              ...sanitizeHtmlTags(),
                            })}
                          />
                          {errors.bankSortCode && (
                            <span className="errorMsg">
                              {errors.bankSortCode.message}
                            </span>
                          )}
                        </Form.Group>
                        <div
                          className={`${styles.updateBtnSpace} commonButton`}
                        >
                          <button type="submit">{t("UPDATE")}</button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
}
export default BankAccount;
