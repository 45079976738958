import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../../../DRIVERSIDE/appComponents/DriverHome/DriverHome.module.css";
import { FiSearch } from "react-icons/fi";
import options from "../../../assets/DriverImages/options.svg";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Toster from "../../../utils/Toaster";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { Icon } from "@iconify/react/dist/iconify.js";
import Loader from "../../../utils/Loader/Loader";
import { useNavigate } from "react-router-dom";
import DocumentAlert from "../../authComponent/DriverSignUp/DocumentAlert";
import Slider from "react-slick";
import { FcNext, FcPrevious } from "react-icons/fc";
import Filter from "./Modal/Filter";

//---------function for driver home page---------
function DriverHome() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);

  const [type, setType] = useState("pending");
  const [date, setDate] = useState();
  const [pageNo, setPageNo] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [jobList, setJobList] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedInfo, setSelectedInfo] = useState({ id: "", status: "" });
  const [filterModal, setFilterModal] = useState({ show: false });
  const [geometry, setGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });
  const [distance, setDistance] = useState(150);
  const [showBadge, setShowBadge] = useState(false);

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <>
        <FcPrevious
          className={`${styles.PrevSlickArrow} slick-arrow`}
          onClick={onClick}
          style={{
            cursor: "pointer",
            border: "1px solid #f5f5f5",
            borderRadius: "50%",
            width: "28px",
            height: "28px",
            padding: "6px",
            background: "#ffffff",
            boxShadow: "0px 0px 4px 0px #00000026",
          }}
        />
      </>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <>
        <FcNext
          className={`${styles.NextSlickArrow} slick-arrow`}
          onClick={onClick}
          style={{
            cursor: "pointer",
            border: "1px solid #f5f5f5",
            borderRadius: "50%",
            width: "28px",
            height: "28px",
            padding: "6px",
            background: "#ffffff",
            boxShadow: "0px 0px 4px 0px #00000026",
          }}
        />
      </>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const getJobLists = (isClear) => {
    setIsLoading(true);

    const params = isClear
      ? { type }
      : {
          type,
          date: date ? dayjs(date).format("YYYY-MM-DD") : "",
          category_ids: selectedCategories.join(","),
          page_no: pageNo,
          search: searchKey,
          distance: distance ? distance : "",
          source_latitude: geometry.lat,
          source_longitude: geometry.lng,
          location: geometry.address,
        };

    let res = SublyApi.getDriversJobList(params, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setJobList(response.data.jobList);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        }
        if (
          response.code == STATUS_CODES.INVALID_TOKEN ||
          response.data.code == STATUS_CODES.INVALID_TOKEN
        ) {
          Toster(t(responseCode), "error");
          dispatch(userLogoutClear());
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getJobLists();
  }, [type]);

  const handleFilterApply = () => {
    getJobLists();
    if (
      selectedCategories.length > 0 ||
      date ||
      distance != 150 ||
      geometry.lat
    ) {
      setShowBadge(true);
    }
  };

  const handleClearFilter = () => {
    setDistance("");
    setGeometry({ address: "", lat: "", lng: "" });
    getJobLists(true);
    setShowBadge(false);
  };

  useEffect(() => {
    getJobLists();
  }, [searchKey]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchKey(value);
  };

  // const handleSearchChange = (e) => {
  //   const { value } = e.target;
  //   setSearchKey(value);

  //   const getFilterdData = setTimeout(() => {
  //     setIsLoading(true);
  //     const params = {
  //       type,
  //       date: date ? dayjs(date).format("YYYY-MM-DD") : "",
  //       category_ids: selectedCategories.join(","),
  //       page_no: pageNo,
  //       search: value,
  //       distance: distance ? distance : "",
  //     };

  //     let res = SublyApi.getDriversJobList(params, userToken);
  //     res.then((response) => {
  //       const responseMsg = response && response.data && response.data.message;
  //       const responseCode =
  //         STATUS_MSG[response && response.data && response.data.code];
  //       setIsLoading(false);
  //       if (response.code === STATUS_CODES.SUCCESS) {
  //         setJobList(response.data.jobList);
  //       } else {
  //         if (
  //           response.code == STATUS_CODES.SERVER_VALIDATION ||
  //           response.data.code == STATUS_CODES.SERVER_VALIDATION
  //         ) {
  //           Toster(responseMsg, "error");
  //         }
  //         if (
  //           response.code == STATUS_CODES.INVALID_TOKEN ||
  //           response.data.code == STATUS_CODES.INVALID_TOKEN
  //         ) {
  //           Toster(t(responseCode), "error");
  //           dispatch(userLogoutClear());
  //         } else {
  //           Toster(t(responseCode), "error");
  //         }
  //       }
  //     });
  //     res.catch(() => {
  //       // setIsLoading(false);
  //     });
  //   }, 1000);

  //   return () => clearTimeout(getFilterdData);
  // };

  const handleViewDetails = (jobId) => {
    navigate(`/driver-job-details/${jobId}`);
  };

  const handleChangeStatus = () => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("request_status", selectedInfo.status);

    let res = SublyApi.changeRequestJobStatus(
      selectedInfo.id,
      userToken,
      requestData
    );
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("STATUS_CHANGE_SUCCESS"), "success");
        getJobLists();
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  const handleOpenAlert = (jobId, type) => {
    setAlertModal(true);
    setSelectedInfo({ id: jobId, status: type });
    if (type == "reject_driver") {
      setTitle(t("REJECT_THIS_JOB"));
    } else {
      setTitle(t("ACCEPT_THIS_JOB"));
    }
  };

  const handleFilter = () => {
    setFilterModal((pre) => ({ ...pre, show: true }));
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManageDriver">
          <div
            style={{
              width: "100%",
              padding: "30px 0px",
              background:
                "linear-gradient(297.65deg, #2FC2FE 14.83%, #0094F6 83.99%)",
            }}
          ></div>
          <Container>
            <div className="">
              <div className={styles.homeJobs}>
                <p>{t("HAVE_JOBS_REQUEST")}</p>
                <h3>{t("OFFFER_FOR_YOU")}</h3>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </p> */}
                <div className={styles.searchBox}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className={styles.searchInput}
                      placeholder={t("Search")}
                      value={searchKey}
                      onChange={(e) => handleSearchChange(e)}
                    />
                  </Form.Group>
                  <FiSearch />
                  {/* <img
                    src={options}
                    alt="svg-img"
                    onClick={() => handleFilter()}
                  /> */}
                  {showBadge && <div className={styles.filterDotIcon}></div>}
                  <Icon
                    icon="circum:filter"
                    className={styles.filterIcon}
                    onClick={() => handleFilter()}
                  />
                </div>
              </div>
            </div>
            <Row className={styles.topWrapper}>
              {jobList &&
                jobList.map((jobInfo) => {
                  return (
                    <Col lg={6} md={6} sm={12} className="mb-3">
                      <div className={styles.topMainContainer}>
                        <div className={styles.topDivSection}>
                          {/* <div
                            className={styles.locationContainer}
                            onClick={() => handleViewDetails(jobInfo.id)}
                          >
                            <div className={styles.topDiv}>
                              <div className={styles.sourceContainer}>
                                <div>
                                  <Icon
                                    icon="mingcute:location-2-fill"
                                    color="#EC3B1E"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div>
                                  <div className={styles.sourceName}>
                                    {t("DESTINATION_LOCATION")}
                                  </div>
                                  <div className={styles.sourceValue}>
                                    {jobInfo.destination_location}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.verticalDivider}>
                              <span></span>
                            </div>
                            <div className={styles.topDiv}>
                              <div className={styles.sourceContainer}>
                                <div>
                                  <Icon
                                    icon="mage:location-fill"
                                    color="#EC3B1E"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div>
                                  <div className={styles.sourceName}>
                                    {t("SOURCE_LOCATION")}
                                  </div>
                                  <div className={styles.sourceValue}>
                                    {jobInfo.source_location}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div
                            className={styles.locationContainer}
                            onClick={() => handleViewDetails(jobInfo.id)}
                          >
                            <div className={styles.viaShow}>
                              <span className={styles.stopTitle}>
                                {t("VIA")} :{" "}
                              </span>
                              <span className={styles.stopValue}>
                                {jobInfo.stopCount == null
                                  ? 0
                                  : jobInfo.stopCount}
                              </span>
                            </div>

                            <div className={styles.addressRouteWrapper}>
                              <div className={styles.subLocationInner}>
                                <div className={styles.routeLinePath}>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                  <div className={styles.leftRouteLine}></div>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      vFlip
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                </div>
                                <div className={styles.subLocationContainer}>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("COLLECT_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.source_city &&
                                      jobInfo.source_postal_code
                                        ? `${jobInfo.source_city} ${jobInfo.source_postal_code}`
                                        : jobInfo.source_city ||
                                          jobInfo.source_location}
                                    </div>
                                  </div>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("DELIVER_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.destination_city &&
                                      jobInfo.destination_postal_code
                                        ? `${jobInfo.destination_city} ${jobInfo.destination_postal_code}`
                                        : jobInfo.destination_city ||
                                          jobInfo.destination_location}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className={styles.hrSection} />
                          <div
                            onClick={() => handleViewDetails(jobInfo.id)}
                            className={styles.stopSection}
                          >
                            <div>
                              <span className={styles.jobTime}>
                                {t("TIME_OF_JOB")} :{" "}
                              </span>
                              <span className={styles.jobDate}>
                                {dayjs(jobInfo.availability_date).format(
                                  "DD MMM YYYY"
                                )}
                                ,{" "}
                                {dayjs(
                                  jobInfo.availability_time,
                                  "HH:mm:ss"
                                ).format("hh:mmA")}
                              </span>
                            </div>
                            <div>
                              <div>
                                <span className={styles.jobTime}>
                                  {t("HELPER")} :{" "}
                                </span>
                                <span className={styles.jobDate}>
                                  {jobInfo.helper}
                                </span>
                              </div>
                              <div>
                                <span className={styles.jobTime}>
                                  {t("FLOOR")} :{" "}
                                </span>
                                <span className={styles.jobDate}>
                                  {jobInfo.floors <= 8
                                    ? jobInfo.floors
                                    : t("ABOVE_8TH_FLOOR")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr className={styles.hrSection} />
                          {jobInfo?.items.length > 1 && (
                            <Slider {...settings}>
                              {jobInfo?.items.map((items, ind, arr) => {
                                return (
                                  <div
                                    className={styles.furnitureContainerSlider}
                                  >
                                    <div className={styles.itemsSection}>
                                      <div className={styles.titleName}>
                                        {items.is_custom_item == 1
                                          ? t("OWN_ITEM")
                                          : jobInfo.category_name}
                                      </div>
                                      <div className={styles.tabsCounter}>
                                        <span>{ind + 1}</span>
                                        <span className={styles.tabCount}>
                                          /{arr.length}
                                        </span>
                                      </div>
                                    </div>
                                    <div className={styles.itemsSection}>
                                      <div className={styles.furnitureName}>
                                        {items.is_custom_item == 1
                                          ? items.item
                                          : items.sub_category}
                                      </div>
                                      <div className={styles.rightQuantity}>
                                        <span className={styles.titleName}>
                                          {t("QUANTITY")} :{" "}
                                        </span>
                                        {items.quantity}
                                      </div>
                                    </div>
                                    <div className={styles.titleName}>
                                      {items.is_custom_item == 1
                                        ? ""
                                        : items.item}
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          )}
                          {jobInfo?.items.length == 1 &&
                            jobInfo?.items.map((items, ind, arr) => {
                              return (
                                <div
                                  className={
                                    styles.singleFurnitureContainerSlider
                                  }
                                  onClick={() => handleViewDetails(jobInfo.id)}
                                >
                                  <div className={styles.itemsSection}>
                                    <div className={styles.titleName}>
                                      {jobInfo.category_name}
                                    </div>
                                    <div className={styles.tabsCounter}>
                                      <span>{ind + 1}</span>
                                      <span className={styles.tabCount}>
                                        /{arr.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.itemsSection}>
                                    <div className={styles.furnitureName}>
                                      {items.sub_category}
                                    </div>
                                    <div className={styles.rightQuantity}>
                                      <span className={styles.titleName}>
                                        {t("QUANTITY")} :{" "}
                                      </span>
                                      {items.quantity}
                                    </div>
                                  </div>
                                  <div className={styles.titleName}>
                                    {items.item}
                                  </div>
                                </div>
                              );
                            })}
                          <hr className={styles.hrSection} />
                          {/* <div
                            className={styles.stopSection}
                            onClick={() => handleViewDetails(jobInfo.id)}
                          >
                            <div>
                              <span className={styles.stopTitle}>
                                {t("STOPS")} :{" "}
                              </span>
                              <span className={styles.stopValue}>
                                {jobInfo.stopCount == null
                                  ? 0
                                  : jobInfo.stopCount}
                              </span>
                            </div>
                            <div>
                              <span className={styles.stopTitle}>
                                {t("HELPER")} :{" "}
                              </span>
                              <span className={styles.stopValue}>
                                {jobInfo.helper}
                              </span>
                            </div>
                          </div> */}
                          {/* <hr className={styles.hrSection} /> */}
                        </div>
                        <div className={styles.acceptSection}>
                          <div className={styles.acceptBtns}>
                            <div
                              className={styles.rejectBtn}
                              onClick={() =>
                                handleOpenAlert(jobInfo.id, "reject_driver")
                              }
                            >
                              <div>
                                <MdCancel color="#EC3B1E" />
                              </div>
                              <div>{t("REJECT")}</div>
                            </div>
                            <div
                              className={styles.acceptBtn}
                              onClick={() =>
                                handleOpenAlert(jobInfo.id, "accept")
                              }
                            >
                              <div>
                                <FaCheckCircle color="#058321" />
                              </div>
                              <div>{t("ACCEPT_TEXT")}</div>
                            </div>
                          </div>
                          {/* <div className={styles.jobAmount}>
                            {"£"}
                            {jobInfo.payment_summary[0].amount}
                          </div> */}

                          <div className={styles.jobAmount}>
                            {"£"}
                            {jobInfo.payment_summary[0].admin_amount
                              ? jobInfo.payment_summary[0].admin_amount
                              : jobInfo.payment_summary[0].amount}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {!isLoading && jobList.length == 0 && (
              <Row>
                <div className={styles.notFoundContainer}>
                  <div className={styles.notFoundIcon}>
                    <Icon
                      icon="lucide:search-x"
                      color="grey"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className={styles.notFoundMessage}>
                    {t("JOBS_NOT_FOUND")}
                  </div>
                </div>
              </Row>
            )}
          </Container>
        </div>
      </div>

      {/* alert modal popup for accept/reject job */}
      <DocumentAlert
        setShowAlertModal={setAlertModal}
        showAlertModal={alertModal}
        onImageRemove={handleChangeStatus}
        isDelete={true}
        title={title}
      />

      {/* modal popup for filter jobs*/}
      <Filter
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        date={date}
        setDate={setDate}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        handleFilterApply={handleFilterApply}
        setSearchKey={setSearchKey}
        geometry={geometry}
        setGeometry={setGeometry}
        distance={distance}
        setDistance={setDistance}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}
export default DriverHome;
