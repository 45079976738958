export const getActiveTab = (tabName) => {
  switch (tabName) {
    case "first":
      return 1;
    case "second":
      return 2;
    case "third":
      return 3;
    case "fourth":
      return 4;
    case "fifth":
      return 5;
    default:
      return 0; // Return 0 or handle other cases if needed
  }
};
