import SignUpFirstForm from "./SignUpFirst";
import SignUpSecondForm from "./SignUpSecond";
import SignUpThirdForm from "./SignUpThirdForm";
import SignUpFinal from "./SignUpFinal";
import { setNextStep, setPreviousStep } from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// ---------function for manage signup steps flow----------
function SignupFlow() {
  const dispatch = useDispatch();
  const { nextStep, previousStep } = useSelector((state) => state.user);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [previousStep, nextStep]);

  return (
    <>
      {nextStep == 0 ? (
        <SignUpFirstForm
          setNextStep={(value) => dispatch(setNextStep(value))}
          previousStep={previousStep}
          setPreviousStep={(value) => dispatch(setPreviousStep(value))}
        />
      ) : nextStep == 1 ? (
        <SignUpSecondForm
          setNextStep={(value) => dispatch(setNextStep(value))}
          previousStep={previousStep}
          setPreviousStep={(value) => dispatch(setPreviousStep(value))}
        />
      ) : nextStep == 2 ? (
        <SignUpThirdForm
          setNextStep={(value) => dispatch(setNextStep(value))}
          previousStep={previousStep}
          setPreviousStep={(value) => dispatch(setPreviousStep(value))}
        />
      ) : nextStep == 3 ? (
        <SignUpFinal
          setNextStep={(value) => dispatch(setNextStep(value))}
          previousStep={previousStep}
          setPreviousStep={(value) => dispatch(setPreviousStep(value))}
        />
      ) : (
        nextStep == 4
      )}
    </>
  );
}

export default SignupFlow;
