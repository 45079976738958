export const DEVICE_TYPE = {
  DEVICE_TYPE: "web",
};

export const SIGNUP_TYPE = {
  NORMAL: "normal",
  SOCIAL: "social",
};

export const SOCIAL_TYPE = {
  GOOGLE: "google",
  FACEBOOK: "facebook",
};

export const USER_TYPE = {
  CUSTOMER: "customer",
  DRIVER: "driver",
};

export const DOCUMENT_TYPE = {
  DLICENSE: "driving_lincence",
  GT_INSURANCE: "goods_in_transit_insurance",
  HR_INSURANCE: "hire_reward_insurance",
  PL_INSURANCE: "public_liability_insurance",
};

export const SETTING_OPTION_TYPE = {
  PAGE: "page",
};

export const SETTING_OPTION = {
  ABOUT_US: "about_us",
  PRIVACY_POLICY: "privacy_policy",
  TermsConditions: "term_and_condition",
};

export const PAYMNET_STATUS = {
  REQUEST_PAYMENT: "request_payment",
  PAYMENT_RELEASE: "payment_release",
  RELEASE_PAYMENT: "release_payment",
};

export const DATE_FORMATES = {
  DD_MM_YY: "DD/MM/YYYY",
};

export const CHECK_TYPE = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
};
