import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import First from "./Tabs/First";
import Second from "./Tabs/Second";
import Third from "./Tabs/Third";
import Fourth from "./Tabs/Fourth";
import Fifth from "./Tabs/Fifth";
import { resetAllJobData, setActiveTab } from "../../../store/slices/createJob";
import { useLocation } from "react-router-dom";
import B2bEnquiry from "./Tabs/B2bEnquiry";
import Header from "../../Header/Header";

const StepsLayout = ({ children }) => {
  return (
    <div>
      <Header children={children} />
    </div>
  );
};

function Furnitures() {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeTab = useSelector((state) => state.createJob.activeTab);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [activeTab]);

  useEffect(() => {
    if (location.state?.sureToClear || location.state == null) {
      dispatch(resetAllJobData());
      dispatch(setActiveTab("first"));
    } else {
    }
  }, []);

  const renderTab = (tabName) => {
    switch (tabName) {
      case "first":
        return <First />;
      case "second":
        return <Second />;
      case "third":
        return <Third />;
      case "fourth":
        return <Fourth />;
      case "fifth":
        return <Fifth />;
      case "b2bEnquiry":
        return <B2bEnquiry />;
      default:
        return null; // You can handle other cases or unknown tabs here
    }
  };

  return (
    <>
      <StepsLayout>{renderTab(activeTab)}</StepsLayout>
    </>
  );
}

export default Furnitures;
