import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { sanitizeHtmlTags } from "../../../../utils/sanitizeHtmlTags";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "../Module/Support.module.css";
import Loader from "../../../../utils/Loader/Loader";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { userLogoutClear } from "../../../../store/slices/UserSlice";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import SublyApi from "../../../../helpers/Api";
import { useNavigate } from "react-router-dom";
import Toster from "../../../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";

const RaiseATicketPopup = ({
  CloseHandleRaiseTiKet,
  showRaiseTicket,
  currentIssueValue,
  setSupport,
  setCurrentBookingValue,
  setCurrentStatusValue,
  setCurrentIssueValue,
  setCurrentValue,
  setIsNoMoreOptions,
  lastOptionID,
}) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const RaiseTicketApi = async (formdata) => {
    let requestData = new FormData();
    requestData.append("support_category_id", lastOptionID);
    requestData.append(
      "description",
      formdata ? formdata.description && formdata.description.trim() : ""
    );

    setLoader(true);
    await SublyApi.ContactSupport(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setLoader(false);
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("SUPPORT_SUBMIT_SUCCESS"), "success");
        CloseHandleRaiseTiKet();
        setSupport([]);
        setCurrentBookingValue({ val: "", id: "" });
        setCurrentStatusValue({ val: "", id: "" });
        setCurrentIssueValue({ val: "", id: "" });
        setCurrentValue({ val: "", id: "" });
        setIsNoMoreOptions(false);

        reset();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onClose = () => {
    CloseHandleRaiseTiKet();
  };

  return (
    <>
      <Modal show={showRaiseTicket} onHide={() => onClose()}>
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title> {t("RAISE_A_TICKET")}</Modal.Title>
            <IoMdCloseCircle onClick={() => onClose()} />
          </Modal.Header>

          <Modal.Body>
            {loader && <Loader />}
            <Form
              onSubmit={handleSubmit(RaiseTicketApi)}
              className="formSectionSupport"
            >
              <Form.Group className={`${styles.formgropupmb} mb-4 mt-2`}>
                <Form.Control
                  placeholder={`${t("ENTER_DESCRIPTION")}`}
                  as="textarea"
                  type="text"
                  rows={5}
                  {...register("description", {
                    onChange: (e) => handleKeyPress(e),
                    required: {
                      value: true,
                      message: `${t("DESCRIPTION_REQUIRED")}`,
                    },
                    minLength: {
                      value: 2,
                      message: `${t("DESCRIPTION_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 200,
                      message: `${t("DESCRIPTION_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />
                {errors.description && (
                  <span className="errorMsg">{errors.description.message}</span>
                )}
              </Form.Group>

              <div className="commonButton" style={{ width: "100%" }}>
                <button type="submit">{t("SUBMIT")}</button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default RaiseATicketPopup;
