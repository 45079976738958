import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./AddCard.module.css";
import { useTranslation } from "react-i18next";
import successImg from "../../../../assets/Images/successImg.png";
import { useNavigate } from "react-router-dom";

function Success({ isPaymentSuccess, setIsPaymentSuccess, jobId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsPaymentSuccess(false);
    navigate(`/job-details/${jobId}`);
  };

  const onClose = () => {
    setIsPaymentSuccess(false);
    navigate(`/job-details/${jobId}`);
  };

  return (
    <>
      <Modal
        show={isPaymentSuccess}
        onHide={() => {
          onClose();
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title></Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className={styles.successInfo}>
              <img src={successImg} alt="success-icon" />
              <div className={styles.successText}>{t("BOOKING_CONFIRMED")}</div>
              <div className={styles.successTextDesc}>
                {t("SUCCESSFULLY_TRANSFERED")}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.addCardBtn}
              onClick={() => handleSubmit()}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Success;
