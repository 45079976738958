import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../../../DRIVERSIDE/appComponents/DriverProfile/DriverProfile.module.css";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import SublyApi from "../../../helpers/Api";
import { TiArrowSortedDown } from "react-icons/ti";
// import VehicleSelectModal from "./VehicleSelectPopup";
import VehicleTypeModal from "../../authComponent/DriverSignUp/Vehicletype";

//---------function for add price---------
function PriceSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [vehicleListModal, setVehicleListModal] = useState(false);
  const [selectVehicle, setSelectVehicle] = useState("");
  const [vehicleList, setVehicleList] = useState([]);

  const { userToken } = useSelector((state) => state.user);

  const onInputChange = (e) => {
    let value = e.target.value.replace(/\s/g, ""); // Remove spaces
    if (value.includes(".")) {
      // Split the value into integer and decimal parts
      let [integerPart, decimalPart] = value.split(".");

      // Limit integer part to 5 digits
      if (integerPart.length > 5) {
        integerPart = integerPart.slice(0, 5);
      }

      // Limit decimal part to 2 digits
      if (decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Combine the parts back together
      value = decimalPart ? `${integerPart}.${decimalPart}` : `${integerPart}.`;
    } else {
      // Handle the case where there is no decimal point
      if (value.length > 5) {
        value = value.slice(0, 5);
      }
    }

    // Set the value in the form state
    setValue(e.target.name, value);
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    const isDecimalPoint = e.keyCode === 190 || e.keyCode === 110;
    if (
      !(
        (
          (e.keyCode >= 48 && e.keyCode <= 57) || // 0-9
          (e.keyCode >= 96 && e.keyCode <= 105) || // Numpad 0-9
          e.keyCode === 8 || // Backspace
          e.keyCode === 9 || // Tab
          e.keyCode === 37 || // Left arrow
          e.keyCode === 39 || // Right arrow
          e.keyCode === 46 || // Delete
          (isDecimalPoint && value.indexOf(".") === -1)
        ) // Allow only one decimal point
      )
    ) {
      e.preventDefault();
    }

    // Restrict the input length to a maximum of 6 characters
    if (value.length >= 6 && e.keyCode !== 8 && e.keyCode !== 46) {
      e.preventDefault();
    }
  };

  //--------function for get vehicle price list api calling--------
  async function getPriceList(show) {
    !show && setLoader(true);
    await SublyApi.getDriverPriceList(userToken, selectVehicle.id).then(
      (response) => {
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          if (
            response &&
            response.data &&
            response.data.priceSettingList &&
            response.data.priceSettingList.length > 0
          ) {
            response.data.priceSettingList.map((item, index) => {
              setValue(item.price_type, item.price_value);
              clearErrors("");
            });
          } else {
            setValue("miles_before_running_mileage", "");
            setValue("arrvival_running_milleage", "");
            setValue("milleage", "");
            setValue("out_of_hours_charge", "");
            setValue("stoppage", "");
            setValue("no_help_required", "");
            setValue("driver_assistance", "");
            setValue("driver_helper", "");
            setValue("driver_2_helper", "");
            setValue("stair_charge", "");
            setValue("packing_price", "");
            clearErrors("");
          }
          setLoader(false);
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
          setLoader(false);
        } else {
          setLoader(false);
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  }

  useEffect(() => {
    getPriceList(true);
    setValue("vanType", selectVehicle.vehicle_name);
  }, [selectVehicle]);

  //----------function for update vehicle price api calling----------
  const onSubmit = async (formData) => {
    let requestData = new FormData();
    const jsonData = JSON.stringify([
      {
        price_type: "miles_before_running_mileage",
        price_value: formData && formData.miles_before_running_mileage,
      },
      {
        price_type: "arrvival_running_milleage",
        price_value: formData && formData.arrvival_running_milleage,
      },
      { price_type: "milleage", price_value: formData && formData.milleage },
      {
        price_type: "out_of_hours_charge",
        price_value: formData && formData.out_of_hours_charge,
      },
      { price_type: "stoppage", price_value: formData && formData.stoppage },
      {
        price_type: "no_help_required",
        price_value: formData && formData.no_help_required,
      },
      {
        price_type: "driver_assistance",
        price_value: formData && formData.driver_assistance,
      },
      {
        price_type: "driver_helper",
        price_value: formData && formData.driver_helper,
      },
      {
        price_type: "driver_2_helper",
        price_value: formData && formData.driver_2_helper,
      },
      {
        price_type: "stair_charge",
        price_value: formData && formData.stair_charge,
      },
      {
        price_type: "packing_price",
        price_value:
          formData && formData.packing_price ? formData.packing_price : 0,
      },
    ]);
    requestData.append("price_setting", jsonData);

    requestData.append(
      "vehicle_type_id",
      selectVehicle && selectVehicle.id ? selectVehicle.id : ""
    );

    setLoader(true);
    await SublyApi.driverPriceSet(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_PRICE_SUCCESS"), "success");
        setLoader(false);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // function for get vehicle list api calling
  async function getVehicleList() {
    const details = await SublyApi.getVehicleList(userToken, "");
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setVehicleList(details && details.data && details.data.vehicleList);
      // setSelectVehicle({
      //   id: details.data.vehicleList[0]?.vehicle_type_id,
      //   vehicle_name: details.data.vehicleList[0]?.vehicle_name,
      // });
      // setValue("vanType", selectVehicle.vehicle_name); // Set the value only when "Done" is clicked
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      navigate("/driver-login");
    }
  }

  useEffect(() => {
    getVehicleList();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row className={styles.drProfileSectionRow}>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3 className={styles.profileHeading}>
                        {t("SET_YOUR")} <span>{t("PRICE")}</span>
                      </h3>
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className=" formTopSpaceCls"
                      >
                        <Form.Group className="mb-3 setIconPassword formSection">
                          <Form.Label>{t("VEHICLE_TYPE")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("VEHICLE_SELECT")}`}
                            type="text"
                            readOnly
                            onClick={() => setVehicleListModal(true)}
                            {...register("vanType", {
                              required: {
                                value: true,
                                message: `${t("VAN_REQUIRED")}`,
                              },
                            })}
                          />
                          {errors.vanType && (
                            <span className="errorMsg">
                              {errors.vanType.message}
                            </span>
                          )}
                          <div className={styles.downiconicon}>
                            <TiArrowSortedDown
                              onClick={() => setVehicleListModal(true)}
                            />
                          </div>
                        </Form.Group>

                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("MILES_BEFORE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("MILES_BEFORE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("miles_before_running_mileage", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.miles_before_running_mileage
                                    ? "red"
                                    : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                            <div> &nbsp; </div>
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("ARRIVAL_MILLEAGE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("ARRIVAL_MILLEAGE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("arrvival_running_milleage", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.arrvival_running_milleage
                                    ? "red"
                                    : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("MILLEAGE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("MILLEAGE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("milleage", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.milleage ? "red" : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("OUT_OF_CHARGE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("OUT_OF_CHARGE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("out_of_hours_charge", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.out_of_hours_charge
                                    ? "red"
                                    : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("STOPAGE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("STOPAGE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("stoppage", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.stoppage ? "red" : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("NO_HELP")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("NO_HELP_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("no_help_required", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.no_help_required
                                    ? "red"
                                    : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("DRIVER_ASSISTANCE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("DRIVER_ASSISTANCE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("driver_assistance", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.driver_assistance
                                    ? "red"
                                    : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("DRIVER_HELPER")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("DRIVER_HELPER_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("driver_helper", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.driver_helper ? "red" : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("DRIVER_TWO_HELPER")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("DRIVER_TWO_HELPER_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("driver_2_helper", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.driver_2_helper ? "red" : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("STAIR_CHARGE")}
                            <span>*</span>
                            <p className={styles.priceSectionInfo}>
                              {t("STAIR_CHARGE_TEXT")}
                            </p>
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("stair_charge", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: true,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.stair_charge ? "red" : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.priceSection}>
                          <Form.Label>
                            {t("PACKING_PRICE")}
                            {/* <p className={styles.priceSectionInfo}>{t("STAIR_CHARGE_TEXT")}</p> */}
                            {/* <span>*</span> */}
                          </Form.Label>
                          <div className={styles.fieldWithUnit}>
                            <div>{"£"}</div>
                            <Form.Control
                              placeholder={`${t("ENTER_HERE")}`}
                              type="text"
                              {...register("packing_price", {
                                onChange: (e) => onInputChange(e),
                                required: {
                                  value: false,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                              style={{
                                borderColor:
                                  errors && errors.packing_price ? "red" : "",
                              }}
                              // onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div
                          className={`${styles.updateBtnSpace} commonButton`}
                        >
                          <button type="submit">{t("UPDATE_PRICE")}</button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload getMainVehicleLists={getVehicleList} />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
        {/* vehicle type modal */}
        {/* <VehicleSelectModal
          setVehicleListModal={setVehicleListModal}
          vehicleListModal={vehicleListModal}
          selectVehicle={selectVehicle}
          setSelectVehicle={setSelectVehicle}
          setValue={setValue}
          clearErrors={clearErrors}
          getPriceList={getPriceList}
          vehicleList={vehicleList}
          getVehicleList={getVehicleList}
        /> */}

        {/* vehicle category modal */}
        <VehicleTypeModal
          setVehicleTypeModal={setVehicleListModal}
          vehicleTypeModal={vehicleListModal}
          selectVehicle={selectVehicle}
          setSelectVehicle={setSelectVehicle}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </div>
    </>
  );
}
export default PriceSettings;
