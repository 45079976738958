//---------------- Common Text----------------------
export const TRANSLATIONS_ZH = {
  //----------------Header Text----------------------
  HOME: "首页",
  CREATE_JOB: "创建工作",
  LOGIN: "登录",
  DRIVER: "成为司机",
  FAQ: "常见问题",
  SUPPORT: "支持",
  JOB: "工作",
  ENGLISH: "英语",
  SPANISH: "西班牙语",
  FRENCH: "法语",
  TURKISH: "土耳其语",
  CHINESE: "中文",
  ARABIC: "阿拉伯语",

  //----------------Footer Text----------------------
  COPYRIGHT: "保留所有权利",
  SWIFTVAN: "斯威夫特万",
  PRIVACY: "隐私政策",
  TERMS: "条款与条件",

  //----------------HomePage Text----------------------
  BOOK_MOVE: "在60秒内预订您的搬家",
  WHAT_MOVING: "您搬什么？",
  BECOME_DRIVER: "成为司机",
  CALCULATE: "计算",
  SOURSE_LOCATION: "输入起始地点",
  DESTINY_LOCATION: "输入目的地",
  ABOUT_US: "关于我们",
  CATEGORIES: "我们喜欢什么",
  DOING: "进行中",
  WORKS: "工程",
  HOW: "如何",
  FURNITURE_OTHER: "家具和其他物品",
  HOME_REMOVAL: "家庭搬迁",
  BTOB: "企业对企业",
  SCHEDULE_PICKUP: "安排轻松取件",
  ARRIVE: "我们抵达您的门前",
  DELIVER: "准时交付",

  //----------------LoginForm Text----------------------
  EMAIL: "电子邮件",
  EMAIL_TEXT: "输入电子邮件",
  PASSWORD: "密码",
  PASSWORD_TEXT: "输入密码",
  LOGIN: "登录",
  FORGOT_PASSWORD: "忘记密码？",
  EMAIL_REQUIRED: "请输入您的电子邮件地址。",
  EMAIL_INVALID: "请输入有效的电子邮件地址。",
  PASSWORD_REQUIRED: "请输入您的密码。",
  PASS_MINLENGTH: "请输入密码，至少6个字符。",
  PASS_MAXLENGTH: "请输入密码，最多12个字符。",
  NO_ACCOUNT: "还没有账号？",
  SINGUP: "注册",

  //----------------ForgotPassword Text----------------------
  CANCEL: "取消",
  RESET: "重置",
  FORGOT_TEXT: "输入您的电子邮件地址，我们会发送重置密码的链接",
  FORGOT_PASSWORD_TEXT: "忘记密码",

  //----------------SignUp Form Text----------------------
  CREATE_ACCOUNT: "创建账号",
  FIRST_NAME: "名字",
  LAST_NAME: "姓氏",
  CONTACT: "联系号码",
  CONFIRM_PASSWORD: "确认密码",
  FIRSTNAME_TEXT: "输入名字",
  FIRSTNAME_REQUIRED: "请输入您的名字。",
  FIRSTNAME_MINLENGTH: "请输入名字，至少2个字符。",
  FIRSTNAME_MAXLENGTH: "请输入名字，最多50个字符。",
  LASTNAME_TEXT: "输入姓氏",
  LASTNAME_REQUIRED: "请输入您的姓氏。",
  LASTNAME_MINLENGTH: "请输入姓氏，至少2个字符。",
  LASTNAME_MAXLENGTH: "请输入姓氏，最多50个字符。",
  CONTACT_TEXT: "选择联系号码",
  CONTACT_REQUIRED: "请输入您的联系号码。",
  CONFIRM_PASS_TEXT: "输入确认密码",
  CONFIRM_PASS_REQUIRED: "请输入您的确认密码。",
  CONFIRM_PASS_MINLENGTH: "请输入确认密码，至少6个字符。",
  CONFIRM_PASS_MAXLENGTH: "请输入确认密码，最多12个字符。",
  NOT_MATCH: "密码与确认密码不匹配。",
  ALREADY_ACCOUNT: "已经有账号了？",
  SIGNIN: "登录",
  SIGNUP_TEXT: "注册",
  INVALID_PASSWORD:
    "密码应包含至少1个大写字母，1个小写字母，1个数字，1个特殊字符，最少6个字符。",

  //----------------Reset Password Text----------------------
  NEW_PASSWORD: "新密码",
  SUBMIT: "提交",
  OTP_REQUIRED: "需要一次性密码",
  DIGIT_REQUIRED: "请输入4位数字的OTP",
  NUMBER_REQUIRED: "请输入数字。",

  //----------------SUCCESS Messages Text----------------------
  LOGIN_SUCCESSFULLY: "登录成功。",
  CREATE_ACCOUNT_SUCCESSFUL: "账号创建成功。",
  RESET_SUCCESFULLY: "重置代码发送成功！",
  UPDATE_SUCCESFULLY: "重置密码成功。",

  //----------------Error Messages Text----------------------
  HEADER_IS_MISSING: "缺少标头。",
  DEVICE_TYPE_NOT_ALLOWED: "不允许的设备类型。",
  INVALID_API_KEY: "无效的API密钥。",
  TOKEN_REQUIRE_AUTHENTICATION: "身份验证需要令牌。",
  INVALID_TOKEN: "无效的令牌。",
  SERVER_VALIDATION: "验证错误。",
  USER_NOT_FOUND: "找不到用户",
  ACCOUNT_BLOCKED: "您的账号目前被封锁。",
  SOCIAL_USER_NOT_FOUND: "找不到社交用户",
  INTERNAL_SERVER_ERROR: "内部服务器错误",
  INVALID_LOGIN_CREDENTIAL: "无效的登录凭据。",
  ACCOUNT_NOT_FOUND: "找不到账号，请输入正确的电子邮件。",
  ACCOUNT_INACTIVE: "您的账号目前不活跃。",
  ACCOUNT_DELETED: "您的账号已被删除。",
  OLD_PASSWORD_DOES_NOT_MATCH: "旧密码不匹配。",
  EMAIL_ALREADY_IN_USE: "电子邮件已被使用，请使用其他电子邮件。",
  PHONE_NUMBER_EXIST: "电话号码已经被使用，请使用其他电话号码。",
  SOCIALID_EXIST: "社交ID已经存在",
  CODE_EXIST: "代码已经被使用",
  CODE_NOT_MATCH: "代码不匹配",
  EMAIL_LINKED_SOCIALUSER: "电子邮件已与社交用户关联",
  EMAIL_LINKED_NORMALUSER: "电子邮件已与普通用户关联",
  EMAIL_FAIL: "电子邮件发送失败",
  VEHICLE_IMG: "需要车辆图片",
  LICENSE_IMG: "需要驾驶执照",
  GT_INSURANCE_IMG: "需要货物在运途中的保险",
  HR_INSURANCE_IMG: "需要租赁和奖励保险",
  PL_INSURANCE_IMG: "需要公共责任保险",
  VEHICLE_IMG_SIZE: "车辆图片最大尺寸为10 MB",
  INVALID_VEHICLE_IMG: `无效的车辆图片扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_VEHICLE_ID: "无效的车辆ID",
  INVALID_COLOR_ID: "无效的车辆颜色ID",
  LICENSE_IMG_SIZE: "驾驶执照最大尺寸为10 MB",
  INVALID_LICENSE_IMG: `无效的驾驶执照扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  GT_INSURANCE_IMG_SIZE: "货物在运途中的保险最大尺寸为10 MB",
  INVALID_GT_INSURANCE_IMG: `无效的货物在运途中的保险扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  HR_INSURANCE_IMG_SIZE: "租赁和奖励保险最大尺寸为10 MB",
  INVALID_HR_INSURANCE_IMG: `无效的租赁和奖励保险扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  PL_INSURANCE_IMG_SIZE: "公共责任保险最大尺寸为10 MB",
  INVALID_PL_INSURANCE_IMG: `无效的公共责任保险扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  FAILED_IMG: "上传图片失败",
  FAILED_LICENSE_IMG: "上传驾驶执照失败",
  FAILED_GT_IMG: "上传货物在运途中的保险失败",
  FAILED_HR_IMG: "上传租赁和奖励保险失败",
  FAILED_PL_IMG: "上传公共责任保险失败",
  INVALID_CATEGORY_ID: "无效/停用的类别ID",
  INVALID_VEHICLETYPE_ID: "无效/停用的车辆类型ID",
  INVALID_DRIVER_ID: "无效/停用的驾驶员ID",
  INVALID_SOURCE_FLOOR_ID: "无效/停用的起始楼层ID",
  INVALID_DESTINATION_FLOOR_ID: "无效/停用的目标楼层ID",
  INVALID_HELPER_ID: "无效/停用的助手ID",
  INVALID_CATEGORY_JSON: "无效/停用的类别JSON",
  ACCESS_DENIED:
    "访问被拒绝提示: 当员工尝试运行其无权运行的API时，将出现此代码",
  WRONG: "出了些问题",
  INVALID_UNIT_ID: "无效/停用的单位ID",
  INVALID_STOP: "无效的添加停车JSON",
  INVALID_SOURCE_PROPERTY_ID: "无效/停用的起始属性类型ID",
  INVALID_DESTINATION_PROPERTY_ID: "无效/停用的目标属性类型ID",
  INVALID_SCHEDULE_DATA: "日程数据的JSON格式无效",
  TIME_EXIST: "该时间已经存在于同一天，请使用其他开始时间",
  CUSTOMER_NOT_PERMIT: "客户无权执行此操作",
  INVALID_PRICE: "价格设置数据的JSON格式无效",
  FOR_SCHEDULE: "仅允许同一天有两个时间段的日程数据",
  DRIVER_NOT_PERMISSION: "驾驶员无权执行此操作",
  CANNOT_DELETE_USER: "用户无法删除此帐户",
  ALREADY_LICENSE_USE: "许可证号已使用，请使用其他许可证号",
  PROFILE_REQUIRED: "需要个人资料图片",
  PROFILE_IMG_SIZE: "个人资料图片最大尺寸为10 MB",
  INVALID_PROFILE: `无效的个人资料图片扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp"`,
  DOC_IMG_REQUIRED: "需要文件图片",
  DOC_IMG_SIZE: "文件图片最大尺寸为10 MB",
  INVALID_DOC_IMG: `无效的文件图片扩展名，请使用 ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_DOC: "无效的文件",
  PASSWORD_NOT_MATCH: "新密码和确认密码不匹配",
  NOTIFY_ALREADY_EXIST: "此通知状态已存在",
  ADD_VEHICLE: "请添加车辆",
  VEHICLE_NAME_ALREADY: "车辆名称已存在，请使用其他名称",
  USER_NOT_PERMITTED: "用户无权执行此操作",
  CANNOT_DELETE_VEHICLE_TYPE: "此车辆类型无法删除",
  VEHICLE_TYPE_STATUS: "数据库中已存在车辆类型状态",
  ESTIMATE_WEIGHT_ID: "无效的估计重量ID",
  INVALID_JOB_TEMP_ID: "无效的作业模板ID",
  INVALID_KEY: "无效的键名",
  MASTER_DATA_EXIST: "主数据名称已存在",
  INVALID_MASTER_DATA: "无效的主数据ID",
  MASTER_DATA_NOT_DELETE: "无法删除此主数据",
  MASTER_DATA_STATUS: "主数据状态已存在",
  CATEGORY_ICON: "需要类别图标",
  CATEGORY_ICON_SIZE: "类别图标最大尺寸为10 MB",
  INVALID_CATEGORY_ICON: `无效的类别图标扩展，请使用".jpg"、".jpeg"、".png"、".webp"`,
  CATEGORY_ADD: "成功添加类别",
  CATEGORY_UPDATE: "成功更新类别",
  CUSTOMER_EXIST: "用户已与客户关联",
  DRIVER_EXIST: "用户已与司机关联",

  //----------------Session Expire Text----------------------
  SESSION_EXPIRED: "您的会话已过期！",

  //----------------Profile Page Text----------------------
  PROFILE: "我的资料",
  SETTINGS: "设置",
  EDIT_PROFILE: "编辑资料",
  USER_INFO: "用户信息",
  UPDATE: "更新",
  LOGOUT: "退出登录",
  DELETE_ACCOUNT: "删除账号",
  SAVE_CARD: "保存银行卡",
  CHANGE_PASSWORD: "更改密码",
  JOB_HISTORY: "工作历史",

  //----------------Driver auth Page Text----------------------
  BACK: "后退",
  NEXT: "下一个",
  DONE: "完成",
  SELECT_VEHICLE_COLOR: "选择车辆颜色",
  SELECT_VAN: "选择货车",
  VEHICLE_INFO: "车辆信息",
  LICENSE_PLATE_NUMBER: "车牌号",
  ENTER_LICENSE: "输入车牌号",
  LICENSE_REQUIRED: "请输入车牌号。",
  LICENSE_MINLENGTH: "请输入至少2个字符的车牌号。",
  LICENSE_MAXLENGTH: "请输入最多50个字符的车牌号。",
  VAN_TYPE: "货车类型",
  SELECT_VAN: "选择货车类型",
  VAN_REQUIRED: "请选择货车。",
  COLOR_VEHICLE: "车辆颜色",
  SELECT_COLOR: "选择车辆颜色",
  COLOR_REQUIRED: "请选择车辆颜色。",
  VEHICLE_YEAR: "车辆年份",
  YEAR_OF_VEHICLE: "输入车辆年份",
  VEHICLE_YEAR_REQUIRED: "请输入车辆年份",
  YEAR_ALLOW_DIGITS: "请输入4位数的年份",
  VEHICLE_PHOTO: "车辆照片",
  VEHICLE_PHOTO_REQUIRED: "车辆照片是必需的",
  UPLOAD_VEHICLE: "上传车辆照片",
  UPLOAD: "上传",
  DESCRIPTION: "描述",
  ENTER_DISCRIPTION: "输入描述",
  DESCIPTION_REQUIRED: "请输入车辆描述。",
  DESCRIPTION_MINLENGTH: "请输入至少2个字符的车辆描述。",
  DESCRIPTION_MAXLENGTH: "请输入最多300个字符的车辆描述。",
  UPLOAD_DOCUMENT: "上传文件",
  DRIVING_LICENSE: "驾驶执照",
  DRIVING_LICENSE_REQUIRED: "驾驶执照是必需的",
  GT_INSURANCE_REQUIRED: "需要货物运输保险",
  HR_INSURANCE_REQUIRED: "需要租赁和奖励保险",
  PL_INSURANCE_REQUIRED: "需要公共责任保险",
  GT_TITLE: "货物运输保险",
  HR_TITLE: "租赁和奖励保险",
  PL_TITLE: "公共责任保险",
  UPLOAD_LICENSE: "上传驾驶执照",
  UPLOAD_REGISTRATION: "上传车辆注册",
  ALERT: "警告",
  REMOVE_ALERT: "确定要删除吗",
  YES: "是",

  //-----------------------Create job localization text--------------------
  ENTER: "输入",
  FURNITURE_AND_OTHER: "家具和其他物品",
  SOURCE_LOCATION: "起始位置",
  DESTINATION_LOCATION: "目的地位置",
  SELECT_FLOOR: "选择楼层",
  LIFT_AVAILABLE: "有电梯",
  STOPS: "停靠点",
  ADD_STOPS: "添加停靠点",
  ADD_MORE_STOPS: "添加更多停靠点",
  AVAILABILITY: "可用性",
  ESTIMATED_TIME_ARRIVAL: "预计到达时间",
  WANT_PACKING: "您想要包装吗？",

  //-----------------------Create job validations text--------------------
  ENTER_SOURCE_LOCATION: "输入起始位置",
  ENTER_DESTINATION_LOCATION: "输入目的地位置",
  ENTER_ALL_STOPS_ADDRESS: "请为所有停靠点输入地址。",
  MAXIMUM_STOPS_ALLOWED: "最多允许 9 个停靠点。",
  MIN_3_CHAR_REQUIRED: "至少需要3个字符。",
  SELECT_VEHICLE_TYPE: "选择您的车辆类型",
  SELECT_HELPER: "选择助手",
  SELECT_DATE: "选择日期",
  SELECT_TIME: "选择时间",
  SELECT_DATE_TIME: "选择可用日期和时间",
};
