import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../../appComponents/DriverProfile/DriverProfile.module.css";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import SublyApi from "../../../helpers/Api";

//---------function for set services--------
function Services() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [chekedCategory, setChekedCategory] = useState("");

  //------get all category-------
  async function allCategory() {
    setLoader(true);
    await SublyApi.getCategoryList().then((response) => {
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setServicesList(response && response.data);
      }
    });
  }

  useEffect(() => {
    const newCheckedItems = {};
    chekedCategory &&
      chekedCategory.map((item) => {
        newCheckedItems[item.category_id] = true;
      });
    setCheckedItems(newCheckedItems);

    // Check if all checkboxes are checked
    const allChecked =
      servicesList.length > 0 &&
      servicesList.every((service) => newCheckedItems[service.id]);
    setSelectAll(allChecked);
  }, [chekedCategory, servicesList]);

  //------get category list-------
  async function categoryList() {
    setLoader(true);
    await SublyApi.getServiceList(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        setChekedCategory(
          response && response.data && response.data.serviceList
        );
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  useEffect(() => {
    if (servicesList.length !== 0) {
      categoryList();
    }
  }, [servicesList]);

  useEffect(() => {
    allCategory();
  }, []);

  // function for manage handle input change
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    const newCheckedItems = { ...checkedItems, [id]: checked };
    setCheckedItems(newCheckedItems);

    // Check if all checkboxes are checked
    const allChecked = servicesList.every(
      (service) => newCheckedItems[service.id]
    );

    setSelectAll(allChecked);
  };

  // function for manage chekall input
  const handleSelectAllChange = () => {
    const newCheckedItems = { ...checkedItems };
    servicesList.forEach((service) => {
      newCheckedItems[service.id] = !selectAll;
    });
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  //----------function for update radius----------
  const onSubmit = async () => {
    const checkedIds = Object.entries(checkedItems)
      .filter(([id, isChecked]) => isChecked)
      .map(([id]) => id);
    const inputId = checkedIds.join(",");

    if (inputId == "") {
      Toster(t("CATEGORY_REQUIRED"), "error");
    } else {
      let requestData = new FormData();
      requestData.append("category_ids", inputId);
      setLoader(true);
      await SublyApi.UpdateService(requestData, userToken).then((response) => {
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          Toster(t("SERVICE_UPDATE"), "success");
          setLoader(false);
          categoryList();
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
          setLoader(false);
        } else {
          setLoader(false);
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row className={styles.drProfileSectionRow}>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3 className={styles.profileHeading}>
                        {t("SET_YOUR")} <span>{t("SERVICES")}</span>
                      </h3>
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="formTopSpaceCls"
                      >
                        <div className="customChekbox">
                          <input
                            type="checkbox"
                            id="all"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />
                          <label className="vehicleInputDisplay" htmlFor="all">
                            <h2>{t("ALLTYPE")}</h2>
                          </label>
                        </div>
                        {servicesList &&
                          servicesList.length > 0 &&
                          servicesList.map((service, index) => (
                            <div className="serviceInput" key={index}>
                              <input
                                type="checkbox"
                                id={service.id}
                                name={service.id}
                                value={service.name}
                                onChange={handleCheckboxChange}
                                checked={checkedItems[service.id]}
                              />
                              <label
                                htmlFor={service.id}
                                className="vehicleInputDisplay"
                              >
                                <div
                                  style={{
                                    background: "#0094f6",
                                    width: "50px",
                                    borderRadius: "50%",
                                    marginRight: "12px",
                                  }}
                                >
                                  <img
                                    src={service.icon}
                                    style={{ padding: "8px" }}
                                    alt="vehicle-img"
                                  />
                                </div>
                                <div>
                                  <h2>{service.name}</h2>
                                  {chekedCategory &&
                                    chekedCategory.length > 0 &&
                                    chekedCategory.map((el) => {
                                      if (
                                        el.status == "approved" &&
                                        service.id == el.category_id
                                      ) {
                                        return (
                                          <p className={styles.activeCls}>
                                            {t("ADMIN_APPROVED")}
                                          </p>
                                        );
                                      }
                                      if (
                                        el.status == "pending" &&
                                        service.id == el.category_id
                                      ) {
                                        return (
                                          <p className={styles.inActiveCls}>
                                            {t("ADMIN_REJECT")}
                                          </p>
                                        );
                                      }
                                      if (
                                        el.status == "rejected" &&
                                        service.id == el.category_id
                                      ) {
                                        return (
                                          <p className={styles.inActiveCls}>
                                            {t("ADMIN_REJECTED")}
                                          </p>
                                        );
                                      }
                                    })}
                                </div>
                              </label>
                            </div>
                          ))}
                        <div
                          className={`${styles.updateBtnSpace} commonButton`}
                        >
                          <button type="submit">{t("UPDATE")}</button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
}
export default Services;
