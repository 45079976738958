import React from "react";
import styles from "../CreateJob/Furnitures/Furnitures.module.css";
import tabStyles from "../CreateJob/Furnitures/Tabs/Tabs.module.css";
import { vehicleDescription } from "../../mockData";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function VanGuide() {
  const { t } = useTranslation();

  return (
    <>
      <div className="main">
        <div className="spaceTopManage"></div>
        <div className={styles.topSubContainer}>
          <div className={styles.topSubHead}>
            <div
              className={styles.topSubHeadTitle}
              style={{ textTransform: "uppercase" }}
            >
              {t("VAN_GUIDE")}
            </div>
            <div className={styles.topSubHeadDesc}>{t("JOB_VAN_GUIDE")}</div>
            <div>
              <div className={tabStyles.vehicleSectionWrapper}>
                <Row
                  style={{
                    justifyContent: "left",
                  }}
                >
                  {vehicleDescription.map((vehicle, ind) => {
                    return (
                      <>
                        {ind % 2 == 0 ? (
                          <Row className={tabStyles.oddVehicleRow}>
                            <Col className="mb-3">
                              <div className={tabStyles.vehicleTextView}>
                                <div className={tabStyles.vehicleViewTitle}>
                                  {vehicle.title}
                                </div>
                                <div className={tabStyles.vehicleViewDesc}>
                                  {vehicle.description}
                                </div>
                                <div className={tabStyles.vehicleViewInfo}>
                                  {Object.entries(vehicle.dimention).map(
                                    ([key, value]) => (
                                      <div key={key}>{value}</div>
                                    )
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={3} md={4} sm={6} className="mb-3">
                              <div>
                                <img
                                  src={vehicle.image}
                                  alt="lutonVan"
                                  className={tabStyles.vehicleImage}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row className={tabStyles.evenVehicleRow}>
                            <Col lg={3} md={4} sm={6} className="mb-3">
                              <div>
                                <img
                                  src={vehicle.image}
                                  alt="lutonVan"
                                  className={tabStyles.vehicleImage}
                                />
                              </div>
                            </Col>
                            <Col className="mb-3">
                              <div
                                className={tabStyles.vehicleTextView}
                                style={{ textAlign: "right" }}
                              >
                                <div className={tabStyles.vehicleViewTitle}>
                                  {vehicle.title}
                                </div>
                                <div className={tabStyles.vehicleViewDesc}>
                                  {vehicle.description}
                                </div>
                                <div className={tabStyles.vehicleViewInfo}>
                                  {Object.entries(vehicle.dimention).map(
                                    ([key, value]) => (
                                      <div key={key}>{value}</div>
                                    )
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}

                        {ind + 1 < vehicleDescription.length && <hr />}
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
