import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "../../../../assets/Styles/Common.css";
import { FiArrowLeft } from "react-icons/fi";
import styles from "../Furnitures.module.css";
import tabStyles from "./Tabs.module.css";
import modalStyles from "../Modals/modal.module.css";
import { useTranslation } from "react-i18next";
import chatIcon from "../../../../assets/Images/chatIcon.png";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  updateJobData,
} from "../../../../store/slices/createJob";
import { getActiveTab } from "../helper";
import { useEffect, useState } from "react";
import SelectModal from "../Modals/selectModal";
import Toster from "../../../../utils/Toaster";

function Second() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.createJob);

  const [showSelectSofas, setShowSelectSofas] = useState({
    show: false,
    id: "",
    title: "",
    subItems: [],
  });

  const [showCustom, setShowCustom] = useState({
    show: false,
    id: "",
    title: "",
    subItems: [],
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isCustomItems, setIsCustomItems] = useState(false);

  let updatedArray = selectedOptions?.flatMap((object) => {
    // Map over the 'items' array within each object and add the new key to each item
    return object.items.map((item) => {
      return {
        ...item,
        title: object.title,
        categoryId: object.id, // Add the new key with the categoryId value
        time: item.time,
        is_custom_item: item.is_custom_item,
      };
    });
  });

  useEffect(() => {
    let isCustom = selectedOptions.find((val) => {
      return val.id == "#custom_id";
    });

    if (isCustom == undefined) {
      setIsCustomItems(false);
    } else {
      setIsCustomItems(true);
    }
  });

  const getActiveSubCategory = jobData.categoryList.filter((category) => {
    return category.id == jobData.activeCategory;
  });

  const [filteredCategory, setFilteredCategory] =
    useState(getActiveSubCategory);

  useEffect(() => {
    if (
      jobData.createJobData &&
      jobData.createJobData.second &&
      jobData.createJobData.second.isSubmit
    ) {
      setSelectedOptions(jobData.createJobData.second?.items);
    }
  }, []);

  const onBack = () => {
    dispatch(setActiveTab("first"));
  };

  const goToNext = () => {
    let isRedirect =
      selectedOptions.length == 0 && !jobData.createJobData.third?.isSubmit;
    if (isRedirect) {
      Toster(t("ADD_SOME_ITEMS"), "error");
    } else {
      dispatch(
        updateJobData({
          step: "second",
          data: {
            items: selectedOptions,
            isSubmit: true,
            isCustomItems,
            allItems: updatedArray,
          },
        })
      );
      // dispatch(
      //   updateJobData({
      //     step: "third",
      //     data: { isSubmit: false, items: [] },
      //   })
      // );
      if (isCustomItems) {
        dispatch(setActiveTab("third"));
      } else {
        dispatch(setActiveTab("fourth"));
      }
    }
  };

  const handleOpenModal = (id, name, subItems) => {
    setShowSelectSofas((pre) => ({
      ...pre,
      show: true,
      id,
      title: name,
      subItems,
    }));
  };

  const handleCustomModal = (id, name, subItems) => {
    setShowCustom((pre) => ({
      ...pre,
      show: true,
      id,
      title: name,
      subItems,
    }));
  };

  const handleSearchCategory = (value) => {
    const query = value.toLowerCase().trim();
    const filteredCategories = getActiveSubCategory.map((category) => {
      return {
        ...category,
        subcategory: category.subcategory.filter((subItem) =>
          subItem.name.toLowerCase().includes(query.toLowerCase())
        ),
      };
    });
    setFilteredCategory(filteredCategories);
  };

  const handleAddOwnItems = () => {
    dispatch(
      updateJobData({
        step: "second",
        data: {
          items: selectedOptions,
          isSubmit: true,
          isCustomItems,
          allItems: updatedArray,
        },
      })
    );
    dispatch(setActiveTab("third"));
  };

  const getCategoryName = () => {
    const categoryName = jobData?.categoryList?.find(
      (val) => val?.id === jobData.activeCategory
    );
    return categoryName?.name;
  };

  return (
    <>
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHead}>
            <div className="backarrow">
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div className="backarrow">
              <span className={styles.tabCount}>
                <strong>{getActiveTab(jobData.activeTab)}</strong>
                <span>/4</span>
              </span>
            </div>
          </div>
          <div className={styles.topSubContainer}>
            <div className={styles.topSubHead}>
              {/* <div className={styles.topSubHeadTitle}>
                Furniture & Other Items
              </div> */}
              <div className={styles.topHeading}>
                <div className={styles.backarrow}>
                  <FiArrowLeft onClick={() => onBack()} />
                </div>
                <div className={styles.topSubHeadTitle}>
                  {/* {t("FURNITURE_AND_OTHER")} */}
                  {/* {getCategoryName()} */}
                  {t("SELECT_YOUR_ITEMS")}
                </div>
                <div className={styles.tabCounting}>
                  <strong>{getActiveTab(jobData.activeTab)}</strong>
                  <span>/4</span>
                </div>
              </div>
              <div className={styles.topSubHeadDesc}>
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type. */}
              </div>
              {/* <div className={styles.imageBox}>
                {jobData.categoryList.map((list) => {
                  return (
                    <>
                      <div className={styles.imageContainer}>
                        <img
                          src={list.icon}
                          alt="User"
                          className={styles.image}
                          style={
                            list.id == jobData.activeCategory
                              ? {
                                  border: "2px solid #0F7DFF",
                                  borderRadius: "50%",
                                }
                              : {}
                          }
                        />
                        <p>{t(list.name)}</p>
                      </div>
                    </>
                  );
                })}
              </div> */}
              <div className={styles.categoryListSection}>
                {jobData.categoryList?.map((list) => {
                  return (
                    <>
                      <div className={styles.tabContainer}>
                        <p
                          style={
                            list.id == jobData.activeCategory
                              ? {
                                  background: "#4770EB",
                                  color: "white",
                                }
                              : {}
                          }
                        >
                          {t(list.name)}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className={tabStyles.furnitureSection}>
              <div className={tabStyles.furnitureWrapper}>
                <div className={tabStyles.furnitureHeading}>
                  {getCategoryName()}
                </div>
                <div className={tabStyles.furnitureFields}>
                  <div className={tabStyles.subFurnitureFields}>
                    <Row>
                      <Col lg={6}>
                        <InputGroup className="mb-3" style={{ height: "50px" }}>
                          <InputGroup.Text id="basic-addon1">
                            <Icon
                              icon="iconamoon:search"
                              color="#4770EB"
                              width="22"
                              height="22"
                            />
                          </InputGroup.Text>
                          <Form.Control
                            type="search"
                            onChange={(e) =>
                              handleSearchCategory(e.target.value)
                            }
                            placeholder={t("ENTER_ITEMS")}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    {filteredCategory.map((items) => {
                      if (items?.subcategory.length == 0) {
                        return <div>{t("NOT_FOUND_RESULTS")}</div>;
                      }
                    })}
                    <Row>
                      {filteredCategory.map((items) => {
                        return items?.subcategory?.map((subItem) => {
                          return (
                            <Col lg={4} className="mb-3">
                              <div
                                className={tabStyles.furnitureType}
                                onClick={() =>
                                  handleOpenModal(
                                    subItem.id,
                                    subItem.name,
                                    subItem.subcategory
                                  )
                                }
                              >
                                <div className={tabStyles.subTypeSection}>
                                  <div className={tabStyles.categoryIcon}>
                                    <img src={subItem.icon} />
                                  </div>
                                  <div className={tabStyles.furnitureName}>
                                    {subItem.name}
                                  </div>
                                </div>
                                <div>
                                  <Icon
                                    icon="bxs:up-arrow"
                                    color="rgba(130, 130, 130, 0.8509803921568627)"
                                    width="22"
                                    height="22"
                                    vFlip={true}
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        });
                      })}
                    </Row>
                  </div>
                </div>
                <div>
                  <Row>
                    {selectedOptions?.map((options) => {
                      if (options.items.length > 0) {
                        return (
                          <Col lg={6}>
                            <div className={tabStyles.categoryBox}>
                              <div className={tabStyles.categoryName}>
                                {options.title}
                              </div>
                              <div>
                                <Row>
                                  {options.items?.map((option) => (
                                    <Col lg={6} className="mb-3">
                                      <div
                                        className={modalStyles.optionContainer}
                                      >
                                        <div className={modalStyles.optionName}>
                                          {option.name}
                                        </div>
                                        <div
                                          className={modalStyles.optionCounts}
                                        >
                                          <div
                                            className={
                                              modalStyles.optionQuantitySecond
                                            }
                                          >
                                            <span
                                              className={
                                                modalStyles.quantityLable
                                              }
                                            >
                                              {"Quantity : "}
                                            </span>
                                            <span>{option.quantity}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </div>
                          </Col>
                        );
                      }
                    })}
                  </Row>
                </div>
                <div className={tabStyles.nextBtnFooterSecond}>
                  <span
                    className={tabStyles.addOwnItemBtn}
                    onClick={() => null}
                  >
                    <div>
                      <Icon
                        icon="dashicons:plus-alt"
                        color="#0F7DFF"
                        width="24"
                        height="24"
                        vFlip={true}
                      />
                    </div>
                    <div
                      className={tabStyles.addOwnItemBtnText}
                      onClick={() => handleAddOwnItems()}
                    >
                      {t("ADD_OWN_ITEM")}
                    </div>
                  </span>
                  <div>
                    <button
                      className={styles.nextBtn}
                      onClick={() => goToNext()}
                    >
                      <span>{t("NEXT")}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className={tabStyles.chatFeatureSecond}>
                {/* <img src={chatIcon} alt="chat-image" /> */}
              </div>
            </div>
          </div>
        </div>
        <SelectModal
          showSelectSofas={showSelectSofas}
          setShowSelectSofas={setShowSelectSofas}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </div>
    </>
  );
}
export default Second;
