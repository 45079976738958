import { useTranslation } from "react-i18next";
import styles from "../../appComponents/Profile/Profile.module.css";
import profile from "../../assets/Images/user.png";
import { useEffect, useState } from "react";
import { AiFillCamera } from "react-icons/ai";

//---------function for profile upload---------
function UploadProfile({ responseData }) {
  const { t } = useTranslation();
  const [profilePreview, setProfilePreview] = useState(profile);

  useEffect(() => {
    if (responseData.profile_image) {
      setProfilePreview(responseData.profile_image);
    }
  }, [responseData]);

  return (
    <>
      <div className={styles.profileSettingsSection}>
        <div className={styles.uploadProfile}>
          <div className={styles.profileImg}>
            <img src={profilePreview} alt="profile" />
          </div>
          <div className={styles.userDetails}>
            <h2>
              {responseData?.first_name} {responseData?.last_name}
            </h2>
            <h4>{responseData?.email}</h4>
            <p>
              {responseData?.dial_code} {responseData?.phone_number}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default UploadProfile;
