import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "./DriverProfile.module.css";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import DriverDocument from "./DriverDocument";
import {
  driverDetails,
  updateDriverDetails,
  userLogoutClear,
} from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import { AiFillCamera } from "react-icons/ai";
import profile from "../../../assets/Images/user.png";
import Loader from "../../../utils/Loader/Loader";
import { Autocomplete } from "@react-google-maps/api";

//---------function for driver profile page---------
function DriverProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;
  const { userToken, isLoading } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const autocomplete = useRef(null);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [profilePreview, setProfilePreview] = useState(profile);
  const [profileImage, setProfileImage] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [activeKey, setActiveKey] = useState("Driver-info");
  const [businessLocation, setBusinessLocation] = useState({
    address: "",
    lat: "",
    lng: "",
  });
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${placeApiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      setGoogleMapsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // set document tab active on notification redirect
  useEffect(() => {
    if (location.state !== null) {
      if (location.state.isFromNotification) {
        setActiveKey("Document");
      }
    }
  }, [location.state]);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // function for apply validation in phoneNumber field
  const validateNumber = () => {
    if (!phoneNo) {
      return `${t("CONTACT_REQUIRED")}`;
    } else {
      const minPhoneNumberLength = 5;
      const maxPhoneNumberLength = 15;
      if (phoneNo.length < minPhoneNumberLength) {
        return `${t("PHONE_MINLENGTH")}`;
      }
      if (phoneNo.length > maxPhoneNumberLength) {
        return `${t("PHONE_MAXLENGTH")}`;
      }

      return true;
    }
  };

  //----- function for select profile image-----
  function onImageChange(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setProfilePreview(URL.createObjectURL(e.target.files[0]));
      setProfileImage(e.target.files[0]);
    }
  }

  //--------function for get driver details api calling--------
  useEffect(() => {
    dispatch(driverDetails(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setValue(
          "firstName",
          response && response.data
            ? response.data.first_name.charAt(0).toUpperCase() +
                response.data.first_name.slice(1).toLowerCase()
            : ""
        );
        setValue(
          "lastName",
          response && response.data
            ? response.data.last_name.charAt(0).toUpperCase() +
                response.data.last_name.slice(1).toLowerCase()
            : ""
        );
        setValue("email", response && response.data ? response.data.email : "");
        setValue(
          "driverCompany",
          response && response.data ? response.data.company_name : ""
        );
        setBusinessLocation(
          response && response.data
            ? {
                address: response.data.location,
                lat: response.data.latitude,
                lng: response.data.longitude,
              }
            : ""
        );
        setDialCode(
          response && response.data && response.data.dial_code
            ? response.data.dial_code
            : ""
        );
        setPhoneNo(response && response.data ? response.data.phone_number : "");
        setCountryCode(
          response && response.data && response.data.country_code
            ? response.data.country_code
            : ""
        );
        if (
          (response && response.data && response.data.profile_image == "") ||
          (response && response.data && response.data.profile_image == null)
        )
          setProfilePreview(profile);
        else {
          setProfilePreview(
            response && response.data && response.data.profile_image
          );
        }
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }, []);

  const onSubmit = async (formdata) => {
    if (!businessLocation.lat) {
      setShowErrorMsg(true);
      return;
    }
    let requestData = new FormData();
    requestData.append(
      "first_name",
      formdata ? formdata.firstName && formdata.firstName.trim() : ""
    );
    requestData.append(
      "last_name",
      formdata ? formdata.lastName && formdata.lastName.trim() : ""
    );
    requestData.append("email", formdata.email ? formdata.email.trim() : "");
    requestData.append(
      "company_name",
      formdata.driverCompany ? formdata.driverCompany.trim() : ""
    );
    requestData.append(
      "location",
      businessLocation ? businessLocation.address : ""
    );
    requestData.append(
      "latitude",
      businessLocation ? Number(businessLocation.lat)?.toFixed(6) : ""
    );
    requestData.append(
      "longitude",
      businessLocation ? Number(businessLocation.lng)?.toFixed(6) : ""
    );
    if (dialCode && !dialCode.includes("+")) {
      requestData.append("dial_code", `+${dialCode}`);
    } else {
      requestData.append("dial_code", dialCode);
    }
    requestData.append("country_code", countryCode ? countryCode : "");
    requestData.append("phone_number", phoneNo ? phoneNo : "");
    requestData.append("profile_image", profileImage ? profileImage : "");
    const data = { requestData: requestData, userToken: userToken };
    dispatch(updateDriverDetails(data)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_PROFILE"), "success");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  const handlePlaceSelected = (place) => {
    setShowErrorMsg(false);
    setBusinessLocation({
      address: autocomplete.current.getPlace().formatted_address,
      lat: autocomplete.current.getPlace().geometry.location.lat(),
      lng: autocomplete.current.getPlace().geometry.location.lng(),
    });
  };

  const handleLocationChange = (value) => {
    setBusinessLocation((prev) => ({
      ...prev,
      lat: "",
      lng: "",
      address: value,
    }));
  };

  const onTabSelect = (tab) => {
    setActiveKey(tab);
  };

  return (
    <div className="main">
      {isLoading === true ? <Loader /> : ""}
      <Container>
        <div className="spaceTopManageDriver">
          <section className="midSection">
            <div className={styles.profileSection}>
              <h2>{t("MY_ACCOUNT")}</h2>
              <p></p>
              {/* <p>Lorem Ipsum is simply dummy</p> */}
            </div>

            <div className={styles.profileBox}>
              <Row className={styles.drProfileSectionRow}>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <SettingSidebar />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className={styles.profileSettingsSection}>
                    <h3 className={styles.profileHeading}>
                      {t("EDIT_PROFILE")}
                    </h3>
                    <Tabs
                      className="tabSection"
                      activeKey={activeKey}
                      onSelect={(key) => onTabSelect(key)}
                    >
                      <Tab eventKey="Driver-info" title={t("DRIVER_INFO")}>
                        <Form
                          onSubmit={handleSubmit(onSubmit)}
                          className="formSection"
                        >
                          <Form.Group className="mb-3">
                            <Form.Label>{t("FIRST_NAME")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("FIRSTNAME_TEXT")}`}
                              type="text"
                              {...register("firstName", {
                                onChange: (e) => handleKeyPress(e),
                                required: {
                                  value: true,
                                  message: `${t("FIRSTNAME_REQUIRED")}`,
                                },
                                minLength: {
                                  value: 2,
                                  message: `${t("FIRSTNAME_MINLENGTH")}`,
                                },
                                maxLength: {
                                  value: 50,
                                  message: `${t("FIRSTNAME_MAXLENGTH")}`,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                            />
                            {errors.firstName && (
                              <span className="errorMsg">
                                {errors.firstName.message}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("LAST_NAME")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("LASTNAME_TEXT")}`}
                              type="text"
                              {...register("lastName", {
                                onChange: (e) => handleKeyPress(e),
                                required: {
                                  value: true,
                                  message: `${t("LASTNAME_REQUIRED")}`,
                                },
                                minLength: {
                                  value: 2,
                                  message: `${t("LASTNAME_MINLENGTH")}`,
                                },
                                maxLength: {
                                  value: 50,
                                  message: `${t("LASTNAME_MAXLENGTH")}`,
                                },
                                ...sanitizeHtmlTags(),
                              })}
                            />
                            {errors.lastName && (
                              <span className="errorMsg">
                                {errors.lastName.message}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("EMAIL")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("EMAIL_TEXT")}`}
                              type="text"
                              {...register("email", {
                                onChange: (e) => onInputChange(e),
                                pattern: {
                                  value:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: `${t("EMAIL_INVALID")}`,
                                },
                                minLength: {
                                  value: 5,
                                  message: `${t("EMAIL_MINLENGTH")}`,
                                },
                                maxLength: {
                                  value: 100,
                                  message: `${t("EMAIL_MAXLENGTH")}`,
                                },
                                ...sanitizeHtmlTags(),
                                required: {
                                  value: true,
                                  message: `${t("EMAIL_REQUIRED")}`,
                                },
                              })}
                            />
                            {errors.email && (
                              <span className="errorMsg">
                                {errors.email.message}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("COMPANY_TEXT")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("ENTER_COMPANY")}`}
                              type="text"
                              {...register("driverCompany", {
                                onChange: (e) => handleKeyPress(e),
                              })}
                            />
                          </Form.Group>
                          {googleMapsLoaded && (
                            <Form.Group className="mb-3">
                              <Form.Label>{t("BUSINESS_LOCATION")}</Form.Label>
                              <Autocomplete
                                placeApiKey={placeApiKey}
                                onLoad={(auto) => {
                                  autocomplete.current = auto;
                                }}
                                onPlaceChanged={(place) =>
                                  handlePlaceSelected(place)
                                }
                              >
                                <input
                                  type="text"
                                  placeholder={t("ENTER_BUSINESS_LOCATION")}
                                  value={businessLocation.address}
                                  onChange={(e) =>
                                    handleLocationChange(e.target.value)
                                  }
                                  className="form-control"
                                />
                              </Autocomplete>
                              {showErrorMsg && (
                                <span className="errorMsg">
                                  {t("PLS_ENTER_BUSINESS_LOCATION")}
                                </span>
                              )}
                            </Form.Group>
                          )}
                          <Form.Group className="mb-3 phoneInputSet">
                            <Form.Label>{t("CONTACT")}</Form.Label>
                            <Controller
                              control={control}
                              name="phoneNumber"
                              render={({ field }) => (
                                <PhoneInput
                                  placeholder={`${t("CONTACT_TEXT")}`}
                                  country={"gb"}
                                  countryCodeEditable={false}
                                  enableLongNumbers={true}
                                  value={
                                    (dialCode && dialCode.toString()) +
                                    (phoneNo && phoneNo.toString())
                                  }
                                  onChange={(value, country) => {
                                    let dialCode = country.dialCode;
                                    let phone = value.slice(
                                      dialCode.length,
                                      value.length
                                    );
                                    setCountryCode(country.countryCode);
                                    setDialCode(dialCode);
                                    setPhoneNo(phone);
                                    field.onChange(value);
                                    clearErrors("phoneNumber");
                                  }}
                                />
                              )}
                              rules={{ validate: validateNumber }}
                            />
                            {errors.phoneNumber && (
                              <span className="errorMsg">
                                {errors.phoneNumber.message}
                              </span>
                            )}
                          </Form.Group>

                          <div className={styles.uploadSection}>
                            <div className={styles.uploadProfile}>
                              <div className={styles.profileImg}>
                                <img src={profilePreview} alt="profile" />
                              </div>
                              <div className={styles.profileInput}>
                                <input
                                  accept="image/*"
                                  className="inputfile hideDiv"
                                  id="file-1"
                                  name="profileImage"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    onImageChange(e);
                                  }}
                                />
                                <label
                                  htmlFor="file-1"
                                  className={styles.uploadPic}
                                >
                                  <AiFillCamera />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${styles.updateBtnSpace} commonButton`}
                          >
                            <button type="submit">{t("UPDATE")}</button>
                          </div>
                        </Form>
                      </Tab>
                      <Tab eventKey="Document" title={t("DOCUMENT")}>
                        <DriverDocument />
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <ProfileUpload />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
}
export default DriverProfile;
