import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../DRIVERSIDE/authComponent/DriverSignUp/DriverSignUp.module.css";
import pdf from "../../../assets/DriverImages/pdf.png";
import {
  driverDocument,
  userLogoutClear,
} from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Toster from "../../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import Loader from "../../../utils/Loader/Loader";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import DocumentEditModal from "./DocumentEditModal";

//---------function for driver document upload in edit profile---------
function DriverDocument() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, isLoading } = useSelector((state) => state.user);
  const [documentData, setDocumentData] = useState("");
  const [documentModal, setDocumentModal] = useState(false);
  const [documentList, setDocumentList] = useState("");
  const [title, setTitle] = useState("");

  //----- function for open document modal popup-----
  const handlePopup = (item) => {
    setDocumentList(item);
    setDocumentModal(true);
    {
      item &&
        item.document_type == "driving_lincence" &&
        setTitle(`${t("DRIVING_LICENSE")}`);
    }
    {
      item &&
        item.document_type == "goods_in_transit_insurance" &&
        setTitle(`${t("GT_TITLE")}`);
    }
    {
      item &&
        item.document_type == "hire_reward_insurance" &&
        setTitle(`${t("HR_TITLE")}`);
    }
    {
      item &&
        item.document_type == "public_liability_insurance" &&
        setTitle(`${t("PL_TITLE")}`);
    }
  };

  //--------function for get driver document api calling--------
  function getDocumentList() {
    dispatch(driverDocument(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setDocumentData(
          response && response.data && response.data.documentList
        );
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  useEffect(() => {
    getDocumentList();
  }, []);

  return (
    <section>
      {isLoading === true ? <Loader /> : ""}
      <div>
        {documentData &&
          documentData.length > 0 &&
          documentData.map((document, index) => {
            return (
              <div>
                {document && document.document_type == "driving_lincence" && (
                  <div className={styles.documentExpirationDate}>
                    <h4>{t("DRIVING_LICENSE")}</h4>
                    <span>
                      {t("EXPIRATION_DATE")}
                      &nbsp;:&nbsp;
                      <p>
                        {document && document.expiry_date ? (
                          <>
                            {moment(document && document.expiry_date).format(
                              "DD/MM/YYYY"
                            )}
                          </>
                        ) : (
                          "NA"
                        )}
                      </p>
                    </span>
                  </div>
                )}
                {document &&
                  document.document_type == "goods_in_transit_insurance" && (
                    <div className={styles.documentExpirationDate}>
                      <hr></hr>
                      <h4>{t("GT_TITLE")}</h4>
                      <span>
                        {t("EXPIRATION_DATE")}
                        &nbsp;:&nbsp;
                        <p>
                          {document && document.expiry_date ? (
                            <>
                              {moment(document && document.expiry_date).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          ) : (
                            "NA"
                          )}
                        </p>
                      </span>
                    </div>
                  )}
                {document &&
                  document.document_type == "hire_reward_insurance" && (
                    <div className={styles.documentExpirationDate}>
                      <hr></hr>
                      <h4>{t("HR_TITLE")}</h4>
                      <span>
                        {t("EXPIRATION_DATE")}
                        &nbsp;:&nbsp;
                        <p>
                          {document && document.expiry_date ? (
                            <>
                              {moment(document && document.expiry_date).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          ) : (
                            "NA"
                          )}
                        </p>
                      </span>
                    </div>
                  )}
                {document &&
                  document.document_type == "public_liability_insurance" && (
                    <div className={styles.documentExpirationDate}>
                      <hr></hr>
                      <h4>{t("PL_TITLE")}</h4>
                      <span>
                        {t("EXPIRATION_DATE")}
                        &nbsp;:&nbsp;
                        <p>
                          {document && document.expiry_date ? (
                            <>
                              {moment(document && document.expiry_date).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          ) : (
                            "NA"
                          )}
                        </p>
                      </span>
                    </div>
                  )}

                <div className={styles.uploadImages}>
                  <div className={styles.uploadInputImg}>
                    {document &&
                    document.document_image.split(".").pop() == "pdf" ? (
                      <img src={pdf} alt="pdf-icon" />
                    ) : (
                      <img src={document && document.document_image} />
                    )}
                  </div>
                  <div className={styles.eyeIcon}>
                    <FiEye
                      onClick={() =>
                        window.open(document && document.document_image)
                      }
                    />
                  </div>
                  <div className={styles.cancelIcon}>
                    <MdCancel
                      onClick={() => {
                        handlePopup(document);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.documentStatus}>
                  {document && document.status == "pending" && (
                    <p className={styles.inActiveCls}>{t("ADMIN_REJECT")}</p>
                  )}
                  {document && document.status == "approved" && (
                    <p className={styles.activeCls}>{t("ADMIN_APPROVED")}</p>
                  )}
                  {document && document.status == "rejected" && (
                    <p className={styles.inActiveCls}>{t("ADMIN_REJECTED")}</p>
                  )}
                </div>
              </div>
            );
          })}

        <DocumentEditModal
          documentModal={documentModal}
          setDocumentModal={setDocumentModal}
          documentList={documentList}
          title={title}
          getDocumentList={getDocumentList}
        />
      </div>
    </section>
  );
}
export default DriverDocument;
