import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverProfile/DriverProfile.module.css";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import Toster from "../../../utils/Toaster";
import { SETTING_OPTION, SETTING_OPTION_TYPE } from "../../../utils/Constants";
import DOMPurify from "dompurify";

const DriverPrivacyPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [privacyPolicy, setPrivacyPolicy] = useState([]);

  const PrivacyPolicyList = async () => {
    setIsLoading(true);
    await SublyApi.getDriverSettingList(
      userToken,
      SETTING_OPTION_TYPE.PAGE,
      SETTING_OPTION.PRIVACY_POLICY
    ).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const policyData = response.data[0]?.option_value || "";
        setPrivacyPolicy(policyData);
        setIsLoading(false);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    PrivacyPolicyList();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3
                        className={`${styles.profileHeading} profileHeadingBotLine`}
                      >
                        {t("PRIVACY")}
                      </h3>
                      <div className=" driverSupportCls">
                        <div className={styles.scrollOverlay}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(privacyPolicy),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
};

export default DriverPrivacyPolicy;
