import "../../assets/Styles/Common.css";
import styles from "../Login/Login.module.css";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/Images/LoginBg.png";
import finalLogo from "../../assets/Images/logo2.png";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { sanitizeHtmlTags } from "../../utils/sanitizeHtmlTags";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import FacebookSocialLogin from "../../commonComponents/FacebooklLogin/FacebookSocialLogin";
import { SIGNUP_TYPE } from "../../utils/Constants";
import { userLogin } from "../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../utils/Toaster";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import GoogleSocialLogin from "../../commonComponents/GoogleLogin/GoogleLogin";
import Loader from "../../utils/Loader/Loader";
import GuestUser from "../GuestUser/GuestUser";

//---------function for Login form----------
function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [passwordShow, setPasswordShow] = useState(false);
  const [isGuestCheckout, setIsGuestCheckout] = useState(false);
  const { isLoading } = useSelector((state) => state.user);
  const { isRedirect } = useSelector((state) => state.createJob);

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // --------function for submit login form--------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("signup_type", SIGNUP_TYPE.NORMAL);
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );
    requestData.append(
      "password",
      formdata ? formdata.password && formdata.password.trim() : ""
    );
    dispatch(userLogin(requestData)).then((responsejson) => {
      const response = responsejson.payload;

      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        // Toster(t("LOGIN_SUCCESSFULLY"), "success");
        if (isRedirect) {
          navigate("/hire-drivers");
        } else {
          navigate("/jobs");
        }
        setValue("email", "");
        setValue("password", "");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleGuestCheckout = () => {
    setIsGuestCheckout(true);
  };

  return (
    <div className="main">
      {isLoading === true ? <Loader /> : ""}
      <div className="spaceTopManage">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          {!isGuestCheckout ? (
            <Container>
              <div className={styles.formBlock}>
                {isRedirect && (
                  <div
                    className={styles.guestBackBtn}
                    onClick={() => navigate("/job-summary")}
                  >
                    &lt;- {t("BACK")}
                  </div>
                )}
                <div className={styles.logoImage}>
                  <NavLink to="/">
                    <img src={finalLogo} alt="logo-image" />
                  </NavLink>
                </div>
                <div className={styles.loginType}>{t("CUSTOMER_LOGIN")}</div>
                <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("EMAIL")}</Form.Label>
                    <Form.Control
                      placeholder={`${t("EMAIL_TEXT")}`}
                      type="text"
                      autoComplete="off"
                      {...register("email", {
                        onChange: (e) => onInputChange(e),
                        required: {
                          value: true,
                          message: `${t("EMAIL_REQUIRED")}`,
                        },
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: `${t("EMAIL_INVALID")}`,
                        },
                        ...sanitizeHtmlTags(),
                      })}
                    />
                    {errors.email && (
                      <span className="errorMsg">{errors.email.message}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="setIconPassword">
                    <Form.Label>{t("PASSWORD")}</Form.Label>
                    <Form.Control
                      placeholder={`${t("PASSWORD_TEXT")}`}
                      type={passwordShow ? "text" : "password"}
                      autoComplete="new-password"
                      {...register("password", {
                        onChange: (e) => {
                          onInputChange(e);
                        },
                        required: {
                          value: true,
                          message: `${t("PASSWORD_REQUIRED")}`,
                        },
                        minLength: {
                          value: 6,
                          message: `${t("PASS_MINLENGTH")}`,
                        },
                        maxLength: {
                          value: 12,
                          message: `${t("PASS_MAXLENGTH")}`,
                        },
                        pattern: {
                          value:
                            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                          message: `${t("INVALID_PASSWORD")}`,
                        },
                      })}
                    />

                    {errors.password && (
                      <span className="errorMsg">
                        {errors.password.message}
                      </span>
                    )}

                    <div className="passwordicon">
                      {passwordShow ? (
                        <FaEye
                          className="icon"
                          onClick={() => setPasswordShow(!passwordShow)}
                        />
                      ) : (
                        <FaEyeSlash
                          className="icon"
                          onClick={() => setPasswordShow(!passwordShow)}
                        />
                      )}
                    </div>
                  </Form.Group>
                  <div className={styles.forgotPassword}>
                    <NavLink to="/forgot-password">
                      {t("FORGOT_PASSWORD")}
                    </NavLink>
                  </div>
                  <div className={styles.loginBtn}>
                    <button type="submit" onClick={() => navigate("/login")}>
                      {t("LOGIN")}
                    </button>
                  </div>
                  {isRedirect && (
                    <>
                      <div className={styles.orSection}>
                        <span></span> OR <span></span>
                      </div>
                      <div
                        className={styles.guestCheckoutBtn}
                        onClick={() => handleGuestCheckout()}
                      >
                        {t("Checkout as guest")}
                      </div>
                    </>
                  )}
                  <div className="socialbutton">
                    {/* <FacebookSocialLogin /> */}
                    <GoogleSocialLogin />
                  </div>

                  <p className={styles.bottomText}>
                    {t("NO_ACCOUNT")}{" "}
                    <NavLink to="/sign-up">{t("SINGUP")}</NavLink>
                  </p>
                </Form>
              </div>
            </Container>
          ) : (
            <GuestUser setIsGuestCheckout={setIsGuestCheckout} />
          )}
        </div>
      </div>
    </div>
  );
}
export default Login;
