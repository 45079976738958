import React, { useEffect, useRef, useState } from "react";
import styles from "./Filter.module.css";
import { Button, Modal, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import {
  DateCalendar,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { useTranslation } from "react-i18next";

export default function Filter({
  filterModal,
  setFilterModal,
  type,
  date,
  setDate,
  selectedCategories,
  setSelectedCategories,
  handleFilterApply,
  setSearchKey,
}) {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);

  //------get all category-------
  async function allCategory() {
    // setLoader(true);
    await SublyApi.getCategoryList().then((response) => {
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setCategories(response && response.data);
      }
    });
  }

  useEffect(() => {
    allCategory();
  }, []);

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleClose = () => {
    setFilterModal((pre) => ({ ...pre, show: false }));
  };

  const handleApply = () => {
    handleFilterApply();
    setFilterModal((pre) => ({ ...pre, show: false }));
  };

  const handleClearAll = () => {
    setSelectedCategories([]);
    setDate(null);
    setSearchKey("");
    handleApply();
  };

  const handleAllSelect = () => {
    if (selectedCategories?.length === categories?.length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories.map((category) => category.id));
    }
  };

  const handleCheckboxChange = (id, isAll) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(
        selectedCategories.filter((categoryId) => categoryId !== id)
      );
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  // Function to check if a category is selected
  const isCategorySelected = (id) => {
    return (
      selectedCategories?.includes(id) ||
      (id === "all" && selectedCategories?.length === categories?.length)
    );
  };

  return (
    <Modal show={filterModal.show} onHide={() => handleClose()}>
      <div className="selectModal">
        <Modal.Header>
          <Modal.Title>{"Filter"}</Modal.Title>
          <IoMdCloseCircle
            onClick={() => {
              handleClose();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          {type == "pending" && (
            <Row className={styles.dateSelector}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  className="filterjobDatepickerfull"
                  value={date}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Row>
          )}
          <Row className={styles.categoryRow}>
            <div className={styles.categoryHead}>Category</div>
            <div
              key="all"
              className="categoryCheckboxes"
              style={{ color: "black" }}
              onClick={() => {
                handleAllSelect();
              }}
            >
              <label
                className={isCategorySelected("all") ? styles.activeFloor : ""}
              >
                All
              </label>
              {/* <Button
                variant={isCategorySelected("all") ? "primary" : "secondary"}
                className={`${styles.selectedBtn}`}
                onClick={(e) => {
                  handleAllSelect();
                }}
              >
                {isCategorySelected("all") ? t("SELECTED") : t("SELECT")}
              </Button> */}
            </div>
            {categories?.map((checkbox) => (
              <div
                key={checkbox.value_code}
                className="categoryCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(checkbox.id, checkbox.name);
                }}
              >
                <label
                  className={
                    selectedCategories?.includes(checkbox.id)
                      ? styles.activeCategory
                      : ""
                  }
                >
                  {checkbox.name}
                </label>
                {/* <Button
                  variant={
                    selectedCategories?.includes(checkbox.id)
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(checkbox.id, checkbox.val);
                  }}
                >
                  {selectedCategories?.includes(checkbox.id)
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Row>
          <Row className={styles.btnApply}>
            <Button className={styles.applyBtn} onClick={() => handleApply()}>
              Apply
            </Button>
          </Row>
          <Row>
            <div className={styles.clearBtn}>
              <span onClick={() => handleClearAll()}>Clear All</span>
            </div>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
}
