import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "./Payments.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Toster from "../../../../utils/Toaster";
import cardScanner from "../../../../assets/Images/cardScanner.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import SublyApi from "../../../../helpers/Api";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import { useStripe } from "@stripe/react-stripe-js";
import Confirm from "../Modals/confirm";
import Loader from "../../../../utils/Loader/Loader";

export default function ConfirmPay({
  location,
  setIsAddingCard,
  cardData,
  setCardData,
  setIsPaymentSuccess,
  setJobId,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const { userToken, stripeCustomerId } = useSelector((state) => state.user);
  const clientSecretKey = process.env.REACT_APP_STRIPE_CLIENT_SECRET_KEY;

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState({ show: false, id: "" });
  const [selectedCard, setSelectedCard] = useState();

  //set card pre selected if single card is available
  useEffect(() => {
    if (cardData.length == 1) {
      setSelectedCard(cardData[0].id);
    }
  }, [cardData]);

  const deleteCard = (id) => {
    setShowConfirm({ show: true, id });
  };

  const handleConfirm = () => {
    handleDeleteCard();
    setShowConfirm({ show: false, id: "" });
  };

  const onBack = () => {
    navigate(-1);
  };

  const handleDeleteCard = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/customers/${stripeCustomerId}/sources/${showConfirm.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + clientSecretKey,
          },
        }
      );

      if (response.ok) {
        setIsLoading(false);
        setSelectedCard();
        Toster(t("CARD_REMOVED"), "success");
        setCardData((prevCards) =>
          prevCards.filter((card) => card.id !== showConfirm.id)
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCardSelect = (id) => {
    setSelectedCard(id);
  };

  const handleSaveJob = (intent_id) => {
    setIsLoading(true);
    let postData = new FormData();
    const payment_summery = {
      amount: location.state.amount,
      processing_fee: location.state.processing_fee,
      total_amount: location.state.total_amount,
      payment_mode: location.state.payment_mode,
      currency_code: location.state.currency_code,
      currency_symbol: location.state.currency_symbol,
    };
    postData.append("temp_id", location.state.responseData.temp_id);
    postData.append("payment_summery", JSON.stringify(payment_summery));
    postData.append("payment_intent_id", intent_id);

    let res = SublyApi.saveJob(postData, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        setJobId(response.data.id);
        setIsLoading(false);
        setIsPaymentSuccess(true);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
          setIsLoading(false);
        } else {
          Toster(t(responseCode), "error");
          setIsLoading(false);
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  const handleConfirmPayment = (client_secret, intent_id) => {
    setIsLoading(true);
    const res = stripe.confirmCardPayment(client_secret, {
      payment_method: selectedCard,
    });
    res.then((data) => {
      if (data.paymentIntent.status === "succeeded") {
        setIsLoading(false);
        handleSaveJob(intent_id);
      }
    });
    res.catch((error) => {
      setIsLoading(false);
    });
  };

  const createPaymentIntent = () => {
    setIsLoading(true);
    let postData = new FormData();
    postData.append("job_id", location.state.responseData.temp_id);
    postData.append("payment_card_id", selectedCard);

    let res = SublyApi.createPaymentIntent(postData, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response.code === STATUS_CODES.PAYMENT_SUCCESS) {
        setIsLoading(false);
        if (response.data.client_secret) {
          handleConfirmPayment(
            response.data.client_secret,
            response.data.intent_id
          );
        }
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
          setIsLoading(false);
        } else {
          Toster(t(responseCode), "error");
          setIsLoading(false);
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  const handlePayPayment = () => {
    if (cardData.length == 0) {
      Toster(t("PLS_ADD_CARD"), "info");
      return;
    }
    if (!selectedCard) {
      Toster(t("PLS_SELECT_CARD"), "info");
      return;
    }
    createPaymentIntent();
  };

  const handleAddCard = () => {
    setIsAddingCard(true);
  };

  const handleCancel = () => {
    setShowConfirm({ show: false, id: "" });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <Row className={styles.topHead}>
            <Col lg={5} md={5} sm={5} xs={3}>
              <div className="backarrow">
                <FiArrowLeft onClick={() => onBack()} />
              </div>
            </Col>
            <Col lg={7} md={7} sm={7} xs={9}>
              <div>
                <span className={styles.headTitle}>{t("PAYMENT")}</span>
              </div>
            </Col>
          </Row>
          {location.state !== null && (
            <div className={styles.topContainerWrapper}>
              {cardData.length !== 0 && (
                <div className={styles.cardContainer}>
                  <Row>
                    {cardData.map((cards, ind) => {
                      return (
                        <Col className="mb-3">
                          <div
                            className={styles.cardBlock}
                            onClick={() => handleCardSelect(cards.id)}
                          >
                            <div className={styles.cardWrapper}>
                              <div className={styles.cardDetails}>
                                <div className={styles.topSection}>
                                  <div>
                                    <div className={styles.cardNumber}>
                                      {t("CARD_NUMBER")}
                                    </div>
                                    <div
                                      className={styles.cardNumberValue}
                                    >{`${"XXXX XXXX XXXX"} ${
                                      cards.last4
                                    }`}</div>
                                  </div>
                                  <div></div>
                                </div>
                                <div className={styles.bottomSection}>
                                  <div className={styles.cardScannerImg}>
                                    <img src={cardScanner} alt="Card Image" />
                                  </div>
                                  <div>
                                    <div className={styles.cardExpiry}>
                                      {t("EXPIRY")}
                                    </div>
                                    <div className={styles.cardExpiryValue}>
                                      {" "}
                                      {`${cards.exp_month}/${cards.exp_year}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Icon
                                icon="ic:round-cancel"
                                width="22"
                                height="22"
                                className={styles.crossIcon}
                                onClick={() => {
                                  deleteCard(cards.id);
                                }}
                              />
                            </div>
                            <div>
                              <input
                                type="radio"
                                checked={selectedCard == cards.id}
                              />
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              <div className={styles.paymentDetails}>
                {t("PAYMENT_DETAILS")}
              </div>
              <div className={styles.amountFeeContainer}>
                <div className={styles.amountFee}>
                  <div className={styles.amountSection}>
                    <div className={styles.amountText}>{t("AMOUNT")}</div>
                    {location.state.driverDetails.coupon_code && (
                      <div className={styles.amountText}>
                        {t("DISCOUNT")} (
                        {location.state.driverDetails.coupon_code_value}%)
                      </div>
                    )}
                    {/* <div className={styles.amountText}>
              {t("PROCESSING_FEE")}
            </div> */}
                  </div>
                  <div className={styles.amountValueSection}>
                    <div className={styles.amountValue}>
                      {location.state.driverDetails?.currency_symbol}
                      {Number(location.state.driverDetails?.amount)?.toFixed(2)}
                    </div>
                    {location.state.driverDetails.coupon_code && (
                      <div className={styles.discountValue}>
                        - {location.state.driverDetails.currency_symbol}
                        {Number(location.state.driverDetails.discount)?.toFixed(
                          2
                        )}
                      </div>
                    )}
                    {/* <div className={styles.amountValue}>
              {location.state.currency_symbol}
              {location.state.processing_fee}
            </div> */}
                  </div>
                </div>
                <hr />
                <div className={styles.totalAmountFee}>
                  <div>{t("TOTAL_AMOUNT")}</div>
                  <div>
                    {location.state.driverDetails.currency_symbol}
                    {Number(location.state.driverDetails.total_amount)?.toFixed(
                      2
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.footerBtnContainer}>
                <div>
                  <button
                    className={styles.addCardBtn}
                    onClick={() => handleAddCard()}
                  >
                    <span>{t("ADD_CARD")}</span>
                  </button>
                </div>
                <div>
                  <Button
                    className={styles.paymentBtn}
                    onClick={() => handlePayPayment()}
                  >
                    {t("PAY")} {location.state.currency_symbol}
                    {location.state.total_amount}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Confirm
        show={showConfirm.show}
        subTitle={t("SURE_CARD_REMOVED")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  );
}
