import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverProfile/DriverProfile.module.css";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import SublyApi from "../../../helpers/Api";

//---------function for change password page---------
function ChangePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [currentpasswordShow, setCurrentPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);

  // match password and confirm password field
  const password = useRef({});
  password.current = watch("newPassword", "");

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  //----------function for change password api----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "old_password",
      formdata
        ? formdata.currentPassword && formdata.currentPassword.trim()
        : ""
    );
    requestData.append(
      "new_password",
      formdata ? formdata.newPassword && formdata.newPassword.trim() : ""
    );
    requestData.append(
      "conf_password",
      formdata
        ? formdata.confirmPassword && formdata.confirmPassword.trim()
        : ""
    );
    setLoader(true);
    await SublyApi.driverChangePassword(requestData, userToken).then(
      (response) => {
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          Toster(t("PASS_CHANGE_SUCCESS"), "success");
          setLoader(false);
          reset();
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
          setLoader(false);
        } else {
          setLoader(false);
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row className={styles.drProfileSectionRow}>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3 className={styles.profileHeading}>
                        {t("CHANGE_PASSWORD")}
                      </h3>
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="formSection formTopSpaceCls"
                      >
                        <Form.Group className="mb-3 setIconPassword">
                          <Form.Label>{t("CURRENT_PASS")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("PASSWORD_TEXT")}`}
                            type={currentpasswordShow ? "text" : "password"}
                            autoComplete="new-password"
                            {...register("currentPassword", {
                              onChange: (e) => {
                                onInputChange(e);
                              },
                              required: {
                                value: true,
                                message: `${t("PASSWORD_REQUIRED_CURRENT")}`,
                              },
                              minLength: {
                                value: 6,
                                message: `${t("PASS_MINLENGTH_CURRENT")}`,
                              },
                              maxLength: {
                                value: 12,
                                message: `${t("PASS_MAXLENGTH_CURRENT")}`,
                              },
                              pattern: {
                                value:
                                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                                message: `${t("INVALID_PASSWORD")}`,
                              },
                              ...sanitizeHtmlTags(),
                            })}
                          />
                          {errors.currentPassword && (
                            <span className="errorMsg">
                              {errors.currentPassword.message}
                            </span>
                          )}

                          <div className="passwordicon">
                            {currentpasswordShow ? (
                              <FaEye
                                className="icon"
                                onClick={() =>
                                  setCurrentPasswordShow(!currentpasswordShow)
                                }
                              />
                            ) : (
                              <FaEyeSlash
                                className="icon"
                                onClick={() =>
                                  setCurrentPasswordShow(!currentpasswordShow)
                                }
                              />
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3 setIconPassword">
                          <Form.Label>{t("NEW_PASSWORD")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("PASSWORD_TEXT")}`}
                            type={passwordShow ? "text" : "password"}
                            autoComplete="new-password"
                            {...register("newPassword", {
                              onChange: (e) => {
                                onInputChange(e);
                              },
                              required: {
                                value: true,
                                message: `${t("PASSWORD_REQUIRED_NEW")}`,
                              },
                              minLength: {
                                value: 6,
                                message: `${t("PASS_MINLENGTH_NEW")}`,
                              },
                              maxLength: {
                                value: 12,
                                message: `${t("PASS_MAXLENGTH_NEW")}`,
                              },
                              pattern: {
                                value:
                                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                                message: `${t("INVALID_PASSWORD")}`,
                              },
                              ...sanitizeHtmlTags(),
                            })}
                          />
                          {errors.newPassword && (
                            <span className="errorMsg">
                              {errors.newPassword.message}
                            </span>
                          )}

                          <div className="passwordicon">
                            {passwordShow ? (
                              <FaEye
                                className="icon"
                                onClick={() => setPasswordShow(!passwordShow)}
                              />
                            ) : (
                              <FaEyeSlash
                                className="icon"
                                onClick={() => setPasswordShow(!passwordShow)}
                              />
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3 setIconPassword">
                          <>
                            <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("PASSWORD_TEXT")}`}
                              type={confirmPassShow ? "text" : "password"}
                              autoComplete="new-password"
                              {...register("confirmPassword", {
                                onChange: (e) => {
                                  onInputChange(e);
                                },
                                ...sanitizeHtmlTags(),
                                required: {
                                  value: true,
                                  message: `${t("CONFIRM_PASS_REQUIRED")}`,
                                },
                                minLength: {
                                  value: 6,
                                  message: `${t("CONFIRM_PASS_MINLENGTH")}`,
                                },
                                maxLength: {
                                  value: 12,
                                  message: `${t("CONFIRM_PASS_MAXLENGTH")}`,
                                },
                                pattern: {
                                  value:
                                    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                                  message: `${t("INVALID_PASSWORD")}`,
                                },
                                validate: (value) =>
                                  value === password.current ||
                                  `${t("NOT_MATCH_NEWPASS")}`,
                              })}
                            />
                            {errors.confirmPassword && (
                              <span className="errorMsg">
                                {errors.confirmPassword.message}
                              </span>
                            )}
                            <div className="passwordicon">
                              {confirmPassShow ? (
                                <FaEye
                                  className="icon"
                                  onClick={() =>
                                    setConfirmPassShow(!confirmPassShow)
                                  }
                                />
                              ) : (
                                <FaEyeSlash
                                  className="icon"
                                  onClick={() =>
                                    setConfirmPassShow(!confirmPassShow)
                                  }
                                />
                              )}
                            </div>
                          </>
                        </Form.Group>
                        <div
                          className={`${styles.updateBtnSpace} commonButton`}
                        >
                          <button type="submit">{t("CHANGE_PASSWORD")}</button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
}
export default ChangePassword;
