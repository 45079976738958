import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../utils/Loader/Loader";
import { Col, Container, Form, Row } from "react-bootstrap";
import SettingSidebar from "../Settings/SettingSidebar";
import styles from "../../appComponents/DriverProfile/DriverProfile.module.css";
import ProfileUpload from "../Settings/ProfileUpload";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Toster from "../../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../../store/slices/UserSlice";

export default function Packing() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const [setting, setSetting] = useState([
    {
      id: "2",
      label: "No Help Required",
      type: "no_help_required",
      isChecked: false,
    },
    {
      id: "3",
      label: "With Driver Help",
      type: "driver_assistance",
      isChecked: false,
    },
    {
      id: "4",
      label: "Driver + 1 Porter",
      type: "driver_helper",
      isChecked: false,
    },
    {
      id: "5",
      label: "Driver + 2 Porters",
      type: "driver_2_helper",
      isChecked: false,
    },
    { id: "1", label: "Packing", type: "packing", isChecked: false },
  ]);

  const { userToken } = useSelector((state) => state.user);

  //------ get service list -------
  async function serviceList() {
    setLoader(true);
    try {
      const response = await SublyApi.getServiceSettingList(userToken);
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];

      if (response && response.code === STATUS_CODES.SUCCESS) {
        const serviceList = response.data.serviceList;

        // Create a map of service type to enable status for easy access
        const serviceMap = serviceList.reduce((map, item) => {
          map[item.service_type] = item.service_enable === 1;
          return map;
        }, {});

        // Update the setting state based on the serviceMap
        setSetting((prevState) =>
          prevState.map((item) => ({
            ...item,
            isChecked: serviceMap[item.type] || false,
          }))
        );

        setLoader(false);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    } catch (error) {
      setLoader(false);
    }
  }

  //------ update service list -------
  async function updateServiceSettingList(type, checked) {
    setLoader(true);
    await SublyApi.updateServiceSettingList(userToken, type).then(
      (response) => {
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          serviceList();
          Toster(t("SERVICE_UPDATE"), "success");
          setLoader(false);
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
          setLoader(false);
        } else {
          setLoader(false);
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  }

  useEffect(() => {
    serviceList();
  }, []);

  // Handle change event
  const handleSettingChange = (type, isChecked) => {
    updateServiceSettingList(type, isChecked);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
              </div>
              <div className={styles.profileBox}>
                <Row className={styles.drProfileSectionRow}>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3 className={styles.profileHeading}>
                        {t("PACKING_HELPER_TEXT")} <span>{t("SETTINGS")}</span>
                      </h3>
                      <p className={styles.profileSubText}>
                        {t("PACKING_SERVICE_SUB_TEXT")}
                      </p>
                      <div className={styles.packingRadioWrapper}>
                        {setting.map((val) => {
                          return (
                            <div className={styles.packingRadio}>
                              <div className={styles.btnText}>
                                {t(val.label)}
                              </div>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="packing_switch"
                                  id="packing-switching"
                                  checked={val.isChecked}
                                  onChange={(e) =>
                                    handleSettingChange(
                                      val.type,
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
}
