import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import styles from "../DriverSignUp/DriverSignUp.module.css";
import { MdCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { masterList } from "../../../store/slices/UserSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { STATUS_CODES } from "../../../utils/StatusCode";

//---------function for vehicle color select popup modal---------
function VehicletColorModal({
  vehicleColorModal,
  setVehicleColorModal,
  setValue,
  selectVehicleColor,
  setSelectVehicleColor,
  clearErrors,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vehicleColor, setVehicleColor] = useState("");

  // ----------- function for manage select vehicle color----------------
  const handleColorChange = (color) => {
    setSelectVehicleColor({
      id: color.id,
      value_name: color.value_name,
    });
    // setValue("vehicleColor", color.value_name);
    // clearErrors("vehicleColor");
  };

  // --------function for driver vehicle list Info api call--------
  useEffect(() => {
    const refKeyData = "colours,property_types,helpers,floors,B2B";
    dispatch(masterList(refKeyData)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setVehicleColor(response && response.data && response.data.colours);
      }
    });
  }, []);

  const handleSumbitColor = () => {
    setValue("vehicleColor", selectVehicleColor.value_name);
    clearErrors("vehicleColor");
    setVehicleColorModal(false);
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={vehicleColorModal}
        onHide={() => {
          setVehicleColorModal(false);
        }}
        className="colorModal"
        backdrop="static"
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <h4>{t("SELECT_VEHICLE_COLOR")}</h4>
            <MdCancel
              onClick={() => {
                setVehicleColorModal(false);
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3 colorInput">
            {vehicleColor &&
              vehicleColor.length > 0 &&
              vehicleColor.map((item, index) => (
                <div className={styles.inpuColorBox} key={index}>
                  <label
                    htmlFor={item.id}
                    className={
                      selectVehicleColor.id === item.id
                        ? styles.activeFloor
                        : ""
                    }
                  >
                    {item.value_name}
                  </label>
                  <input
                    type="radio"
                    id={item.id}
                    name="vehiclecolor"
                    value={item.value_name}
                    checked={
                      selectVehicleColor && selectVehicleColor.id === item.id
                    }
                    onChange={() => handleColorChange(item)}
                  />
                </div>
              ))}
          </Form.Group>
          <div className="commonButton">
            <button
              type="submit"
              onClick={() => {
                // setVehicleColorModal(false);
                handleSumbitColor();
              }}
            >
              {t("DONE")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VehicletColorModal;
