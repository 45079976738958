import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverProfile/DriverProfile.module.css";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { TiArrowSortedDown } from "react-icons/ti";
import SelectCategory from "./Module/SelectCategory";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import Toster from "../../../utils/Toaster";
import SelectBookingType from "./Module/SelectBookingType";
import SelectStatusType from "./Module/SelectStatusType";
import SelectIssueType from "./Module/SelectIssueType";
import RaiseATicketPopup from "./Module/RaiseATicketPopup";

const DriverSupport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { userToken } = useSelector((state) => state.user);

  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
  });

  const [currentBookingValue, setCurrentBookingValue] = useState({
    val: "",
    id: "",
  });

  const [currentStatusValue, setCurrentStatusValue] = useState({
    val: "",
    id: "",
  });

  const [currentIssueValue, setCurrentIssueValue] = useState({
    val: "",
    id: "",
  });

  const [support, setSupport] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showBookingType, setShowBookingType] = useState(false);
  const [showStatusType, setShowStatusType] = useState(false);
  const [showIssueType, setShowIssueType] = useState(false);
  const [showRaiseTicket, setShowRaiseTicket] = useState(false);
  const [isNoMoreOptions, setIsNoMoreOptions] = useState(false);
  const [lastOptionID, setLastOptionID] = useState("");
  const [options, setOptions] = useState({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });
  const [show, setShow] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });
  const HandleShow = () => {
    setShowPopup(true);
  };

  const handleShowBookingType = () => {
    setShowBookingType(true);
  };

  const handleShowStatusType = () => {
    setShowStatusType(true);
  };

  const handleShowIssueType = () => {
    setShowIssueType(true);
  };

  const handleShowRaiseTicket = () => {
    setShowRaiseTicket(true);
  };

  //----- function for delete van -----
  const SupportListApi = async (id, stage = "first") => {
    setIsLoading(true);
    await SublyApi.getDriverSupportList(userToken, id, true).then(
      (response) => {
        setIsLoading(false);
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          setSupport(response && response.data);
          setIsNoMoreOptions(response.data.length == 0 ? true : false);
          if (stage == "first") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              first: response.data,
            }));
          }
          if (stage == "second") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              second: response.data,
            }));
            setShow((prevOptions) => ({
              ...prevOptions,
              second: response.data.length == 0 ? false : true,
              third: response.data.length == 0 ? false : true,
              fourth: response.data.length == 0 ? false : true,
            }));
          }
          if (stage == "third") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              third: response.data,
            }));
            setShow((prevOptions) => ({
              ...prevOptions,
              third: response.data.length == 0 ? false : true,
              fourth: response.data.length == 0 ? false : true,
            }));
          }
          if (stage == "fourth") {
            setOptions((prevOptions) => ({
              ...prevOptions,
              fourth: response.data,
            }));
            setShow((prevOptions) => ({
              ...prevOptions,
              fourth: response.data.length == 0 ? false : true,
            }));
          }
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
        } else {
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  useEffect(() => {
    SupportListApi("", "first");
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3
                        className={`${styles.profileHeading} profileHeadingBotLine`}
                      >
                        {t("INSTANT")} <span>{t("SUPPORT")}</span>
                      </h3>
                      <p className={styles.profileSubText}>
                        {t("SUBTEXT_SUPPORT")}
                      </p>
                      <div className=" driverSupportCls">
                        <Form.Group className="mb-3 mt-4 setIconPassword formSection">
                          <Form.Label>{t("CATEGORIE")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("SELECT_VEHICLE")}`}
                            type="text"
                            readOnly
                            value={currentValue.val}
                            onClick={() => {
                              HandleShow();
                            }}
                            className={styles.inputFieldClass}
                          />

                          <div className={styles.downiconicon}>
                            <TiArrowSortedDown
                              onClick={() => {
                                HandleShow();
                              }}
                            />
                          </div>
                        </Form.Group>

                        {currentValue.id != "" && show.second && (
                          <Form.Group className="mb-3 mt-4 setIconPassword formSection">
                            <Form.Label>{t("BOOKING_TYPE")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("SELECT_VEHICLE")}`}
                              type="text"
                              value={currentBookingValue.val}
                              onClick={() => {
                                handleShowBookingType(currentValue.id);
                              }}
                              className={styles.inputFieldClass}
                            />
                            <div className={styles.downiconicon}>
                              <TiArrowSortedDown
                                onClick={() => {
                                  handleShowBookingType(currentValue.id);
                                }}
                              />
                            </div>
                          </Form.Group>
                        )}

                        {currentBookingValue.id != "" && show.third && (
                          <Form.Group className="mb-3 mt-4 setIconPassword formSection">
                            <Form.Label>{t("STATUS_TYPE")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("SELECT_VEHICLE")}`}
                              type="text"
                              readOnly
                              value={currentStatusValue.val}
                              onClick={() => {
                                handleShowStatusType(currentBookingValue.id);
                              }}
                              className={styles.inputFieldClass}
                            />
                            <div className={styles.downiconicon}>
                              <TiArrowSortedDown
                                onClick={() => {
                                  handleShowStatusType(currentBookingValue.id);
                                }}
                              />
                            </div>
                          </Form.Group>
                        )}

                        {currentStatusValue.id != "" && show.fourth && (
                          <Form.Group className="mb-4 mt-4 setIconPassword formSection">
                            <Form.Label>{t("ISSUE_TYPE")}</Form.Label>
                            <Form.Control
                              placeholder={`${t("SELECT_VEHICLE")}`}
                              type="text"
                              readOnly
                              value={currentIssueValue.val}
                              onClick={() => {
                                handleShowIssueType(currentStatusValue.id);
                              }}
                              className={styles.inputFieldClass}
                            />
                            <div className={styles.downiconicon}>
                              <TiArrowSortedDown
                                onClick={() => {
                                  handleShowIssueType(currentStatusValue.id);
                                }}
                              />
                            </div>
                          </Form.Group>
                        )}

                        {isNoMoreOptions && (
                          <div
                            className={styles.supportDriverBtn}
                            style={{ width: "100%" }}
                          >
                            <button
                              onClick={() =>
                                handleShowRaiseTicket(currentIssueValue.id)
                              }
                            >
                              {t("RAISE_A_TICKET")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>

      <SelectCategory
        showPopup={showPopup}
        setCurrentValue={setCurrentValue}
        currentValue={currentValue}
        CloseHandlePopup={() => setShowPopup(false)}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
      />
      <SelectBookingType
        showBookingType={showBookingType}
        CloseHandleBookingType={() => setShowBookingType(false)}
        currentValue={currentValue}
        setCurrentBookingValue={setCurrentBookingValue}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
      />

      <SelectStatusType
        CloseHandleStatusType={() => setShowStatusType(false)}
        showStatusType={showStatusType}
        setCurrentStatusValue={setCurrentStatusValue}
        currentStatusValue={currentStatusValue}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
      />

      <SelectIssueType
        setCurrentIssueValue={setCurrentIssueValue}
        currentIssueValue={currentIssueValue}
        CloseHandleIssueType={() => setShowIssueType(false)}
        showIssueType={showIssueType}
        SupportListApi={SupportListApi}
        options={options}
        setLastOptionID={setLastOptionID}
        setIsNoMoreOptions={setIsNoMoreOptions}
      />

      <RaiseATicketPopup
        showRaiseTicket={showRaiseTicket}
        CloseHandleRaiseTiKet={() => setShowRaiseTicket(false)}
        currentIssueValue={currentIssueValue}
        setCurrentValue={setCurrentValue}
        setCurrentBookingValue={setCurrentBookingValue}
        setCurrentStatusValue={setCurrentStatusValue}
        setCurrentIssueValue={setCurrentIssueValue}
        setSupport={setSupport}
        setIsNoMoreOptions={setIsNoMoreOptions}
        lastOptionID={lastOptionID}
      />
    </>
  );
};

export default DriverSupport;
