import React, { Component } from "react";
import { Crisp } from "crisp-sdk-web";

class CrispChat extends Component {
  componentDidMount() {
    Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID);
  }

  render() {
    return <></>;
  }
}

export default CrispChat;
