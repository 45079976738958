import React, { useEffect, useRef, useState } from "react";
import styles from "./Filter.module.css";
import { Button, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import {
  DateCalendar,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import SublyApi from "../../../../helpers/Api";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import { Autocomplete } from "@react-google-maps/api";
import Slider from "rc-slider";

export default function Filter({
  filterModal,
  setFilterModal,
  date,
  setDate,
  selectedCategories,
  setSelectedCategories,
  handleFilterApply,
  setSearchKey,
  geometry,
  setGeometry,
  distance,
  setDistance,
  handleClearFilter,
}) {
  const { t } = useTranslation();
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;
  const autocomplete = useRef(null);

  const [categories, setCategories] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${placeApiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      setGoogleMapsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //------get all category-------
  async function allCategory() {
    // setLoader(true);
    await SublyApi.getCategoryList().then((response) => {
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setCategories(response && response.data);
      }
    });
  }

  useEffect(() => {
    allCategory();
  }, []);

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handlePlaceSelected = (place, type) => {
    setGeometry({
      address: autocomplete.current.getPlace().formatted_address,
      lat: autocomplete.current.getPlace().geometry.location.lat(),
      lng: autocomplete.current.getPlace().geometry.location.lng(),
    });
  };

  const handleLocationChange = (value, type) => {
    setGeometry((prev) => ({
      ...prev,
      lat: "",
      lng: "",
      address: value,
    }));
    if (!value) {
      setShowErrorMsg(false);
    }
  };

  // function for change radius
  const handleRadiusChange = (value) => {
    setDistance(value);
  };

  const handleClose = () => {
    setShowErrorMsg(false);
    setFilterModal((pre) => ({ ...pre, show: false }));
  };

  const handleApply = () => {
    if (geometry.address && !geometry.lat) {
      setShowErrorMsg(true);
      return;
    }
    handleFilterApply();
    setFilterModal((pre) => ({ ...pre, show: false }));
  };

  const handleClearAll = () => {
    handleClearFilter();
    setSelectedCategories([]);
    setDate(null);
    setSearchKey("");
    setDistance(150);
    setShowErrorMsg(false);
    setFilterModal((pre) => ({ ...pre, show: false }));
    // handleApply();
  };

  const handleAllSelect = () => {
    if (selectedCategories?.length === categories?.length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories.map((category) => category.id));
    }
  };

  const handleCheckboxChange = (id, isAll) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(
        selectedCategories.filter((categoryId) => categoryId !== id)
      );
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  // Function to check if a category is selected
  const isCategorySelected = (id) => {
    return (
      selectedCategories?.includes(id) ||
      (id === "all" && selectedCategories?.length === categories?.length)
    );
  };

  return (
    <Modal show={filterModal.show} onHide={() => handleClose()}>
      <div className="selectModal">
        <Modal.Header>
          <Modal.Title>{t("FILTER")}</Modal.Title>
          <IoMdCloseCircle
            onClick={() => {
              handleClose();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className={styles.dateSelector}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                className="filterjobDatepickerfull muiFullYearSelection"
                value={date}
                onChange={handleDateChange}
                views={["year", "month", "day"]}
              />
            </LocalizationProvider>
          </Row>
          <Row className="mt-3">
            <Autocomplete
              placeApiKey={placeApiKey}
              onLoad={(auto) => {
                autocomplete.current = auto;
              }}
              onPlaceChanged={(place) => handlePlaceSelected(place)}
              className={styles.locationInput}
            >
              <input
                type="text"
                placeholder={t("SELECT_LOCATION_TEXT")}
                value={geometry.address}
                onChange={(e) =>
                  handleLocationChange(e.target.value, "sourceLocation")
                }
                className="form-control"
                style={{
                  border:
                    showErrorMsg && !geometry.lat ? "1px solid #dc3545" : "",
                }}
              />
            </Autocomplete>
            {showErrorMsg && !geometry.lat && (
              <span className="errorMsg">{t("SELECT_LOCATION")}</span>
            )}
          </Row>
          <Row className={styles.distanceSectionRow}>
            <div className={styles.distanceSection}>
              <div className={styles.distanceHead}>{t("RADIUS")}</div>
              <div className={styles.distanceValue}>{distance} Miles</div>
            </div>
            <div className="SliderSet">
              <Slider
                min={1}
                max={500}
                value={distance}
                onChange={handleRadiusChange}
              />
            </div>
          </Row>
          <Row className={styles.categoryRow}>
            <div className={styles.categoryHead}>Category</div>
            <div
              key="all"
              className="categoryCheckboxes"
              style={{ color: "black" }}
              onClick={() => {
                handleAllSelect();
              }}
            >
              <label
                className={isCategorySelected("all") ? styles.activeFloor : ""}
              >
                All
              </label>
              {/* <Button
                variant={isCategorySelected("all") ? "primary" : "secondary"}
                className={`${styles.selectedBtn}`}
                onClick={(e) => {
                  handleAllSelect();
                }}
              >
                {isCategorySelected("all") ? t("SELECTED") : t("SELECT")}
              </Button> */}
            </div>
            {categories?.map((checkbox) => (
              <div
                key={checkbox.value_code}
                className="categoryCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(checkbox.id, checkbox.name);
                }}
              >
                <label
                  className={
                    selectedCategories?.includes(checkbox.id)
                      ? styles.activeCategory
                      : ""
                  }
                >
                  {checkbox.name}
                </label>
                {/* <Button
                  variant={
                    selectedCategories?.includes(checkbox.id)
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(checkbox.id, checkbox.val);
                  }}
                >
                  {selectedCategories?.includes(checkbox.id)
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Row>
          <Row className={styles.btnApply}>
            <Button className={styles.applyBtn} onClick={() => handleApply()}>
              Apply
            </Button>
          </Row>
          <Row>
            <div className={styles.clearBtn}>
              <span onClick={() => handleClearAll()}>Clear All</span>
            </div>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
}
