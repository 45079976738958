//---------------- Common Text----------------------
export const TRANSLATIONS_FR = {
  //----------------Header Text----------------------
  HOME: "Maison",
  CREATE_JOB: "Créer un emploi",
  LOGIN: "Se connecter",
  DRIVER: "Devenez chauffeur",
  FAQ: "FAQ",
  SUPPORT: "Soutien",
  JOB: "Emplois",
  ENGLISH: "Anglais",
  SPANISH: "Espagnol",
  FRENCH: "Français",
  TURKISH: "Turc",
  CHINESE: "Chinois",
  ARABIC: "Arabe",

  //----------------Footer Text----------------------
  COPYRIGHT: "Tous droits réservés",
  SWIFTVAN: "SwiftVan",
  PRIVACY: "politique de confidentialité",
  TERMS: "Conditions générales",

  //----------------HomePage Text----------------------
  BOOK_MOVE: "Réservez votre déménagement dans 60 secondes",
  WHAT_MOVING: "Que déménagez-vous?",
  BECOME_DRIVER: "Devenez CONDUCTEUR",
  CALCULATE: "Calculer",
  SOURSE_LOCATION: "Entrez l'emplacement source",
  DESTINY_LOCATION: "Entrez l'emplacement de destination",
  ABOUT_US: "À propos de nous",
  CATEGORIES: "Ce que nous aimons",
  DOING: "faire",
  WORKS: "travaux",
  HOW: "Comment cela",
  FURNITURE_OTHER: "Meubles et autres objets",
  HOME_REMOVAL: "Déménagements à domicile",
  BTOB: "Entreprise à entreprise",
  SCHEDULE_PICKUP: "Planifiez un ramassage facile",
  ARRIVE: "Nous arrivons à votre porte",
  DELIVER: "Livrer à temps",

  //----------------LoginForm Text----------------------
  EMAIL: "E-mail",
  EMAIL_TEXT: "Entrez l'e-mail",
  PASSWORD: "Mot de passe",
  PASSWORD_TEXT: "Entrer le mot de passe",
  LOGIN: "SE CONNECTER",
  FORGOT_PASSWORD: "Mot de passe oublié?",
  EMAIL_REQUIRED: "Veuillez entrer votre adresse e-mail.",
  EMAIL_INVALID: "S'il vous plaît, mettez une adresse email valide.",
  PASSWORD_REQUIRED: "S'il vous plait entrez votre mot de passe.",
  PASS_MINLENGTH:
    "Veuillez entrer un mot de passe, avec un minimum de 6 caractères.",
  PASS_MAXLENGTH:
    "Veuillez saisir un mot de passe, avec un maximum de 12 caractères.",
  NO_ACCOUNT: "Je n'ai pas de compte?",
  SINGUP: "S'inscrire",

  //----------------ForgotPassword Text----------------------
  CANCEL: "Annuler",
  RESET: "Réinitialiser",
  FORGOT_TEXT:
    "Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe",
  FORGOT_PASSWORD_TEXT: "MOT DE PASSE OUBLIÉ",

  //----------------SignUp Form Text----------------------
  CREATE_ACCOUNT: "Créer un compte",
  FIRST_NAME: "Prénom",
  LAST_NAME: "Nom de famille",
  CONTACT: "Numéro de contact",
  CONFIRM_PASSWORD: "Confirmez le mot de passe",
  FIRSTNAME_TEXT: "Entrez votre prénom",
  FIRSTNAME_REQUIRED: "Veuillez entrer votre prénom.",
  FIRSTNAME_MINLENGTH: "Veuillez entrer un prénom d'au moins 2 caractères.",
  FIRSTNAME_MAXLENGTH: "Veuillez entrer un prénom d'au plus 50 caractères.",
  LASTNAME_TEXT: "Entrez votre nom de famille",
  LASTNAME_REQUIRED: "Veuillez entrer votre nom de famille.",
  LASTNAME_MINLENGTH:
    "Veuillez entrer un nom de famille d'au moins 2 caractères.",
  LASTNAME_MAXLENGTH:
    "Veuillez entrer un nom de famille d'au plus 50 caractères.",
  CONTACT_TEXT: "Sélectionnez le numéro de contact",
  CONTACT_REQUIRED: "Veuillez entrer votre numéro de contact.",
  CONFIRM_PASS_TEXT: "Entrez le mot de passe de confirmation",
  CONFIRM_PASS_REQUIRED: "Veuillez entrer votre mot de passe de confirmation.",
  CONFIRM_PASS_MINLENGTH:
    "Veuillez entrer un mot de passe de confirmation d'au moins 6 caractères.",
  CONFIRM_PASS_MAXLENGTH:
    "Veuillez entrer un mot de passe de confirmation d'au plus 12 caractères.",
  NOT_MATCH:
    "Le mot de passe ne correspond pas au mot de passe de confirmation.",
  ALREADY_ACCOUNT: "Vous avez déjà un compte?",
  SIGNIN: "Se connecter",
  SIGNUP_TEXT: "S'inscrire",
  INVALID_PASSWORD:
    "Le mot de passe doit contenir 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial minimum 6 caractères.",

  //----------------Reset Password Text----------------------
  NEW_PASSWORD: "Nouveau mot de passe",
  SUBMIT: "Soumettre",
  OTP_REQUIRED: "L'OTP est requis",
  DIGIT_REQUIRED: "Veuillez entrer un OTP à 4 chiffres",
  NUMBER_REQUIRED: "Veuillez entrer uniquement des chiffres.",

  //----------------SUCCESS Messages Text----------------------
  LOGIN_SUCCESSFULLY: "Connecté avec succès.",
  CREATE_ACCOUNT_SUCCESSFUL: "Création du compte réussie.",
  RESET_SUCCESFULLY: "Code de réinitialisation envoyé avec succès!",
  UPDATE_SUCCESFULLY: "Réinitialisez le mot de passe avec succès.",

  //----------------Error Messages Text----------------------
  HEADER_IS_MISSING: "L'en-tête est manquant.",
  DEVICE_TYPE_NOT_ALLOWED: "Type de périphérique non autorisé.",
  INVALID_API_KEY: "Clé API non valide.",
  TOKEN_REQUIRE_AUTHENTICATION: "Un jeton est requis pour l'authentification.",
  INVALID_TOKEN: "Jeton non valide.",
  SERVER_VALIDATION: "Erreur de validation.",
  USER_NOT_FOUND: "Utilisateur non trouvé",
  ACCOUNT_BLOCKED: "Votre compte est actuellement bloqué.",
  SOCIAL_USER_NOT_FOUND: "Utilisateur social non trouvé",
  INTERNAL_SERVER_ERROR: "Erreur interne du serveur",
  INVALID_LOGIN_CREDENTIAL:
    "Informations d'identification de connexion non valides.",
  ACCOUNT_NOT_FOUND:
    "Compte introuvable, veuillez saisir l'adresse e-mail correcte.",
  ACCOUNT_INACTIVE: "Votre compte est actuellement inactif.",
  ACCOUNT_DELETED: "Votre compte a été supprimé.",
  OLD_PASSWORD_DOES_NOT_MATCH: "Ancien mot de passe ne correspond pas.",
  EMAIL_ALREADY_IN_USE:
    "L'email est déjà utilisé, veuillez utiliser un autre email.",
  PHONE_NUMBER_EXIST:
    "Le numéro de téléphone est déjà utilisé, veuillez utiliser un autre numéro de téléphone.",
  SOCIALID_EXIST: "L'identifiant social existe déjà",
  CODE_EXIST: "Le code est déjà utilisé",
  CODE_NOT_MATCH: "Le code ne correspond pas",
  EMAIL_LINKED_SOCIALUSER: "L'email est lié à un utilisateur social",
  EMAIL_LINKED_NORMALUSER: "L'email est lié à un utilisateur normal",
  EMAIL_FAIL: "Échec de l'envoi de l'e-mail",
  VEHICLE_IMG: "L'image du véhicule est obligatoire",
  LICENSE_IMG: "Le permis de conduire est obligatoire",
  GT_INSURANCE_IMG: "L'assurance marchandises en transit est obligatoire",
  HR_INSURANCE_IMG: "L'assurance location et récompense est obligatoire",
  PL_INSURANCE_IMG: "L'assurance responsabilité civile est obligatoire",
  VEHICLE_IMG_SIZE: "Taille maximale de l'image du véhicule : 10 Mo",
  INVALID_VEHICLE_IMG: `Extension d'image de véhicule invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_VEHICLE_ID: "ID de véhicule invalide",
  INVALID_COLOR_ID: "ID de couleur de véhicule invalide",
  LICENSE_IMG_SIZE: "Taille maximale du permis de conduire : 10 Mo",
  INVALID_LICENSE_IMG: `Extension de permis de conduire invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  GT_INSURANCE_IMG_SIZE:
    "Taille maximale de l'assurance marchandises en transit : 10 Mo",
  INVALID_GT_INSURANCE_IMG: `Extension d'assurance marchandises en transit invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  HR_INSURANCE_IMG_SIZE:
    "Taille maximale de l'assurance location et récompense : 10 Mo",
  INVALID_HR_INSURANCE_IMG: `Extension d'assurance location et récompense invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  PL_INSURANCE_IMG_SIZE:
    "Taille maximale de l'assurance responsabilité civile : 10 Mo",
  INVALID_PL_INSURANCE_IMG: `Extension d'assurance responsabilité civile invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  FAILED_IMG: "Échec de téléchargement de l'image",
  FAILED_LICENSE_IMG: "Échec de téléchargement du permis de conduire",
  FAILED_GT_IMG:
    "Échec de téléchargement de l'assurance marchandises en transit",
  FAILED_HR_IMG:
    "Échec de téléchargement de l'assurance location et récompense",
  FAILED_PL_IMG: "Échec de téléchargement de l'assurance responsabilité civile",
  INVALID_CATEGORY_ID: "ID de catégorie invalide/inactif",
  INVALID_VEHICLETYPE_ID: "ID de type de véhicule invalide/inactif",
  INVALID_DRIVER_ID: "ID de conducteur invalide/inactif",
  INVALID_SOURCE_FLOOR_ID: "ID d'étage source invalide/inactif",
  INVALID_DESTINATION_FLOOR_ID: "ID d'étage destination invalide/inactif",
  INVALID_HELPER_ID: "ID d'assistant invalide/inactif",
  INVALID_CATEGORY_JSON: "JSON de catégorie invalide/inactif",
  ACCESS_DENIED:
    "Accès refusé NOTE : ce code apparaîtra lorsqu'un membre du personnel tente d'exécuter des API auxquelles il n'est pas autorisé",
  WRONG: "Quelque chose s'est mal passé",
  INVALID_UNITID: "ID d'unité invalide/inactif",
  INVALID_STOP: "JSON d'arrêt invalide",
  INVALID_SPROPERTYID: "ID de type de propriété source invalide/inactif",
  INVALID_DPROPERTYID: "ID de type de propriété destination invalide/inactif",
  INVALID_SHEDULE_DATA: "Format JSON invalide des données de planification",
  TIME_EXIST:
    "Cette heure existe déjà pour le même jour, veuillez utiliser une autre heure de début",
  CUSTOMER_NOT_PERMIT: "Le client n'a pas l'autorisation pour cette action",
  INVALID_PRICE: "Format JSON invalide des données de configuration des prix",
  FOR_SCHEDULE:
    "Pour les données de planification, seulement deux créneaux sont autorisés le même jour",
  DRIVER_NOT_PERMISION:
    "Le conducteur n'a pas l'autorisation pour cette action",
  CAN_NOT_DELETE: "L'utilisateur ne peut pas supprimer ce compte",
  ALEREADY_LICENCE_USE:
    "Le numéro de licence est déjà utilisé, veuillez utiliser d'autres numéros de licence.",
  PROFILE_REQUIRED: "L'image de profil est obligatoire",
  PROFILE_IMG_SIZE: "Taille maximale de l'image de profil : 10 Mo",
  INVALID_PROFILE: `Extension d'image de profil invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp"`,
  DOC_IMG_REQUIRED: "L'image du document est obligatoire",
  DOC_IMG_SIZE: "Taille maximale de l'image du document : 10 Mo",
  INVALID_DOC_IMG: `Extension d'image de document invalide, veuillez utiliser ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_DOC: "Document invalide",
  PASS_NOT_MATCH:
    "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas",
  NOTIFY_ALREADY_EXIST: "Ce statut de notification existe déjà",
  ADD_VEHICLE: "Veuillez ajouter un véhicule",
  VEHICLE_NAME_ALREDY:
    "Le nom du véhicule existe déjà, veuillez utiliser un autre nom",
  NOT_PERMIT_USER: "L'utilisateur n'a pas l'autorisation pour cette action",
  VEHICLE_TYPE_NOT_DELETE: "Ce type de véhicule ne peut pas être supprimé",
  VEHICLE_TYPE_STATUS:
    "Le statut du type de véhicule existe déjà dans la base de données",
  ESTIMATE_WEIGHT_ID: "ID de poids estimé invalide",
  INVALID_JOB_TEMP_ID: "ID de modèle d'emploi invalide",
  INVALID_KEY: "Nom de clé invalide",
  MASTER_DATA_EXIST: "Le nom des données principales existe déjà",
  INVALID_MASTER_DATA: "ID de données principales invalide",
  MASTER_DATA_NOT_DELETE: "Impossible de supprimer ces données principales",
  MASTER_DATA_STATUS: "Le statut des données principales existe déjà",
  CATEGORY_ICON: "L'icône de catégorie est requise",
  CATEGORY_ICON_SIZE: "Taille maximale de l'icône de catégorie : 10 Mo",
  INVALID_CATEGORY_ICON: `Extension d'icône de catégorie invalide. Veuillez utiliser ".jpg", ".jpeg", ".png", ".webp"`,
  CATEGORY_ADD: "Catégorie ajoutée avec succès",
  CATEGORY_UPDATE: "Catégorie mise à jour avec succès",
  CUSTOMER_EXIST: "Utilisateurs déjà associés au client",
  DRIVER_EXIST: "Utilisateurs déjà associés au conducteur",

  //----------------Session Expire Text----------------------
  SESSION_EXPIRED: "Votre session a expiré !",

  //----------------Profile Page Text----------------------
  PROFILE: "Mon profil",
  SETTINGS: "Paramètres",
  EDIT_PROFILE: "Modifier le profil",
  USER_INFO: "Informations utilisateur",
  UPDATE: "Mettre à jour",
  LOGOUT: "Déconnexion",
  DELETE_ACCOUNT: "Supprimer le compte",
  SAVE_CARD: "Enregistrer les cartes",
  CHANGE_PASSWORD: "Changer le mot de passe",
  JOB_HISTORY: "Historique des travaux",

  //----------------Driver auth Page Text----------------------
  BACK: "Dos",
  NEXT: "Suivante",
  DONE: "Terminé",
  SELECT_VEHICLE_COLOR: "Sélectionner la couleur du véhicule",
  SELECT_VAN: "Sélectionner une camionnette",
  VEHICLE_INFO: "Informations sur le véhicule",
  LICENSE_PLATE_NUMBER: "Numéro de plaque d'immatriculation",
  ENTER_LICENSE: "Entrer le numéro de plaque d'immatriculation",
  LICENSE_REQUIRED: "Veuillez entrer le numéro de plaque d'immatriculation.",
  LICENSE_MINLENGTH:
    "Veuillez entrer le numéro de plaque d'immatriculation avec un minimum de 2 caractères.",
  LICENSE_MAXLENGTH:
    "Veuillez entrer le numéro de plaque d'immatriculation avec un maximum de 50 caractères.",
  VAN_TYPE: "Type de camionnette",
  SELECT_VAN: "Sélectionner le type de camionnette",
  VAN_REQUIRED: "Veuillez sélectionner une camionnette.",
  COLOR_VEHICLE: "Couleur du véhicule",
  SELECT_COLOR: "Sélectionner la couleur du véhicule",
  COLOR_REQUIRED: "Veuillez sélectionner la couleur du véhicule.",
  VEHICLE_YEAR: "Année du véhicule",
  YEAR_OF_VEHICLE: "Entrer l'année du véhicule",
  VEHICLE_YEAR_REQUIRED: "Veuillez entrer l'année du véhicule",
  YEAR_ALLOW_DIGITS: "Veuillez entrer une année à 4 chiffres",
  VEHICLE_PHOTO: "Photo du véhicule",
  VEHICLE_PHOTO_REQUIRED: "La photo du véhicule est obligatoire",
  UPLOAD_VEHICLE: "Télécharger la photo du véhicule",
  UPLOAD: "Télécharger",
  DESCRIPTION: "Description",
  ENTER_DESCRIPTION: "Entrer la description",
  DESCRIPTION_REQUIRED: "Veuillez entrer la description du véhicule.",
  DESCRIPTION_MINLENGTH:
    "Veuillez entrer la description du véhicule avec un minimum de 2 caractères.",
  DESCRIPTION_MAXLENGTH:
    "Veuillez entrer la description du véhicule avec un maximum de 300 caractères.",
  UPLOAD_DOCUMENT: "TÉLÉCHARGER LES DOCUMENTS",
  DRIVING_LICENSE: "Permis de conduire",
  DRIVING_LICENSE_REQUIRED: "Le permis de conduire est obligatoire",
  GT_INSURANCE_REQUIRED: "L'assurance marchandises en transit est obligatoire",
  HR_INSURANCE_REQUIRED: "L'assurance location et récompense est obligatoire",
  PL_INSURANCE_REQUIRED: "L'assurance responsabilité civile est obligatoire",
  GT_TITLE: "Assurance marchandises en transit",
  HR_TITLE: "Assurance location et récompense",
  PL_TITLE: "Assurance responsabilité civile",
  UPLOAD_LICENSE: "Télécharger le permis de conduire",
  UPLOAD_REGISTRATION: "Télécharger l'immatriculation du véhicule",
  ALERT: "Alerte",
  REMOVE_ALERT: "Êtes-vous sûr de vouloir supprimer",
  YES: "Oui",

  //-----------------------Create job localization text--------------------
  ENTER: "Entrer",
  FURNITURE_AND_OTHER: "Meubles et Autres Articles",
  SOURCE_LOCATION: "Emplacement de la Source",
  DESTINATION_LOCATION: "Emplacement de Destination",
  SELECT_FLOOR: "Sélectionner l'Étage",
  LIFT_AVAILABLE: "Ascenseur Disponible",
  STOPS: "Arrêts",
  ADD_STOPS: "Ajouter des Arrêts",
  ADD_MORE_STOPS: "Ajouter Plus d'Arrêts",
  AVAILABILITY: "Disponibilité",
  ESTIMATED_TIME_ARRIVAL: "Heure d'arrivée estimée",
  WANT_PACKING: "Voulez-vous emballer ?",

  //-----------------------Create job validations text--------------------
  ENTER_SOURCE_LOCATION: "Entrez l'Emplacement de la Source",
  ENTER_DESTINATION_LOCATION: "Entrez l'Emplacement de Destination",
  ENTER_ALL_STOPS_ADDRESS: "Veuillez entrer l'adresse de tous les arrêts.",
  MAXIMUM_STOPS_ALLOWED: "Un maximum de 9 arrêts est autorisé.",
  MIN_3_CHAR_REQUIRED: "Au moins 3 caractères sont requis.",
  SELECT_VEHICLE_TYPE: "Sélectionnez votre type de véhicule",
  SELECT_HELPER: "Sélectionnez l'aide",
  SELECT_DATE: "Sélectionnez la date",
  SELECT_TIME: "Sélectionnez l'heure",
  SELECT_DATE_TIME: "Sélectionnez la date et l'heure de disponibilité",
};
