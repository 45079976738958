import { Container, Form } from "react-bootstrap";
import backgroundImage from "../../../assets/DriverImages/Bg.png";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverSignUp/DriverSignUp.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import { FiArrowLeft } from "react-icons/fi";

import { useEffect, useState } from "react";

import { setSignupFormData } from "../../../store/slices/UserSlice";

//---------function for signUp Third step form----------
function SignUpThirdForm({ setNextStep, previousStep, setPreviousStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { signupFormData, nextStep } = useSelector((state) => state.user);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // Function to sanitize input value to ensure only numbers are accepted
  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  // --------function for show prefilled formdata--------
  useEffect(() => {
    if (previousStep == true) {
      setValue(
        "bankName",
        signupFormData.bankName ? signupFormData.bankName : ""
      );
      setValue(
        "bankAccountNumber",
        signupFormData.bankAccountNumber ? signupFormData.bankAccountNumber : ""
      );
      setValue(
        "bankSortCode",
        signupFormData.bankSortCode ? signupFormData.bankSortCode : ""
      );
    }
  }, [nextStep, previousStep]);

  // --------function for submit formdata--------
  const onSubmit = async (formdata) => {
    let userData = {
      ...formdata,
    };
    dispatch(setSignupFormData(userData));
    setNextStep(3);
  };

  return (
    <div className="main">
      <div className="spaceTopManageDriver">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlocks}>
              <div className={styles.signPageCls}>
                <span>
                  <strong>3</strong>/4
                </span>
                <div className={styles.signPageArrow}>
                  <FiArrowLeft
                    onClick={() => {
                      setNextStep(1);
                      setPreviousStep(true);
                    }}
                  />
                  <div className="commonheading">{t("ADD_BANK_ACCOUNT")}</div>
                </div>
              </div>
              {/* <div className={styles.signupPage}> */}
              {/* <p className={styles.signUpPara}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <Form.Label>{t("BANK_NAME")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_BANK_NAME")}`}
                    type="text"
                    {...register("bankName", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("BANK_NAME_REQUIRED")}`,
                      },
                      minLength: {
                        value: 3,
                        message: `${t("BANK_NAME_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 100,
                        message: `${t("BANK_NAME_MAXLENGTH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.bankName && (
                    <span className="errorMsg">{errors.bankName.message}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("BANK_ACCOUNT_NUMBER")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_BANK_ACCOUNT_NUMBER")}`}
                    type="text"
                    {...register("bankAccountNumber", {
                      onChange: handleChange, // Sanitize input value
                      onKeyPress: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("BANK_ACCOUNT_NUMBER_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("BANK_ACCOUNT_NUMBER_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 25,
                        message: `${t("BANK_ACCOUNT_NUMBER_MAXLENGTH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.bankAccountNumber && (
                    <span className="errorMsg">
                      {errors.bankAccountNumber.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("BANK_SORT_CODE")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_BANK_SORT_CODE")}`}
                    type="text"
                    {...register("bankSortCode", {
                      onChange: handleChange, // Sanitize input value
                      onKeyPress: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("BANK_SORT_CODE_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("BANK_SORT_CODE_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 25,
                        message: `${t("BANK_SORT_CODE_MAXLENGTH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.bankSortCode && (
                    <span className="errorMsg">
                      {errors.bankSortCode.message}
                    </span>
                  )}
                </Form.Group>
                <div className="commonButton">
                  <button type="submit">{t("NEXT")}</button>
                </div>
              </Form>
              {/* </div> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default SignUpThirdForm;
