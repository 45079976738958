import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCard from "./AddCard";
import Success from "./Success";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../../../utils/Loader/Loader";
import ConfirmPay from "./ConfirmPay";

export default function Payments() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stripeCustomerId } = useSelector((state) => state.user);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState();
  const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
  const clientSecretKey = process.env.REACT_APP_STRIPE_CLIENT_SECRET_KEY;
  const stripePromise = loadStripe(secretKey);

  useEffect(() => {
    if (location.state == null) {
      navigate("/hire-drivers");
    }
  }, []);

  const getCardList = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/customers/${stripeCustomerId}/cards`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + clientSecretKey,
          },
        }
      );
      const responseData = await response.json();

      if (responseData.data) {
        setIsLoading(false);
        setCardData(responseData.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCardList();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <>
        <Elements stripe={stripePromise}>
          <ConfirmPay
            location={location}
            setIsAddingCard={setIsAddingCard}
            cardData={cardData}
            setCardData={setCardData}
            setIsPaymentSuccess={setIsPaymentSuccess}
            setJobId={setJobId}
          />
          <AddCard
            isAddingCard={isAddingCard}
            setIsAddingCard={setIsAddingCard}
            getCardList={getCardList}
          />
        </Elements>
      </>

      <Success
        isPaymentSuccess={isPaymentSuccess}
        setIsPaymentSuccess={setIsPaymentSuccess}
        jobId={jobId}
      />
    </>
  );
}
