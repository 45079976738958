import { Button, Col, Form, Row } from "react-bootstrap";
import "../../../../assets/Styles/Common.css";
import { FiArrowLeft } from "react-icons/fi";
import styles from "../Furnitures.module.css";
import tabStyles from "./Tabs.module.css";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnitTypeList,
  setActiveTab,
  updateJobData,
} from "../../../../store/slices/createJob";
import { getActiveTab } from "../helper";
import { useEffect, useState } from "react";
import SelectUnits from "../Modals/selectUnits";
import chatIcon from "../../../../assets/Images/chatIcon.png";
import { v4 as uuidv4 } from "uuid";
import Toster from "../../../../utils/Toaster";

function Third() {
  var uniqueId = uuidv4().substring(0, 8);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.createJob.createJobData);
  const activeTab = useSelector((state) => state.createJob.activeTab);
  const [showSelectName, setShowSelectName] = useState({
    show: false,
    pro: {},
  });
  const [showUnitSelector, setShowUnitSelector] = useState({
    show: false,
    type: "",
    pro: {},
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [products, setProducts] = useState([
    {
      id: generateUniqueId(),
      name: "",
      quantity: "",
      width: "",
      height: "",
      depth: "",
      unit: { val: "", id: "" },
      weight: "",
      weightUnit: { val: "", id: "" },
      categoryId: "n/a",
      itemId: "n/a",
      time: 0,
      is_custom_item: 1,
    },
  ]);

  useEffect(() => {
    if (jobData.third && jobData.third.isSubmit) {
      setProducts(jobData.third.customItems);
    }
  }, []);

  useEffect(() => {
    dispatch(getUnitTypeList()).then((response) => {});
  }, []);

  const onBack = () => {
    dispatch(setActiveTab("second"));
  };

  const hasAllProductsValues = (product) => {
    // Iterate through each object in the array
    for (const obj of product) {
      // Iterate through each key in the object
      for (const key in obj) {
        // Check if the value corresponding to the key is an empty string or undefined
        if (
          obj.hasOwnProperty(key) &&
          (obj[key] === "" ||
            obj[key] === undefined ||
            (key === "unit" &&
              (obj[key].val === "" || obj[key].val === undefined)) ||
            (key === "weightUnit" &&
              (obj[key].val === "" || obj[key].val === undefined)))
        ) {
          return false; // If any value is empty or undefined, return false
        }
      }
    }
    return true; // If all keys have values in all objects, return true
  };

  const hasProductSelect = (product) => {
    // Iterate through each object in the array
    for (const obj of product) {
      // Check if the value corresponding to the 'proName' key is empty string or undefined
      if (
        !obj.hasOwnProperty("name") ||
        obj["name"] === "" ||
        obj["name"] === undefined ||
        !obj.hasOwnProperty("quantity") ||
        obj["quantity"] === "" ||
        obj["quantity"] === undefined
      ) {
        return false; // If 'proName' is empty or undefined, return false
      }
    }
    return true; // If all objects have 'proName' with non-empty value, return true
  };

  const isDuplicateName = (name) => {
    const names = products.map((prod) => prod.name);
    const occurrences = names.filter((n) => n === name).length;
    return occurrences > 1;
  };

  const goToNext = () => {
    setIsSubmitting(true);
    if (hasAllProductsValues(products)) {
      const names = products.map((product) => product.name);
      const isDuplicate = names.some(
        (name, index) => names.indexOf(name) !== index
      );

      if (isDuplicate) {
        // Handle duplicate name case, for example, show an error message
        Toster(t("ITEM_NOT_SAME"), "error");
        return;
      }

      // if (hasProductSelect(products)) {
      // if (false) {
      // dispatch(
      //   updateJobData({
      //     step: "third",
      //     data: { isSubmit: true, items: products, isBack: false },
      //   })
      // );
      // dispatch(setActiveTab("fourth"));
      // const updateReduxArrayWithProducts = (products) => {
      //   let array = [];
      //   // Iterate through products array and append each product to reduxArray
      //   products.map((productItem) => {
      //     array.push(productItem);
      //   });
      //   return jobData.second.allItems; // Return the updated array
      // };
      // let newArray = jobData.second.allItems.map((val) => {
      //   return products.map((productItem) => {
      //     return { ...val, productItem };
      //   });
      // });
      // Call this function when the button is clicked or whenever you want to update Redux array with products array data
      // const updatedReduxArray = updateReduxArrayWithProducts(products);
      // dispatch(
      //   updateJobData({
      //     step: "second",
      //     data: {
      //       items: jobData.second.items,
      //       isSubmit: true,
      //       isCustomItems: true,
      //       allItems: products,
      //       customItems: products,
      //     },
      //   })
      // );
      dispatch(
        updateJobData({
          step: "third",
          data: {
            isSubmit: true,
            items: products,
            customItems: products,
            isBack: false,
          },
        })
      );
      dispatch(setActiveTab("fourth"));
    } else {
      Toster(t("ENTER_PACKAGE_INFO"), "error");
    }
  };

  const handleShowName = (pro) => {
    setShowSelectName((pre) => ({ ...pre, show: true, pro }));
  };

  const handleUnitSelector = (unitType, product) => {
    setShowUnitSelector((pre) => ({
      ...pre,
      type: unitType,
      show: true,
      pro: product,
    }));
  };

  const addMoreProduct = () => {
    setIsSubmitting(false);
    setProducts((prevProducts) => [
      ...prevProducts,
      {
        id: generateUniqueId(),
        name: "",
        quantity: "",
        width: "",
        height: "",
        depth: "",
        unit: { val: "", id: "" },
        weight: "",
        weightUnit: { val: "", id: "" },
        categoryId: "n/a",
        itemId: "n/a",
        time: 0,
        is_custom_item: 1,
      },
    ]);
    // Toster("Package added successfully.", "success");
  };

  const handleDeleteProduct = (proId) => {
    setProducts((pre) => pre.filter((pro) => pro.id !== proId));
    // Toster("Package removed successfully.", "error");
  };

  const handleChangeProduct = (key, value, productId) => {
    // Handle name validation separately
    if (key === "name") {
      // Name length should not exceed 50 characters
      if (value.length > 50) {
        return;
      }
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? { ...product, [key]: value } : product
        )
      );
      return;
    }

    // Handle numerical values
    const numericValue = value;
    // Validate if the numeric value is a valid number
    if (isNaN(numericValue)) {
      // If value is not a number, simply return
      return;
    }

    // Check if the value exceeds the maximum allowed value
    if (numericValue > 1000000) {
      return;
    }

    // Ensure that the value does not start with '0' unless it is '0'
    if (value.startsWith("0")) {
      return;
    }

    // Check if the number has more than two decimal places
    if (value.includes(".")) {
      const decimalPlaces = value.split(".")[1].length;
      if (decimalPlaces > 2) {
        return;
      }
    }

    // Update the product state with validated numeric value
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId
          ? { ...product, [key]: numericValue ? numericValue : "" }
          : product
      )
    );
  };

  function generateUniqueId() {
    const uuid = uuidv4().substring(0, 8);
    const timestamp = Math.floor(Date.now() / 1000).toString(16); // convert current time to hexadecimal
    return `${uuid}-${timestamp}`;
  }

  return (
    <>
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHead}>
            <div className="backarrow">
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div className="backarrow">
              <span className={styles.tabCount}>
                <strong>{getActiveTab(activeTab) - 1}</strong>
                <span>/4</span>
              </span>
            </div>
          </div>
          <div className={styles.topSubContainer}>
            <div className={styles.topSubHead}>
              {/* <div className={styles.topSubHeadTitle}>
                SELECT YOUR PACKAGES INFO
              </div> */}
              <div className={styles.topHeading}>
                <div className={styles.backarrow}>
                  <FiArrowLeft onClick={() => onBack()} />
                </div>
                <div className={styles.topSubHeadTitle}>
                  {t("SELECT_PACKAGES_INFO")}
                </div>
                <div className={styles.tabCounting}>
                  <strong>{getActiveTab(activeTab) - 1}</strong>
                  <span>/4</span>
                </div>
              </div>
              <div className={styles.topSubHeadDesc}></div>
            </div>
          </div>
          <div className={tabStyles.thirdTabFieldsMain}>
            {products.map((product) => {
              return (
                <div className={tabStyles.thirdTabFields}>
                  <Row>
                    <Col lg={4}>
                      <Form.Group className="formOrderSection thirdTab">
                        <Form.Control
                          placeholder={"Enter item name"}
                          value={product.name}
                          onChange={(e) =>
                            handleChangeProduct(
                              "name",
                              e.target.value,
                              product.id
                            )
                          }
                          isInvalid={
                            isSubmitting &&
                            (!product.name || isDuplicateName(product.name))
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="formOrderSection thirdTab">
                        <Form.Control
                          placeholder={`Quantity`}
                          value={product.quantity}
                          onChange={(e) =>
                            handleChangeProduct(
                              "quantity",
                              e.target.value.replace(/\D/, ""),
                              product.id
                            )
                          }
                          isInvalid={isSubmitting && !product.quantity}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className={tabStyles.dimensionHead}>
                    {t("ESTIMATED_DIMENSIONS")}
                    <span style={{ color: "#dc3545" }}>*</span>
                  </div>
                  <div>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={3}>
                            <Form.Group className="formOrderSection thirdTab">
                              <Form.Control
                                isInvalid={isSubmitting && !product.width}
                                placeholder={t("Width")}
                                value={product.width}
                                onChange={(e) =>
                                  handleChangeProduct(
                                    "width",
                                    e.target.value,
                                    product.id
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={3}>
                            <Form.Group className="formOrderSection thirdTab">
                              <Form.Control
                                isInvalid={isSubmitting && !product.height}
                                placeholder={t("Height")}
                                value={product.height}
                                onChange={(e) =>
                                  handleChangeProduct(
                                    "height",
                                    e.target.value,
                                    product.id
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={3}>
                            <Form.Group className="formOrderSection thirdTab">
                              <Form.Control
                                isInvalid={isSubmitting && !product.depth}
                                placeholder={t("Depth")}
                                value={product.depth}
                                onChange={(e) =>
                                  handleChangeProduct(
                                    "depth",
                                    e.target.value,
                                    product.id
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={2}>
                            <Form.Group
                              className="formOrderSection thirdTab"
                              style={{ width: "80px" }}
                            >
                              <span
                                onClick={() =>
                                  handleUnitSelector("dimension_units", product)
                                }
                              >
                                <p
                                  style={{
                                    border:
                                      isSubmitting && !product.unit.val
                                        ? "1px solid #dc3545"
                                        : "",
                                  }}
                                >
                                  {!product.unit.val
                                    ? "Unit"
                                    : product.unit.val}
                                </p>
                                <Icon
                                  icon="bxs:up-arrow"
                                  color="rgba(130, 130, 130, 0.8509803921568627)"
                                  width="18"
                                  height="18"
                                  vFlip={true}
                                />
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="formOrderSection thirdTab">
                              <Form.Control
                                isInvalid={isSubmitting && !product.weight}
                                placeholder={t("Estimated weight")}
                                value={product.weight}
                                onChange={(e) =>
                                  handleChangeProduct(
                                    "weight",
                                    e.target.value,
                                    product.id
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={3}>
                            <Form.Group className="formOrderSection thirdTab">
                              <span
                                onClick={() =>
                                  handleUnitSelector("weight_units", product)
                                }
                              >
                                <p
                                  style={{
                                    border:
                                      isSubmitting && !product.weightUnit.val
                                        ? "1px solid #dc3545"
                                        : "",
                                  }}
                                >
                                  {!product.weightUnit.val
                                    ? "Unit"
                                    : product.weightUnit.val}
                                </p>
                                <Icon
                                  icon="bxs:up-arrow"
                                  color="rgba(130, 130, 130, 0.8509803921568627)"
                                  width="18"
                                  height="18"
                                  vFlip={true}
                                />
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {products.length > 1 && (
                    <Icon
                      icon="ic:round-cancel"
                      width="26"
                      height="26"
                      className={tabStyles.crossIcon}
                      onClick={() => {
                        handleDeleteProduct(product.id);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.addStopSection}>
            <span
              className={tabStyles.thirdAddBtn}
              onClick={() => addMoreProduct()}
            >
              {t("ADD_MORE")}
            </span>
          </div>
          <div className={tabStyles.furnitureSection}>
            <div className={styles.nextBtnFooter}>
              <button className={styles.nextBtn} onClick={() => goToNext()}>
                <span>{t("SUBMIT")}</span>
              </button>
            </div>
          </div>
        </div>
        {/* <SelectName
          showSelectName={showSelectName}
          setShowSelectName={setShowSelectName}
          // selectedName={selectedName}
          // setSelectedName={setSelectedName}
          products={products}
          setProducts={setProducts}
        /> */}
        <SelectUnits
          showUnitSelector={showUnitSelector}
          setShowUnitSelector={setShowUnitSelector}
          setProducts={setProducts}
        />
      </div>
    </>
  );
}
export default Third;
