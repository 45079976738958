import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { sofasOptions } from "../../../../mockData";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import styles from "./modal.module.css";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SelectModal({
  showSelectSofas,
  setShowSelectSofas,
  selectedOptions,
  setSelectedOptions,
}) {
  const [options, setOptions] = useState();
  const [filteredOptions, setFilteredOptions] = useState();
  const jobData = useSelector((state) => state.createJob.createJobData);
  useEffect(() => {
    if (jobData.second.isSubmit) {
      // setProducts(jobData.third.items);
      // setProducts(modifiedItems);
      // setProducts(allItems);
    }
  }, []);

  useEffect(() => {
    // Assuming showSelectSofas.subItems is an array of objects with 'name' property
    // const updatedOptions = showSelectSofas.subItems?.map((option) => ({
    //   ...option,
    //   quantity: 0, // Add quantity property with initial value of 0
    // }));
    // setOptions(updatedOptions);
    // setFilteredOptions(updatedOptions);
    // if (selectedOptions.length > 0) {
    //   const updatedOptions = showSelectSofas.subItems?.map((option) => ({
    //     ...option,
    //     quantity: 0, // Add quantity property with initial value of 0
    //   }));
    //   setOptions(updatedOptions);
    // } else {
    //   const updatedOptions = showSelectSofas.subItems?.map((option) => ({
    //     ...option,
    //     quantity: 0, // Add quantity property with initial value of 0
    //   }));
    //   setOptions(updatedOptions);
    //   setFilteredOptions(updatedOptions);
    // }
    // Clone the subItems array to avoid mutating the original data
    const getQuantity = (items, showSelectSofas, option) => {
      // Initialize total quantity
      let totalQuantity = 0;

      // showSelectSofas.subItems.map((val) => {
      items.map((el) => {
        if (option.id == el.id) {
          return (totalQuantity = el.quantity);
        }
      });
      // });
      // Iterate over items in showSelectSofas.subItems
      // for (const item of items) {
      //   // Check if the item exists in showSelectSofas.subItems
      //   const matchingItem = showSelectSofas?.subItems?.find(
      //     (selectedItem) => selectedItem.id === item.id
      //   );
      //   const get = selectedOptions[0].items.find(
      //     (val) => val.id == matchingItem.id
      //   );
      //   // If a matching item is found, add its quantity to the total quantity
      //   if (matchingItem) {
      //     // totalQuantity += matchingItem.quantity;
      //     // totalQuantity = matchingItem.quantity ? matchingItem.quantity : 0;
      //     totalQuantity = get.quantity || 0;
      //   }
      // }

      // Return the total quantity
      return totalQuantity;
    };

    const calculateQuantity = (option, selectedOptions) => {
      // Find the selected option with the same ID as the current option
      const selectedOption = selectedOptions.find(
        (selectedOption) => selectedOption.id === showSelectSofas.id
      );
      // If a matching selected option is found, return the total quantity, otherwise return 0
      return selectedOption
        ? getQuantity(selectedOption.items, showSelectSofas, option)
        : 0;
    };

    // const updatedOptions = showSelectSofas.subItems?.map((option) => {
    //   const selectedOption = selectedOptions.find(
    //     (selectedOption) => selectedOption.id === showSelectSofas.id
    //   );

    //   // If a selected option with the same ID is found, update the quantity
    //   if (selectedOption) {
    //     return {
    //       ...option,
    //       quantity: getQuantity(selectedOption.items), // Use the quantity of the first item of the selected option
    //     };
    //   }
    //   // If no selected option with the same ID is found, keep the quantity as 0
    //   return {
    //     ...option,
    //     quantity: 0,
    //   };
    // });
    const updatedOptions = showSelectSofas.subItems?.map((option) => ({
      ...option,
      quantity: calculateQuantity(option, selectedOptions),
      is_custom_item: 0,
      width: "",
      height: "",
      depth: "",
      unit: { val: "", id: "" },
      weight: "",
      weightUnit: { val: "", id: "" },
    }));

    // Set the updated options with pre-filled quantities
    setOptions(updatedOptions);
    setFilteredOptions(updatedOptions);
  }, [showSelectSofas.subItems, selectedOptions, showSelectSofas.show]);

  const handleQuantityChange = (id, value) => {
    // setOptions((prevOptions) => {
    //   const updatedOptions = prevOptions.map((option) => {
    //     if (option.id === id) {
    //       return {
    //         ...option,
    //         quantity: option.quantity + value,
    //       };
    //     }
    //     return option;
    //   });
    //   return updatedOptions;
    // });
    setFilteredOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === id) {
          return {
            ...option,
            quantity: option.quantity + value,
            is_custom_item: 0,
          };
        }
        return option;
      });
      return updatedOptions;
    });
  };

  const handleClose = () => {
    setShowSelectSofas((pre) => ({ ...pre, show: false }));
  };

  const handleSubmit = () => {
    if (options == undefined) {
      handleClose();
      return;
    }
    setSelectedOptions((prevOptions) => {
      // Check if an option with the same id already exists in selectedOptions
      const existingOptionIndex = prevOptions.findIndex(
        (option) => option.id === showSelectSofas.id
      );

      // Filter out options with quantity greater than 0
      const filteredOptions2 = filteredOptions.filter(
        (option) => option.quantity > 0
      );

      // Create a new array with the updated options
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === showSelectSofas.id) {
          // If the option matches the id, update its items property with the filtered options
          return { ...option, items: filteredOptions2 };
        }
        return option;
      });

      // If the option with the same id doesn't exist and there are options with quantity greater than 0,
      // add a new entry to selectedOptions
      if (existingOptionIndex === -1 && filteredOptions2.length > 0) {
        updatedOptions.push({
          id: showSelectSofas.id,
          title: showSelectSofas.title,
          items: filteredOptions2,
        });
      }

      return updatedOptions.filter((option) => option.items.length > 0);
    });

    handleClose();
  };

  const handleSearchSubCategory = (value) => {
    const query = value.toLowerCase().trim();
    // const filteredCategories = getActiveSubCategory.map((category) => {
    //   return {
    //     ...category,
    //     subcategory: category.subcategory.filter((subItem) =>
    //       subItem.name.toLowerCase().includes(query.toLowerCase())
    //     ),
    //   };
    // });
    const filteredSubCategories = options?.filter((subItem) => {
      return subItem.name.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredOptions(filteredSubCategories);
  };
  return (
    <>
      <Modal show={showSelectSofas.show} onHide={() => handleClose()}>
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{showSelectSofas.title} </Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <InputGroup className="mb-3" style={{ height: "45px" }}>
                  <InputGroup.Text
                    id="basic-addon1"
                    // style={{
                    //   borderRight: "none",
                    //   backgroundColor: "white",
                    // }}
                  >
                    <Icon
                      icon="iconamoon:search"
                      color="#4770EB"
                      width="22"
                      height="22"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    onChange={(e) => handleSearchSubCategory(e.target.value)}
                    // style={{ borderLeft: "none" }}
                    placeholder="Enter your items here"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              {filteredOptions?.map((option) => (
                <Col lg={6} className="mb-3">
                  <div className={styles.optionContainer}>
                    <div className={styles.optionName}>{option.name}</div>
                    <div className={styles.optionCounting}>
                      <div className={styles.optionCounts}>
                        {option.quantity > 0 ? (
                          <Icon
                            icon="ic:round-minus"
                            color="#202020"
                            width="19"
                            height="19"
                            onClick={() => handleQuantityChange(option.id, -1)}
                          />
                        ) : (
                          <Icon
                            icon="ic:round-minus"
                            color="#828282"
                            width="19"
                            height="19"
                          />
                        )}
                        <div className={styles.optionQuantity}>
                          {option.quantity}
                        </div>
                        <div>
                          {option.quantity >= 20 ? (
                            <Icon
                              icon="ic:round-plus"
                              color="rgba(130, 130, 130, 0.8509803921568627)"
                              width="20"
                              height="20"
                            />
                          ) : (
                            <Icon
                              icon="ic:round-plus"
                              color="#202020"
                              width="20"
                              height="20"
                              onClick={() =>
                                handleQuantityChange(option.id, +1)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              {options?.length == 0 ||
                (!options && <div>Subcategory not available!</div>)}
              {filteredOptions?.length == 0 && (
                <div>Does not found any results.!</div>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              Done
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default SelectModal;
