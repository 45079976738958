import Modal from "react-bootstrap/Modal";
import { MdCancel } from "react-icons/md";
import "../../../../assets/Styles/DriverCommon.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { sanitizeHtmlTags } from "../../../../utils/sanitizeHtmlTags";
import { TiArrowSortedDown } from "react-icons/ti";
import { CiCamera } from "react-icons/ci";
import uploadImg from "../../../../assets/DriverImages/uploadIcon.png";
import VehicletColorModal from "../../../authComponent/DriverSignUp/VehicleColor";
import styles from "../../../authComponent/DriverSignUp/DriverSignUp.module.css";
import VehicleTypeModal from "../../../authComponent/DriverSignUp/Vehicletype";
import SublyApi from "../../../../helpers/Api";
import Loader from "../../../../utils/Loader/Loader";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import Toster from "../../../../utils/Toaster";
import { userLogoutClear } from "../../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

//---------function for add and edit van popup modal---------
function AddVanPopup({
  showVanModal,
  setShowVanModal,
  isUpdate,
  vanList,
  getVehicleList,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vehiclePreview, setVehiclePreview] = useState("");
  const [vehicleImage, setVehicleImage] = useState("");
  const [vehicleColorModal, setVehicleColorModal] = useState(false);
  const [selectVehicleColor, setSelectVehicleColor] = useState("");
  const [vehicleTypeModal, setVehicleTypeModal] = useState(false);
  const [selectVehicle, setSelectVehicle] = useState("");
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [selectedYear, setSelectedYear] = useState(null);
  const [formatedDate, setFormatedDate] = useState("");

  const currentDate = new Date();
  const maxDate = new Date(currentDate); // Create a new date object based on the current date
  // maxDate.setFullYear(currentDate.getFullYear() + 1);
  maxDate.setFullYear(currentDate.getFullYear());

  // function handle date change
  const handleDateChange = (date) => {
    setSelectedYear(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY");

      setFormatedDate(formattedYear);
    } else {
      setFormatedDate("");
    }
  };

  // function for apply validation in year field
  const validateYear = () => {
    if (!formatedDate) {
      return `${t("VEHICLE_YEAR_REQUIRED")}.`;
    }
    return true;
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // function for apply validation in image field
  const validateImage = () => {
    if (!vehiclePreview) {
      return `${t("VEHICLE_PHOTO_REQUIRED")}`;
    }
    return true;
  };

  //----- function for Upload image-----
  function onImageChange(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setVehiclePreview(URL.createObjectURL(e.target.files[0]));
      setVehicleImage(e.target.files[0]);
    }
  }

  //----- function for remove image-----
  function onImageRemove() {
    setVehiclePreview("");
    setVehicleImage("");
  }

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
      setVehiclePreview("");
      setVehicleImage("");
      setSelectVehicle("");
      setSelectVehicleColor("");
      setSelectedYear("");
      setFormatedDate("");
    }
  }, [showVanModal]);

  useEffect(() => {
    reset();
    if (Object.keys(vanList).length > 0) {
      setValue(
        "LicencePlateNumber",
        vanList ? vanList.licence_plate_number : ""
      );
      setValue(
        "vehicleDescription",
        vanList && vanList.vehicle_description
          ? vanList.vehicle_description.charAt(0).toUpperCase() +
              vanList.vehicle_description.slice(1).toLowerCase()
          : ""
      );
      setVehiclePreview(vanList ? vanList && vanList.vehicle_image : "");
      setValue("vanType", (vanList && vanList.vehicle_name) || "");
      setSelectVehicle({
        id: (vanList && vanList.vehicle_type_id) || "",
        vehicle_name: (vanList && vanList.vehicle_name) || "",
      });
      setValue("vehicleColor", (vanList && vanList.value_name) || "");
      setSelectVehicleColor({
        id: (vanList && vanList.colour_id) || "",
        vehicle_name: (vanList && vanList.value_name) || "",
      });
      setFormatedDate((vanList.year && vanList.year) || "");
    } else {
      setVehiclePreview("");
      setVehicleImage("");
      reset();
      clearErrors();
      setSelectVehicle("");
      setSelectVehicleColor("");
      setSelectedYear("");
      setFormatedDate("");
    }
  }, [vanList, showVanModal]);

  // --------function for submit add van/edit van api calling--------
  const onSubmit = (formdata) => {
    {
      isUpdate === true ? updateApi(formdata) : createApi(formdata);
    }
  };

  //----- function for edit van-----
  const updateApi = async (formdata) => {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("driver_vehicle_id", vanList ? vanList.id : "");
    requestData.append(
      "licence_plate_number",
      formdata
        ? formdata.LicencePlateNumber && formdata.LicencePlateNumber.trim()
        : ""
    );
    requestData.append(
      "vehicle_type_id",
      selectVehicle ? selectVehicle && selectVehicle.id : ""
    );
    requestData.append(
      "vehicle_colour_id",
      selectVehicleColor ? selectVehicleColor && selectVehicleColor.id : ""
    );
    requestData.append(
      "vehicle_description",
      formdata
        ? formdata.vehicleDescription && formdata.vehicleDescription.trim()
        : ""
    );
    requestData.append("vehicle_year", formatedDate ? formatedDate : "");

    requestData.append("vehicle_image", vehicleImage ? vehicleImage : "");
    await SublyApi.addVehicle(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        setShowVanModal(false);
        Toster(t("UPDATE_VAN"), "success");
        getVehicleList();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        setLoader(false);
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- function for add van-----
  const createApi = async (formdata) => {
    setLoader(true);
    let requestData = new FormData();
    requestData.append(
      "licence_plate_number",
      formdata
        ? formdata.LicencePlateNumber && formdata.LicencePlateNumber.trim()
        : ""
    );
    requestData.append(
      "vehicle_type_id",
      selectVehicle ? selectVehicle && selectVehicle.id : ""
    );
    requestData.append(
      "vehicle_colour_id",
      selectVehicleColor ? selectVehicleColor && selectVehicleColor.id : ""
    );
    requestData.append(
      "vehicle_description",
      formdata
        ? formdata.vehicleDescription && formdata.vehicleDescription.trim()
        : ""
    );
    // requestData.append("vehicle_year", selectedYear ? selectedYear : "");
    requestData.append("vehicle_year", formatedDate ? formatedDate : "");
    requestData.append("vehicle_image", vehicleImage ? vehicleImage : "");
    await SublyApi.addVehicle(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        setShowVanModal(false);
        Toster(t("ADD_VAN_SUCCESS"), "success");
        getVehicleList();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        setLoader(false);
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={showVanModal}
          onHide={() => {
            setShowVanModal(false);
          }}
          className="colorModal"
          backdrop="static"
        >
          <Modal.Header>
            <div className={styles.modalHeader}>
              {vanList && vanList.id ? (
                <h4>{t("UPDATE_VAN_TEXT")}</h4>
              ) : (
                <h4>{t("ADD_VAN")}</h4>
              )}
              <MdCancel
                onClick={() => {
                  setShowVanModal(false);
                }}
              />
            </div>
          </Modal.Header>

          <Modal.Body>
            <>
              {loader && <Loader />}
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <Form.Label>{t("LICENSE_PLATE_NUMBER")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_LICENSE")}`}
                    type="text"
                    {...register("LicencePlateNumber", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("LICENSE_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("LICENSE_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `${t("LICENSE_MAXLENGTH")}`,
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                        message: `${t("LICENSE_PATTERN_MATCH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.LicencePlateNumber && (
                    <span className="errorMsg">
                      {errors.LicencePlateNumber.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 setIconPassword">
                  <Form.Label>{t("VEHICLE_CATEGORY")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("SELECT_VEHICLE")}`}
                    type="text"
                    readOnly
                    onClick={() => setVehicleTypeModal(true)}
                    {...register("vanType", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("VAN_REQUIRED")}`,
                      },
                    })}
                  />
                  {errors.vanType && (
                    <span className="errorMsg">{errors.vanType.message}</span>
                  )}
                  <div className={styles.downiconicon}>
                    <TiArrowSortedDown
                      onClick={() => setVehicleTypeModal(true)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 setIconPassword">
                  <Form.Label>{t("COLOR_VEHICLE")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("SELECT_COLOR")}`}
                    type="text"
                    readOnly
                    onClick={() => setVehicleColorModal(true)}
                    {...register("vehicleColor", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("COLOR_REQUIRED")}`,
                      },
                    })}
                  />
                  {errors.vehicleColor && (
                    <span className="errorMsg">
                      {errors.vehicleColor.message}
                    </span>
                  )}
                  <div className={styles.downiconicon}>
                    <TiArrowSortedDown
                      onClick={() => setVehicleColorModal(true)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("VEHICLE_YEAR")}</Form.Label>
                  <Controller
                    control={control}
                    name="vehicleYear"
                    render={({ field }) => (
                      // <div className="muiDatepicker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          defaultValue={dayjs(`${vanList && vanList.year}`)}
                          className="muiDatepicker muiYearSelection"
                          views={["year"]}
                          maxDate={dayjs(maxDate)}
                          onChange={handleDateChange}
                          renderInput={(params) => <input {...params} />}
                        />
                      </LocalizationProvider>
                      // </div>
                    )}
                    rules={{ validate: validateYear }}
                  />
                  {!selectedYear && errors.vehicleYear && (
                    <span className="errorMsg">
                      {errors.vehicleYear.message}
                    </span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className={styles.uploadSection}>
                    <h4>{t("VEHICLE_PHOTO")}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="vehicleImage"
                        render={({ field }) => (
                          <input
                            id="uploadImage"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => {
                              onImageChange(e);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage }}
                      />
                      <div className={styles.cancelIcon}>
                        {vehiclePreview && (
                          <MdCancel
                            onClick={(e) => {
                              onImageRemove(e);
                            }}
                          />
                        )}
                      </div>
                      {vehiclePreview ? (
                        <div className={styles.uploadInputImg}>
                          <img src={vehiclePreview} />
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>{t("UPLOAD_VEHICLE")}</p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label
                          htmlFor="uploadImage"
                          className={styles.uploadbutton}
                        >
                          <CiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!vehiclePreview && errors.vehicleImage && (
                        <span className="errorMsg">
                          {errors.vehicleImage.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DESCRIPTION")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_DISCRIPTION")}`}
                    as="textarea"
                    rows={3}
                    {...register("vehicleDescription", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("DESCIPTION_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("DESCRIPTION_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 300,
                        message: `${t("DESCRIPTION_MAXLENGTH")}`,
                      },
                    })}
                  />
                  {errors.vehicleDescription && (
                    <span className="errorMsg">
                      {errors.vehicleDescription.message}
                    </span>
                  )}
                  {vanList && vanList.id && (
                    <div>
                      {vanList && vanList.status == "pending" && (
                        <p className={styles.inActiveCls}>
                          {t("ADMIN_REJECT")}
                        </p>
                      )}
                      {vanList && vanList.status == "approved" && (
                        <p className={styles.activeCls}>
                          {t("ADMIN_APPROVED")}
                        </p>
                      )}
                      {vanList && vanList.status == "rejected" && (
                        <p className={styles.inActiveCls}>
                          {t("ADMIN_REJECTED")}
                        </p>
                      )}
                    </div>
                  )}
                </Form.Group>
                <div className="commonButton">
                  {vanList && vanList.id ? (
                    <button type="submit">{t("UPDATE_VAN_TEXT")}</button>
                  ) : (
                    <button type="submit">{t("ADD_VAN")}</button>
                  )}
                </div>
              </Form>
            </>
          </Modal.Body>
        </Modal>
        {/* vehicle color modal */}
        <VehicletColorModal
          setVehicleColorModal={setVehicleColorModal}
          vehicleColorModal={vehicleColorModal}
          setValue={setValue}
          selectVehicleColor={selectVehicleColor}
          setSelectVehicleColor={setSelectVehicleColor}
          clearErrors={clearErrors}
        />
        {/* vehicle category modal */}
        <VehicleTypeModal
          setVehicleTypeModal={setVehicleTypeModal}
          vehicleTypeModal={vehicleTypeModal}
          selectVehicle={selectVehicle}
          setSelectVehicle={setSelectVehicle}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </div>
    </>
  );
}

export default AddVanPopup;
