import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./Filter.module.css";
import { useTranslation } from "react-i18next";
import successImg from "../../../assets/Images/successImg.png";

function Success({ isDoneReview, setIsDoneReview }) {
  const { t } = useTranslation();

  const onClose = () => {
    setIsDoneReview(false);
  };

  return (
    <>
      <Modal
        show={isDoneReview}
        onHide={() => {
          onClose();
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("RATING")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className={styles.successInfo}>
              <img src={successImg} alt="success-icon" />
              <div className={styles.successText}>{t("THANKS_4_REVIEW")}</div>
              <div className={styles.successTextDesc}>
                {t("THANKS_RATING_TEXT")}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className={styles.addCardBtn} onClick={() => onClose()}>
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Success;
