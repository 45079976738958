import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadProfile from "../Setting/UploadProfile";
import SettingSidebar from "../Setting/SettingSidebar";
import Loader from "../../utils/Loader/Loader";
import { Col, Container, Row } from "react-bootstrap";
import "../../assets/Styles/Common.css";
import styles from "../Profile/Profile.module.css";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import { userDetails, userLogoutClear } from "../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import Toster from "../../utils/Toaster";
import { SETTING_OPTION, SETTING_OPTION_TYPE } from "../../utils/Constants";
import SublyApi from "../../helpers/Api";
import DOMPurify from "dompurify";

const TermsConditions = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);
  const [termsConditions, setTermsConditions] = useState([]);

  const TermsConditionsList = async () => {
    setLoader(true);
    await SublyApi.getCustomerSettingList(
      userToken,
      SETTING_OPTION_TYPE.PAGE,
      SETTING_OPTION.TermsConditions
    ).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setLoader(false);
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const policyData = response.data[0]?.option_value || "";
        setTermsConditions(policyData);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    TermsConditionsList();
  }, []);

  const getProfileDetails = () => {
    dispatch(userDetails(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setResponseData(response.data);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <div className="main">
      {loader && <Loader />}
      <Container>
        <div className="spaceTopManage">
          <section className="midSection">
            <div className={styles.profileSection}>
              <h2>{t("PROFILE")}</h2>
              {/* <p>Lorem Ipsum is simply dummy</p> */}
            </div>

            <div className={styles.profileBox}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <SettingSidebar />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className={styles.profileSettingsSection}>
                    <h3
                      className={`${styles.profileHeading} profileHeadingBotLine`}
                    >
                      {t("TERMS")}
                    </h3>
                    <div className={styles.scrollOverlay}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(termsConditions),
                        }}
                      ></div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <UploadProfile responseData={responseData} />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default TermsConditions;
