import React, { useEffect, useState } from "react";
import styles from "./B2b.module.css";
import { FiArrowLeft } from "react-icons/fi";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  fetchMasterDataList,
  setActiveCategory,
  setActiveTab,
} from "../../../../store/slices/createJob";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import SublyApi from "../../../../helpers/Api";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import Toster from "../../../../utils/Toaster";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import Loader from "../../../../utils/Loader/Loader";
import SelectB2B from "../Modals/selectB2b";
import { sanitizeHtmlTags } from "./Validations";
import PhoneInput from "react-phone-input-2";
// import chatIcon from "../../../../assets/Images/chatIcon.png";
import { removeExtraPlusSigns } from "../../../../utils/Loader/Helper/helper";
import { useNavigate } from "react-router-dom";
import img from "../../../../assets/Images/ContactGroup.png";

export default function B2bEnquiry() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({ id: "", val: "" });
  const [showSelectCategory, setShowSelectCategory] = useState({
    show: false,
    type: "",
    value: { id: "", val: "" },
  });
  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState();
  const [countryCode, setCountryCode] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const getCategoriesList = () => {
    dispatch(fetchMasterDataList("B2B")).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setOptions(response.data.B2B);
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  const validationSchema = {
    fullName: {
      required: t("ENTER_FULL_NAME"),
      minLength: {
        value: 2,
        message: t("MIN_2_CHAR_REQUIRED"),
      },
      maxLength: {
        value: 50,
        message: t("MAX_50_CHAR_ALLOWED"),
      },
      ...sanitizeHtmlTags(),
    },
    companyName: {
      maxLength: {
        value: 50,
        message: t("MAX_50_CHAR_ALLOWED"),
      },
      ...sanitizeHtmlTags(),
    },
    estimatedStops: {
      maxLength: {
        value: 25,
        message: t("MAX_25_CHAR_ALLOWED"),
      },
      ...sanitizeHtmlTags(),
    },
    email: {
      required: t("EMAIL_REQUIRED"),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: t("EMAIL_INVALID"),
      },
      minLength: {
        value: 5,
        message: t("EMAIL_MINLENGTH"),
      },
      maxLength: {
        value: 100,
        message: t("EMAIL_MAXLENGTH"),
      },
      ...sanitizeHtmlTags(),
    },
    phoneNumber: {
      required: t("CONTACT_REQUIRED"),
      minLength: {
        value: 5,
        message: t("PHONE_MINLENGTH"),
      },
    },
    description: {
      required: t("ENTER_DISCRIPTION"),
      minLength: {
        value: 2,
        message: t("MIN_2_CHAR_REQUIRED"),
      },
      maxLength: {
        value: 500,
        message: t("MAX_500_CHAR_ALLOWED"),
      },
      ...sanitizeHtmlTags(),
    },
  };

  const onBack = () => {
    dispatch(setActiveTab("first"));
    dispatch(setActiveCategory("lejRej"));
    navigate("/create-job", {
      state: {
        fromHomePage: false,
      },
    });
  };

  const handleShowCategory = () => {
    setShowSelectCategory((prevState) => ({ ...prevState, show: true }));
  };

  const handelPhoneChange = (phone, data) => {
    setDialCode(data.dialCode);
    setCountryCode(data.countryCode);
    setPhone(phone);
    setValue("phoneNumber", phone, {
      shouldValidate: true,
    });
  };

  const postB2bEnquiryData = (postData) => {
    setIsLoading(true);
    let res = SublyApi.saveB2bEnquiryData(postData);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("ENQUIRY_SUBMITTED"), "success");
        setIsLoading(false);
        dispatch(setActiveTab("first"));
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
          setIsLoading(false);
        } else {
          Toster(t(responseCode), "error");
          setIsLoading(false);
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  const onFinalSubmit = (formData) => {
    setShowErrorMsg(true);
    if (!selectedCategory.id) {
      Toster(t("SELECT_VEHICLE"), "error");
      return;
    }
    var postData = new FormData();

    postData.append("category_id", selectedCategory.id);
    postData.append("full_name", formData.fullName);
    postData.append("company_name", formData.companyName);
    postData.append("description", formData.description);
    postData.append("estimated_stops", formData.estimatedStops);
    postData.append("email", formData.email);
    postData.append("dial_code", removeExtraPlusSigns(dialCode));
    postData.append("country_code", countryCode);
    postData.append("phone_number", formData.phoneNumber);

    postB2bEnquiryData(postData);
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // Function to sanitize input value to ensure only numbers are accepted
  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHead}>
            <div className="backarrow">
              <FiArrowLeft
                onClick={() => {
                  onBack();
                }}
              />
            </div>
          </div>
          <div className={styles.topSubContainer}>
            <Form onSubmit={handleSubmit(onFinalSubmit)}>
              <div className={styles.topSubHead}>
                <div className={styles.contactImage}>
                  <div>
                    <img src={img} alt="contact-image" />
                  </div>
                </div>

                <div className={styles.topSubHeadTitle}>{t("Contact us")}</div>
                <div className={styles.fieldWrappers}>
                  <Row className={styles.fieldWrapper}>
                    <Col lg={4} md={6} sm={8} xs={8} className="mb-3">
                      <Row>
                        <Col className="mb-3">
                          <Form.Group className="formSection">
                            <Form.Control
                              placeholder={`${t("Full name")}`}
                              {...register("fullName", {
                                ...validationSchema.fullName,
                                onChange: (e) => handleKeyPress(e), // Restrict key presses
                              })}
                              isInvalid={!!errors.fullName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {t(errors.fullName?.message)}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <Form.Group className="formSection">
                            <Form.Control
                              placeholder={`${t("Company name")}`}
                              {...register("companyName", {
                                ...validationSchema.companyName,
                                onChange: (e) => handleKeyPress(e), // Restrict key presses
                              })}
                              isInvalid={!!errors.companyName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {t(errors.companyName?.message)}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <Form.Group className="formSection">
                            <Form.Control
                              placeholder={`${t("Email")}`}
                              {...register("email", validationSchema.email)}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {t(errors.email?.message)}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="formSection phoneInputSet">
                            <PhoneInput
                              placeholder="Contact number"
                              {...register(
                                "phoneNumber",
                                validationSchema.phoneNumber
                              )}
                              country={"gb"}
                              countryCodeEditable={false}
                              value={phone}
                              onChange={(phone, country) => {
                                handelPhoneChange(phone, country);
                              }}
                            />
                            {errors.phoneNumber && (
                              <div className={styles.customError}>
                                {errors.phoneNumber.message}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={6} sm={8} xs={8}>
                      <Row className="mb-3">
                        <Form.Group className="formSection">
                          <Form.Control
                            placeholder={`${t("Estimated stops per week")}`}
                            {...register("estimatedStops", {
                              ...validationSchema.estimatedStops,
                              onChange: handleChange, // Sanitize input value
                              onKeyPress: (e) => handleKeyPress(e), // Restrict key presses
                            })}
                            isInvalid={!!errors.estimatedStops}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(errors.estimatedStops?.message)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="formOrderSection contactInfo">
                          <p
                            style={{
                              border:
                                showErrorMsg && !selectedCategory.val
                                  ? "1px solid #dc3545"
                                  : "",
                            }}
                            onClick={() => {
                              handleShowCategory();
                            }}
                          >
                            {selectedCategory.val === "" ? (
                              <>{t("Select category")}</>
                            ) : (
                              selectedCategory.val
                            )}
                          </p>
                          <Icon
                            icon="bxs:up-arrow"
                            color="rgba(130, 130, 130, 0.8509803921568627)"
                            width="22"
                            height="22"
                            vFlip={true}
                            onClick={() => {
                              handleShowCategory();
                            }}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="formSection">
                          <Form.Control
                            placeholder={`${t("ENTER_DISCRIPTION")}`}
                            {...register("description", {
                              ...validationSchema.description,
                              onChange: (e) => handleKeyPress(e), // Restrict key presses
                            })}
                            isInvalid={!!errors.description}
                            as="textarea"
                            rows={5}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(errors.description?.message)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={styles.fieldWrapper}>
                    <Col
                      lg={4}
                      md={6}
                      sm={8}
                      xs={8}
                      className={styles.btnSubmit}
                    >
                      <Button type="submit">Submit</Button>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={styles.chatcontactInfo}>
                {/* <img src={chatIcon} alt="chat-image" /> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <SelectB2B
        showSelectCategory={showSelectCategory}
        setShowSelectCategory={setShowSelectCategory}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        options={options}
      />
    </>
  );
}
