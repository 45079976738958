import React, { useState, useEffect } from "react";
import Styles from "./Notification.module.css";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoNotifications } from "react-icons/io5";
import moment from "moment";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import {
  getDriverNotificationList,
  userLogoutClear,
} from "../../../store/slices/UserSlice";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Confirm from "../../../appComponents/CreateJob/Furnitures/Modals/confirm";

function NotificationPage() {
  const { userToken, currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notificationData, setNotificationData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumberList, setPageNumberList] = useState(1);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showBadge, setShowBadge] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  //========== function for call notification =========
  useEffect(() => {
    callNotificationList();
  }, []);

  useEffect(() => {
    const isAllread = notificationData.every((val) => val.is_read == 1);
    if (isAllread) {
      setShowBadge(false);
    } else {
      setShowBadge(true);
    }
  }, [notificationData]);

  //========== function for call notification list =========
  const callNotificationList = (isLoadMore = false) => {
    let pageNo = pageNumberList;
    if (isLoadMore === true) {
      setPageNumberList((preState) => preState + 1);
      pageNo = pageNo + 1;
    }
    dispatch(
      getDriverNotificationList({ userToken: userToken, page_no: pageNo })
    ).then((res) => {
      setIsloading(false);
      const response = res.payload;
      const ResponseCode = STATUS_MSG[response.data.code];

      if (
        response.code == STATUS_CODES.SUCCESS ||
        response.data.code == STATUS_CODES.SUCCESS
      ) {
        setTotalCount(response.data.totalCount);
        setLoadMore(response.data.loadMore);
        if (isLoadMore == true) {
          setNotificationData([
            ...notificationData,
            ...response.data.notification_list,
          ]);
        } else {
          setNotificationData(response.data.notification_list);
        }
      } else if (
        response.code === STATUS_CODES.INVALID_TOKEN ||
        response.data.code === STATUS_CODES.INVALID_TOKEN ||
        response.data.code === STATUS_CODES.FAILED_TO_ACCESS
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else if (
        response &&
        response.data.code === STATUS_CODES.VALIDATION_ERROR
      ) {
        Toster(response && response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const handleRedirect = (action, type_id) => {
    switch (action) {
      case "job_offer":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "job_rating":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "service_verification":
        navigate("/service");
        break;
      case "today_job":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "customer_cancelled_job":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "payment_released":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "job_unassigned":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "job_transfer":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "direct_job":
        navigate(`/driver-job-details/${type_id}`);
        break;
      case "document_verification":
        navigate(`/driver-profile`, {
          state: {
            isFromNotification: true,
          },
        });
        break;
      case "broadcast_notification":
        break;
      default:
        navigate("/");
        break;
    }
  };
  //============ function for handle notification status===============
  const handleChangeStatus = (status_id) => {
    let res = SublyApi.DriverReadNotification(userToken, status_id);
    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        const updatedNotifications = notificationData.map((notification) => {
          if (notification.id === status_id) {
            return { ...notification, is_read: 1 };
          }
          return notification;
        });
        setNotificationData(updatedNotifications);
      }
    });
  };

  // =======function for calling logOut api=======
  const notificationDelete = () => {
    SublyApi.clearNotificationDriver(userToken, currentUser.id).then(
      (responsejson) => {
        const ResponseCode =
          STATUS_MSG[
            (responsejson && responsejson.code) ||
              (responsejson && responsejson.data.code)
          ];
        if (responsejson.code === STATUS_CODES.SUCCESS) {
          Toster(t("CLEAR_NOTIFICATION_SUCCUSS"), "success");
          callNotificationList();
          setShowConfirm(false);
          setShow(false);
        } else if (responsejson.code === STATUS_CODES.VALIDATION_ERROR) {
          Toster(responsejson.code, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  const handleConfirm = () => {
    notificationDelete();
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  return (
    <div>
      <div className={Styles.topIcon}>
        <div className={Styles.notificationSection}>
          {showBadge && <div className={Styles.notificationCount}></div>}
          <IoNotifications
            className={Styles.notificationImg}
            ref={target}
            onClick={(event) => {
              setTarget(event.target);
              setPageNumberList(1);
              setShow(!show);
              // setIsloading(true);
              // callNotificationList();
            }}
          />
        </div>

        <Overlay
          show={show}
          target={target}
          placement="bottom"
          rootClose={true}
          containerPadding={20}
          onHide={() => setShow(false)}
        >
          <Popover id="popover-contained">
            <Popover.Body
              onScroll={(e) => {
                const element = e.target;

                if (
                  Math.abs(
                    element.scrollHeight -
                      element.scrollTop -
                      element.clientHeight
                  ) <= 1 &&
                  notificationData.length > 0 &&
                  loadMore == true
                ) {
                  callNotificationList(true);
                }
              }}
            >
              {isloading ? (
                <div className={Styles.loadingMessage}>Loading...</div>
              ) : notificationData.length > 0 ? (
                <div>
                  {notificationData.map((notification) => {
                    const hours = moment(notification?.created_at).fromNow();
                    return (
                      <div
                        key={notification.id}
                        className={
                          notification.is_read !== 1
                            ? Styles.NotificationUnread
                            : Styles.NotificationRead
                        }
                        onClick={() => {
                          if (notification.is_read !== 1) {
                            handleChangeStatus(notification.id);
                          }
                          handleRedirect(
                            notification.action,
                            notification.type_id
                          );
                          setShow(false);
                        }}
                      >
                        <div>
                          <h5>{notification.title}</h5>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                notification.description
                              ),
                            }}
                            style={{ wordBreak: "break-word" }}
                          ></div>
                          <p>{hours}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={Styles.noResultsMessage}>
                  {t("NO_NOTIFICATION")}
                </div>
              )}

              {notificationData.length > 0 && (
                <div className={Styles.clearBtn}>
                  <Button
                    className={Styles.bottomBtnEdit}
                    onClick={() => setShowConfirm(true)}
                  >
                    {t("CLEAR_ALL")}
                  </Button>
                </div>
              )}
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
      <Confirm
        show={showConfirm}
        subTitle={t("CLEAR_ALL_NOTIFICATION")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default NotificationPage;
