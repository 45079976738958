import { Col, Container, Form, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "./Schedule.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MdCancel } from "react-icons/md";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import { useForm } from "react-hook-form";
import chatIcon from "../../../assets/DriverImages/chatIcon.png";

// function for schedule
function Schedule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [sheduleList, setSheduleList] = useState([]);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [alldays, setAllDays] = useState([
    {
      days: "monday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
    {
      days: "tuesday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
    {
      days: "wednesday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
    {
      days: "thursday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
    {
      days: "friday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
    {
      days: "saturday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
    {
      days: "sunday",
      time_slots: [
        {
          start_time: "",
          end_time: "",
        },
      ],
      is_available: "0",
    },
  ]);

  // Function to handle toggling of each day
  const toggleDay = (day, value) => {
    setAllDays((prevDays) =>
      prevDays.map((d) => {
        if (d.days === day) {
          if (!Number(d.is_available)) {
            // If the day is being activated, add a default time slot
            return {
              ...d,
              is_available: "1",
              time_slots: [{ start_time: "07:00:00", end_time: "19:00:00" }],
            };
          } else {
            // If the day is being deactivated, reset is_available and time_slots
            return {
              ...d,
              is_available: "0",
              time_slots: [],
            };
          }
        } else {
          return d;
        }
      })
    );
  };

  //function for manage time change
  const handleTimeChange = (newTime, slotIndex, timeType, dayIndex) => {
    const updatedAlldays = [...alldays];
    updatedAlldays[dayIndex].time_slots[slotIndex][timeType] =
      newTime.format("HH:mm:ss");
    setAllDays(updatedAlldays);
  };

  //function for manage add more
  const handleAddMore = (day) => {
    // Find the index of the day in the array
    const index = alldays.findIndex((d) => d.days === day);

    // Check if there are already two time slots for the day
    if (alldays[index].time_slots.length < 2) {
      // Create a new time slot object with default values
      const newTimeSlot = { start_time: "", end_time: "" };

      // Create a new object with the same day name and time slots, adding the new time slot
      const updatedAlldays = [
        ...alldays.slice(0, index),
        {
          ...alldays[index],
          time_slots: [...alldays[index].time_slots, newTimeSlot],
        },
        ...alldays.slice(index + 1),
      ];

      // Update the state with the new array
      setAllDays(updatedAlldays);
    } else {
      // Alert or handle the case where the maximum limit of time slots is reached
      // alert("Maximum limit of time slots reached for this day.");
    }
  };

  //function for manage delete time slot
  const handleDeleteSlot = (day, index) => {
    // Find the index of the day in the array
    const dayIndex = alldays.findIndex((d) => d.days === day);

    // Check if there is only one slot remaining for the specified day
    if (alldays[dayIndex].time_slots.length > 1) {
      // Create a copy of the time slots array for the specified day and remove the slot at the specified index
      const updatedTimeSlots = [
        ...alldays[dayIndex].time_slots.slice(0, index),
        ...alldays[dayIndex].time_slots.slice(index + 1),
      ];

      // Create a new object with the same day name and updated time slots
      const updatedAlldays = [
        ...alldays.slice(0, dayIndex),
        {
          ...alldays[dayIndex],
          time_slots: updatedTimeSlots,
        },
        ...alldays.slice(dayIndex + 1),
      ];

      // Update the state with the new array
      setAllDays(updatedAlldays);
    } else {
      // If there is only one slot left, set is_available to false and empty the time_slots array
      const updatedAlldays = alldays.map((d) => {
        if (d.days === day) {
          return {
            ...d,
            is_available: "0",
            time_slots: [],
          };
        }
        return d;
      });

      // Update the state with the new array
      setAllDays(updatedAlldays);
    }
  };

  //------function for get shedule list-------
  async function sheduleListGet() {
    setLoader(true);
    await SublyApi.getScheduleList(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        setSheduleList(response && response.data && response.data.scheduleList);
        // setAllDays(response && response.data && response.data.scheduleList);
        const updatedScheduleList = response.data.scheduleList.map((day) => ({
          ...day,
          is_available: day.is_available.toString(),
        }));
        if (updatedScheduleList.length !== 0) {
          setAllDays(updatedScheduleList);
        }
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  useEffect(() => {
    sheduleListGet();
  }, []);

  // ----function for update shedule list------
  const onSubmit = async () => {
    let hasEmptyTimeSlot = false;

    // Iterate through each day
    alldays.forEach((day) => {
      // Check if the day is available
      if (day.is_available === "1") {
        // Iterate through each time slot
        day.time_slots.forEach((slot) => {
          // Check if either start_time or end_time is empty
          if (!slot.start_time || !slot.end_time) {
            hasEmptyTimeSlot = true;
          }
        });
      }
    });

    const updatedAllDays = alldays.map((val) => {
      if (val.is_available == "0") {
        return {
          ...val,
          time_slots: [],
        };
      } else {
        return val;
      }
    });

    if (hasEmptyTimeSlot) {
      Toster(t("ENTER_SCHEDULE_TIME"), "error");
      return;
    }

    let requestData = new FormData();
    requestData.append("availability_json", JSON.stringify(updatedAllDays));
    setLoader(true);
    await SublyApi.UpdateScheduleList(requestData, userToken).then(
      (response) => {
        const msg = response && response.data && response.data.message;
        const ResponseCode =
          STATUS_MSG[response && response.data && response.data.code];
        if (response && response.code === STATUS_CODES.SUCCESS) {
          Toster(t("SCHEDULE_UPDATE"), "success");
          setLoader(false);
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          navigate("/driver-login");
          setLoader(false);
        } else {
          setLoader(false);
          if (
            response &&
            response.data &&
            response.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <div className="spaceTopManageDriver">
          <div className={styles.bgColor}>
            <Container>
              <h6>{t("AVAILABILITY")}</h6>
              <FiArrowLeft
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Container>
          </div>
          <Container>
            <h1 className={styles.textHeading}>
              {t("YOUR_AVAILABILTITY")} <span>{t("AVAILABILITY")}</span>
            </h1>
            <Form onSubmit={handleSubmit(onSubmit)} className="formTopSpaceCls">
              <div className={styles.sheduleBox}>
                <Row>
                  {alldays &&
                    alldays.length > 0 &&
                    alldays.map((shedule, index) => (
                      <Col xl={6} lg={6} key={index}>
                        <div className={styles.sheduleInnerBox}>
                          <div className={styles.sheduleToggleFlex}>
                            <h3>{shedule.days}</h3>
                            <Form.Check
                              type="switch"
                              className="toggle_switch"
                              id={shedule.prevDayss}
                              checked={Number(shedule.is_available)}
                              onChange={(e) =>
                                toggleDay(shedule.days, e.target.checked)
                              }
                            />
                          </div>
                          {shedule.is_available == "1" && (
                            <>
                              {shedule.time_slots.map((slot, ind, arr) => {
                                return (
                                  <>
                                    <div>
                                      <div className={styles.inputTime}>
                                        <Form.Group className="timePickerWrapper">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["TimePicker"]}
                                            >
                                              <DesktopTimePicker
                                                label={t("SELECT_TIME")}
                                                className="scheduleTimePicker"
                                                value={
                                                  slot.start_time
                                                    ? dayjs(
                                                        slot.start_time,
                                                        "HH:mm:ss"
                                                      )
                                                    : undefined
                                                }
                                                onChange={(newTime) =>
                                                  handleTimeChange(
                                                    newTime,
                                                    ind,
                                                    "start_time",
                                                    index
                                                  )
                                                }
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </Form.Group>
                                        <span className={styles.textSeprate}>
                                          {t("TO")}
                                        </span>
                                        <Form.Group className="timePickerWrapper">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["TimePicker"]}
                                            >
                                              <DesktopTimePicker
                                                label={t("SELECT_TIME")}
                                                className="scheduleTimePicker"
                                                value={
                                                  slot.end_time
                                                    ? dayjs(
                                                        slot.end_time,
                                                        "HH:mm:ss"
                                                      )
                                                    : undefined
                                                }
                                                // minTime={dayjs(
                                                //   slot.start_time,
                                                //   "HH:mm:ss"
                                                // )}
                                                onChange={(newTime) =>
                                                  handleTimeChange(
                                                    newTime,
                                                    ind,
                                                    "end_time",
                                                    index
                                                  )
                                                }
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </Form.Group>

                                        <div className={styles.cancelIcon}>
                                          <MdCancel
                                            onClick={() =>
                                              handleDeleteSlot(
                                                shedule.days,
                                                ind
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      {arr.length == 1 && (
                                        <p
                                          className={styles.addText}
                                          onClick={() =>
                                            handleAddMore(shedule.days)
                                          }
                                        >
                                          {t("ADD_MORES")}
                                        </p>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
              <div className={styles.updateBtnSpace}>
                <button type="submit">{t("UPDATE")}</button>
              </div>
            </Form>
          </Container>
          {/* <div className={styles.chatFeature}>
            <img src={chatIcon} alt="chat-image" />
          </div> */}
        </div>
      </div>
    </>
  );
}
export default Schedule;
