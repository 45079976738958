import FacebookLogin from 'react-facebook-login';
import Facebook from "../../../assets/DriverImages/Facebook.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { STATUS_CODES } from '../../../utils/StatusCode';
import { driverLogin, setSocialUserData } from '../../../store/slices/UserSlice';
import { SIGNUP_TYPE, SOCIAL_TYPE } from '../../../utils/Constants';
import Toster from '../../../utils/Toaster';
import { STATUS_MSG } from '../../../utils/StatusMsg';


//---------function for facebook social api call----------
function DriverFacebookSocialLogin() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const responseFacebook = (response) => {
        let userData = response;
        if (userData.id) {
            let requestData = new FormData();
            requestData.append("signup_type", SIGNUP_TYPE.SOCIAL);
            requestData.append("social_id", userData && userData.id);
            dispatch(driverLogin(requestData)).then((response) => {
                const facebookResponse = response.payload;

                const msg = facebookResponse && facebookResponse.data && facebookResponse.data.message;
                const ResponseCode = STATUS_MSG[facebookResponse && facebookResponse.data && facebookResponse.data.code];
                if (response && response.payload && response.payload.code === STATUS_CODES.SUCCESS) {
                    // Toster(t("LOGIN_SUCCESSFULLY"), "success");
                    navigate("/driver-profile")
                } else if (facebookResponse.data && facebookResponse.data.code === STATUS_CODES.SOCIAL_USER_NOT_FOUND) {
                    dispatch(setSocialUserData({ id: userData.id, name: userData.name, email: userData.email, socialType: SOCIAL_TYPE.FACEBOOK }));
                    navigate("/driver-sign-up", {
                        state: {
                            profileData: { id: userData.id, name: userData.name, email: userData.email, socialType: SOCIAL_TYPE.FACEBOOK },
                            socialType: SOCIAL_TYPE.FACEBOOK,
                        },
                    });
                }
                else {
                    dispatch(setSocialUserData({ id: userData.id, name: userData.name, email: userData.email, socialType: SOCIAL_TYPE.FACEBOOK }));

                    if (facebookResponse && facebookResponse.data && facebookResponse.data.code === STATUS_CODES.SERVER_VALIDATION) {
                        Toster(msg, "error");
                    } else {
                        Toster(t(ResponseCode), "error");
                    }
                }
            })
        }
    }

    return (
        <div className="SocialLoginBtn">
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                textButton={<img src={Facebook} alt="facebook-logo" />}
            />
        </div>
    );
}
export default DriverFacebookSocialLogin;