import { useTranslation } from "react-i18next";
import { IoIosArrowForward } from "react-icons/io";
import { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../appComponents/Profile/Profile.module.css";
import { NavLink } from "react-router-dom";
import { userLogoutClear } from "../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserSettingData } from "../../mockData";
import { updateIsRedirect } from "../../store/slices/createJob";
import Loader from "../../utils/Loader/Loader";
import SublyApi from "../../helpers/Api";
import Confirm from "../../appComponents/CreateJob/Furnitures/Modals/confirm";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";

//---------function for setting sidebar---------
function SettingSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");

  //----- function for manage user logout-----
  const logout = async () => {
    dispatch(userLogoutClear());
    dispatch(updateIsRedirect(false));
    navigate("/");
  };

  //----- function for delete customer account-----
  const deleteCustomerAccount = async () => {
    setIsLoading(true);
    await SublyApi.deleteCustomerAccount(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        setShowAlertModal(false);
        Toster(t("DELETE_ACCOUNT_SUCCESS"), "success");
        dispatch(userLogoutClear());
        dispatch(updateIsRedirect(false));
        navigate("/login");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/login");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleConfirm = () => {
    deleteCustomerAccount();
  };

  const handleCancel = () => {
    setShowAlertModal(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.profileSettingsSection}>
        <h3 className={styles.profileHeading}>{t("SETTINGS")}</h3>
        {UserSettingData.map((item, index) => (
          <NavLink to={item.link} key={index} className="userActive">
            <div className={styles.displayOption}>
              <p>{item.text}</p>
              <IoIosArrowForward />
            </div>
          </NavLink>
        ))}
        <div
          className={styles.displayOption}
          onClick={() => {
            setShowAlertModal(true);
            setTitle(`${t("DELETYETITLE")}`);
          }}
        >
          <p>{t("DELETE_ACCOUNT")}</p>
          <IoIosArrowForward />
        </div>
        <div
          className={`${styles.displayOption} ${styles.displayOptionBorder}`}
        >
          <p onClick={logout}>{t("LOGOUT")}</p>
        </div>

        {/* alert modal popup for delete account*/}
        <Confirm
          show={showAlertModal}
          subTitle={t("Are you sure you want to delete Account.")}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      </div>
    </>
  );
}
export default SettingSidebar;
