//---------------- Common Text----------------------
export const TRANSLATIONS_ES = {
  //----------------Header Text----------------------
  HOME: "Hogar",
  CREATE_JOB: "crear trabajo",
  LOGIN: "Acceso",
  DRIVER: "Conviértete en conductora",
  FAQ: "Preguntas frecuentes",
  SUPPORT: "Apoyo",
  JOB: "Trabajos",
  ENGLISH: "Inglés",
  SPANISH: "Español",
  FRENCH: "Francés",
  TURKISH: "Turco",
  CHINESE: "Chino",
  ARABIC: "Arábica",

  //----------------Footer Text----------------------
  COPYRIGHT: "Reservados todos los derechos",
  SWIFTVAN: "SwiftVan",
  PRIVACY: "política de privacidad",
  TERMS: "Términos y condiciones",

  //----------------HomePage Text----------------------
  BOOK_MOVE: "Reserva te mudas en 60 seg",
  WHAT_MOVING: "Qué te estás moviendo?",
  BECOME_DRIVER: "Conviértete en una conductora",
  CALCULATE: "Calcular",
  SOURSE_LOCATION: "Ingrese la ubicación de origen",
  DESTINY_LOCATION: "Ingrese la ubicación de destino",
  ABOUT_US: "Sobre nosotras",
  CATEGORIES: "lo que amamos",
  DOING: "haciendo",
  WORKS: "obras",
  HOW: "Cómo",
  FURNITURE_OTHER: "Muebles y otros artículos.",
  HOME_REMOVAL: "Mudanzas a domicilio",
  BTOB: "Empresa a empresa",
  SCHEDULE_PICKUP: "Programe una recogida fácil",
  ARRIVE: "Nosotras llegamos a tu puerta",
  DELIVER: "Entregar a tiempo",

  //----------------LoginForm Text----------------------
  EMAIL: "Correo electrónico",
  EMAIL_TEXT: "Ingrese correo electrónico",
  PASSWORD: "Contraseña",
  PASSWORD_TEXT: "Introducir la contraseña",
  LOGIN: "ACCESO",
  FORGOT_PASSWORD: "Has olvidado tu contraseña?",
  EMAIL_REQUIRED: "Por favor, introduzca su dirección de correo electrónico.",
  EMAIL_INVALID:
    "Por favor, introduce una dirección de correo electrónico válida.",
  PASSWORD_REQUIRED: "Por favor, introduzca su contraseña.",
  PASS_MINLENGTH:
    "Por favor ingrese la contraseña, con un mínimo de 6 caracteres.",
  PASS_MAXLENGTH:
    "Por favor introduzca la contraseña, con un máximo de 12 caracteres.",
  NO_ACCOUNT: "No tienes una cuenta?",
  SINGUP: "Inscribirse",

  //----------------ForgotPassword Text----------------------
  CANCEL: "Cancelar",
  RESET: "Reiniciar",
  FORGOT_TEXT:
    "Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña",
  FORGOT_PASSWORD_TEXT: "HAS OLVIDADO TU CONTRASEÑA",

  //----------------SignUp Form Text----------------------
  CREATE_ACCOUNT: "Crear una cuenta",
  FIRST_NAME: "Nombre de pila",
  LAST_NAME: "Apellido",
  CONTACT: "Número de contacto",
  CONFIRM_PASSWORD: "Confirmar Contraseña",
  FIRSTNAME_TEXT: "Introduce el nombre",
  FIRSTNAME_REQUIRED: "Por favor, introduzca su nombre de pila.",
  FIRSTNAME_MINLENGTH:
    "Por favor ingrese el nombre, con un mínimo de 2 caracteres.",
  FIRSTNAME_MAXLENGTH:
    "Por favor introduzca el nombre, con un máximo de 50 caracteres.",
  LASTNAME_TEXT: "Introduzca el apellido",
  LASTNAME_REQUIRED: "Por favor ingrese su apellido.",
  LASTNAME_MINLENGTH:
    "Por favor ingrese el apellido, con un mínimo de 2 caracteres.",
  LASTNAME_MAXLENGTH:
    "Por favor introduzca el apellido, con un máximo de 50 caracteres.",
  CONTACT_TEXT: "Seleccionar número de contacto",
  CONTACT_REQUIRED: "Por favor ingrese su número de contacto.",
  CONFIRM_PASS_TEXT: "Ingrese confirmar contraseña",
  CONFIRM_PASS_REQUIRED: "Por favor ingrese su contraseña de confirmación.",
  CONFIRM_PASS_MINLENGTH:
    "Por favor ingrese la contraseña de confirmación, con un mínimo de 6 caracteres.",
  CONFIRM_PASS_MAXLENGTH:
    "Por favor ingrese la contraseña de confirmación, con un máximo de 12 caracteres.",
  NOT_MATCH: "La contraseña no coincide con la contraseña confirmada.",
  ALREADY_ACCOUNT: "Ya tienes una cuenta?",
  SIGNIN: "Iniciar sesión",
  SIGNUP_TEXT: "Inscribirse",
  INVALID_PASSWORD:
    "La contraseña debe tener 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial mínimo 6 caracteres.",

  //----------------Reset Password Text----------------------
  NEW_PASSWORD: "Nueva contraseña",
  SUBMIT: "Entregar",
  OTP_REQUIRED: "Se requiere OTP",
  DIGIT_REQUIRED: "Por favor ingrese una OTP de 4 dígitos",
  NUMBER_REQUIRED: "Por favor ingrese solo números.",

  //----------------SUCCESS Messages Text----------------------
  LOGIN_SUCCESSFULLY: "Inicié sesión exitosamente.",
  CREATE_ACCOUNT_SUCCESSFUL: "Crear cuenta exitosamente.",
  RESET_SUCCESFULLY: "Restablecer código enviado exitosamente!",
  UPDATE_SUCCESFULLY: "Restablecer contraseña exitosamente.",

  //----------------Error Messages Text----------------------
  HEADER_IS_MISSING: "Falta el encabezado.",
  DEVICE_TYPE_NOT_ALLOWED: "Tipo de dispositivo no permitido.",
  INVALID_API_KEY: "Clave API no válida.",
  TOKEN_REQUIRE_AUTHENTICATION: "Se requiere un token para la autenticación.",
  INVALID_TOKEN: "Simbolo no valido.",
  SERVER_VALIDATION: "Error de validacion.",
  USER_NOT_FOUND: "Usuario no encontrado",
  ACCOUNT_BLOCKED: "Su cuenta está actualmente bloqueada.",
  SOCIAL_USER_NOT_FOUND: "Usuario social no encontrado",
  INTERNAL_SERVER_ERROR: "Error Interno del Servidor",
  INVALID_LOGIN_CREDENTIAL: "Credenciales de acceso invalidos.",
  ACCOUNT_NOT_FOUND:
    "Cuenta no encontrada, ingrese el correo electrónico correcto.",
  ACCOUNT_INACTIVE: "Su cuenta está actualmente inactiva.",
  ACCOUNT_DELETED: "Tu cuenta ha sido eliminada.",
  OLD_PASSWORD_DOES_NOT_MATCH: "La contraseña anterior no coincide.",
  EMAIL_ALREADY_IN_USE:
    "Correo electrónico ya en uso, utilice otros correos electrónicos.",
  PHONE_NUMBER_EXIST:
    "El número de teléfono ya está en uso; utilice otros números de teléfono.",
  SOCIALID_EXIST: "La identificación social ya existe.",
  CODE_EXIST: "Código ya usado",
  CODE_NOT_MATCH: "El código no coincide",
  EMAIL_LINKED_SOCIALUSER:
    "El correo electrónico está vinculado con el usuario social.",
  EMAIL_LINKED_NORMALUSER:
    "El correo electrónico está vinculado con la usuario normal",
  EMAIL_FAIL: "Error al enviar correo electrónico",
  VEHICLE_IMG: "Se requiere imagen del vehículo",
  LICENSE_IMG: "Se requiere licencia de conducir",
  GT_INSURANCE_IMG: "Se requiere seguro de mercancías en tránsito",
  HR_INSURANCE_IMG: "Se requiere seguro de alquiler y recompensa",
  PL_INSURANCE_IMG: "Se requiere seguro de responsabilidad civil",
  VEHICLE_IMG_SIZE: "Tamaño máximo de imagen del vehículo 10 mb",
  INVALID_VEHICLE_IMG: `Extensión de imagen de vehículo no válida, por favor use ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_VEHICLE_ID: "ID de vehículo no válido",
  INVALID_COLOR_ID: "ID de color de vehículo no válido",
  LICENSE_IMG_SIZE: "Tamaño máximo de licencia de conducir 10 mb",
  INVALID_LICENSE_IMG: `Extensión de licencia de conducir no válida, por favor use ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  GT_INSURANCE_IMG_SIZE:
    "Tamaño máximo de seguro de mercancías en tránsito 10 mb",
  INVALID_GT_INSURANCE_IMG: `Extensión de seguro de mercancías en tránsito no válida, por favor use ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  HR_INSURANCE_IMG_SIZE:
    "Tamaño máximo de seguro de alquiler y recompensa 10 mb",
  INVALID_HR_INSURANCE_IMG: `Extensión de seguro de alquiler y recompensa no válida, por favor use ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  PL_INSURANCE_IMG_SIZE:
    "Tamaño máximo de seguro de responsabilidad civil 10 mb",
  INVALID_PL_INSURANCE_IMG: `Extensión de seguro de responsabilidad civil no válida, por favor use ".jpg", ".jpeg", ".png", ".webp , ".pdf""`,
  FAILED_IMG: "Error al subir la imagen",
  FAILED_LICENSE_IMG: "Error al subir la licencia de conducir",
  FAILED_GT_IMG: "Error al subir el seguro de mercancías en tránsito",
  FAILED_HR_IMG: "Error al subir el seguro de alquiler y recompensa",
  FAILED_PL_IMG: "Error al subir el seguro de responsabilidad civil",
  INVALID_CATEGORY_ID: "ID de categoría no válido/inactivo",
  INVALID_VEHICLETYPE_ID: "ID de tipo de vehículo no válido/inactivo",
  INVALID_DRIVER_ID: "ID de conductor no válido/inactivo",
  INVALID_SOURSE_FLOOR_ID: "ID de piso de origen no válido/inactivo",
  INVALID_DESTINATION_FLOOR_ID: "ID de piso de destino no válido/inactivo",
  INVALID_HELPER_ID: "ID de ayudante no válido/inactivo",
  INVALID_CATEGORY_JSON: "JSON de categoría no válido/inactivo",
  ACCESS_DENIED:
    "Acceso denegado NOTA: este código aparecerá cuando un empleado intente ejecutar los APIs a los que no tiene permiso",
  WRONG: "Algo salió mal",
  INVALID_UNITID: "ID de unidad no válido/inactivo",
  INVALID_STOP: "JSON de parada no válido",
  INVALID_SPROPERTYID: "ID de tipo de propiedad de origen no válido/inactivo",
  INVALID_DPROPERTYID: "ID de tipo de propiedad de destino no válido/inactivo",
  INVALID_SHEDULE_DATA: "Formato JSON inválido de datos de programación",
  TIME_EXIST:
    "Esta hora ya existe para el mismo día, por favor use otra hora de inicio",
  CUSTOMER_NOT_PERMIT: "El cliente no tiene permiso para esta acción",
  INVALID_PRICE: "Formato JSON inválido de datos de configuración de precio",
  FOR_SCHEDULE:
    "Para datos de programación solo se permiten dos ranuras en el mismo día",
  DRIVER_NOT_PERMISION: "El conductor no tiene permiso para esta acción",
  CAN_NOT_DELETE: "El usuario no puede eliminar esta cuenta",
  ALEREADY_LICENCE_USE:
    "El número de licencia ya está en uso, por favor use otros números de licencia.",
  PROFILE_REQUIRED: "Se requiere imagen de perfil",
  PROFILE_IMG_SIZE: "Tamaño máximo de imagen de perfil 10 mb",
  INVALID_PROFILE: `Extensión de imagen de perfil no válida, por favor use ".jpg", ".jpeg", ".png", ".webp"`,
  DOC_IMG_REQUIRED: "Se requiere imagen del documento",
  DOC_IMG_SIZE: "Tamaño máximo de imagen del documento 10 mb",
  INVALID_DOC_IMG: `Extensión de imagen del documento no válida, por favor use ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_DOC: "Documento no válido",
  PASS_NOT_MATCH:
    "La nueva contraseña y la confirmación de contraseña no coinciden",
  NOTIFY_ALREADY_EXIST: "Este estado de notificación ya existe",
  ADD_VEHICLE: "Por favor, añada un vehículo",
  VEHICLE_NAME_ALREDY:
    "El nombre del vehículo ya existe, por favor use otro nombre",
  NOT_PERMIT_USER: "El usuario no tiene permiso para esta acción",
  VEHICLE_TYPE_NOT_DELETE: "No se puede eliminar este tipo de vehículo",
  VEHICLE_TYPE_STATUS:
    "El estado del tipo de vehículo ya existe en la base de datos",
  ESTIMATE_WEIGHT_ID: "ID de peso estimado no válido",
  INVALID_JOB_TEMP_ID: "ID de plantilla de trabajo no válido",
  INVALID_KEY: "Nombre de clave no válido",
  MASTER_DATA_EXIST: "El nombre de los datos maestros ya existe",
  INVALID_MASTER_DATA: "ID de datos maestros no válido",
  MASTER_DATA_NOT_DELETE: "No se puede eliminar estos datos maestros",
  MASTER_DATA_STATUS: "El estado de los datos maestros ya existe",
  CATEGORY_ICON: "Se requiere un ícono de categoría",
  CATEGORY_ICON_SIZE: "Tamaño máximo del ícono de categoría: 10 MB",
  INVALID_CATEGORY_ICON: `Extensión de ícono de categoría no válida, por favor use ".jpg", ".jpeg", ".png", ".webp"`,
  CATEGORY_ADD: "Categoría añadida exitosamente",
  CATEGORY_UPDATE: "Categoría actualizada exitosamente",
  CUSTOMER_EXIST: "Los usuarios ya están asociados con el cliente",
  DRIVER_EXIST: "Los usuarios ya están asociados con el conductor",

  //----------------Session Expire Text----------------------
  SESSION_EXPIRED: "Tu sesión expiró!",

  //----------------Profile Page Text----------------------
  PROFILE: "Mi perfil",
  SETTINGS: "Ajustes",
  EDIT_PROFILE: "Editar perfil",
  USER_INFO: "Información de usuario",
  UPDATE: "Actualizar",
  LOGOUT: "Cerrar sesión",
  DELETE_ACCOUNT: "Borrar cuenta",
  SAVE_CARD: "Guardar tarjetas",
  CHANGE_PASSWORD: "Cambiar la contraseña",
  JOB_HISTORY: "Historia laboral",

  //----------------Driver auth Page Text----------------------
  BACK: "Atrás",
  NEXT: "Próxima",
  DONE: "Hecho",
  SELECT_VEHICLE_COLOR: "Seleccionar color del vehículo",
  SELECT_VAN: "Seleccionar furgoneta",
  VEHICLE_INFO: "Información del vehículo",
  LICENSE_PLATE_NUMBER: "Número de matrícula",
  ENTER_LICENSE: "Introducir número de matrícula",
  LICENSE_REQUIRED: "Por favor, introduzca el número de matrícula.",
  LICENSE_MINLENGTH:
    "Por favor, introduzca el número de matrícula, con un mínimo de 2 caracteres.",
  LICENSE_MAXLENGTH:
    "Por favor, introduzca el número de matrícula, con un máximo de 50 caracteres.",
  VAN_TYPE: "Tipo de furgoneta",
  SELECT_VAN: "Seleccionar tipo de furgoneta",
  VAN_REQUIRED: "Por favor, seleccione una furgoneta.",
  COLOR_VEHICLE: "Color del vehículo",
  SELECT_COLOR: "Seleccionar color del vehículo",
  COLOR_REQUIRED: "Por favor, seleccione el color del vehículo.",
  VEHICLE_YEAR: "Año del vehículo",
  YEAR_OF_VEHICLE: "Introducir año del vehículo",
  VEHICLE_YEAR_REQUIRED: "Por favor, introduzca el año del vehículo",
  YEAR_ALLOW_DIGITS: "Por favor, introduzca un año de 4 dígitos",
  VEHICLE_PHOTO: "Foto del vehículo",
  VEHICLE_PHOTO_REQUIRED: "Se requiere foto del vehículo",
  UPLOAD_VEHICLE: "Subir foto del vehículo",
  UPLOAD: "Subir",
  DESCRIPTION: "Descripción",
  ENTER_DISCRIPTION: "Introducir descripción",
  DESCIPTION_REQUIRED: "Por favor, introduzca la descripción del vehículo.",
  DESCRIPTION_MINLENGTH:
    "Por favor, introduzca la descripción del vehículo, con un mínimo de 2 caracteres.",
  DESCRIPTION_MAXLENGTH:
    "Por favor, introduzca la descripción del vehículo, con un máximo de 300 caracteres.",
  UPLOAD_DOCUMENT: "SUBIR DOCUMENTOS",
  DRIVING_LICENSE: "Licencia de conducir",
  DRIVING_LICENSE_REQUIRED: "Se requiere licencia de conducir",
  GT_INSURANCE_REQUIRED: "Se requiere seguro de mercancías en tránsito",
  HR_INSURANCE_REQUIRED: "Se requiere seguro de alquiler y recompensa",
  PL_INSURANCE_REQUIRED: "Se requiere seguro de responsabilidad civil",
  GT_TITLE: "Seguro de mercancías en tránsito",
  HR_TITLE: "Seguro de alquiler y recompensa",
  PL_TITLE: "Seguro de responsabilidad civil",
  UPLOAD_LICENSE: "Subir licencia de conducir",
  UPLOAD_REGISTRATION: "Subir registro del vehículo",
  ALERT: "Alerta",
  REMOVE_ALERT: "¿Está seguro de que desea eliminar",
  YES: "Sí",

  //-----------------------Create job localization text--------------------
  ENTER: "Entrar",
  FURNITURE_AND_OTHER: "Muebles y Otros Artículos",
  SOURCE_LOCATION: "Ubicación de Origen",
  DESTINATION_LOCATION: "Ubicación de Destino",
  SELECT_FLOOR: "Seleccionar Piso",
  LIFT_AVAILABLE: "Ascensor Disponible",
  STOPS: "Paradas",
  ADD_STOPS: "Agregar Paradas",
  ADD_MORE_STOPS: "Agregar más Paradas",
  AVAILABILITY: "Disponibilidad",
  ESTIMATED_TIME_ARRIVAL: "Tiempo estimado de llegada",
  WANT_PACKING: "¿Desea embalar?",

  //-----------------------Create job validations text--------------------
  ENTER_SOURCE_LOCATION: "Ingrese la Ubicación de Origen",
  ENTER_DESTINATION_LOCATION: "Ingrese la Ubicación de Destino",
  ENTER_ALL_STOPS_ADDRESS:
    "Por favor, ingrese la dirección de todas las paradas.",
  MAXIMUM_STOPS_ALLOWED: "Se permite un máximo de 9 paradas.",
  MIN_3_CHAR_REQUIRED: "Se requieren al menos 3 caracteres.",
  SELECT_VEHICLE_TYPE: "Seleccione su tipo de vehículo",
  SELECT_HELPER: "Seleccione ayudante",
  SELECT_DATE: "Seleccione la fecha",
  SELECT_TIME: "Seleccione la hora",
  SELECT_DATE_TIME: "Seleccione la fecha y hora de disponibilidad",
};
