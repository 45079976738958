import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverProfile/DriverProfile.module.css";
import { useEffect, useState } from "react";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Loader from "../../../utils/Loader/Loader";
import SublyApi from "../../../helpers/Api";

const Tutorial = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [checkedItems, setCheckedItems] = useState({ id: "", name: "" });
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    const fetchAllCategory = async () => {
      setLoader(true);
      const response = await SublyApi.getCategoryList();
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setServicesList(response.data);
      }
      setLoader(false);
    };

    fetchAllCategory();
  }, []);

  useEffect(() => {
    if (servicesList.length > 0) {
      setCheckedItems({ id: servicesList[0].id, name: servicesList[0].name });
    }
  }, [servicesList]);

  const handleCheckboxChange = (event, service_name) => {
    const { id } = event.target;
    setCheckedItems({ id: id, name: service_name });
  };

  const handleViewTutorialVideos = () => {
    navigate(`/tutoria-videos/${checkedItems.id}`, {
      state: { categoryName: checkedItems.name },
    });
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <div className={styles.tutorialHeading}>
                        <h2>You can learn how to use application.</h2>
                      </div>
                      <div className="formTopSpaceCls">
                        {servicesList.map((service) => (
                          <div className="serviceInput" key={service.id}>
                            <input
                              type="checkbox"
                              id={service.id}
                              name={service.id}
                              value={service.name}
                              onChange={(e) =>
                                handleCheckboxChange(e, service.name)
                              }
                              checked={checkedItems.id === service.id}
                            />
                            <label
                              htmlFor={service.id}
                              className="vehicleInputDisplay"
                            >
                              <div
                                style={{
                                  background: "#0094f6",
                                  width: "50px",
                                  borderRadius: "50%",
                                  marginRight: "12px",
                                }}
                              >
                                <img
                                  src={service.icon}
                                  alt="vehicle-img"
                                  style={{ padding: "8px" }}
                                />
                              </div>
                              <div>
                                <h2>{service.name}</h2>
                              </div>
                            </label>
                          </div>
                        ))}
                        <div
                          className={`${styles.updateBtnSpace} commonButton`}
                        >
                          <button
                            onClick={() => handleViewTutorialVideos()}
                            type="submit"
                          >
                            {t("CONTINUE")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Tutorial;
