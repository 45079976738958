import "../../assets/Styles/Common.css";
import styles from "../ResetPassword/ResetPassword.module.css";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/Images/Resetbg.png";
import { Container, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { sanitizeHtmlTags } from "../../utils/sanitizeHtmlTags";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useRef, useState } from "react";
import OtpInput from "react-otp-input";
import lock from "../../assets/Images/key.png";
import SublyApi from "../../helpers/Api";
import Toster from "../../utils/Toaster";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";

//---------function for reset password----------
function ResetPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const userEmail = location.state;

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [loader, setLoader] = useState(false);

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  const password = useRef({});
  password.current = watch("password", "");

  // --------function for update password api calling--------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("email", userEmail && userEmail.email);
    requestData.append(
      "password",
      formdata ? formdata.password && formdata.password.trim() : ""
    );
    requestData.append(
      "cnf_password",
      formdata
        ? formdata.confirmPassword && formdata.confirmPassword.trim()
        : ""
    );
    requestData.append("code", formdata ? formdata.otp : "");
    setLoader(true);
    await SublyApi.updatePassword(requestData).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        Toster(t("UPDATE_SUCCESFULLY"), "success");
        navigate("/login");
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopManage">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlock}>
              <div className={styles.resetImage}>
                <img src={lock} alt="logo-image" />
              </div>
              <div className={styles.resetText}>
                <h3>{t("NEW_PASSWORD")}</h3>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <div className="otpInput">
                  <Controller
                    name="otp"
                    type="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: `${t("OTP_REQUIRED")}`,
                      minLength: {
                        value: 4,
                        message: `${t("DIGIT_REQUIRED")}`,
                      },
                    }}
                    render={({ field }) => (
                      <OtpInput
                        inputStyle="inputStyle"
                        {...field}
                        numInputs={4}
                        isInputNum
                        shouldAutoFocus={field.value.length < 4} // Only autofocus if less than 4 digits
                        renderInput={(props, index) => <input {...props} />}
                        onChange={(value) => {
                          let numbers = /^[0-9]+$/;
                          if (value.match(numbers) || value === "") {
                            field.onChange(value);
                          } else {
                            return false;
                          }
                        }}
                      />
                    )}
                  />
                </div>

                {errors.otp && (
                  <div className="otpError">
                    <span className="errorMsg">{errors.otp.message}</span>
                  </div>
                )}

                <Form.Group className="mb-3 setIconPassword">
                  <Form.Label>{t("PASSWORD")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("PASSWORD_TEXT")}`}
                    type={passwordShow ? "text" : "password"}
                    autoComplete="new-password"
                    {...register("password", {
                      onChange: (e) => {
                        onInputChange(e);
                      },
                      required: {
                        value: true,
                        message: `${t("PASSWORD_REQUIRED")}`,
                      },
                      minLength: {
                        value: 6,
                        message: `${t("PASS_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 12,
                        message: `${t("PASS_MAXLENGTH")}`,
                      },
                      pattern: {
                        value:
                          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                        message: `${t("INVALID_PASSWORD")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />

                  {errors.password && (
                    <span className="errorMsg">{errors.password.message}</span>
                  )}

                  <div className="passwordicon">
                    {passwordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      />
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 setIconPassword">
                  <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("CONFIRM_PASS_TEXT")}`}
                    type={confirmPassShow ? "text" : "password"}
                    autoComplete="new-password"
                    {...register("confirmPassword", {
                      onChange: (e) => {
                        onInputChange(e);
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: true,
                        message: `${t("CONFIRM_PASS_REQUIRED")}`,
                      },
                      minLength: {
                        value: 6,
                        message: `${t("CONFIRM_PASS_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 12,
                        message: `${t("CONFIRM_PASS_MAXLENGTH")}`,
                      },
                      pattern: {
                        value:
                          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                        message: `${t("INVALID_PASSWORD")}`,
                      },

                      validate: (value) =>
                        value === password.current || `${t("NOT_MATCH")}`,
                    })}
                  />

                  {errors.confirmPassword && (
                    <span className="errorMsg">
                      {errors.confirmPassword.message}
                    </span>
                  )}

                  <div className="passwordicon">
                    {confirmPassShow ? (
                      <FaEye
                        className="icon"
                        onClick={() => setConfirmPassShow(!confirmPassShow)}
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() => setConfirmPassShow(!confirmPassShow)}
                      />
                    )}
                  </div>
                </Form.Group>
                <div className={styles.loginBtn}>
                  <button type="submit">{t("SUBMIT")}</button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
