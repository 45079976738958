import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./AddCard.module.css";
import { useTranslation } from "react-i18next";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../utils/Loader/Loader";
import Toster from "../../../../utils/Toaster";

function AddCard({ isAddingCard, setIsAddingCard, getCardList }) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { stripeCustomerId } = useSelector((state) => state.user);
  const clientSecretKey = process.env.REACT_APP_STRIPE_CLIENT_SECRET_KEY;
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const addNewCard = async (tokenStripe) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/customers/${stripeCustomerId}/sources`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + clientSecretKey,
          },
          body: `source=${tokenStripe.id}`,
        }
      );
      const responseData = await response.json();

      if (responseData.last4) {
        Toster(t("CARD_ADDED"), "success");
        setIsAddingCard(false);
        getCardList();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        setIsLoading(false);
        console.log(responseData?.error?.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    try {
      const { error, token } = await stripe.createToken({
        ...cardElement,
        // ...cardNumberElement,
        // ...cardExpiryElement,
        // ...cardCvcElement,
        type: "Card",
      });

      if (token) {
        addNewCard(token);
      } else {
        Toster(error.message, "error");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const onClose = () => {
    setIsAddingCard(false);
  };

  return (
    <>
      <Modal
        show={isAddingCard}
        onHide={() => {
          onClose();
        }}
      >
        {isLoading && <Loader />}
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("ADD_CARD")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <CardElement
              options={{
                hidePostalCode: true,
                hideIcon: false,
              }}
            />
            {/* <div className="input-group">
                <label>{t("CARD_NUMBER")}</label>
                <CardNumberElement
                  options={{
                    showIcon: true,
                  }}
                  className="card-input"
                />
              </div>
              <div className="input-group">
                <label>{t("EXPIRY")}</label>
                <CardExpiryElement className="card-input" />
              </div>
              <div className="input-group">
                <label>{t("CVV")}</label>
                <CardCvcElement className="card-input" />
              </div> */}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => handleSubmit()}
              disabled={isLoading}
              className={styles.addCardBtn}
            >
              {t("ADD_CARD")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default AddCard;
