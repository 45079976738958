import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo2 from "../../../assets/Images/logo2.png";
import finalLogo from "../../../assets/Images/finalLogo.png";
// import logoSmall from "../../../assets/Images/logoSmall.png";
import logoSmall from "../../../assets/Images/logo3.png";
import { NavLink, useNavigate } from "react-router-dom";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../../../DRIVERSIDE/appComponents/DriverHeader/DriverHeader.module.css";
import { BiSolidBell } from "react-icons/bi";
import profile from "../../../assets/Images/user.png";
import { DriverHeaderData, HeaderData } from "../../../mockData";
import { useSelector } from "react-redux";
// import finalLogo from "../../../assets/DriverImages/finalLogo.png";
import { useState } from "react";
import Footer from "../../../appComponents/Footer/Footer";
import facebook from "../../../assets/Images/faceBook.png";
import twitter from "../../../assets/Images/twitter.png";
import pinrest from "../../../assets/Images/pinrest.png";
import linkedin from "../../../assets/Images/linkedin.png";
import vanLoginImg from "../../../assets/Images/vanLoginImg.png";
import NotificationPage from "../Notification/NotificationPage";

//---------function for driver header----------
function DriverHeader({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, updateUser } = useSelector((state) => state.user);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "210px";
    setIsSideBarOpen(true);
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    setIsSideBarOpen(false);
  };

  return (
    <>
      <section>
        {/* navbar header */}
        <div className={`${styles.navbarshow} ${styles.headerSection}`}>
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand>
                <NavLink to="/">
                  <div className={styles.logoImg}>
                    <img src={logo2} alt="logo" />
                  </div>
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                {Object.keys(currentUser).length == 0 ? (
                  <Nav className="activeShow justify-content-end">
                    {HeaderData.map((item, index) => (
                      <NavLink to={item.link} key={index}>
                        {item.text}
                      </NavLink>
                    ))}
                    <img
                      src={vanLoginImg}
                      onClick={() => navigate("/driver-login")}
                      className={styles.vanLoginImg}
                    ></img>
                    <button
                      type="button"
                      onClick={() => navigate("/login")}
                      className={styles.loginButton}
                    >
                      {t("MY_ACCOUNT")}
                    </button>
                  </Nav>
                ) : (
                  <Nav
                    className={`${styles.navbarAfterLogin} activeShow justify-content-end`}
                  >
                    {DriverHeaderData.map((item, index) => (
                      <NavLink to={item.link} key={index}>
                        {item.text}
                      </NavLink>
                    ))}
                    <NotificationPage />
                    <NavLink to="/driver-profile">
                      {(updateUser && updateUser.profile_image == "") ||
                      (updateUser && updateUser.profile_image == null) ? (
                        <div className={styles.profileImg}>
                          <img src={profile} alt="profile-image" />
                        </div>
                      ) : (
                        <div className={styles.profileImg}>
                          <img
                            src={updateUser && updateUser.profile_image}
                            alt="profile-image"
                          />
                        </div>
                      )}
                    </NavLink>
                  </Nav>
                )}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        {/* sidebar header */}
        <div className={styles.sidebarShow}>
          <div id="mySidenav" className={styles.sidenav}>
            <div>
              <NavLink to="/">
                {/* <div className={styles.logoImg}>
                  <img src={logo2} alt="logo" />
                </div> */}

                <div className={styles.sideLogo}>
                  <img src={finalLogo} alt="logo" />
                </div>
              </NavLink>
              <a
                href="javascript:void(0)"
                className={styles.closebtn}
                onClick={closeNav}
              >
                &times;
              </a>

              {Object.keys(currentUser).length == 0 ? (
                <>
                  <div className="activeShow">
                    {HeaderData.map((item, index) => (
                      <>
                        <NavLink to={item.link} key={index}>
                          {item.text}
                        </NavLink>
                        <hr className={styles.tabHrDivider} />
                      </>
                    ))}
                  </div>
                  <img
                    src={vanLoginImg}
                    onClick={() => navigate("/driver-login")}
                    className={styles.vanLoginImgSidebar}
                  ></img>
                  <hr className={styles.tabHrDivider} />
                  <div className={styles.buttonCenter}>
                    <button
                      type="button"
                      onClick={() => navigate("/login")}
                      className={styles.loginButton}
                    >
                      {t("LOGIN")}
                    </button>
                  </div>
                </>
              ) : (
                <div className="activeShow">
                  {DriverHeaderData.map((item, index) => (
                    <>
                      <NavLink
                        to={item.link}
                        key={index}
                        onClick={() => closeNav()}
                      >
                        {item.text}
                      </NavLink>
                      <hr className={styles.tabHrDivider} />
                    </>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.socialIcons}>
              <div>
                <a
                  href={process.env.REACT_APP_FACEBOOK_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="facebook" />
                </a>
              </div>
              <div>
                <a
                  href={process.env.REACT_APP_TWITTER_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
              <div>
                <a
                  href={process.env.REACT_APP_PINTEREST_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={pinrest} alt="Pinterest" />
                </a>
              </div>
              <div>
                <a
                  href={process.env.REACT_APP_LINKEDIN_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
          <div
            className={`${styles.sidebarNotification} ${
              isSideBarOpen ? "moveSideContent" : ""
            }`}
          >
            <div
              style={{
                fontSize: "30px",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              onClick={openNav}
            >
              &#9776;{" "}
            </div>
            {/* {!isSideBarOpen && ( */}
            <div onClick={() => navigate("/")}>
              <img src={logoSmall} className={styles.smallLogo} alt="logo" />
            </div>
            {/* )} */}
            <div style={{ marginRight: "18px" }}>
              {Object.keys(currentUser).length !== 0 && <NotificationPage />}
            </div>
          </div>
        </div>
      </section>
      <div className={isSideBarOpen ? "moveSideContent" : ""}>
        {children}
        <Footer />
      </div>
    </>
  );
}
export default DriverHeader;
