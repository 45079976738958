import { useGoogleLogin } from "@react-oauth/google";
import Google from "../../assets/Images/google.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { setSocialUserData, userLogin } from "../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { SIGNUP_TYPE, SOCIAL_TYPE } from "../../utils/Constants";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import { STATUS_MSG } from "../../utils/StatusMsg";

//---------function for google social api call----------
function GoogleSocialLogin() {
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isRedirect } = useSelector((state) => state.createJob);

  // Social Login with google
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (profile) {
      let requestData = new FormData();
      requestData.append("signup_type", SIGNUP_TYPE.SOCIAL);
      requestData.append("social_id", profile.id);
      if (profile.id) {
        dispatch(userLogin(requestData)).then((response) => {
          const socialRes = response.payload;

          const msg = socialRes && socialRes.data && socialRes.data.message;
          const ResponseCode =
            STATUS_MSG[socialRes && socialRes.data && socialRes.data.code];
          if (
            response &&
            response.payload &&
            response.payload.code === STATUS_CODES.SUCCESS
          ) {
            // Toster(t("LOGIN_SUCCESSFULLY"), "success");
            if (isRedirect) {
              navigate("/hire-drivers");
            } else {
              navigate("/jobs");
            }
          } else if (
            socialRes.data &&
            socialRes.data.code === STATUS_CODES.SOCIAL_USER_NOT_FOUND
          ) {
            dispatch(
              setSocialUserData({
                id: profile.id,
                name: profile.name,
                email: profile.email,
                socialType: SOCIAL_TYPE.GOOGLE,
              })
            );
            navigate("/sign-up", {
              state: {
                profileData: {
                  id: profile.id,
                  name: profile.name,
                  email: profile.email,
                  socialType: SOCIAL_TYPE.GOOGLE,
                },
                socialType: SOCIAL_TYPE.GOOGLE,
              },
            });
          } else {
            dispatch(
              setSocialUserData({
                id: profile.id,
                name: profile.name,
                email: profile.email,
                socialType: SOCIAL_TYPE.GOOGLE,
              })
            );

            if (
              socialRes &&
              socialRes.data &&
              socialRes.data.code === STATUS_CODES.SERVER_VALIDATION
            ) {
              Toster(msg, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => err);
    }
  }, [user]);

  return (
    <>
      <img src={Google} alt="google-logo" onClick={() => login()} />
    </>
  );
}
export default GoogleSocialLogin;
