import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "../src/utils/Localization/en/translation";
import { TRANSLATIONS_ES } from "../src/utils/Localization/es/translation";
import { TRANSLATIONS_TR } from "./utils/Localization/tr/translation";
import { TRANSLATIONS_FR } from "./utils/Localization/fr/translation";
import { TRANSLATIONS_AR } from "./utils/Localization/ar/translation";
import { TRANSLATIONS_ZH } from "./utils/Localization/zh/translation";

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: '',
  resources: {
    en: {
      translations: TRANSLATIONS_EN
    },
    es: {
      translations: TRANSLATIONS_ES
    },
    fr: {
      translations: TRANSLATIONS_FR
    },
    tr: {
      translations: TRANSLATIONS_TR
    },
    ar: {
      translations: TRANSLATIONS_AR
    },
    zh: {
      translations: TRANSLATIONS_ZH
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es', 'fr', 'tr', 'ar', 'zh'];

export default i18n;

