import "../../assets/Styles/Common.css";
import styles from "../SignUp/SignUp.module.css";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/Images/Resetbg.png";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { sanitizeHtmlTags } from "../../utils/sanitizeHtmlTags";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { setSocialUserData, userSignup } from "../../store/slices/UserSlice";
import { SIGNUP_TYPE } from "../../utils/Constants";
import FacebookSocialLogin from "../../commonComponents/FacebooklLogin/FacebookSocialLogin";
import GoogleSocialLogin from "../../commonComponents/GoogleLogin/GoogleLogin";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/StatusCode.js";
import { STATUS_MSG } from "../../utils/StatusMsg.js";
import Loader from "../../utils/Loader/Loader";

//---------function for signUp form----------
function SignUp() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [isSocial, setIsSocial] = useState(1); // 1 is for not social login 2 for social login
  const socialProfileData = location.state;

  const { socialUserData, isLoading } = useSelector((state) => state.user);
  const { isRedirect } = useSelector((state) => state.createJob);

  useEffect(() => {
    if (
      socialProfileData &&
      socialProfileData.profileData &&
      socialProfileData.profileData.id
    ) {
      setIsSocial(socialProfileData && socialProfileData.id ? 2 : 1);
      dispatch(
        setSocialUserData({
          id: socialProfileData.profileData.id,
          name: socialProfileData.profileData.name,
          email: socialProfileData.profileData.email,
          socialType: socialProfileData.socialType,
        })
      );
    } else {
      dispatch(setSocialUserData({}));
      setIsSocial(1);
    }
  }, []);

  // match password and confirm password field
  const password = useRef({});
  password.current = watch("password", "");

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // function for apply validation in phoneNumber field
  const validateNumber = () => {
    if (!phoneNo) {
      if (socialUserData && socialUserData.id) {
        return true;
      } else {
        return `${t("CONTACT_REQUIRED")}`;
      }
    } else {
      const minPhoneNumberLength = 5;
      const maxPhoneNumberLength = 15;
      if (phoneNo.length < minPhoneNumberLength) {
        return `${t("PHONE_MINLENGTH")}`;
      }
      if (phoneNo.length > maxPhoneNumberLength) {
        return `${t("PHONE_MAXLENGTH")}`;
      }
      return true;
    }
  };

  // for separate name field
  const nameArray =
    socialUserData && socialUserData.name && socialUserData.name.split(" ");
  const firstName = nameArray && nameArray[0];
  const lastName = nameArray && nameArray.slice(1).join(" ");

  useEffect(() => {
    clearErrors("");
    setValue("firstName", firstName ? firstName : "");
    setValue("lastName", lastName ? lastName : "");
    setValue("email", socialUserData ? socialUserData.email : "");
    setIsSocial(socialUserData && socialUserData.id ? 2 : 1);
  }, [socialUserData]);

  // --------function for create account api calling--------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "signup_type",
      socialUserData && socialUserData.id
        ? SIGNUP_TYPE.SOCIAL
        : SIGNUP_TYPE.NORMAL
    );
    requestData.append(
      "first_name",
      formdata ? formdata.firstName && formdata.firstName.trim() : ""
    );
    requestData.append(
      "last_name",
      formdata ? formdata.lastName && formdata.lastName.trim() : ""
    );
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );

    requestData.append("dial_code", dialCode ? `+${dialCode}` : "");
    requestData.append("phone_country_code", countryCode ? countryCode : "");
    requestData.append("phone_num", phoneNo ? phoneNo : "");

    if (socialUserData && socialUserData.id) {
      requestData.append("social_id", socialUserData && socialUserData.id);
      requestData.append(
        "social_type",
        socialUserData && socialUserData.socialType
      );
    } else {
      requestData.append(
        "password",
        formdata ? formdata.password && formdata.password.trim() : ""
      );
      requestData.append(
        "cnf_password",
        formdata
          ? formdata.confirmPassword && formdata.confirmPassword.trim()
          : ""
      );
    }

    dispatch(userSignup(requestData)).then((responsejson) => {
      const response = responsejson.payload;

      const msg = response && response.data && response.data.message;

      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        // Toster(t("CREATE_ACCOUNT_SUCCESSFUL"), "success");
        dispatch(setSocialUserData({}));
        if (isRedirect) {
          navigate("/hire-drivers");
        } else {
          navigate("/login");
        }
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {isLoading === true ? <Loader /> : ""}
      <div className="spaceTopManage">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          {/* <Container> */}
          <div className={styles.formBlock}>
            <div className={styles.signUpText}>
              <h3>{t("CREATE_ACCOUNT")}</h3>
            </div>
            <div className={styles.signUpForm}>
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className="colSpace">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("FIRST_NAME")}</Form.Label>
                      <Form.Control
                        placeholder={`${t("FIRSTNAME_TEXT")}`}
                        type="text"
                        {...register("firstName", {
                          onChange: (e) => handleKeyPress(e),
                          required: {
                            value: true,
                            message: `${t("FIRSTNAME_REQUIRED")}`,
                          },
                          minLength: {
                            value: 2,
                            message: `${t("FIRSTNAME_MINLENGTH")}`,
                          },
                          maxLength: {
                            value: 50,
                            message: `${t("FIRSTNAME_MAXLENGTH")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      {errors.firstName && (
                        <span className="errorMsg">
                          {errors.firstName.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6} className="colSpace">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("LAST_NAME")}</Form.Label>
                      <Form.Control
                        placeholder={`${t("LASTNAME_TEXT")}`}
                        type="text"
                        {...register("lastName", {
                          onChange: (e) => handleKeyPress(e),
                          required: {
                            value: true,
                            message: `${t("LASTNAME_REQUIRED")}`,
                          },
                          minLength: {
                            value: 2,
                            message: `${t("LASTNAME_MINLENGTH")}`,
                          },
                          maxLength: {
                            value: 50,
                            message: `${t("LASTNAME_MAXLENGTH")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      {errors.lastName && (
                        <span className="errorMsg">
                          {errors.lastName.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className="colSpace">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("EMAIL")}</Form.Label>
                      <Form.Control
                        disabled={socialUserData.email}
                        placeholder={`${t("EMAIL_TEXT")}`}
                        type="text"
                        {...register("email", {
                          onChange: (e) => onInputChange(e),
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: `${t("EMAIL_INVALID")}`,
                          },
                          minLength: {
                            value: 5,
                            message: `${t("EMAIL_MINLENGTH")}`,
                          },
                          maxLength: {
                            value: 100,
                            message: `${t("EMAIL_MAXLENGTH")}`,
                          },
                          ...sanitizeHtmlTags(),
                          required: {
                            value: isSocial == 1 ? true : false,
                            message: `${t("EMAIL_REQUIRED")}`,
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="errorMsg">{errors.email.message}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="colSpace">
                    <Form.Group className="mb-3 phoneInputSet">
                      <Form.Label>{t("CONTACT")}</Form.Label>
                      <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field }) => (
                          <PhoneInput
                            placeholder={`${t("CONTACT_TEXT")}`}
                            country={"gb"}
                            countryCodeEditable={false}
                            value={dialCode.toString() + phoneNo.toString()}
                            enableLongNumbers={true}
                            onChange={(value, country) => {
                              let dialCode = country.dialCode;
                              let phone = value.slice(
                                dialCode.length,
                                value.length
                              );
                              setCountryCode(country.countryCode);
                              setDialCode(dialCode);
                              setPhoneNo(phone);
                              field.onChange(value); // Update the form value
                              clearErrors("phoneNumber");
                            }}
                          />
                        )}
                        // rules={
                        //   isSocial == 1 ? { validate: validateNumber } : ""
                        // }
                        rules={{ validate: validateNumber }}
                      />
                      {errors.phoneNumber && (
                        <span className="errorMsg">
                          {errors.phoneNumber.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className="colSpace">
                    <Form.Group className="mb-3 setIconPassword">
                      {socialUserData && socialUserData.id ? (
                        ""
                      ) : (
                        <>
                          <Form.Label>{t("PASSWORD")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("PASSWORD_TEXT")}`}
                            type={passwordShow ? "text" : "password"}
                            autoComplete="new-password"
                            {...register("password", {
                              onChange: (e) => {
                                onInputChange(e);
                              },
                              required: {
                                value: true,
                                message: `${t("PASSWORD_REQUIRED")}`,
                              },
                              minLength: {
                                value: 6,
                                message: `${t("PASS_MINLENGTH")}`,
                              },
                              maxLength: {
                                value: 12,
                                message: `${t("PASS_MAXLENGTH")}`,
                              },
                              pattern: {
                                value:
                                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                                message: `${t("INVALID_PASSWORD")}`,
                              },
                              ...sanitizeHtmlTags(),
                            })}
                          />
                          {socialUserData && socialUserData.id ? (
                            ""
                          ) : (
                            <>
                              {errors.password && (
                                <span className="errorMsg">
                                  {errors.password.message}
                                </span>
                              )}
                            </>
                          )}

                          <div className="passwordicon">
                            {passwordShow ? (
                              <FaEye
                                className="icon"
                                onClick={() => setPasswordShow(!passwordShow)}
                              />
                            ) : (
                              <FaEyeSlash
                                className="icon"
                                onClick={() => setPasswordShow(!passwordShow)}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6} className="colSpace">
                    <Form.Group className="mb-3 setIconPassword">
                      {socialUserData && socialUserData.id ? (
                        ""
                      ) : (
                        <>
                          <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                          <Form.Control
                            placeholder={`${t("CONFIRM_PASS_TEXT")}`}
                            type={confirmPassShow ? "text" : "password"}
                            autoComplete="new-password"
                            {...register("confirmPassword", {
                              onChange: (e) => {
                                onInputChange(e);
                              },
                              ...sanitizeHtmlTags(),
                              required: {
                                value: true,
                                message: `${t("CONFIRM_PASS_REQUIRED")}`,
                              },
                              minLength: {
                                value: 6,
                                message: `${t("CONFIRM_PASS_MINLENGTH")}`,
                              },
                              maxLength: {
                                value: 12,
                                message: `${t("CONFIRM_PASS_MAXLENGTH")}`,
                              },
                              pattern: {
                                value:
                                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                                message: `${t("INVALID_PASSWORD")}`,
                              },
                              validate: (value) =>
                                value === password.current ||
                                `${t("NOT_MATCH")}`,
                            })}
                          />
                          {socialUserData && socialUserData.id ? (
                            ""
                          ) : (
                            <>
                              {errors.confirmPassword && (
                                <span className="errorMsg">
                                  {errors.confirmPassword.message}
                                </span>
                              )}
                            </>
                          )}

                          <div className="passwordicon">
                            {confirmPassShow ? (
                              <FaEye
                                className="icon"
                                onClick={() =>
                                  setConfirmPassShow(!confirmPassShow)
                                }
                              />
                            ) : (
                              <FaEyeSlash
                                className="icon"
                                onClick={() =>
                                  setConfirmPassShow(!confirmPassShow)
                                }
                              />
                            )}
                          </div>
                        </>
                      )}

                      <div className={styles.loginBtn}>
                        <button onClick={handleSubmit(onSubmit)} type="submit">
                          {t("SIGNUP_TEXT")}
                        </button>
                      </div>

                      <div className="socialbutton">
                        {/* <FacebookSocialLogin /> */}
                        <GoogleSocialLogin />
                      </div>

                      <p className={styles.bottomText}>
                        {t("ALREADY_ACCOUNT")}{" "}
                        <NavLink to="/login">{t("SIGNIN")}</NavLink>
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          {/* </Container> */}
        </div>
      </div>
    </div>
  );
}
export default SignUp;
