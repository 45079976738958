import Modal from "react-bootstrap/Modal";
import "../../../assets/Styles/DriverCommon.css";
import { LuAlertTriangle } from "react-icons/lu";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//---------function for vehicle type popup modal---------
function DocumentAlert({
  setShowAlertModal,
  showAlertModal,
  onImageRemove,
  title,
  id,
  isDelete,
}) {
  const { t } = useTranslation();

  // ----------- function for manage checkbox----------------
  const handleAlertDoc = (id) => {
    onImageRemove(id);
    setShowAlertModal(false);
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={showAlertModal}
        onHide={() => {
          setShowAlertModal(false);
        }}
        className="colorModal"
        backdrop="static"
      >
        <div className="alertbox"></div>
        <Modal.Body>
          <Container>
            <div className="alertIcon">
              <LuAlertTriangle />
            </div>
            <div className="alertIconMsg">
              <h4>{t("ALERT")}</h4>
              {isDelete == true ? (
                <p>
                  {t("DELETE_ALERT")} {title}
                  {"?"}
                </p>
              ) : (
                <p>
                  {t("REMOVE_ALERT")} {title}
                  {"?"}
                </p>
              )}
            </div>
            <div className="alertButton">
              <button
                type="button"
                variant="light"
                className="alertRightButton"
                onClick={() => {
                  setShowAlertModal(false);
                }}
              >
                <span
                  style={{
                    backgroundImage:
                      "linear-gradient(297.65deg, #2FC2FE 14.83%, #0094F6 83.99%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    borderRadius: "3px",
                  }}
                >
                  {t("CANCEL")}
                </span>
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleAlertDoc(id);
                }}
                className="alertLeftButton"
              >
                {t("YES")}
              </button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DocumentAlert;
