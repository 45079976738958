import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

function SelectDate({
  selectDate,
  setSelectDate,
  selectTime,
  setIsDayToday,
  setShowTimeError,
}) {
  const [currentValue, setCurrentValue] = useState(undefined);

  const currentTime = dayjs();

  // Add 1 hour to the current time
  const oneHourLater = currentTime.add(1, "hour");

  // Assuming selectedTime is the time selected by the user
  const selectedTime = dayjs(selectTime); // Replace with the actual selected time

  // Check if the selected time is after 1 hour from the current hour
  const isAfterOneHour = selectedTime.isAfter(oneHourLater);

  useEffect(() => {
    setCurrentValue(selectDate.val);
  }, [selectDate]);

  const isToday2 = (date_) => {
    const date = dayjs(selectDate.val);
    // Get the current date
    const currentDate = dayjs();
    // Check if the date is today
    const isToday = date_.isSame(currentDate, "day");
    return isToday; // Compares only the date portion
  };

  const handleSubmit = () => {
    setSelectDate((prevState) => ({
      ...prevState,
      show: false,
      val: currentValue,
    }));
    setIsDayToday(isToday2(currentValue));
    setShowTimeError(isToday2(currentValue));
  };

  const onClose = () => {
    setSelectDate((pre) => ({ ...pre, show: false }));
  };

  return (
    <>
      <Modal
        show={selectDate.show}
        onHide={() => {
          onClose();
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>Select date</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                defaultValue={selectDate.val}
                onChange={(newValue) => {
                  setCurrentValue(newValue);
                }}
                views={["day"]}
                minDate={dayjs()}
              />
            </LocalizationProvider>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              Done
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default SelectDate;
