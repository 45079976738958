import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "../../assets/Styles/Common.css";
import styles from "../../appComponents/Profile/Profile.module.css";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import { userLogoutClear } from "../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import Toster from "../../utils/Toaster";
import { SETTING_OPTION, SETTING_OPTION_TYPE } from "../../utils/Constants";
import chatIcon from "../../assets/Images/chatIcon.png";
import image from "../../assets/Images/Rectangle.png";
import workImage1 from "../../assets/Images/workImg1.png";
import workImage2 from "../../assets/Images/workImg2.png";
import workImage3 from "../../assets/Images/workImg3.png";
import { FiArrowLeft } from "react-icons/fi";
import DOMPurify from "dompurify";

const AboutUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [abousUs, setAbousUs] = useState([]);

  const AbousUsList = async () => {
    setIsLoading(true);
    await SublyApi.getCustomerSettingList(
      userToken,
      SETTING_OPTION_TYPE.PAGE,
      SETTING_OPTION.ABOUT_US
    ).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const dataText = response.data[0]?.option_value || "";
        setAbousUs(dataText);
        setIsLoading(false);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    AbousUsList();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.topHeadAboutUs}>
                <div className={styles.topHeadBackBtn}>
                  <FiArrowLeft onClick={() => navigate(-1)} />
                </div>
                <div>
                  <h2 className={styles.topTitle}>{t("ABOUT_US")}</h2>
                </div>
                <div className={styles.topHeadBackBtn}></div>
              </div>
              <div className={styles.aboutPage}>
                <Container></Container>
                <div className={styles.aboutBackground}>
                  <Container>
                    <div className={styles.aboutBox}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                          <div className={styles.aboutImage}>
                            <img src={image} alt="image" />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                          {/* <div className={styles.aboutDiscription}>
                            <strong>{abousUs}</strong>
                          </div> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(abousUs),
                            }}
                          ></div>
                        </Col>
                        <Container>
                          <h5>
                            {t("HOW")} <strong>{t("WORKS")}</strong>
                          </h5>
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4}>
                              <div className={styles.worksImg}>
                                <img src={workImage1} alt="works-image" />
                                <h6>
                                  <span>Schedule</span>&nbsp;for a easy pickup
                                </h6>
                                <p></p>
                                {/* <p>
                                  Lorem Ipsum is simply dummy text and Printing
                                  typesetting industry.{" "}
                                </p> */}
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                              <div className={styles.worksImg}>
                                <img src={workImage2} alt="works-image" />
                                <h6>
                                  We arrive at your doorstep&nbsp;
                                  <span>doorstep</span>
                                </h6>
                                <p></p>
                                {/* <p>
                                  Lorem Ipsum is simply dummy text and Printing
                                  typesetting industry.{" "}
                                </p> */}
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                              <div className={styles.worksImg}>
                                <img src={workImage3} alt="works-image" />
                                <h6>
                                  Deliver on&nbsp;<span>Time</span>
                                </h6>
                                <p></p>
                                {/* <p>
                                  Lorem Ipsum is simply dummy text and Printing
                                  typesetting industry.{" "}
                                </p> */}
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                    </div>
                  </Container>
                </div>
                {/* <div className={styles.chatFeature}>
                  <img src={chatIcon} alt="chat-image" />
                </div> */}
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
