import React, { useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "./Filter.module.css";
import { Rating } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sanitizeHtmlTags } from "../../CreateJob/Furnitures/Tabs/Validations";
import { useForm } from "react-hook-form";
import Toster from "../../../utils/Toaster";
import SublyApi from "../../../helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Loader from "../../../utils/Loader/Loader";

const Review = ({
  isReviewing,
  setIsReviewing,
  jobId,
  getJobDetails,
  setIsDoneReview,
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { userToken } = useSelector((state) => state.user);
  const [ratingUpdate, setRatingUpdate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // disable space on key press input email field
  const onInputChange = (e) => {
    if (e.target.value == " ") {
      setValue(e.target.name, e.target.value.trim());
    } else {
      setValue(e.target.name, e.target.value.replace(/ \s/g, "")); // this remove from all string
    }
  };

  const handleRatingChange = (newValue) => {
    setRatingUpdate(newValue);
  };

  const handleClose = () => {
    reset();
    setRatingUpdate();
    setIsReviewing((pre) => ({ ...pre, show: false }));
  };

  const onFinalSubmit = (formData) => {
    if (!ratingUpdate) {
      Toster(t("PLEASE_SELECT_RATING"), "error");
      return;
    }

    setIsLoading(true);
    var postData = new FormData();

    postData.append("job_id", jobId);
    postData.append("rating", ratingUpdate);
    postData.append("review", formData.description);
    const res = SublyApi.giveReviewRating(userToken, postData);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("REVIEW_SUBMITTED"), "success");
        setIsDoneReview(true);
        getJobDetails();
        handleClose();
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Modal show={isReviewing.show} onHide={() => handleClose()}>
        {isLoading && <Loader />}
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("REVIEW")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onFinalSubmit)}>
              <Row>
                <div className={styles.ratingSection}>
                  <Rating
                    name="reviewRating"
                    onChange={(e, newValue) => {
                      handleRatingChange(newValue);
                    }}
                    value={ratingUpdate}
                    precision={1}
                  />
                </div>
              </Row>
              <Row className={styles.descriptionSection}>
                <Form.Group className="formSection">
                  <Form.Control
                    placeholder={`${t("ENTER_DISCRIPTION")}`}
                    {...register("description", {
                      onChange: (e) => onInputChange(e),
                      required: t("ENTER_DISCRIPTION"),
                      minLength: {
                        value: 3,
                        message: t("MIN_3_CHAR_REQUIRED"),
                      },
                      maxLength: {
                        value: 1000,
                        message: t("MAX_1000_CHAR_ALLOWED"),
                      },
                      ...sanitizeHtmlTags(),
                    })}
                    isInvalid={!!errors.description}
                    as="textarea"
                    rows={5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(errors.description?.message)}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className={styles.sumbmitBtnSection}>
                <Button className={styles.applyBtn} type="submit">
                  {t("SUBMIT")}
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default Review;
