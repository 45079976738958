import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../../appComponents/DriverProfile/DriverProfile.module.css";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import SettingSidebar from "../Settings/SettingSidebar";
import ProfileUpload from "../Settings/ProfileUpload";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
import SublyApi from "../../../helpers/Api";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

//---------function for set radius---------
function RadiusSet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [radius, setRadius] = useState(30); // Initial radius value

  // function for change radius
  const handleRadiusChange = (value) => {
    setRadius(value);
  };

  // If radius is null or empty, set it to 0
  useEffect(() => {
    if (!radius) {
      setRadius(0);
    }
  }, [radius]);

  //--------function for get radius--------
  async function getRadiusValue() {
    setLoader(true);
    await SublyApi.getRadius(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        setRadius(
          response && response.data.radius !== null
            ? response.data.radius
            : radius
        );
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  useEffect(() => {
    getRadiusValue();
  }, []);

  //----------function for update radius----------
  const onSubmit = async (formData) => {
    let requestData = new FormData();
    requestData.append("radius", radius);
    setLoader(true);
    await SublyApi.setRadius(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("SET_RADIUS"), "success");
        setLoader(false);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <Container>
          <div className="spaceTopManageDriver">
            <section className="midSection">
              <div className={styles.profileSection}>
                <h2>{t("PROFILE")}</h2>
                <p></p>
                {/* <p>Lorem Ipsum is simply dummy</p> */}
              </div>

              <div className={styles.profileBox}>
                <Row className={styles.drProfileSectionRow}>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.profileSettingsSection}>
                      <h3 className={styles.profileHeading}>
                        {t("SERVICE")} <span>{t("AREA")}</span>
                      </h3>
                      <p className={styles.profileSubText}>
                        {t("SERVICE_AREA_SUB_TEXT")}
                      </p>
                      <div className={styles.radiusSection}>
                        {/* <h1>What is Lorem Ipsum</h1>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum.
                        </p> */}
                        <h3>{t("RADIUS")}</h3>
                      </div>

                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="formTopSpaceCls"
                      >
                        <div className="SliderSet">
                          <Slider
                            min={1}
                            max={300}
                            value={radius}
                            onChange={handleRadiusChange}
                          />
                        </div>
                        <div className={styles.radiusInput}>
                          <Form.Control
                            placeholder="150 Miles"
                            type="text"
                            value={radius + " Miles"}
                            readOnly
                          />
                        </div>
                        <div
                          className={`${styles.updateBtnSpace} commonButton`}
                        >
                          <button type="submit">{t("UPDATE_RADIUS")}</button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <ProfileUpload />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </div>
    </>
  );
}
export default RadiusSet;
