import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "../Module/Support.module.css";

const SelectStatusType = ({
  showStatusType,
  CloseHandleStatusType,
  setCurrentStatusValue,
  currentStatusValue,
  SupportListApi,
  options,
  setLastOptionID,
}) => {
  const { t } = useTranslation();
  const [tempValue, setTempValue] = useState(currentStatusValue);

  const handleCheckboxChange = (id, value) => {
    setTempValue({ val: value, id: id });
  };

  const handleDoneClick = () => {
    SupportListApi(tempValue.id, "fourth");
    setCurrentStatusValue(tempValue);
    setLastOptionID(tempValue.id);
    CloseHandleStatusType();
  };
  const onClose = () => {
    CloseHandleStatusType();
  };

  return (
    <>
      <Modal show={showStatusType} onHide={() => onClose()}>
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("SELECT_STATUS_TYPE")}</Modal.Title>
            <IoMdCloseCircle onClick={() => onClose()} />
          </Modal.Header>

          <Modal.Body>
            {options.third &&
              options.third.map((item, index) => {
                return (
                  <div
                    className="floorCheckboxes"
                    key={index}
                    onClick={(e) => {
                      handleCheckboxChange(item.id, item.name);
                    }}
                  >
                    <label
                      className={
                        tempValue && tempValue.val === item.name
                          ? styles.activeFloor
                          : ""
                      }
                    >
                      {item.name}
                    </label>
                    {/* <input
                      type="radio"
                      checked={tempValue && tempValue.val === item.name}
                    /> */}
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <div className="commonButton" style={{ width: "100%" }}>
              <button type="submit" onClick={handleDoneClick}>
                {t("DONE")}
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default SelectStatusType;
